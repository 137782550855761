import 'froala-editor/css/froala_editor.pkgd.min.css';
import 'froala-editor/js/plugins/align.min.js';
import 'froala-editor/js/plugins/colors.min.js';
import 'froala-editor/js/plugins/file.min.js';
import 'froala-editor/js/plugins/files_manager.min.js';
import 'froala-editor/js/plugins/font_family.min.js';
import 'froala-editor/js/plugins/font_size.min.js';
import 'froala-editor/js/plugins/image.min.js';
import 'froala-editor/js/plugins/link.min.js';
import 'froala-editor/js/plugins/code_view.min.js';
import 'froala-editor/js/plugins/code_beautifier.min.js';
import 'froala-editor/js/plugins/table.min.js';
import 'froala-editor/js/plugins/lists.min.js';
import 'froala-editor/js/plugins/help.min.js';
import 'froala-editor/js/plugins/emoticons.min.js';
import 'froala-editor/js/plugins/char_counter.min.js';
import 'froala-editor/js/plugins/quick_insert.min.js';
import React, { useEffect, useState } from 'react';
import FroalaEditor from 'react-froala-wysiwyg';
import FroalaEditorView from 'react-froala-wysiwyg/FroalaEditorView';
import { api } from './axios';

export const Editor = (props: any) => {
  const [signature, setSignature] = useState<any>();
  useEffect(() => {
    if (signature) return;
    api.get('/froala/signature').then(({ data }) => setSignature(data));
  }, [signature]);

  const config = Object.assign(
    {
      attribution: false,
      filesManagerAllowedTypes: ['*'],
      fileAllowedTypes: ['*'],
      imageEditButtons: [
        'imageDisplay',
        'imageAlign',
        'imageRemove',
        'imageLink',
        'linkEdit',
      ],
      imageUploadToS3: signature,
      fileUploadToS3: signature,
      fileMaxSize: 1024 * 1024 * 100,
      filesManagerUploadToS3: signature,
      key: process.env.REACT_APP_FROALA_KEY,
      htmlExecuteScripts: true,
      // codeMirror: true,
      codeMirrorOptions: {
        indentWithTabs: true,
        lineNumbers: true,
        lineWrapping: true,
        mode: 'text/html',
        tabMode: 'indent',
        tabSize: 2,
      },
      codeBeautifierOptions: {
        end_with_newline: true,
        indent_inner_html: true,
        extra_liners:
          "['p', 'h1', 'h2', 'h3', 'h4', 'h5', 'h6', 'blockquote', 'pre', 'ul', 'ol', 'table', 'dl']",
        brace_style: 'expand',
        indent_char: '\t',
        indent_size: 1,
        wrap_line_length: 0,
      },
    },
    props.config
  );

  return signature ? (
    <FroalaEditor
      config={config}
      model={props.model}
      onModelChange={props.onModelChange}
    />
  ) : (
    <></>
  );
};

export { FroalaEditorView as EditorView };
