import { PaperClipIcon } from '@heroicons/react/24/outline';
import React, { FC } from 'react';

export interface AttachedFilesProps {
  filesItems: any[];
  ctaText?: string;
  onClickCta?: (item: any) => void;
}

export const AttachedFiles: FC<AttachedFilesProps> = ({
  filesItems,
  ctaText,
  onClickCta,
}) => {
  return (
    <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
      <ul className="divide-y divide-gray-200 rounded-md border border-gray-200">
        {filesItems.map((item, index) => {
          return (
            <li
              key={`attached_${index}`}
              className="flex items-center justify-between py-3 pl-3 pr-4 text-sm"
            >
              <div className="flex w-0 flex-1 items-center">
                {/* <!-- Heroicon name: mini/paper-clip --> */}
                <PaperClipIcon className="h-5 w-5 flex-shrink-0 text-gray-400" />
                <span className="ml-2 w-0 flex-1 truncate">
                  {item.fileName}
                </span>
              </div>
              <div className="ml-4 flex-shrink-0">
                <button
                  onClick={() => onClickCta?.(item)}
                  type="button"
                  className="font-medium text-brand-blue"
                >
                  {ctaText ? ctaText : 'Download'}
                </button>
              </div>
            </li>
          );
        })}
      </ul>
    </dd>
  );
};
