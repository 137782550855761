import React from 'react';
import { Link } from 'react-router-dom';
import HeroImg from '@img/heroimg-signin.png';
import { ReactComponent as TickCircleIcon } from '@svg/icon-tickcircle.svg';

export const SignupPendingPage = () => {
  return (
    <>
      <div
        className=" absolute inset-0 bg-center bg-cover bg-no-repeat grid place-items-center px-4"
        style={{ backgroundImage: `url('${HeroImg}')` }}
      >
        <div className="bg-white rounded-md py-8 px-10 w-full max-w-lg grid place-items-center text-center space-y-6">
          <TickCircleIcon />

          <h1 className="text-30 font-bold ">Confirmation Pending</h1>

          <p className="text-gray-500">
            Thank you for registering with us! <br />
            We will review your profile and send you a confirmation of your
            registration promptly. <br />
            <br />
            In the meanwhile, we kindly ask for your patience.
          </p>

          <Link
            to=""
            className="filled-brand-1 rounded-full w-full py-3 text-center "
          >
            Go back to Home
          </Link>
        </div>
      </div>
    </>
  );
};
