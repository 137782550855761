import { Divider } from '@material-ui/core';
import React, { FC, InputHTMLAttributes, useRef } from 'react';
import { Icon } from './icons';
import { Select } from './Select';

interface SearchProps extends InputHTMLAttributes<HTMLInputElement> {
  onSearch?: () => void;
  onSearchTypeChange?: (value: string) => void;
  className?: string;
  withBorder?: boolean;
}

export const SearchMentoring: FC<SearchProps> = ({
  value,
  className,
  withBorder,
  onSearch = () => {},
  onSearchTypeChange = () => {},
  ...props
}) => {
  const input = useRef<HTMLInputElement>(null);
  return (
    <>
      <form
        className={`relative w-full md:w-auto flex flex-col md:flex-row items-center md:pr-4 md:py-2 rounded-full  md:bg-white pointer-events-auto  md:h-14 ${
          withBorder ? 'border' : ''
        }`}
        onSubmit={(e) => {
          e.preventDefault();
          onSearch();
        }}
      >
        <div className="rounded-full flex items-center bg-white w-full pr-4 md:pr-0 overflow-hidden">
          <input
            ref={input}
            className={` pl-4 flex-1 text-sm placeholder-gray-400 md:min-w-[350px] h-12 md:h-auto ${className}`}
            value={value}
            {...props}
          />
          <div className="flex justify-center items-center -mr-2 wh-10 pointer-cursor-pointer">
            <Icon.Search
              className="wh-5 stroke-current text-gray-400"
              onClick={() => onSearch()}
            />
          </div>
        </div>
        <svg
          viewBox="0 0 384 12"
          fill="none"
          aria-hidden="true"
          className="absolute top-full right-0 w-[384px] max-w-[120%] transition"
        >
          <mask
            id=":r1:-a"
            maskUnits="userSpaceOnUse"
            x="48"
            y="0"
            width="269"
            height="4"
            style={{ maskType: 'alpha' }}
          >
            <path
              transform="rotate(180 316.656 4)"
              fill="#C4C4C4"
              d="M316.656 4h268v4h-268z"
            ></path>
          </mask>
          <g filter="url(#:r1:-b)" mask="url(#:r1:-a)">
            <path
              transform="rotate(180 292.656 1)"
              fill="url(#:r1:-c)"
              d="M292.656 1h220v2h-220z"
            ></path>
          </g>
          <mask
            id=":r1:-d"
            maskUnits="userSpaceOnUse"
            x="116"
            y="0"
            width="268"
            height="12"
            style={{ maskType: 'alpha' }}
          >
            <path
              transform="rotate(180 384 12)"
              fill="#C4C4C4"
              d="M384 12h268v12H384z"
            ></path>
          </mask>
          <g filter="url(#:r1:-e)" mask="url(#:r1:-d)">
            <path
              transform="rotate(180 360 1)"
              fill="url(#:r1:-f)"
              d="M360 1h220v2H360z"
            ></path>
          </g>
          <defs>
            <linearGradient
              id=":r1:-c"
              x1="292.656"
              y1="1"
              x2="512.656"
              y2="1"
              gradientUnits="userSpaceOnUse"
            >
              <stop stop-color="#A78BFA" stop-opacity="0"></stop>
              <stop offset=".323" stop-color="#A78BFA"></stop>
              <stop offset=".672" stop-color="#EC4899" stop-opacity=".3"></stop>
              <stop offset="1" stop-color="#EC4899" stop-opacity="0"></stop>
            </linearGradient>
            <linearGradient
              id=":r1:-f"
              x1="360"
              y1="1"
              x2="580"
              y2="1"
              gradientUnits="userSpaceOnUse"
            >
              <stop stop-color="#A78BFA" stop-opacity="0"></stop>
              <stop offset=".323" stop-color="#A78BFA"></stop>
              <stop offset=".672" stop-color="#EC4899" stop-opacity=".3"></stop>
              <stop offset="1" stop-color="#EC4899" stop-opacity="0"></stop>
            </linearGradient>
            <filter
              id=":r1:-b"
              x="71.656"
              y="-2"
              width="222"
              height="4"
              filterUnits="userSpaceOnUse"
              color-interpolation-filters="sRGB"
            >
              <feFlood flood-opacity="0" result="BackgroundImageFix"></feFlood>
              <feBlend
                in="SourceGraphic"
                in2="BackgroundImageFix"
                result="shape"
              ></feBlend>
              <feGaussianBlur
                stdDeviation=".5"
                result="effect1_foregroundBlur_311_43467"
              ></feGaussianBlur>
            </filter>
            <filter
              id=":r1:-e"
              x="131"
              y="-10"
              width="238"
              height="20"
              filterUnits="userSpaceOnUse"
              color-interpolation-filters="sRGB"
            >
              <feFlood flood-opacity="0" result="BackgroundImageFix"></feFlood>
              <feBlend
                in="SourceGraphic"
                in2="BackgroundImageFix"
                result="shape"
              ></feBlend>
              <feGaussianBlur
                stdDeviation="4.5"
                result="effect1_foregroundBlur_311_43467"
              ></feGaussianBlur>
            </filter>
          </defs>
        </svg>
      </form>
    </>
  );
};
