import { parse } from 'qs';
import { useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import HeroImg from '@img/heroimg-signin.png';
import { ReactComponent as TickCircleIcon } from '@svg/icon-tickcircle.svg';
import { Button } from '@components/Button';
import { useAuth } from '@src/hooks';

export const SignupStudentValidationPage = () => {
  const { push } = useHistory();
  const { search } = useLocation();
  const { code } = parse(search, { ignoreQueryPrefix: true });
  const { verifyEmail } = useAuth();

  useEffect(() => {
    if (code) {
      verifyEmail(code);
    } else {
      push('/');
    }
  }, [code]);

  return (
    <>
      <div
        className=" absolute inset-0 bg-center bg-cover bg-no-repeat grid place-items-center px-4"
        style={{ backgroundImage: `url('${HeroImg}')` }}
      >
        <div className="bg-white rounded-lg py-8 px-10 w-full max-w-lg grid place-items-center text-center space-y-6">
          <TickCircleIcon />

          <h1 className="text-30 font-bold ">Thank you!</h1>

          <p className="text-gray-500">Your account has been verified.</p>

          <Button
            text="Back to Main Page"
            className="filled-brand-1 rounded-full w-full"
            onClick={() => push('/')}
          />
        </div>
      </div>
    </>
  );
};
