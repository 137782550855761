import { ArrowDownTrayIcon, PaperClipIcon } from '@heroicons/react/24/outline';
import { api } from '@src/plugins/axios';
import { JobFile } from '@src/types';
import { getS3Uri } from '@src/utils';
import React, { FC, useRef, useState } from 'react';

export interface DownloadFileItemProps {
  file: JobFile;
  ctaText?: string;
  onClickCta?: (item: any) => void;
}

export const DownloadFileItem: FC<DownloadFileItemProps> = ({
  file,
  ctaText,
  onClickCta,
}) => {
  const onLinkClick = (file: JobFile) => {
    api
      .get(`/s3/presignedUrl?key=${file.fileKey}`)
      .then((response) => {
        console.log(
          '🚀 ~ file: DownloadFileItem.tsx:25 ~ .then ~ response',
          response
        );
        const url = response.data;
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `${file.filename}`);
        document.body.appendChild(link);
        link.click(); // 자동으로 눌러버리기
        setTimeout((_) => {
          window.URL.revokeObjectURL(url); // 해당 url을 더 사용 못하게 날려버린다
        }, 60000);
        link.remove(); // a를 다 사용했으니 지워준다
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <li
      key={`attached_${file.id}`}
      className="flex items-center justify-between py-3 pl-3 pr-4 text-sm "
    >
      <div className="flex w-0 flex-1 items-center">
        {/* <!-- Heroicon name: mini/paper-clip --> */}
        <PaperClipIcon className="wh-4 flex-shrink-0 text-gray-400" />
        <span className="ml-2 w-0 flex-1 truncate">{file.filename}</span>
      </div>
      <div className="ml-4 flex-shrink-0 flex items-center">
        <button
          className="font-medium text-brand-blue"
          onClick={() => onLinkClick(file)}
          aria-label="Download link"
        >
          <ArrowDownTrayIcon className="wh-4 text-brand-blue" />
        </button>
      </div>
    </li>
  );
};
