import { useEffect } from 'react';
// import '../plugins/iframeCORS.css';

export const RefundAndPenaltyFeeTable = () => {
  useEffect(() => {
    // get all iframes that were parsed before this tag
    var iframes: any = document.getElementsByTagName('iframe');
    if (iframes) {
      for (let i = 0; i < iframes.length; i++) {
        var url = iframes[i].getAttribute('src');
        if (url?.startsWith('https://docs.google.com/document/d/')) {
          // create div and replace iframe
          let d = document.createElement('div');
          d.classList.add('embedded-google-doc'); // optional
          iframes[i].parentElement.replaceChild(d, iframes[i]);

          // CORS request
          var xhr = new XMLHttpRequest();
          xhr.open('GET', url, true);
          // eslint-disable-next-line no-loop-func
          xhr.onload = function () {
            // display response
            d.innerHTML = xhr.responseText;
          };
          xhr.send();
        }
      }
    }
  }, []);

  return (
    <div className="py-10 w-full h-full bg-gray-100 text-center overflow-auto">
      <iframe
        style={{
          display: 'block',
          width: '100vw',
          maxWidth: '832px',
          height: '100vh',
          margin: 'auto',
        }}
        frameBorder="0"
        //   height="600px"
        //   width="100%"
        className="rounded-lg"
        title="termsAndConditions"
        src="https://docs.google.com/document/d/e/2PACX-1vS4a_qUAPoYiKqlMwpuqkHeBBIKQoOtPmCZkdvnYZlgVRm0QzF4F_f3EK5bPHX0ZnnHBwvpKd4A2h91/pub?embedded=true"
      ></iframe>
    </div>
  );
};
