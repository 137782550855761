import { AxiosResponse } from 'axios';
import { Paginated } from '../types';
import { api } from './axios';

export function fetcher({ queryKey }: any) {
  return api.get(queryKey[0]).then(({ data }) => data);
}

export function getNextPageParam<T>(
  limit: number,
  last: AxiosResponse<Paginated<T>>,
  pages: AxiosResponse<Paginated<T>>[]
) {
  const currentItems = pages.reduce(
    (acc, page) => acc + page.data.items.length,
    0
  );
  const nextPage = Math.floor(currentItems / limit) + 1;
  if (currentItems >= last.data.total) {
    return undefined;
  }
  return nextPage;
}
