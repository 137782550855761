import { EssayItemType } from '../types';
import logoHarvard from '../assets/img/logo-harvard.jpg';
import logoDartmouth from '../assets/img/logo-dartmouth.jpg';
import logoUCBerkeley from '../assets/img/logo-ucBerkeley.jpg';
import essayPDF01 from '../assets/pdf/FreeEssays/1_Harvard Common App Essay.pdf';
import essayPDF02 from '../assets/pdf/FreeEssays/2. Harvard Supplement.pdf';
import essayPDF03 from '../assets/pdf/FreeEssays/3. Dartmouth Common App Essay.pdf';
import essayPDF04 from '../assets/pdf/FreeEssays/4. UC Personal.pdf';

export const FREE_ESSAYS_ITEMS: EssayItemType[] = [
  {
    id: 'Harvard-Common-App',
    school: {
      name: 'Harvard University',
      logo: logoHarvard,
    },
    title: 'Architectural Studies',
    subtitle: 'Common App Essay',
    pdf: essayPDF01,
    locked: false,
  },
  {
    id: 'Harvard-Supplemental-Essay',
    school: {
      name: 'Harvard University',
      logo: logoHarvard,
    },
    title: 'Architectural Studies',
    subtitle: 'Supplemental Essay',
    pdf: essayPDF02,
    locked: false,
  },
  {
    id: 'Dartmouth-Common-App',
    school: {
      name: 'Dartmouth College',
      logo: logoDartmouth,
    },
    title: 'English / Art History',
    subtitle: 'Common App Essay',
    pdf: essayPDF03,
    locked: false,
  },
  {
    id: 'UCBerkeley-PIQ',
    school: {
      name: 'UC Berkeley',
      logo: logoUCBerkeley,
    },
    title: 'Physics / Operations Research & Management Sciences',
    subtitle: 'Personal Insight Questions',
    pdf: essayPDF04,
    locked: false,
  },
];
