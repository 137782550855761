import {
  CalendarIcon,
  ChevronRightIcon,
  ClockIcon,
  CurrencyDollarIcon,
} from '@heroicons/react/24/outline';
import { fetcher } from '@src/plugins/react-query';
import { getS3Uri } from '@src/utils';
import React, { FC, useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { Link } from 'react-router-dom';
import { api } from '../plugins/axios';
import { MomentFormat, utcToLocalFormat } from '../plugins/moment';
import * as Types from '../types';
import { Avatar } from './Avatar';
import { InfoItem } from './InfoItem';

interface TransactionItemProps {
  payment: Types.Payment;
  index: number;
}

export const TransactionItem: FC<TransactionItemProps> = ({
  payment,
  index,
}) => {
  const { data: sessionItem } = useQuery<Types.SessionType>(
    `/lessons/detail/${payment.lessonId}`,
    fetcher,
    {
      keepPreviousData: true,
      enabled: !!payment.lessonId,
      refetchOnWindowFocus: false,
    }
  );

  const { data: mentor } = useQuery<Types.User[]>(
    `/users/mentors/${sessionItem?.mentorId}`,
    fetcher,
    {
      keepPreviousData: true,
      enabled: !!sessionItem?.mentorId,
      refetchOnWindowFocus: false,
    }
  );

  if (!mentor) return null;

  return (
    <>
      <li key={payment.id}>
        <div className="block hover:bg-gray-50 w-full">
          <div className="flex items-center px-4 py-4 sm:px-6 w-full justify-between">
            <div className="min-w-0 flex-1 sm:flex sm:items-center sm:justify-between">
              <div className="truncate">
                <div className="flex text-sm flex-wrap">
                  <div className="flex items-center gap-2">
                    <span className="inline-flex items-center rounded-full bg-green-100 px-2.5 py-0.5 text-xs font-medium text-green-800">
                      {payment.status === Types.PaymentStatus.SUCCESS
                        ? 'PAID'
                        : payment.status === Types.PaymentStatus.CANCEL &&
                          'REFUNDED'}
                    </span>
                    <Link
                      to={`/my/dashboard/session/${sessionItem?.id}`}
                      className="truncate font-medium text-indigo-600"
                    >
                      {sessionItem?.title}
                    </Link>
                  </div>
                  <div className="hidden mt-2 xl:flex items-start space-x-3 xl:mt-0 xl:ml-3 xl:border-l xl:border-gray-400 xl:border-opacity-50 xl:pl-3 text-gray-500">
                    <dt className="flex items-center text-sm gap-1 ">
                      <CurrencyDollarIcon className="wh-4" aria-hidden="true" />
                      <dd className="">
                        {(Number(payment.amount) / 100).toFixed(2)}
                      </dd>
                    </dt>
                  </div>
                </div>
                <div className="mt-2 flex gap-2">
                  <div className="flex items-center text-sm text-gray-500">
                    <CalendarIcon
                      className="mr-1.5 wh-4 flex-shrink-0 text-gray-400"
                      aria-hidden="true"
                    />
                    <p>
                      <time
                        dateTime={utcToLocalFormat(
                          payment.createdAt,
                          MomentFormat.YYYYMMDD
                        )}
                      >
                        {utcToLocalFormat(
                          payment.createdAt,
                          MomentFormat.YYYYMMDD
                        )}
                      </time>
                    </p>
                  </div>
                  <div className="flex items-center text-sm text-gray-500 gap-1.5">
                    <ClockIcon
                      className="wh-4 flex-shrink-0 text-gray-400"
                      aria-hidden="true"
                    />
                    <p>
                      <time
                        dateTime={utcToLocalFormat(
                          payment.createdAt,
                          MomentFormat.HHmm
                        )}
                      >
                        {utcToLocalFormat(payment.createdAt, MomentFormat.HHmm)}
                      </time>
                    </p>
                  </div>
                </div>
                <div className="mt-2 xl:hidden flex gap-2">
                  <div className="flex items-start space-x-3 text-gray-500">
                    <dt className="flex items-center text-sm gap-1 ">
                      <CurrencyDollarIcon
                        className="wh-4  text-indigo-600"
                        aria-hidden="true"
                      />
                      <dd className="text-indigo-600">
                        {(Number(payment.amount) / 100).toFixed(2)}
                      </dd>
                    </dt>
                  </div>
                </div>
              </div>
              <div className="mt-4 flex-shrink-0 sm:mt-0 sm:ml-5">
                <div className="flex overflow-hidden items-center gap-3 ">
                  <div>
                    <p className="text-sm text-gray-500">
                      {sessionItem?.mentor.firstName &&
                      sessionItem?.mentor.lastName
                        ? sessionItem?.mentor.firstName +
                          ' ' +
                          sessionItem?.mentor.lastName
                        : ''}
                    </p>
                  </div>
                  <Avatar
                    src={getS3Uri(mentor[0]?.avatar)}
                    alt="avatar"
                    className=" inline-block wh-8 rounded-full ring-2 ring-white"
                  />
                </div>
              </div>
            </div>
            {/* <div className="ml-5 flex-shrink-0">
              <ChevronRightIcon
                className="h-5 w-5 text-gray-400"
                aria-hidden="true"
              />
            </div> */}
          </div>
        </div>
      </li>
    </>
  );
};
