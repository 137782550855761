import React, { CSSProperties } from 'react';
import {
  Route,
  Switch,
  Router as Routers,
  useLocation,
} from 'react-router-dom';
// import { Admin } from './admin/Admin';
import { AdminRoute } from './components/AdminRoute';
import { ScrollToTop } from './components/ScrollToTop';
import { Router } from './pages/Router';
import { CookiesProvider } from 'react-cookie';
import loadable from '@loadable/component';
import FadeLoader from 'react-spinners/BeatLoader';
import twConfig from './tailwindResolver';
import * as Sentry from '@sentry/react';
import { createBrowserHistory } from 'history';
import { Helmet } from 'react-helmet-async';

import Zendesk, { ZendeskAPI } from './ZendexConfig';
const ZENDESK_KEY = '8bc2486d-83bf-4402-acf5-5f7e11c2ab68';

const override: CSSProperties = {
  display: 'flex',
  justifyContent: 'center',
  height: '100vh',
  alignItems: 'center',
};

const history = createBrowserHistory();

// Create Custom Sentry Route component
const SentryRoute = Sentry.withSentryRouting(Route);

const Admin = loadable(
  () => import(/* webpackChunkName: "admin-chunk" */ './admin/Admin'),
  {
    fallback: (
      <FadeLoader
        cssOverride={override}
        color={twConfig.theme.colors['brand-1']}
        loading={true}
        size={10}
      />
    ),
  }
);

function App() {
  const handleLoaded = () => {
    // https://developer.zendesk.com/documentation/zendesk-web-widget-sdks/sdks/web/sdk_api_reference/

    if (window.location.pathname.startsWith('/my/chats')) {
      // zendesk 위젯 감추는 코드
      ZendeskAPI('messenger:set', 'zIndex', -9999);
    }
  };

  return (
    <CookiesProvider>
      {/* <BrowserRouter> */}
      <Routers history={history}>
        <ScrollToTop />
        <Helmet>
          <meta charSet="utf-8" />
          <title>Linkstory</title>
          <meta name="viewport" content="width=device-width, initial-scale=1" />
          <meta name="title" content="Linkstory" data-rh="true" />
          <link rel="canonical" href="https://www.linkstory.me" />
          <meta name="robots" content="index, follow" />
          {/* Icons and themes Starts */}
          <link rel="manifest" href="manifest.json" />

          <link rel="apple-touch-icon" href="apple-touch-icon.png" />
          {/* Icons and themes Ends */}
          <meta
            name="description"
            content="Find personalized, affordable college admissions consultants and subject tutors. Schedule an online session anytime, anywhere."
            data-rh="true"
          />
          {/* OpenGraph starts */}
          <meta
            property="og:url"
            content="https://linkstory.me/"
            data-rh="true"
          />
          <meta property="og:type" content="website" data-rh="true" />
          <meta property="og:title" content="Linkstory" data-rh="true" />
          <meta
            property="og:description"
            content="An online platform featuring unique insight from experienced mentors to guide students through the college admissions process."
            data-rh="true"
          />
          <meta
            property="og:image"
            content="https://drive.google.com/uc?id=1qhRj7Gn-uvPTcbRcX51YPTVplb6SqZr2"
            data-rh="true"
          />
          {/* OpenGraph Ends */}
          {/* Twitter starts */}
          <meta name="twitter:title" content="Linkstory" data-rh="true" />
          <meta
            name="twitter:card"
            content="summary_large_image"
            data-rh="true"
          />
          <meta
            property="twitter:domain"
            content="web.stage.linkstory.me"
            data-rh="true"
          />
          <meta
            property="twitter:url"
            content="https://linkstory.me/"
            data-rh="true"
          />

          <meta
            name="twitter:description"
            content="An online platform featuring unique insight from experienced mentors to guide students through the college admissions process."
            data-rh="true"
          />
          <meta
            name="twitter:image"
            content="https://drive.google.com/uc?id=1qhRj7Gn-uvPTcbRcX51YPTVplb6SqZr2"
            data-rh="true"
          />
          {/* Twitter Ends */}
        </Helmet>
        {/* Zendesk Widget Code */}
        <Zendesk defer zendeskKey={ZENDESK_KEY} onLoaded={handleLoaded} />
        <Switch>
          <AdminRoute path="/admin" component={Admin} />
          <SentryRoute path="/" component={Router} />
        </Switch>
      </Routers>
      {/* </BrowserRouter> */}
    </CookiesProvider>
  );
}

export default App;
