import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Button } from './Button';
import { Icon } from './icons';
import { modalDiscountState } from '../plugins/ridge';
import { TextField } from '../components/TextField';
import { useOnClickOutside } from 'usehooks-ts';
// import { useMailChimpForm, useFormFields } from 'use-mailchimp-form';
import { MC_TAG_DOLLAR_A_MONTH_ID } from '../constants/mailchimp';
import { useQuery } from 'react-query';
import { fetcher } from '../plugins/react-query';
import { useAuth } from '../hooks';
import { MailChimpMemberStatus, MailChimpTagsType, User } from '../types';
import { useForm } from 'react-hook-form';
import {
  postMailChimpAddMember,
  patchMailChimpUpdateMember,
} from '../api/mailchimp';

interface FormValues {
  EMAIL: string;
  tags: string;
}

export const ModalDiscount = () => {
  const modal = modalDiscountState.useValue();
  const { authenticated } = useAuth();

  const { data: me } = useQuery<User>('/users/me', fetcher, {
    enabled: !!authenticated,
    retryOnMount: true,
    onSuccess: (me) => {
      let defaultValues = { EMAIL: me?.email, tags: MC_TAG_DOLLAR_A_MONTH_ID };
      reset({ ...defaultValues });
    },
  });

  const {
    register,
    watch,
    reset,
    setError,
    clearErrors,
    handleSubmit: handleUseFormSubmit,
    formState: { errors },
  } = useForm<FormValues>({
    mode: 'onBlur',
    defaultValues: {
      //@ts-ignore
      EMAIL: authenticated ? me?.email : '',
      tags: MC_TAG_DOLLAR_A_MONTH_ID,
    },
  });

  const location = useLocation();
  const refPopup = React.useRef(null);

  const [formIsDirty, setFormIsDirty] = useState<boolean>(false);
  const [success, setSuccess] = useState<boolean>(false);

  useEffect(() => {
    //  if route opens block body scroll
    if (modal.open) {
      document.body.style.overflow = 'hidden';
    } else {
      return () => {
        document.body.style.overflow = 'auto';
      };
    }
  }, [modal.open]);

  useEffect(() => {
    //  if route changes close modal
    onClose();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  const onClose = () => {
    // if modal closed body to be scrollable
    // setFormStatusChanged(true);
    modalDiscountState.reset();
    reset();
  };

  const EmailInput = watch('EMAIL');

  useEffect(() => {
    setFormIsDirty(true);
  }, [EmailInput]);

  useOnClickOutside(refPopup, () => {
    onClose();
  });

  const handlePostMailChimpMember = async (formData: any) => {
    const response = await postMailChimpAddMember(formData);
    // console.log('response', response);
    if (response !== 'success') {
      // setErrorMessage(response);
      setError('EMAIL', { type: 'custom', message: response });
    } else {
      setSuccess(true);
      clearErrors('EMAIL');
    }
  };

  const onSubmit = handleUseFormSubmit((data) => {
    setFormIsDirty(false);
    const formData = {
      email_address: authenticated ? me?.email : data.EMAIL,
      tags: [MailChimpTagsType.DOLLAR_COUPON_MAIL],
      status: MailChimpMemberStatus.SUBSCRIBED,
    };
    if (formData.email_address) {
      authenticated
        ? patchMailChimpUpdateMember(formData)
        : handlePostMailChimpMember(formData);
    }
  });

  if (!modal.open || (authenticated && !me)) return <></>;

  return (
    <div className="z-50 fixed inset-0 bg-littleblack flex items-center justify-center px-4">
      <div
        ref={refPopup}
        className=" w-80 flex flex-col  bg-white rounded-xl overflow-hidden p-6 space-y-2"
      >
        <button className="self-end" onClick={onClose}>
          <Icon.X />
        </button>

        <div className="flex flex-col justify-center items-center text-center space-y-5">
          <h1 className="font-header text-lg md:text-20 font-bold flex-1 whitespace-pre-line">
            {modal.title}
          </h1>
          {/* <!-- Begin Mailchimp Signup Form --> */}
          <form
            onSubmit={handleUseFormSubmit(() => {
              onSubmit();
            })}
            className="flex space-y-4 flex-col w-full"
          >
            <TextField
              id="EMAIL"
              type="email"
              disabled={authenticated && me?.email ? true : false}
              placeholder={'Enter your email'}
              required
              className={` rounded-full opacity-90 h-10 md:h-12  disabled:cursor-not-allowed`}
              helper={errors.EMAIL?.message}
              {...register('EMAIL', {
                required: 'Please enter your email.',
                validate: {
                  email: (value: string) => {
                    const regex =
                      // eslint-disable-next-line no-useless-escape
                      /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
                    if (!regex.test(value)) {
                      return 'Please enter valid email format.';
                    }
                  },
                },
              })}
            />
            <input
              id="tags"
              type="hidden"
              name="tags"
              value={MC_TAG_DOLLAR_A_MONTH_ID}
            />
            <div className="flex flex-col items-center">
              <Button
                disabled={!formIsDirty && success}
                className={`disabled:bg-gray-400 bg-brand-orange text-black items-center rounded-full h-10 md:h-12 px-10 md:px-12 text-sm md:text-md w-full `}
              >
                {!formIsDirty && success ? 'Submitted' : 'Submit'}
              </Button>
            </div>
          </form>
          <p className="text-black !mt-2 text-sm whitespace-pre-line">
            {!success && (
              <p className="text-xs">
                {`By proceeding, you agree to Linkstory's \n`}
                <Link
                  target="_blank"
                  rel="noopener noreferrer"
                  to="/termsandconditions"
                  className="underline font-semibold"
                >
                  Terms & Conditions
                </Link>
                {` and `}
                <Link
                  target="_blank"
                  rel="noopener noreferrer"
                  to="/privacy"
                  className="underline font-semibold"
                >
                  Privacy Policy
                </Link>
              </p>
            )}
            {/* {error && message} */}
            {!formIsDirty && success && (
              <p className="text-green-600">
                Please allow up to two minutes for the discount code to arrive
              </p>
            )}
          </p>
        </div>
      </div>
    </div>
  );
};
