import React, { useEffect, useState } from 'react';
import { Link, useHistory, useParams } from 'react-router-dom';
import { BreadCrumbs } from '@src/components/BreadCrumbs';
import * as Icons from '@heroicons/react/24/outline';
import { useQuery } from 'react-query';
import * as Types from '@src/types';
import { fetcher } from '@src/plugins/react-query';
import moment from 'moment';
import { MomentFormat, utcToLocalFormat } from '@src/plugins/moment';
import { useAuth } from '@src/hooks';
import { DownloadFiles } from '@src/components/DownloadFiles';
import { HiddenHeadingMeta } from '@src/components/HiddenHeadingMeta';
import { ReactHelmet } from '@src/components/ReactHelmet';

export const JobsDetails = () => {
  const { push, goBack } = useHistory();
  const { id } = useParams<{ id: string }>();
  const { authenticated } = useAuth();
  const { data: me } = useQuery<Types.User>('/users/me', fetcher, {
    enabled: !!authenticated,
  });

  const [isNotAllowed, setIsNotAllowed] = useState<boolean | null>(null);

  const { data: item } = useQuery<Types.JobType>(`/v2/jobs/${id}`, fetcher, {
    enabled: !!id,
    keepPreviousData: true,
  });

  const { data: mentorSubjects, isFetching } = useQuery<any[]>(
    `/v2/subject-link?mentorId=${Number(me?.id)}`,
    fetcher,
    {
      enabled: !!me?.id && !!item?.subjectId,
      onSuccess(data) {
        const result = data.find((i) => i.subjectId === item?.subjectId);
        if (!result) {
          setIsNotAllowed(true);
        } else {
          setIsNotAllowed(false);
        }
      },
    }
  );

  const AlreadyAppliedBanner = () => {
    return (
      <div className="m-4 rounded-md bg-green-50 p-4">
        <div className="flex">
          <div className="flex-shrink-0">
            <Icons.CheckCircleIcon
              className="h-5 w-5 text-green-400"
              aria-hidden="true"
            />
          </div>
          <div className="ml-3">
            <h3 className="text-sm font-medium text-green-800">
              This is a job you have already applied for.
            </h3>
          </div>
        </div>
      </div>
    );
  };

  const NotAllowedToApplySection = () => {
    return (
      <div className="my-4 mx-4 rounded-md bg-yellow-50 p-4">
        <div className="flex">
          <div className="flex-shrink-0">
            <Icons.ExclamationTriangleIcon
              className="h-5 w-5 text-yellow-400"
              aria-hidden="true"
            />
          </div>
          <div className="ml-3">
            <h3 className="text-sm font-medium text-yellow-800">
              You're not qualified to apply to this job at this time.
            </h3>
          </div>
        </div>
      </div>
    );
  };

  const contentsSection = () => (
    <>
      <div className="">
        <div className="border-t border-gray-200">
          {/* Not allowed */}
          {isNotAllowed && !isFetching && NotAllowedToApplySection()}
          {/* Not allowed */}

          {/* start Already applied banner */}
          {item && item?.jobSubmits.length > 0 && AlreadyAppliedBanner()}
          {/* end Already applied banner */}
          <dl>
            <div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt className="text-sm font-medium text-gray-500">Student</dt>
              <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                {item?.writer.firstName}
              </dd>
            </div>
            <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt className="text-sm font-medium text-gray-500">
                Availability
              </dt>
              <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0 space-y-3">
                {item?.firstRequestTime && (
                  <dt className="text-sm flex gap-2 items-center">
                    <Icons.CalendarIcon
                      className="wh-4 flex-shrink-0"
                      aria-hidden="true"
                    />
                    <div>
                      {utcToLocalFormat(
                        item?.firstRequestTime,
                        MomentFormat.YYYYMMDDhmma
                      )}
                    </div>

                    <div>{moment.tz(moment.tz.guess()).zoneAbbr()}</div>
                  </dt>
                )}
                {item?.secondRequestTime && (
                  <dt className="text-sm flex gap-2 items-center">
                    <Icons.CalendarIcon
                      className="wh-4 flex-shrink-0"
                      aria-hidden="true"
                    />
                    <div>
                      {utcToLocalFormat(
                        item?.secondRequestTime,
                        MomentFormat.YYYYMMDDhmma
                      )}
                    </div>

                    <div>{moment.tz(moment.tz.guess()).zoneAbbr()}</div>
                  </dt>
                )}
                {item?.thirdRequestTime && (
                  <dt className="text-sm flex gap-2 items-center">
                    <Icons.CalendarIcon
                      className="wh-4 flex-shrink-0"
                      aria-hidden="true"
                    />
                    <div>
                      {utcToLocalFormat(
                        item?.thirdRequestTime,
                        MomentFormat.YYYYMMDDhmma
                      )}
                    </div>

                    <div>{moment.tz(moment.tz.guess()).zoneAbbr()}</div>
                  </dt>
                )}
              </dd>
            </div>
            <div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt className="text-sm font-medium text-gray-500">
                Education level
              </dt>
              <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0 truncate-2-lines">
                {item?.grade.title}
              </dd>
            </div>
            <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt className="text-sm font-medium text-gray-500">Subject</dt>
              <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                {item?.subject.title}
              </dd>
            </div>
            <div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt className="text-sm font-medium text-gray-500">Description</dt>
              <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                {item?.description}
              </dd>
            </div>
            <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt className="text-sm font-medium text-gray-500">
                Tell us more
              </dt>
              <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0 space-y-3">
                {item?.questionAnswers?.map((item) => {
                  return (
                    <div>
                      <h4 className="font-semibold">
                        {` Q. ${item.question.question}`}
                      </h4>
                      <p> {` A. ${item.text}`}</p>
                    </div>
                  );
                })}
              </dd>
            </div>
            {item?.jobFiles && item?.jobFiles?.length > 0 && (
              <div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                <dt className="text-sm font-medium text-gray-500">
                  Attachments
                </dt>
                <DownloadFiles filesItems={item.jobFiles} />
              </div>
            )}

            {isNotAllowed === false &&
              item &&
              item?.jobSubmits.length === 0 && (
                <div className="px-4 py-3 text-right sm:px-6">
                  <button
                    onClick={() =>
                      push(`/my/dashboard/jobs/application/${item?.id}`)
                    }
                    className="inline-flex justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                  >
                    Apply
                  </button>
                </div>
              )}
          </dl>
        </div>
      </div>
    </>
  );

  return (
    <>
      <HiddenHeadingMeta text="Job details | Linkstory" />
      <ReactHelmet keywords="job" title="Job details | Linkstory" />
      <BreadCrumbs
        homePath="/my/dashboard"
        breadCrumbsPages={[
          {
            name: 'Jobs',
            path: '/my/dashboard/jobs',
          },
          {
            name: 'Details',
            path: `/my/dashboard/jobs/${id}`,
          },
        ]}
      />
      <div className="space-y-4 bg-white border w-full rounded-md overflow-hidden">
        <section className="whitespace-pre-line">
          <div className=" border-gray-200 bg-white px-4 py-5 sm:px-6">
            <div className="-ml-4 -mt-2 flex flex-wrap items-center justify-between sm:flex-nowrap gap-2">
              <div className="ml-4 mt-2 flex gap-2 items-center">
                <Icons.BriefcaseIcon className="wh-6 flex-shrink-0 self-center text-xs" />
                <h3 className="text-lg font-medium leading-6 text-gray-900">
                  Job Details
                </h3>
              </div>
            </div>
          </div>
          {item && item.subjectId && contentsSection()}
        </section>
      </div>
    </>
  );
};
