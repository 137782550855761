import * as Ably from 'ably';
import { forEach, isEmpty } from 'lodash';
export class ChatRoomManager {
  static rooms: { [key: string]: any } = {};

  static enterRoom(name: string, ably: Ably.Types.RealtimeChannelCallbacks) {
    this.leaveAllRoom();
    ably.presence.enter();
    this.rooms[name] = ably;
  }

  static leaveRoom(name: string) {
    const room = this.rooms[name];
    if (room) {
      room.presence.leave();
      room.unsubscribe();
      delete this.rooms[name];
    }
  }

  static leaveAllRoom() {
    if (!isEmpty(this.rooms)) {
      forEach(this.rooms, (_, key) => {
        this.leaveRoom(key);
      });
    }
  }
}
