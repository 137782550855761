import { BannerCard } from '@src/components/BannerCard';
import { DashBoardSideBar } from '@src/components/DashBoardSideBar';
import { SessionRateEdit } from '@src/components/SessionRateEdit';
import { useAuth } from '@src/hooks';
import { fetcher } from '@src/plugins/react-query';
import { Role, User } from '@src/types';
import { useQuery } from 'react-query';
import { Route, Switch } from 'react-router-dom';
import { DashboardLessons } from '@src/pages/dashboard/DashboardLessons';
import { InCompleteSessions } from './InCompleteSessions';
import { Jobs } from '../jobs/Jobs';
import { JobsApplication } from '../jobs/JobsApplication';
import { JobsApplied } from '../jobs/JobsApplied';
import { JobsAppliedDetails } from '../jobs/JobsAppliedDetails';
import { JobsDetails } from '../jobs/JobsDetails';
import { MentorDashboardDefault } from './MentorDashboardDefault';
import { MyPage } from '@src/pages/dashboard/MyPage';
import { MyTransactionHistoryPage } from '@src/pages/dashboard/MyTransactionHistoryPage';
import { PaymentsPage } from '@src/pages/dashboard/PaymentsPage';
import { RecordingsPage } from '@src/pages/dashboard/RecordingsPage';
import { SessionDetails } from '@src/pages/dashboard/SessionDetails';
import { SessionEdit } from '@src/pages/dashboard/SessionEdit';
import { StudentDashboardDefault } from './StudentDashboardDefault';
import { SubmitSessionFormPage } from '@src/pages/dashboard/SubmitSessionFormPage';
import { SubmittedSessionDetails } from '@src/pages/dashboard/SubmittedSessionDetails';

export const Dashboard = () => {
  const { authenticated } = useAuth();
  const { data: me } = useQuery<User>('/users/me', fetcher, {
    enabled: !!authenticated,
  });

  const RoutesItems = [
    // {
    //   path: '/my/dashboard/recordings',
    //   component: RecordingsPage,
    //   authorized: [Role.MENTOR, Role.STUDENT],
    // },
    {
      path: '/my/dashboard/payments',
      component: PaymentsPage,
      authorized: [Role.MENTOR],
    },
    {
      path: '/my/dashboard/sessions/submit/:id',
      component: SubmitSessionFormPage,
      authorized: [Role.MENTOR],
    },
    {
      path: '/my/dashboard/sessions/submit',
      component: InCompleteSessions,
      authorized: [Role.MENTOR],
    },
    {
      path: '/my/dashboard/sessions',
      component: DashboardLessons,
      authorized: [Role.MENTOR, Role.STUDENT],
    },
    {
      path: '/my/dashboard/jobs/application/:id',
      component: JobsApplication,
      authorized: [Role.MENTOR],
    },
    {
      path: '/my/dashboard/jobs/applied/:id',
      component: JobsAppliedDetails,
      authorized: [Role.MENTOR],
    },
    {
      path: '/my/dashboard/jobs/applied',
      component: JobsApplied,
      authorized: [Role.MENTOR],
    },
    {
      path: '/my/dashboard/jobs/:id',
      component: JobsDetails,
      authorized: [Role.MENTOR],
    },
    {
      path: '/my/dashboard/jobs',
      component: Jobs,
      authorized: [Role.MENTOR],
    },
    {
      path: '/my/dashboard/session/edit/:id',
      component: SessionEdit,
      authorized: [Role.MENTOR],
    },
    {
      path: '/my/dashboard/session/rate-edit/:id',
      component: SessionRateEdit,
      authorized: [Role.MENTOR],
    },
    {
      path: '/my/dashboard/session/:id/submitted',
      component: SubmittedSessionDetails,
      authorized: [Role.MENTOR],
    },
    {
      path: '/my/dashboard/session/:id',
      component: SessionDetails,
      authorized: [Role.MENTOR, Role.STUDENT],
    },
    {
      path: '/my/dashboard/transactions',
      component: MyTransactionHistoryPage,
      authorized: [Role.STUDENT],
    },
    {
      path: '/my/dashboard/account',
      component: MyPage,
      authorized: [Role.MENTOR, Role.STUDENT],
    },
    {
      path: '/my/dashboard',
      component: StudentDashboardDefault,
      authorized: [Role.STUDENT],
    },
    {
      path: '/my/dashboard',
      component: MentorDashboardDefault,
      authorized: [Role.MENTOR],
    },
  ];

  if (!me) return <></>;

  return (
    <div className="bg-[#edf2f9]">
      <div className="mx-auto max-w-[1280px]">
        <div className="flex flex-1 flex-row overflow-x-hidden">
          <DashBoardSideBar me={me} />
          <main
            className="flex-1 min-h-screen"
            style={{ width: 'calc(100% - 255px)' }}
          >
            <div className="py-4 md:py-6">
              <div className="mx-auto w-full px-4 md:px-6 space-y-4 md:space-y-6">
                <Switch>
                  {RoutesItems.filter((item) =>
                    item.authorized.includes(me.role)
                  ).map((item) => {
                    return (
                      <Route path={item.path} component={item.component} />
                    );
                  })}
                </Switch>
              </div>
            </div>
          </main>
        </div>
      </div>
    </div>
  );
};
