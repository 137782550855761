import { ChatState, Role, User } from '@src/types';
import React, { FC, useState, Fragment } from 'react';
import { Avatar } from '@src/components/Avatar';
import { NavLink } from 'react-router-dom';
import { getS3Uri } from '@src/utils';
import { Switch } from '@headlessui/react';
import {
  CalendarIcon,
  HomeIcon,
  InformationCircleIcon,
  VideoCameraIcon,
  BriefcaseIcon,
  CreditCardIcon,
  UserCircleIcon,
  BanknotesIcon,
} from '@heroicons/react/24/outline';
import { Sidebar } from './Sidebar';
import ReactTooltip from 'react-tooltip';
import { ToggleSwitch } from './ToggleSwitch';
import { api } from '@src/plugins/axios';

const navigation = [
  {
    name: 'Dashboard',
    path: '/my/dashboard',
    Icon: HomeIcon,
    authorized: [Role.STUDENT, Role.MENTOR],
    exact: true,
  },
  {
    name: 'Account',
    path: '/my/dashboard/account',
    Icon: UserCircleIcon,
    exact: false,
    authorized: [Role.MENTOR, Role.STUDENT],
  },
  {
    name: 'Transactions',
    path: '/my/dashboard/transactions',
    Icon: BanknotesIcon,
    exact: false,
    authorized: [Role.STUDENT],
  },
  {
    name: 'Jobs',
    path: '/my/dashboard/jobs',
    Icon: BriefcaseIcon,
    exact: false,
    authorized: [Role.MENTOR],
  },
  {
    name: 'Payments',
    path: '/my/dashboard/payments',
    Icon: CreditCardIcon,
    exact: false,
    authorized: [Role.MENTOR],
  },
  {
    name: 'Session History',
    path: '/my/dashboard/sessions',
    Icon: CalendarIcon,
    exact: false,
    authorized: [Role.MENTOR, Role.STUDENT],
  },
  // {
  //   name: 'Recordings',
  //   path: '/my/dashboard/recordings',
  //   Icon: VideoCameraIcon,
  //   exact: false,
  //   authorized: [Role.MENTOR, Role.STUDENT],
  // },
];

export interface DashBoardSideBarProps {
  me: User;
}

export const DashBoardSideBar: FC<DashBoardSideBarProps> = ({ me }) => {
  const MentorSideBar = () => {
    return (
      <>
        {/* Static sidebar for desktop */}
        <div className="hidden md:flex md:w-64 md:flex-col">
          {/* Sidebar component, swap this element with another sidebar if you like */}
          <div className="flex min-h-0 flex-1 flex-col border-r border-l border-gray-200 bg-white">
            <div className="flex flex-shrink-0 border-b border-gray-200 p-4 pt-5 pb-4 flex-col gap-3">
              <Switch.Group
                as="div"
                className="flex items-center gap-2 justify-between"
              >
                <button className="group block w-full flex-shrink-0">
                  <div className="flex items-center justify-between">
                    <div className="flex items-center">
                      <Avatar
                        src={getS3Uri(me?.avatar)}
                        alt="avatar"
                        className="!wh-9 object-cover rounded-full"
                      />
                      <div className="ml-3">
                        <p className="text-sm font-medium text-gray-700 group-hover:text-gray-900 text-left">
                          {me?.firstName + ' ' + me?.lastName}
                        </p>
                        <p className="text-xs font-medium text-gray-500 group-hover:text-gray-700 flex items-center gap-1">
                          <span
                            className={`wh-2 rounded-full ${
                              me?.chatState === ChatState.AVAILABLE
                                ? 'bg-green-600'
                                : 'bg-yellow-300'
                            }`}
                          />
                          <span
                            className={` ${
                              me?.chatState === ChatState.AVAILABLE
                                ? 'text-black'
                                : 'text-gray-500'
                            }`}
                          >
                            {me?.chatState === ChatState.AVAILABLE
                              ? 'Available'
                              : 'Unavailable'}
                          </span>
                          <p
                            data-for="availability-tooltip"
                            data-tip={
                              me?.chatState === ChatState.AVAILABLE
                                ? 'Accepting new students'
                                : 'Your profile is hidden from search results.'
                            }
                          >
                            <InformationCircleIcon className="wh-4 text-gray-500" />
                          </p>
                          <ReactTooltip
                            id="availability-tooltip"
                            className="max-w-[80vw]"
                            delayHide={1000}
                            effect="solid"
                            place="bottom"
                          />
                        </p>
                      </div>
                    </div>
                    <ToggleSwitch
                      checked={
                        me?.chatState === ChatState.AVAILABLE ? true : false
                      }
                      onChange={async (checked: boolean) => {
                        await api
                          .patch(`/users/update-mentor-availability`, {
                            availability: checked,
                          })
                          .then(() => {
                            alert('Successfully saved!');
                          });
                      }}
                    />
                  </div>
                </button>
              </Switch.Group>
            </div>
            <div className="flex flex-1 flex-col overflow-y-auto pt-5 pb-4">
              <nav className="flex-1 space-y-1 bg-white px-2">
                <Sidebar className="w-full border-0">
                  <Sidebar.Menu className="p-0">
                    {navigation
                      .filter((item) => item.authorized?.includes(Role.MENTOR))
                      .map((item) => (
                        <Sidebar.Menu.Item
                          to={item.path}
                          Icon={item.Icon}
                          exact={item.exact}
                        >
                          {item.name}
                        </Sidebar.Menu.Item>
                      ))}
                  </Sidebar.Menu>
                </Sidebar>
              </nav>
            </div>
          </div>
        </div>
      </>
    );
  };

  const StudentSideBar = () => {
    return (
      <>
        {/* Static sidebar for desktop */}
        <div className="hidden md:flex md:w-64 md:flex-col">
          {/* <div className="hidden md:fixed md:top-[67.2px] md:bottom-0 md:flex md:w-64 md:flex-col"> */}
          {/* Sidebar component, swap this element with another sidebar if you like */}
          <div className="flex min-h-0 flex-1 flex-col border-r border-gray-200 bg-white">
            <div className="flex flex-shrink-0 border-b border-gray-200 p-4 pt-5 pb-4 flex-col gap-3">
              <button className="group block w-full flex-shrink-0">
                <div className="flex items-center justify-between">
                  <div className="flex items-center">
                    <div>
                      <Avatar
                        src={getS3Uri(me?.avatar)}
                        alt="avatar"
                        className="!wh-9 object-cover rounded-full"
                      />
                    </div>
                    <div className="ml-3">
                      <p className="text-sm font-medium text-gray-700 group-hover:text-gray-900 text-left">
                        {me?.firstName + ' ' + me?.lastName}
                      </p>
                      <p className="text-xs font-medium text-gray-500 group-hover:text-gray-700 flex items-center gap-1">
                        <p className=" text-gray-500">Student</p>
                      </p>
                    </div>
                  </div>
                </div>
              </button>
              <div className="flex space-x-2 text-xs">
                <div>Payment Information</div>
                <NavLink
                  to="/my/dashboard/account/payment-method"
                  className="text-brand-1 font-bold"
                >
                  Edit
                </NavLink>
              </div>
            </div>
            <div className="flex flex-1 flex-col overflow-y-auto pt-5 pb-4">
              <nav className="flex-1 space-y-1 bg-white px-2">
                <Sidebar>
                  <Sidebar.Menu className="p-0">
                    {navigation
                      .filter((item) => item.authorized?.includes(Role.STUDENT))
                      .map((item) => (
                        <Sidebar.Menu.Item
                          to={item.path}
                          Icon={item.Icon}
                          exact={item.exact}
                        >
                          {item.name}
                        </Sidebar.Menu.Item>
                      ))}
                  </Sidebar.Menu>
                </Sidebar>
              </nav>
            </div>
          </div>
        </div>
      </>
    );
  };

  if (!me) return <></>;

  return (
    <>
      {me && me.role === Role.MENTOR
        ? MentorSideBar()
        : Role.STUDENT
        ? StudentSideBar()
        : null}
    </>
  );
};
