import React, { Fragment, useEffect, useState } from 'react';
import { Link, useHistory, useLocation, useParams } from 'react-router-dom';
import { Avatar } from '@components/Avatar';
import { useQuery } from 'react-query';
import {
  Chat,
  ChatState,
  Lesson,
  LessonReview,
  Paginated,
  Role,
  Tag,
  TagLink,
  TagType,
  User,
} from '@src/types';
import { fetcher } from '@plugins/react-query';
import { api } from '@plugins/axios';
import ReactPlayer from 'react-player';
import { EditorView } from '@plugins/froala';
import { useAuth } from '@src/hooks';
import { getS3Uri } from '@src/utils';
import styled from 'styled-components';
import HTMLEllipsis from 'react-lines-ellipsis/lib/html';
import responsiveHOC from 'react-lines-ellipsis/lib/responsiveHOC';
import 'react-tiny-fab/dist/styles.css';
import { useInView } from 'react-intersection-observer';
import { Icon } from '@src/components/icons';
import { StarIcon } from '@heroicons/react/20/solid';
import ReactStars from 'react-rating-stars-component';
import Item from 'antd/lib/list/Item';
import ProgressBar from '@ramonak/react-progress-bar';
import {
  ArrowLeftIcon,
  ChevronLeftIcon,
  CodeBracketIcon,
  EllipsisVerticalIcon,
  FlagIcon,
} from '@heroicons/react/24/outline';
import { Menu, Transition } from '@headlessui/react';
import mixpanel from 'mixpanel-browser';

const ResponsiveEllipsis = responsiveHOC()(HTMLEllipsis);

interface LocationState {
  from: {
    pathname: string;
  };
}

export const MentorPage = () => {
  const { authenticated } = useAuth();
  const location = useLocation<LocationState>();
  const [locationState] = useState<LocationState>(
    location.state || { from: { pathname: '/mentors' } }
  );
  const { push, goBack } = useHistory();
  const { id } = useParams<{ id: string }>();
  const { data: me } = useQuery<User>('/users/me', fetcher);
  const { data: mentor, status } = useQuery<User>(`/users/${id}`, fetcher);
  const [useEllipsis, setUseEllipsis] = useState<boolean>(true);

  const { data: firstSessionLessons } = useQuery<Lesson[]>(
    `/lessons/first-sessions`,
    fetcher,
    {
      enabled: me?.role === Role.STUDENT,
    }
  );

  const isNotFirstSession =
    firstSessionLessons &&
    firstSessionLessons?.length > 0 &&
    me?.firstSessionCompleted;

  const { data: schoolTags } = useQuery<Tag[]>(
    `/tags?type=${TagType.SCHOOL}`,
    fetcher
  );
  const { data: majorTags } = useQuery<Tag[]>(
    `/tags?type=${TagType.MAJOR}`,
    fetcher
  );
  const { data: tagLinks } = useQuery<TagLink[]>(
    `/tag-links/mentor/${id}`,
    fetcher
  );

  const { data: chat } = useQuery<Chat>(`/chats/mentor/${id}`, fetcher, {
    enabled: me?.role === Role.STUDENT,
  });

  const { data: isChatExist } = useQuery<Chat>(
    `/chats/v2/check-exists/${id}`,
    fetcher,
    {
      enabled: !!authenticated,
    }
  );

  const { data: mentorSubjects } = useQuery<any[]>(
    `/v2/subject-link?mentorId=${id}`,
    fetcher,
    {
      enabled: !!id,
    }
  );

  const { data: reivewItems } = useQuery<Paginated<LessonReview>>(
    `/lesson-reviews/mentor/${id}`,
    fetcher,
    {
      enabled: !!id,
    }
  );

  const { data: reviewRatingItems } = useQuery<{ rating: number }[]>(
    `/lesson-reviews/mentor/${id}/ratings`,
    fetcher,
    {
      enabled: !!id,
    }
  );

  const schoolTagId = tagLinks?.find(
    (tagLink: any) => tagLink.tag.type === TagType.SCHOOL
  )?.tagId;

  const majorTagId = tagLinks?.find(
    (tagLink: any) => tagLink.tag.type === TagType.MAJOR
  )?.tagId;

  const school = schoolTags?.find((tag) => tag.id === schoolTagId);
  const major = majorTags?.find((tag) => tag.id === majorTagId);

  useEffect(() => {
    if (mentor) {
      mixpanel.track('Page Viewed', {
        item_id: mentor.id,
        item_name: `${mentor?.firstName} ${mentor?.lastName}`,
        item_category: 'Mentor Details',
      });
      window.dataLayer.push({ ecommerce: null });
      window.dataLayer.push({
        event: 'view_item',
        ecommerce: {
          items: [
            {
              item_id: id,
              item_name: `${mentor?.firstName} ${mentor?.lastName}`,
              item_category: 'mentor',
              item_variant: `${school?.name} ${major?.name}`,
            },
          ],
        },
      });
    }
  }, [mentor]);

  useEffect(() => {
    // 강제로 Froala 에디터로 작성된 Heading 폰트 리사이즈 하는 코드
    //@ts-ignore
    const headingTags: HTMLCollectionOf<HTMLSpanElement>[] =
      document.getElementsByTagName('span');
    for (let i = 0; i < headingTags.length; i++) {
      //@ts-ignore
      const froalaHeading = headingTags[i].style.fontSize == '24px';
      if (froalaHeading) {
        //@ts-ignore
        headingTags[i].classList.add('froala-header');
      }
    }
  });

  const handleEllipsis = (e: React.MouseEvent) => {
    e.preventDefault();
    setUseEllipsis(false);
  };

  useEffect(() => {
    if (status !== 'loading' && !mentor) {
      push('/404');
    }
  }, [status, mentor]);

  if (status === 'loading' || !mentor) return <></>;

  const handleBookAMentor = (me: any) => {
    if (!authenticated) {
      push({
        pathname: '/login',
        state: { from: { pathname: locationState.from.pathname } },
      });
    } else if (me?.role !== Role.STUDENT) {
      alert('Only student accounts are allowed to book a session');
    } else {
      // GTM book a mentor event
      window.dataLayer.push({
        event: 'book_a_mentor_CTA',
      });

      if (isChatExist) {
        chat
          ? push(`/my/chats?id=${chat.id}`)
          : api
              .post('/chats', { mentorId: mentor.id })
              .then(({ data }) => push(`/my/chats?id=${data.id}`))
              .catch(() => alert('Something went wrong'));
      } else {
        push(`/student-request/${id}`);
      }
    }
  };

  const profileCard = () => {
    return (
      <div className="p-4 lg:p-8 shadow-sm boder rounded-lg bg-white overflow-hidden flex flex-col gap-2 lg:gap-4">
        <div className="flex justify-between items-center">
          <Avatar
            src={getS3Uri(mentor.avatar)}
            alt="avatar"
            className="!wh-16 object-cover rounded-full"
          />
          <div className="flex justify-between w-full pl-4 lg:px-6 items-start lg:items-center">
            {/* <div className="flex flex-col lg:flex-row lg:gap-2  items-center text-center">
              <span className="font-bold">13</span>
              <span className="text-xs lg:text-sm whitespace-pre-line lg:whitespace-normal">{`lessons \ncompleted`}</span>
            </div> */}
            {mentor?.totalReviewCount > 0 && (
              <div className="flex gap-2 items-center text-sm">
                <StarIcon className="wh-4 text-brand-orange" />
                <p>{`${mentor?.avgRating ?? 'N/A'} (${
                  mentor?.totalReviewCount ?? 0
                })`}</p>
              </div>
            )}
          </div>
        </div>
        <div className="flex justify-between items-center">
          <div className="flex flex-col lg:flex-row gap-2 lg:gap-4 lg:items-center">
            <h1 className="font-header font-bold text-xl md:text-3xl break-word">
              {mentor.firstName} {mentor.lastName}
            </h1>
            <div
              style={{ width: 'fit-content' }}
              className="text-brand-blue text-xs bg-[#edf2f9] py-1 px-3 rounded-md font-semibold"
            >
              {school?.name}
            </div>
          </div>
        </div>
        <div className="text-[#7783A8] text-xs lg:text-sm">
          <p>{major?.name}</p>
        </div>
        <p
          className={`${
            mentor.introduction ? 'block' : 'hidden'
          } pt-4 pb-4 text-14 leading-[1.5rem] hidden md:block`}
        >
          {useEllipsis ? (
            <div onClick={(e) => handleEllipsis(e)}>
              <ResponsiveEllipsis
                unsafeHTML={mentor.introduction}
                maxLine={'3'}
                ellipsisHTML={`...<button class=" text-[#AAAAAA]  ml-2 px-2 leading-[1.3rem] rounded-full">more</button>`}
                basedOn="letters"
                onReflow={(reflow) => console.log(reflow)}
              />
            </div>
          ) : (
            <div>{mentor.introduction}</div>
          )}
        </p>
      </div>
    );
  };

  const floatingRightSection = () => {
    return (
      <div className="sticky top-20">
        <div className=" lg:max-w-96 bg-white shadow-sm border rounded-lg  space-y-6 overflow-hidden">
          <div className="flex flex-col items-start">
            <div
              className="relative cursor-pointer pt-123"
              style={{ aspectRatio: '16/9' }}
            >
              <StyledPlayerContainer>
                <ReactPlayer
                  url={mentor.introductionVideoUrl}
                  className="react-player"
                  width="100%"
                  height="100%"
                  style={{ position: 'absolute', top: 0, left: 0 }}
                  controls
                />
              </StyledPlayerContainer>
            </div>
            <div className="hidden lg:flex flex-col justify-between items-center space-y-8 py-6 lg:py-8 px-4 lg:px-6 w-full">
              {mentor.adminMemo && (
                <>
                  <h1 className="flex text-left font-bold text-xlgit lg:text-2xl w-full">
                    Highlights
                  </h1>
                  <p
                    className="text-sm md:text-sm  text-left whitespace-pre-line w-full !mt-4"
                    style={{ wordBreak: 'break-word' }}
                  >
                    {mentor.adminMemo}
                  </p>
                </>
              )}
              {/* {process.env.REACT_APP_WEB_ENV !== 'prod' && ( */}
              <div className="flex items-center flex-col gap-3">
                <h2 className="font-bold text-md lg:text-lg">
                  {`${mentor.firstName} can help you with:`}
                </h2>
                <div className="flex justify-center flex-wrap gap-3">
                  {mentorSubjects
                    ?.filter((i) => i.subject.isHidden === false)
                    .map((item) => {
                      return (
                        <span className="bg-[#F2F5FA] py-1 px-3 text-[#64789A] font-semibold text-xs rounded-md">
                          {item.subject.title}
                        </span>
                      );
                    })}
                </div>
              </div>
              {/* )} */}
              {
                // me?.role === Role.STUDENT &&
                mentor.chatState === ChatState.AVAILABLE && (
                  <>
                    <button
                      className="hidden bg-brand-1 rounded-md w-full py-2 font-bold text-white lg:flex items-center gap-2 justify-center"
                      onClick={() => handleBookAMentor(me)}
                    >
                      <Icon.CalendarOutlined />
                      <span>
                        {isChatExist
                          ? `Message ${mentor.firstName}`
                          : 'Request session'}
                      </span>
                    </button>
                    {/* {!isNotFirstSession && (
                      <p className=" text-black text-13 bg-gray-200 rounded-md p-3 w-full">
                        Note: Your first session will be paid by Linkstory
                      </p>
                    )} */}
                  </>
                )
              }
            </div>
          </div>
        </div>
        {process.env.REACT_APP_WEB_ENV !== 'prod' && (
          <div className="hidden lg:flex justify-center mt-4 gap-16">
            <div className="flex flex-col items-center gap-2">
              <Icon.CreditCardOutlined />
              <span className="text-center text-xs text-[#7581A5]">
                No upfront
                <br />
                payments
              </span>
            </div>
            <div className="flex flex-col items-center gap-2">
              <Icon.ClockOutlined />
              <span className="text-center text-xs text-[#7581A5]">
                Only pay for
                <br />
                time used
              </span>
            </div>
          </div>
        )}
      </div>
    );
  };

  function classNames(...classes: string[]) {
    return classes.filter(Boolean).join(' ');
  }

  const ReviewSection = () => {
    return (
      <div className="bg-white rounded-lg shadow-sm p-4 lg:p-8 text-14 leading-normal space-y-6">
        <h3 className="text-xl font-bold">Rating and Reviews</h3>
        {/* Rating 섹션 */}
        <div className="flex gap-2 items-center">
          <ReactStars
            count={5}
            size={20}
            isHalf={true}
            value={Number(mentor.avgRating) ?? 0}
            // emptyIcon={<i className="far fa-star" />}
            // halfIcon={<i className="fa fa-star-half-alt" />}
            // fullIcon={<i className="fa fa-star" />}
            activeColor="#FFB400"
            edit={false}
          />
          <span>{mentor.avgRating}</span>
          <span className="text-gray-500 text-sm">
            {mentor.totalReviewCount ?? 0} ratings
          </span>
        </div>
        {reviewRatingItems && (
          <div className="space-y-2">
            <div className="flex gap-2 items-center">
              <div className="whitespace-nowrap w-[40px]">5 star</div>
              <ProgressBar
                className="w-full"
                completed={(
                  (reviewRatingItems?.filter((item) => item.rating === 5)
                    .length /
                    mentor.totalReviewCount) *
                  100
                ).toFixed(0)}
                bgColor="#FFB400"
                isLabelVisible={false}
              />
              <div className="whitespace-nowrap w-[40px]">
                ({reviewRatingItems?.filter((item) => item.rating === 5).length}
                )
              </div>
            </div>
            <div className="flex gap-2 items-center">
              <div className="whitespace-nowrap w-[40px]">4 star</div>
              <ProgressBar
                className="w-full"
                completed={(
                  (reviewRatingItems?.filter((item) => item.rating === 4)
                    .length /
                    mentor.totalReviewCount) *
                  100
                ).toFixed(0)}
                bgColor="#FFB400"
                isLabelVisible={false}
              />
              <div className="whitespace-nowrap w-[40px]">
                ({reviewRatingItems?.filter((item) => item.rating === 4).length}
                )
              </div>
            </div>
            <div className="flex gap-2 items-center">
              <div className="whitespace-nowrap w-[40px]">3 star</div>
              <ProgressBar
                className="w-full"
                completed={(
                  (reviewRatingItems?.filter((item) => item.rating === 3)
                    .length /
                    mentor.totalReviewCount) *
                  100
                ).toFixed(0)}
                bgColor="#FFB400"
                isLabelVisible={false}
              />
              <div className="whitespace-nowrap w-[40px]">
                ({reviewRatingItems?.filter((item) => item.rating === 3).length}
                )
              </div>
            </div>
            <div className="flex gap-2 items-center">
              <div className="whitespace-nowrap w-[40px]">2 star</div>
              <ProgressBar
                className="w-full"
                completed={(
                  (reviewRatingItems?.filter((item) => item.rating === 2)
                    .length /
                    mentor.totalReviewCount) *
                  100
                ).toFixed(0)}
                bgColor="#FFB400"
                isLabelVisible={false}
              />
              <div className="whitespace-nowrap w-[40px]">
                ({reviewRatingItems?.filter((item) => item.rating === 2).length}
                )
              </div>
            </div>
            <div className="flex gap-2 items-center">
              <div className="whitespace-nowrap  w-[40px]">1 star</div>
              <ProgressBar
                className="w-full"
                completed={(
                  (reviewRatingItems?.filter((item) => item.rating === 1)
                    .length /
                    mentor.totalReviewCount) *
                  100
                ).toFixed(0)}
                bgColor="#FFB400"
                isLabelVisible={false}
              />
              <div className="whitespace-nowrap w-[40px]">
                ({reviewRatingItems?.filter((item) => item.rating === 1).length}
                )
              </div>
            </div>
          </div>
        )}

        {/* 리뷰 세션 */}
        <div className="flex flex-col gap-4 ">
          {reivewItems?.items.map((item) => {
            return (
              <div className="bg-white space-y-2">
                <div className="flex space-x-3">
                  {/* <div className="flex-shrink-0">
                    <img
                      className="h-10 w-10 rounded-full"
                      src="https://images.unsplash.com/photo-1550525811-e5869dd03032?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80"
                      alt=""
                    />
                  </div> */}
                  <div className="min-w-0 flex-1">
                    <div className="text-sm font-medium text-gray-900 break-words">
                      <div className="">{item.title}</div>
                    </div>
                    <div className="text-sm text-gray-500">
                      <div className="">{item.student.firstName}</div>
                    </div>
                  </div>

                  {/* <div className="flex flex-shrink-0 self-center">
                    <Menu as="div" className="relative inline-block text-left">
                      <div>
                        <Menu.Button className="-m-2 flex items-center rounded-full p-2 text-gray-400 hover:text-gray-600">
                          <span className="sr-only">Open options</span>
                          <EllipsisVerticalIcon
                            className="h-5 w-5"
                            aria-hidden="true"
                          />
                        </Menu.Button>
                      </div>

                      <Transition
                        as={Fragment}
                        enter="transition ease-out duration-100"
                        enterFrom="transform opacity-0 scale-95"
                        enterTo="transform opacity-100 scale-100"
                        leave="transition ease-in duration-75"
                        leaveFrom="transform opacity-100 scale-100"
                        leaveTo="transform opacity-0 scale-95"
                      >
                        <Menu.Items className="absolute right-0 z-10 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                          <div className="py-1">
                            <Menu.Item>
                              {({ active }) => (
                                <Link
                                  to="#"
                                  className={classNames(
                                    active
                                      ? 'bg-gray-100 text-gray-900'
                                      : 'text-gray-700',
                                    'flex px-4 py-2 text-sm'
                                  )}
                                >
                                  <StarIcon
                                    className="mr-3 h-5 w-5 text-gray-400"
                                    aria-hidden="true"
                                  />
                                  <span>Add to favorites</span>
                                </Link>
                              )}
                            </Menu.Item>
                            <Menu.Item>
                              {({ active }) => (
                                <Link
                                  to="#"
                                  className={classNames(
                                    active
                                      ? 'bg-gray-100 text-gray-900'
                                      : 'text-gray-700',
                                    'flex px-4 py-2 text-sm'
                                  )}
                                >
                                  <CodeBracketIcon
                                    className="mr-3 h-5 w-5 text-gray-400"
                                    aria-hidden="true"
                                  />
                                  <span>Embed</span>
                                </Link>
                              )}
                            </Menu.Item>
                            <Menu.Item>
                              {({ active }) => (
                                <Link
                                  to="#"
                                  className={classNames(
                                    active
                                      ? 'bg-gray-100 text-gray-900'
                                      : 'text-gray-700',
                                    'flex px-4 py-2 text-sm'
                                  )}
                                >
                                  <FlagIcon
                                    className="mr-3 h-5 w-5 text-gray-400"
                                    aria-hidden="true"
                                  />
                                  <span>Report content</span>
                                </Link>
                              )}
                            </Menu.Item>
                          </div>
                        </Menu.Items>
                      </Transition>
                    </Menu>
                  </div> */}
                </div>
                <div className="opacity-60 sm:pt-0 break-words">
                  <p>{item.description}</p>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    );
  };

  return (
    <>
      <div className="bg-[#F7F9FC] flex-1">
        <div className="max-w-container grid grid-cols-12 gap-4 py-4 lg:!py-8">
          <div className="col-span-12  text-brand-blue">
            <button
              onClick={goBack}
              className="pl-2 pr-4 bg-white py-2 md:w-auto rounded-md text-brand-1 border text-sm font-semibold flex items-center gap-2"
            >
              <ChevronLeftIcon className="wh-4 text-brand-blue" /> Back
            </button>
          </div>
          {/* Left Section */}
          <div className="col-span-12 lg:col-span-8 space-y-4 lg:!mt-4">
            {profileCard()}
            <div className="block lg:hidden">{floatingRightSection()}</div>
            <div className="bg-white rounded-lg shadow-sm p-4 lg:p-8 text-14 leading-normal">
              <StyledFroalaContainer>
                <EditorView model={mentor.description} />
              </StyledFroalaContainer>
            </div>
            {mentor.totalReviewCount > 0 && ReviewSection()}
          </div>
          {/* Right Section */}
          <div className="hidden lg:block col-span-4 !mt-4">
            {floatingRightSection()}
          </div>
        </div>
        <button
          onClick={() => handleBookAMentor(me)}
          className="shadow-xl fixed lg:hidden bottom-0 bg-brand-blue  px-6 py-3 font-bold text-white flex items-center justify-center gap-2 w-full z-10"
        >
          <Icon.CalendarOutlined />
          <span>
            {isChatExist ? `Message ${mentor.firstName}` : 'Request session'}
          </span>
        </button>
      </div>
    </>
  );
};

const StyledPlayerContainer = styled.div`
  iframe {
    width: 100%;
    height: 100%;
  }
`;

const StyledFroalaContainer = styled.div`
  @media (max-width: 768px) {
    .fr-view img.fr-dii.fr-fil {
      display: none;
    }
  }
`;
