import { useState, useEffect, CSSProperties, useRef, Fragment } from 'react';
import { useQuery } from 'react-query';
import { MentorCard } from '@components/MentorCard';
import { fetcher } from '@plugins/react-query';
import { Subject, Tag, TagType } from '@src/types';
import bottomBannerImg from '@assets/img/bottom_banner_img.png';
import styled from 'styled-components';
import { PopupButton } from '@typeform/embed-react';
import { FAQSectionView } from '@components/FAQSectionView';
import FadeLoader from 'react-spinners/BeatLoader';
import twConfig from '@src/tailwindResolver';
import { useInView } from 'react-intersection-observer';
import { MENTORS_FAQS } from '@constants/faqs';
import { useHistory, useLocation } from 'react-router-dom';
import { parse } from 'qs';
import { useAdvisors } from '@src/hooks/advisors';
import { SearchMentoring } from '@src/components/SearchMentoring';
import { Sidetab } from '@typeform/embed-react';
import { ReactHelmet } from '@src/components/ReactHelmet';
import { HiddenHeadingMeta } from '@src/components/HiddenHeadingMeta';
import { ChevronUpIcon, FunnelIcon } from '@heroicons/react/24/outline';
import { useTranslation } from 'react-i18next';
import { Disclosure, Transition } from '@headlessui/react';
import { useMediaQuery } from 'usehooks-ts';
import { NumericFormat } from 'react-number-format';
import mixpanel from 'mixpanel-browser';

const override: CSSProperties = {
  display: 'flex',
};

export const MentorsPage = () => {
  const { t, i18n } = useTranslation('mentorsList');
  const screenMatches = useMediaQuery('(max-width: 768px)');
  const { search } = useLocation();
  const { replace } = useHistory();
  const { searchterm } = parse(search, { ignoreQueryPrefix: true });
  const { ref: lastRef, inView } = useInView({ threshold: 0.3 });
  const { ref: ctaRef, inView: ctaRefInView } = useInView({ threshold: 0.3 });
  const firstRef = useRef<HTMLDivElement>(null);
  const [initialLoading, setInitialLoading] = useState<boolean>(true);
  // const [tagIds, setTagIds] = useState<number[]>([0]);
  // const [allTagIds, setAllTagIds] = useState<number[]>([]);
  const [selectedSubjectIds, setSelectedSubjectIds] = useState<number[]>([0]);
  const [allSubjectIds, setAllSubjectIds] = useState<number[]>([]);
  const [q, setQ] = useState(searchterm?.toString() || '');

  useEffect(() => {
    // 검색 초기값 설정
    if (searchterm) {
      setQ(searchterm.toString());
    }
  }, [searchterm]);

  useEffect(() => {
    mixpanel.track('Page Viewed', {
      item_category: 'Mentor List',
    });
  }, []);

  const { data: subjects } = useQuery<Subject[]>(`/v2/subjects`, fetcher, {
    refetchOnWindowFocus: false,
    onSuccess: (data: any) => {
      const filteredSubjects: any = [];

      for (let index = 0; index < data.length; index++) {
        filteredSubjects.push(data[index].id);
      }
      setAllSubjectIds((prev) => [...prev, ...filteredSubjects]);
    },
  });

  const {
    data,
    isPreviousData,
    isFetchingNextPage,
    hasNextPage,
    fetchNextPage,
  } = useAdvisors({
    page: 1,
    limit: 5,
    q: q,
    sort: {
      id: 'DESC',
    },
    // tagIds: tagIds.length !== 1 ? tagIds.join(',') : allTagIds.join(','),
    subjectIds:
      selectedSubjectIds.length !== 1
        ? selectedSubjectIds.join(',')
        : allSubjectIds.join(','),
  });

  useEffect(() => {
    if (data && inView && hasNextPage) {
      fetchNextPage();
    }
  }, [data, inView]);

  // Filters settings
  const handleTagsChange = (subjectId: number) => {
    if (selectedSubjectIds.includes(subjectId)) {
      setSelectedSubjectIds((prev) => prev.filter((id) => id !== subjectId));
    } else {
      setSelectedSubjectIds((prev) => [...prev, subjectId]);
    }
  };

  const handleCheckTags = (e: any, tagId: number) => {
    handleTagsChange(tagId);
  };

  // useEffect(() => {
  //   if (isPreviousData) {
  //     setInitialLoading(false);
  //   }
  // }, [isPreviousData]);

  const filterPanel2 = () => {
    return (
      <>
        {/* Filters */}
        <Disclosure
          as="section"
          aria-labelledby="filter-heading"
          className="grid items-center"
          defaultOpen={screenMatches ? false : true}
        >
          {({ open }) => (
            <>
              <h2 id="filter-heading" className="sr-only">
                Filters
              </h2>
              <div className="relative col-start-1 row-start-1 py-4 space-y-4">
                <div className="mx-auto items-center flex max-w-7xl space-x-6 divide-x divide-gray-200 text-sm">
                  <div>
                    <Disclosure.Button className="group flex items-center font-medium text-gray-700">
                      <FunnelIcon
                        className="mr-2 h-5 w-5 flex-none text-gray-400 group-hover:text-gray-500"
                        aria-hidden="true"
                      />
                      {selectedSubjectIds.length - 1 !== 0 &&
                        selectedSubjectIds.length - 1}{' '}
                      Filters
                    </Disclosure.Button>
                  </div>
                  <div className="pl-6">
                    <button
                      onClick={() => setSelectedSubjectIds([0])}
                      type="button"
                      className="text-gray-500"
                    >
                      Clear all
                    </button>
                  </div>
                </div>
                {selectedSubjectIds.length - 1 > 0 && subjects && (
                  <div className="flex flex-wrap items-center gap-2">
                    {subjects
                      ?.filter((item) => selectedSubjectIds.includes(item.id))
                      .map((activeFilter) => (
                        <span
                          key={activeFilter.id}
                          className=" inline-flex items-center rounded-full border border-gray-200 bg-white py-1.5 pl-3 pr-2 text-xs font-medium text-gray-900"
                        >
                          <span>{activeFilter.title}</span>
                          <button
                            onClick={(e) => handleCheckTags(e, activeFilter.id)}
                            type="button"
                            className="ml-1 inline-flex h-4 w-4 flex-shrink-0 rounded-full p-1 text-gray-400 hover:bg-gray-200 hover:text-gray-500"
                          >
                            <span className="sr-only">
                              Remove filter for {activeFilter.title}
                            </span>
                            <svg
                              className="h-2 w-2"
                              stroke="currentColor"
                              fill="none"
                              viewBox="0 0 8 8"
                            >
                              <path
                                strokeLinecap="round"
                                strokeWidth="1.5"
                                d="M1 1l6 6m0-6L1 7"
                              />
                            </svg>
                          </button>
                        </span>
                      ))}
                  </div>
                )}
              </div>
              <Transition
                enter="transition duration-100 ease-out"
                enterFrom="transform height-0 opacity-0"
                enterTo="transform height-full opacity-100"
                leave="transition duration-75 ease-out"
                leaveFrom="transform height-full opacity-100"
                leaveTo="transform height-0 opacity-0"
              >
                <Disclosure.Panel className="border rounded-md mb-6 border-gray-200 py-6 md:py-10 relative bg-gray-50">
                  <Disclosure.Button className="absolute top-4 right-4 inline-flex  flex-shrink-0 rounded-full p-1 text-gray-500 bg-gray-200 hover:text-gray-500">
                    <ChevronUpIcon className="wh-4" />
                  </Disclosure.Button>
                  <legend className="block font-medium px-4 sm:px-6 lg:px-8">
                    Subjects
                  </legend>
                  <div className="mx-auto max-w-7xl px-4 text-sm sm:px-6  lg:px-8">
                    <div className="grid pt-6  sm:pt-4 auto-rows-min grid-cols-1 gap-y-4 md:gap-y-6 md:grid-cols-3 md:gap-x-6">
                      {subjects?.map((option, optionIdx) => (
                        <fieldset>
                          <div className="space-y-2 sm:space-y-4 ">
                            <div
                              key={option.id}
                              className="flex items-center text-base sm:text-sm"
                            >
                              <input
                                id={`subject-${optionIdx}`}
                                name="subject[]"
                                defaultValue={option.id}
                                type="checkbox"
                                className="h-4 w-4 flex-shrink-0 rounded border-gray-300 text-brand-blue focus:ring-brand-blue"
                                checked={selectedSubjectIds.includes(option.id)}
                                onChange={(e) => handleCheckTags(e, option.id)}
                              />
                              <label
                                htmlFor={`subject-${optionIdx}`}
                                className="ml-3 min-w-0 flex-1 text-gray-600 text-sm"
                              >
                                {option.title}
                              </label>
                            </div>
                          </div>
                        </fieldset>
                      ))}
                    </div>
                  </div>
                </Disclosure.Panel>
              </Transition>
            </>
          )}
        </Disclosure>
      </>
    );
  };

  const skeleton = () => {
    const result = [];
    for (let i = 0; i < 6; i++) {
      result.push(<MentorCard key={`skelton_${i}`} skeleton={true} />);
    }
    return result;
  };

  // if (!data) return <></>;
  return (
    <>
      {/* {i18n.language === 'ko' && !ctaRefInView && (
        <Sidetab
          id={i18n.language === 'ko' ? 'nXe9J7su' : 'kz1bVnqW'}
          buttonText={`${t(`hero.cta`)}`}
          buttonColor="#396CF7"
          onReady={() => {
            window.dataLayer.push({ event: 'find_me_a_mentor_CTA' });
          }}
        />
      )} */}
      <HiddenHeadingMeta text="Browse tutors | Linkstory" />
      <ReactHelmet
        keywords="tutor, find"
        description="Match with mentors who specialize in various subjects and aspects of college admissions."
        title="Browse tutors | Linkstory"
      />

      {/* desktop main slider section */}
      <div className="relative max-w-container mt-[2rem] md:mt-[4rem] lg:mt-0">
        <div className="flex flex-col items-center py-10">
          <h1 className="whitespace-pre-line text-center font-bold text-[1.5rem] md:text-[2rem] lg:text-4xl lg:mt-8">
            {t('hero.title')}
          </h1>
          <div className="mt-4 lg:mt-8 mb-6 font-semibold text-center whitespace-pre-line">
            {t('hero.subtitle')}
          </div>
        </div>
        <SearchMentoring
          withBorder
          placeholder="Search - school, major, subject"
          value={q}
          className="text-black font-semibold"
          onChange={(e) => {
            setQ(e.target.value);
            replace(`/mentors?searchterm=${e.target.value}`);
          }}
          onSearch={() =>
            function (e: any) {
              setQ(e.target.value);
              window.dataLayer.push({
                event: 'search',
                ecommerce: {
                  search_term: e.target.value,
                },
              });
            }
          }
          // onSearchTypeChange={(value) => setSearchType(value)}
        />
      </div>

      <div className=" max-w-container flex flex-col space-y-2 md:flex-row md:space-x-8 md:space-y-0 mb-20">
        {/* filter - desktop */}

        <div className=" flex-1 flex flex-col">
          {filterPanel2()}
          <div className="text-xl md:text-2xl my-4">
            <span className="font-bold">
              <NumericFormat
                value={data?.pages[0]?.data?.total}
                displayType={'text'}
                thousandSeparator={true}
              />
            </span>{' '}
            <span className="text-lg md:text-xl">Fit your choices</span>
          </div>
          {!isPreviousData && data ? (
            <div
              className="whitespace-pre h-full relative grid grid-cols-12 gap-4"
              ref={firstRef}
            >
              {data?.pages[0]?.data?.items.length ? (
                data.pages.map((page, pageIndex: number) => {
                  return (
                    <>
                      {page.data.items.map((mentor, mentorIndex: number) => (
                        <>
                          <MentorCard
                            key={mentor.id}
                            mentor={mentor}
                            query={q}
                          />
                        </>
                      ))}
                    </>
                  );
                })
              ) : (
                <div className="col-span-12 flex justify-center h-full items-center w-full whitespace-pre-wrap">
                  There are no results that match your search.
                </div>
              )}
              <FadeLoader
                className="col-span-12 flex w-full justify-center"
                cssOverride={override}
                color={twConfig.theme.colors['brand-1']}
                loading={isFetchingNextPage}
                size={10}
              />
              <div
                ref={lastRef}
                className="absolute bottom-[50vh] invisible w-full h-px"
              />
            </div>
          ) : (
            <div className="whitespace-pre h-full relative grid grid-cols-12 gap-4">
              {initialLoading ? (
                skeleton()
              ) : (
                <FadeLoader
                  className="flex justify-center h-full items-center col-span-12"
                  cssOverride={override}
                  color={twConfig.theme.colors['brand-1']}
                  loading={true}
                  size={10}
                />
              )}
            </div>
          )}
          {/* <FrontPagination
            basePath="/mentors"
            total={data.total}
            defaultLimit={5}
          /> */}
        </div>
      </div>

      {/* FAQ section */}
      {/* <FAQSectionView faqItems={MENTORS_FAQS} /> */}

      {/* bottom banner section */}
      {/* <div className="w-full h-[400px] md:h-full overflow-hidden bg-center bg-cover bg-no-repeat relative flex justify-center">
        <img
          className="w-full h-full object-cover object-center"
          src={bottomBannerImg}
          alt={'mentoringOverviewImg'}
        />
        <div className="absolute max-w-container  h-full object-cover object-center flex flex-col justify-center">
          <div className="w-full sm:px-6">
            <h2 className="text-2xl md:text-3xl lg:text-5xl font-bold">
              Want to become a
              <br /> mentor at Linkstory?
            </h2>
            <p className="mt-4 md:mt-8 text-sm md:text-lg max-w-sm">
              Book a session with our recruiters today.
            </p>
            <div className="flex flex-wrap">
              <PopupButton
                id="Gnw156Gn"
                className="rounded-full bg-brand-blue text-white text-xs md:text-sm px-4 md:px-6 py-2 md:py-3 mt-4 md:mt-8 mr-4"
              >
                Become a mentor
              </PopupButton>
              <PopupButton
                id="Wc6oj5aq"
                className="rounded-full bg-brand-blue text-white text-xs md:text-sm px-4 md:px-6 py-2 md:py-3 mt-4 md:mt-8 mr-4"
              >
                Become a consultant
              </PopupButton>
            </div>
            <div className="flex flex-wrap">
              <PopupButton
                id="GIrWpsPP"
                className="rounded-full  bg-brand-blue text-white text-xs md:text-sm px-4 md:px-6 py-2 md:py-3 mt-4 md:mt-8 mr-4"
              >
                Become a tutor
              </PopupButton>
              <PopupButton
                id="GIrWpsPP"
                className="rounded-full  bg-brand-blue text-white text-xs md:text-sm px-4 md:px-6 py-2 md:py-3 mt-4 md:mt-8"
              >
                Become a career advisor
              </PopupButton>
            </div>
          </div>
        </div>
      </div> */}
    </>
  );
};
