import { Link, useLocation } from 'react-router-dom';
import { Icon } from '@components/icons';
import { Button } from '@components/Button';
import { GOOGLE_SOCIAL_URL } from '@src/types';
import { FaceBookLoginButton } from '@components/FaceBookLoginButton';
import { useEffect, useState } from 'react';
import { ReactHelmet } from '@src/components/ReactHelmet';
interface LocationState {
  from: {
    pathname: string;
  };
}

export const SignupPage = () => {
  const location = useLocation<LocationState>();
  const [locationState] = useState<LocationState>(
    location.state || { from: { pathname: '/' } }
  );

  useEffect(() => {}, [locationState, location]);
  return (
    <>
      <ReactHelmet
        keywords="signup"
        description="Sign up and get started."
        title="Sign Up | Linkstory"
      />
      <div
        className="w-full bg-center bg-cover bg-no-repeat "
        // style={{ backgroundImage: `url('${HeroImg}')` }}
      >
        <div className="max-w-screen-xl mx-auto h-screen flex items-center justify-center px-4">
          <div className="max-w-lg bg-white rounded-md py-8 px-4 md:px-10 space-y-4 w-full">
            <h1 className="text-30 font-bold text-center pb-6 text-brand-navy3">
              Sign up
            </h1>
            <div className="flex flex-col space-y-4 mt-4">
              <Button
                className="w-full group h-12 px-6 border border-gray-300 rounded-full transition duration-300 hover:border-brand-blue focus:bg-blue-50 active:bg-blue-100 mb-4"
                onClick={() => window.location.replace(GOOGLE_SOCIAL_URL)}
              >
                <div className="relative flex items-center space-x-4 justify-center">
                  <Icon.GoogleLogo className="w-5" />
                  <span className="block w-max font-semibold tracking-wide text-gray-700 text-sm transition duration-300 group-hover:text-brand-blue sm:text-base">
                    Continue with Google
                  </span>
                </div>
              </Button>
              <FaceBookLoginButton from={locationState.from.pathname} />
              <div className="relative flex pb-5 items-center">
                <div className="flex-grow border-t border-gray-400 border-opacity-60"></div>
                <span className="flex-shrink mx-4 text-xs text-gray-400">
                  OR
                </span>
                <div className="flex-grow border-t border-gray-400 border-opacity-60"></div>
              </div>

              <Link
                to="/signup/student"
                className="filled-brand-1 rounded-full py-3 text-center font-bold"
              >
                Sign up with email
              </Link>
              {/* <a
                href="https://x5hkp5wdxn2.typeform.com/Mentor-Interest"
                className="outlined-brand-1 rounded-full py-3 text-center font-bold"
              >
                I’m signing up as a Mentor
              </a> */}
              {/* <PopupButton
                id="SMzN0Uxj"
                className="outlined-brand-1 rounded-full py-3 text-center font-bold"
              >
                Sign up as a mentor
              </PopupButton> */}
              <p className="text-center">
                Already have an account?
                <Link to="/login" className="underline mx-1">
                  Log in
                </Link>
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
