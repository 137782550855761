import { Role } from '@src/types';

export const GNB_MENUS = [
  {
    mainmenu: {
      i18nkey: 'findAMentor',
      to: null,
      showBeforeLogin: true,
      showAfterLoginAuth: [Role.STUDENT, Role.ADMIN],
    },
    submenu: [
      {
        i18nkey: 'requestAMentor',
        to: '/student-request',
        isNew: true,
      },
      {
        i18nkey: 'searchForMentors',
        to: '/mentors',
      },
    ],
  },
  {
    mainmenu: {
      i18nkey: 'sessionHistory',
      to: '/my/dashboard/sessions',
      showBeforeLogin: false,
      mobileOnly: true,
      showAfterLoginAuth: [Role.STUDENT],
    },
  },
  {
    mainmenu: {
      i18nkey: 'transactions',
      to: '/my/dashboard/transactions',
      showBeforeLogin: false,
      mobileOnly: true,
      showAfterLoginAuth: [Role.STUDENT],
    },
  },
  {
    mainmenu: {
      i18nkey: 'howItWorks',
      to: null,
      showBeforeLogin: true,

      showAfterLoginAuth: [Role.STUDENT, Role.MENTOR],
    },
    submenu: [
      { i18nkey: 'ForStudents', to: '/howitworks/student', isNew: true },
      { i18nkey: 'ForTutors', to: '/howitworks/tutor', isNew: true },
      // { i18nkey: 'faq', to: 'https://linkstory.zendesk.com/hc' },
    ],
  },
  {
    mainmenu: {
      i18nkey: 'resources',
      to: null,
      showBeforeLogin: true,
      showAfterLoginAuth: [Role.STUDENT],
    },
    submenu: [
      { i18nkey: 'aboutEssaysVideos', to: '/about-resources' },
      // { i18nkey: 'acceptedEssays', to: '/essays' },
      { i18nkey: 'videoLessons', to: '/vods' },
      { i18nkey: 'webinars', to: '/webinar' },
    ],
  },
  {
    mainmenu: {
      i18nkey: 'My business',
      to: null,
      showBeforeLogin: false,
      showAfterLoginAuth: [Role.MENTOR],
    },
    submenu: [
      { i18nkey: 'Jobs', to: '/my/dashboard/jobs' },
      { i18nkey: 'Payments', to: '/my/dashboard/payments' },
      { i18nkey: 'Session history', to: '/my/dashboard/sessions' },
      // { i18nkey: 'Recordings', to: '/my/dashboard/recordings' },
    ],
  },
];
