import { ComponentType, FC, SVGProps } from 'react';
import { ReactComponent as ArrowLeft } from '@icons/icon-arrow-left.svg';
import { ReactComponent as ArrowRight } from '@icons/icon-arrow-right.svg';
import { ReactComponent as BellOff } from '@icons/icon-bell-off.svg';
import { ReactComponent as Bell } from '@icons/icon-bell.svg';
import { ReactComponent as Bookmark } from '@icons/icon-bookmark.svg';
import { ReactComponent as Calendar } from '@icons/icon-calendar.svg';
import { ReactComponent as Check } from '@icons/icon-check-circle.svg';
import { ReactComponent as ChevronDown } from '@icons/icon-chevron-down.svg';
import { ReactComponent as ChevronLeft } from '@icons/icon-chevron-left.svg';
import { ReactComponent as ChevronRight } from '@icons/icon-chevron-right.svg';
import { ReactComponent as ChevronUp } from '@icons/icon-chevron-up.svg';
import { ReactComponent as Clock } from '@icons/icon-clock.svg';
import { ReactComponent as CreditCard } from '@icons/icon-credit-card.svg';
import { ReactComponent as Download } from '@icons/icon-download.svg';
import { ReactComponent as ExternalLink } from '@icons/icon-external-link.svg';
import { ReactComponent as EyeOff } from '@icons/icon-eye-off.svg';
import { ReactComponent as Eye } from '@icons/icon-eye.svg';
import { ReactComponent as Facebook } from '@icons/icon-facebook.svg';
import { ReactComponent as File } from '@icons/icon-file.svg';
import { ReactComponent as Folder } from '@icons/icon-folder.svg';
import { ReactComponent as Heart } from '@icons/icon-heart.svg';
import { ReactComponent as Home } from '@icons/icon-home.svg';
import { ReactComponent as Instagram } from '@icons/icon-instagram.svg';
import { ReactComponent as Link } from '@icons/icon-link.svg';
import { ReactComponent as Mail } from '@icons/icon-mail.svg';
import { ReactComponent as MapPin } from '@icons/icon-map-pin.svg';
import { ReactComponent as Map } from '@icons/icon-map.svg';
import { ReactComponent as Menu } from '@icons/icon-menu.svg';
import { ReactComponent as Minus } from '@icons/icon-minus.svg';
import { ReactComponent as MoreHorizontal } from '@icons/icon-more-horizontal.svg';
import { ReactComponent as MoreVertical } from '@icons/icon-more-vertical.svg';
import { ReactComponent as Phone } from '@icons/icon-phone.svg';
import { ReactComponent as Plus } from '@icons/icon-plus.svg';
import { ReactComponent as Search } from '@icons/icon-search.svg';
import { ReactComponent as Send } from '@icons/icon-send.svg';
import { ReactComponent as Settings } from '@icons/icon-settings.svg';
import { ReactComponent as Share } from '@icons/icon-share.svg';
import { ReactComponent as ShoppingCart } from '@icons/icon-shopping-cart.svg';
import { ReactComponent as Star } from '@icons/icon-star.svg';
import { ReactComponent as Trash } from '@icons/icon-trash.svg';
import { ReactComponent as Upload } from '@icons/icon-upload.svg';
import { ReactComponent as User } from '@icons/icon-user.svg';
import { ReactComponent as X } from '@icons/icon-x.svg';
import { ReactComponent as Youtube } from '@icons/icon-youtube.svg';
import { ReactComponent as Tiktok } from '@icons/icon-tiktok.svg';
import { ReactComponent as Twitter } from '@icons/icon-twitter.svg';
import { ReactComponent as GoogleLogIn } from '@icons/icon-google-sign-in.svg';
import { ReactComponent as GoogleSignUp } from '@icons/icon-google-sign-up.svg';
import { ReactComponent as GoogleLogo } from '@icons/icon-google-logo.svg';
import { ReactComponent as PlayOutlined } from '@icons/icon-play-outlined.svg';
import { ReactComponent as PlayFilled } from '@icons/icon-play-filled.svg';
import { ReactComponent as DegreeHat } from '@icons/icon-degree-hat.svg';
import { ReactComponent as ProfileFilled } from '@icons/icon-profile-filled.svg';
import { ReactComponent as Document } from '@icons/icon-document.svg';
import { ReactComponent as Download2 } from '@icons/icon-download2.svg';
import { ReactComponent as Check2 } from '@icons/icon-check.svg';
import { ReactComponent as LinkstoryPlusLogo } from '@svg/Linkstory+Logo.svg';
import { ReactComponent as FactCheck } from '@icons/icon-fact-check.svg';
import { ReactComponent as Groups } from '@icons/icon-groups.svg';
import { ReactComponent as HistoryEdu } from '@icons/icon-history-edu.svg';
import { ReactComponent as Sports } from '@icons/icon-sports-football.svg';
import { ReactComponent as Grading } from '@icons/icon-grading.svg';
import { ReactComponent as Ballot } from '@icons/icon-ballot.svg';
import { ReactComponent as Person } from '@icons/icon-person.svg';
import { ReactComponent as Assignment } from '@icons/icon-assignment-return.svg';
import { ReactComponent as IconUC } from '@icons/icon-uc-typo.svg';
import { ReactComponent as LockFilled } from '@icons/icon-lock-filled.svg';
import { ReactComponent as InfoOutlined } from '@icons/icon-info-outlined.svg';
import { ReactComponent as CloseX } from '@icons/icon-close-x.svg';
import { ReactComponent as ArrowUp } from '@icons/icon-arrow-up.svg';
import { ReactComponent as ArrowLongLeft } from '@icons/icon-arrow-left-long.svg';
import { ReactComponent as ClockOutlined } from '@icons/icon-clock-outlined.svg';
import { ReactComponent as CreditCardOutlined } from '@icons/icon-creditcard-outlined.svg';
import { ReactComponent as StarOutlined } from '@icons/icon-star-outlined.svg';
import { ReactComponent as CalendarOutlined } from '@icons/icon-calendar-outlined.svg';
import { ReactComponent as MessageFilled } from '@icons/icon-message-filled.svg';
import { ReactComponent as BriefCaseFilled } from '@icons/icon-briefcase-filled.svg';
function withStroke<T extends SVGProps<SVGSVGElement>>(
  Icon: ComponentType<T>
): FC<T> {
  return ({ className = '', ...props }) => (
    <Icon className={`stroke-current ${className}`} {...(props as T)} />
  );
}

function withFill<T extends SVGProps<SVGSVGElement>>(
  Icon: ComponentType<T>
): FC<T> {
  return ({ className = '', ...props }) => (
    <Icon className={`fill-current ${className}`} {...(props as T)} />
  );
}

export const Icon = {
  ArrowLeft: withStroke(ArrowLeft),
  ArrowRight: withStroke(ArrowRight),
  BellOff: withStroke(BellOff),
  Bell: withStroke(Bell),
  Bookmark: withStroke(Bookmark),
  Calendar: withStroke(Calendar),
  Check: withStroke(Check),
  ChevronDown: withStroke(ChevronDown),
  ChevronLeft: withStroke(ChevronLeft),
  ChevronRight: withStroke(ChevronRight),
  ChevronUp: withStroke(ChevronUp),
  Clock: withStroke(Clock),
  CreditCard: withStroke(CreditCard),
  Download: withStroke(Download),
  ExternalLink: withStroke(ExternalLink),
  EyeOff: withStroke(EyeOff),
  Eye: withStroke(Eye),
  Facebook: Facebook,
  File: withStroke(File),
  Folder: withStroke(Folder),
  Heart: withStroke(Heart),
  Home: withStroke(Home),
  Instagram: withStroke(Instagram),
  Link: withStroke(Link),
  Mail: withStroke(Mail),
  MapPin: withStroke(MapPin),
  Map: withStroke(Map),
  Menu: withStroke(Menu),
  Minus: withStroke(Minus),
  MoreHorizontal: withStroke(MoreHorizontal),
  MoreVertical: withStroke(MoreVertical),
  Phone: withStroke(Phone),
  Plus: withStroke(Plus),
  Search: withStroke(Search),
  Send: withStroke(Send),
  Settings: withStroke(Settings),
  Share: withStroke(Share),
  ShoppingCart: withStroke(ShoppingCart),
  Star: withStroke(Star),
  Trash: withStroke(Trash),
  Upload: withStroke(Upload),
  User: withStroke(User),
  X: withStroke(X),
  Youtube: withStroke(Youtube),
  Twitter: withStroke(Twitter),
  Tiktok: withStroke(Tiktok),
  GoogleLogIn: GoogleLogIn,
  GoogleSignUp: GoogleSignUp,
  GoogleLogo: GoogleLogo,
  PlayOutlined: withFill(PlayOutlined),
  PlayFilled: withFill(PlayFilled),
  DegreeHat: withFill(DegreeHat),
  ProfileFilled: withFill(ProfileFilled),
  Document: withFill(Document),
  Download2: withFill(Download2),
  Check2: withFill(Check2),
  LinkstoryPlusLogo: LinkstoryPlusLogo,
  FactCheck: withStroke(FactCheck),
  Groups: withStroke(Groups),
  HistoryEdu: withStroke(HistoryEdu),
  Sports: withStroke(Sports),
  Grading: withFill(Grading),
  Ballot: withFill(Ballot),
  Person: withFill(Person),
  Assignment: withFill(Assignment),
  IconUC: withFill(IconUC),
  LockFilled: withFill(LockFilled),
  InfoOutlined: withFill(InfoOutlined),
  CloseX: withStroke(CloseX),
  ArrowUp: withStroke(ArrowUp),
  ArrowLongLeft: withStroke(ArrowLongLeft),
  ClockOutlined: withFill(ClockOutlined),
  CreditCardOutlined: withFill(CreditCardOutlined),
  StarOutlined: withStroke(StarOutlined),
  CalendarOutlined: withFill(CalendarOutlined),
  MessageFilled: withFill(MessageFilled),
  BriefCaseFilled: withFill(BriefCaseFilled),
};
