import React, { useEffect, useRef, useState } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { Avatar } from '@components/Avatar';
import { useQuery } from 'react-query';
import * as Types from '@src/types';
import { fetcher } from '@plugins/react-query';
import { useAuth } from '@src/hooks';
import { getS3Uri } from '@src/utils';
import HTMLEllipsis from 'react-lines-ellipsis/lib/html';
import responsiveHOC from 'react-lines-ellipsis/lib/responsiveHOC';
import 'react-tiny-fab/dist/styles.css';
import { Select } from '@src/components/Select';
import { TextField } from '@src/components/TextField';
import { TextArea } from '@src/components/TextArea';
import { useFieldArray, useForm } from 'react-hook-form';
import { api } from '@src/plugins/axios';
import moment from 'moment';
import { MomentFormat, utcToLocalFormat } from '@src/plugins/moment';
import * as Icons from '@heroicons/react/24/outline';
import { AttachedFiles } from '@src/components/AttachedFiles';
import axios from 'axios';
import { resizeImage } from '@src/plugins/compress';
import { Button } from '@src/components/Button';
import { StarIcon } from '@heroicons/react/20/solid';
import { uniqueId } from 'lodash';
import { HiddenHeadingMeta } from '@src/components/HiddenHeadingMeta';
import { ReactHelmet } from '@src/components/ReactHelmet';
import mixpanel from 'mixpanel-browser';
import { StepsComponents } from '@src/components/StepsComponents';

const ResponsiveEllipsis = responsiveHOC()(HTMLEllipsis);

interface LocationState {
  from: {
    pathname: string;
  };
}

interface FormValues {
  firstRequestTime: string;
  secondRequestTime?: string;
  thirdRequestTime?: string;
  gradeId: number;
  subjectId: number;
  description: string;
  targetMentorId?: number;
  fileUrl?: string;
  questionAnswers: Types.QuestionAnswerType[];
}

export const StudentRequest = () => {
  const { authenticated } = useAuth();
  const [currentStatusId, setCurrentStatusId] = useState<string>('01');
  const { data: me, isFetching: meIsFetching } = useQuery<Types.User>(
    '/users/v2/me',
    fetcher,
    {
      enabled: !!authenticated,
    }
  );

  const { push, goBack } = useHistory();
  const { id } = useParams<{ id: string }>();
  const {
    watch,
    setValue,
    register,
    handleSubmit,
    setError,
    clearErrors,
    control,
    formState: { isSubmitting, errors, isDirty, isValid },
  } = useForm<FormValues>({
    mode: 'onChange',
  });
  //@ts-ignore
  const { fields, replace } = useFieldArray({
    control,
    name: 'questionAnswers',
    // keyName: 'text',
  });

  const [currentTime, setCurrentTime] = useState<string>(
    moment().toISOString()
  );
  const [files, setFiles] = useState<any[]>([]);

  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    mixpanel.track('Page Viewed', {
      item_category: 'Quick Request',
    });
    const timerID = setInterval(() => tick(), 1000);
    return () => {
      clearInterval(timerID);
    };
  }, []);

  const tick = () => {
    setCurrentTime(moment().toISOString());
  };

  const location = useLocation<LocationState>();
  const [locationState] = useState<LocationState>(
    location.state || { from: { pathname: '/mentors' } }
  );

  const { data: isChatExist } = useQuery<boolean>(
    `/chats/v2/check-exists/${id}`,
    fetcher,
    {
      enabled: !!id,
      onSuccess(data) {
        // 채팅 내역이 이미 존재 하는 경우 나가져야함
        if (data === true) {
          goBack();
        }
      },
    }
  );

  const { data: mentor, status } = useQuery<Types.User>(
    `/users/${id}`,
    fetcher,
    {
      refetchOnWindowFocus: false,
    }
  );
  const [useEllipsis, setUseEllipsis] = useState<boolean>(true);

  const { data: gradesItems } = useQuery<Types.Grade[]>(
    `/v2/grades/visibility`,
    fetcher,
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
    }
  );

  const { data: subjectsItems } = useQuery<Types.Subject[]>(
    `/v2/subjects`,
    fetcher,
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
    }
  );

  const { data: mentorSubjects } = useQuery<any[]>(
    `/v2/subject-link?mentorId=${id}`,
    fetcher,
    {
      enabled: !!id,
    }
  );

  // const [subjectQuestions, setSubjectQuestions] = useState<SubjectQuestion[]>();

  const { data: schoolTags } = useQuery<Types.Tag[]>(
    `/tags?type=${Types.TagType.SCHOOL}`,
    fetcher,
    {
      refetchOnWindowFocus: false,
    }
  );
  const { data: majorTags } = useQuery<Types.Tag[]>(
    `/tags?type=${Types.TagType.MAJOR}`,
    fetcher,
    {
      refetchOnWindowFocus: false,
    }
  );
  const { data: tagLinks } = useQuery<Types.TagLink[]>(
    `/tag-links/mentor/${id}`,
    fetcher,
    {
      enabled: !!id,
      refetchOnWindowFocus: false,
    }
  );

  const subjectId = watch('subjectId');

  const { data: subjectQuestions } = useQuery<Types.SubjectQuestion[]>(
    `/v2/subject-questions?subjectId=${subjectId}`,
    fetcher,
    {
      enabled: !!subjectId,
      refetchOnWindowFocus: false,

      onSuccess(data) {
        // setValue('questionAnswers', []);
        const filteredData: { text: string; questionId: number }[] = [];

        data.map((item, index) => {
          filteredData.push({ text: '', questionId: item.id });
        });
        if (filteredData) {
          //@ts-ignore
          replace(filteredData);
        }
      },
    }
  );

  const schoolTagId = tagLinks?.find(
    (tagLink: any) => tagLink.tag.type === Types.TagType.SCHOOL
  )?.tagId;

  const majorTagId = tagLinks?.find(
    (tagLink: any) => tagLink.tag.type === Types.TagType.MAJOR
  )?.tagId;

  const school = schoolTags?.find((tag) => tag.id === schoolTagId);
  const major = majorTags?.find((tag) => tag.id === majorTagId);

  useEffect(() => {
    if (mentor) {
      window.dataLayer.push({ ecommerce: null });
      window.dataLayer.push({
        event: 'view_item',
        ecommerce: {
          items: [
            {
              item_id: id,
              item_name: `${mentor?.firstName} ${mentor?.lastName}`,
              item_category: 'mentor',
              item_variant: `${school?.name} ${major?.name}`,
            },
          ],
        },
      });
    }
  }, [mentor]);

  const handleEllipsis = (e: React.MouseEvent) => {
    e.preventDefault();
    setUseEllipsis(false);
  };

  useEffect(() => {
    console.log('!!test', errors);
  }, [errors]);

  if (status === 'loading') return <></>;
  // if (!mentor) return <>404 Not Found</>;

  const profileCard = () => {
    return (
      <div className="sticky top-20 p-4 lg:p-8 shadow-sm boder rounded-lg bg-white overflow-hidden flex flex-col gap-2 lg:gap-4">
        <div className="flex justify-between items-center">
          <Avatar
            src={getS3Uri(mentor?.avatar)}
            alt="avatar"
            className="!wh-16 object-cover rounded-full"
          />
          {Number(mentor?.totalReviewCount) >= 1 && (
            <div className="flex gap-1 items-center text-sm">
              <StarIcon className="wh-4 text-brand-orange" />
              <p>{`${mentor?.avgRating ?? 'N/A'} (${
                mentor?.totalReviewCount
              })`}</p>
            </div>
          )}
        </div>
        <div className="flex justify-between items-center">
          <div className="flex flex-col lg:flex-row gap-2 lg:gap-4 lg:items-center flex-wrap">
            <h1 className="font-header font-bold text-xl md:text-2xl break-word">
              {mentor?.firstName} {mentor?.lastName}
            </h1>
            <div
              style={{ width: 'fit-content' }}
              className="text-brand-blue text-xs bg-[#edf2f9] py-1 px-3 rounded-md font-semibold"
            >
              {school?.name}
            </div>
          </div>
        </div>
        <div className="text-[#7783A8] text-xs lg:text-sm">
          <p>{school?.name}</p>
          <p>{major?.name}</p>
        </div>
        <p
          className={`${
            mentor?.introduction ? 'block' : 'hidden'
          } pt-4 pb-4 text-14 leading-[1.5rem] hidden md:block`}
        >
          {useEllipsis ? (
            <div onClick={(e) => handleEllipsis(e)}>
              <ResponsiveEllipsis
                unsafeHTML={mentor?.introduction}
                maxLine={'3'}
                ellipsisHTML={`...<button class=" text-[#AAAAAA]  ml-2 px-2 leading-[1.3rem] rounded-full">more</button>`}
                basedOn="letters"
              />
            </div>
          ) : (
            <div>{mentor?.introduction}</div>
          )}
        </p>
      </div>
    );
  };

  const EmptyStateSection = () => {
    return (
      <div className="relative block w-full rounded-lg border-2 border-dashed border-gray-300 p-12 text-center hover:border-gray-400 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 col-span-3">
        <Icons.AcademicCapIcon className="wh-10 text-gray-500 mx-auto" />
        <span className="mt-2 block text-sm font-medium text-gray-900">
          Select a subject to see related quesitons
        </span>
      </div>
    );
  };

  const formSection = () => {
    return (
      <>
        <form
          className="space-y-8 divide-y divide-gray-200 bg-white mx-auto max-w-7xl py-12 px-4 sm:px-6 lg:px-8 border rounded-md"
          onSubmit={handleSubmit(async (data) => {
            if (isChatExist) {
              return;
            }
            try {
              let fileData: {
                filename: string;
                fileType: string;
                fileKey: string;
              }[] = [];

              if (files.length > 0) {
                for (const file of files) {
                  const formData = new FormData();
                  const { data: post } = await api.get(
                    `/s3/presignedPost?filename=${file.fileName}`
                  );

                  Object.entries(post.fields).forEach(([name, value]) =>
                    formData.append(name, value as any)
                  );
                  // IMPORTANCE: https://stackoverflow.com/a/15235866
                  // AWS ignores all fields in the request after the file field, so all other fields must appear before the file.
                  formData.append(
                    'file',
                    file.file.type.startsWith('image/')
                      ? await resizeImage(file.file)
                      : file.file
                  );
                  await axios.post(post.url, formData, {
                    headers: { 'Content-Type': 'multipart/form-data' },
                  });
                  fileData.push({
                    filename: file.fileName,
                    fileType: file.file.type.startsWith('image/')
                      ? Types.FileType.IMAGE
                      : Types.FileType.FILE,
                    fileKey: post.fields.key,
                  });
                }
              }

              await api
                .post('/v2/jobs', {
                  ...data,
                  isOnline: true,
                  isPrivate: false,
                  firstRequestTime: moment(data.firstRequestTime).toISOString(),
                  ...(data.secondRequestTime && {
                    secondRequestTime: moment(
                      data.secondRequestTime
                    ).toISOString(),
                  }),
                  ...(data.thirdRequestTime && {
                    thirdRequestTime: moment(
                      data.thirdRequestTime
                    ).toISOString(),
                  }),
                  ...(fileData && { files: fileData }),
                  ...(id && { targetMentorId: Number(id) }),
                })
                .then(() => {
                  if (id) {
                    // 튜터 지정 요청

                    window.dataLayer.push({ event: 'student_request_job' });
                    push(`/request-sent/${id}`);
                    mixpanel.track('Request', {
                      request_type: 'Specific',
                      request_subject: mentorSubjects?.find(
                        (option) => option.subjectId === data.subjectId
                      ).title,
                    });
                  } else {
                    // 일반 요청
                    window.dataLayer.push({ event: 'student_request_quick' });
                    mixpanel.track('Request', {
                      request_type: 'General',
                      request_subject: mentorSubjects?.find(
                        (option) => option.subjectId === data.subjectId
                      ).title,
                    });
                    // 유저의 요청 카운트 로직 1씩 중가
                    mixpanel.people.increment('Request Counts', 1);
                    // 최초 요청 시간 - 한번만 등록 되고 중복 수정 되지 않음
                    mixpanel.people.set_once({
                      'First Request Date': new Date().toISOString(),
                    });
                    // 최초 요청 시간 - 지속적으로 업데이트 됨
                    mixpanel.people.set({
                      'Last Request Date': new Date().toISOString(),
                    });
                    mixpanel.identify(me?.email);

                    push(`/request-sent`);
                  }
                });
            } catch (error) {
              alert('Request failed.');
              console.error(error);
            }
          })}
        >
          <div className="space-y-8 divide-y divide-gray-200 sm:space-y-5">
            <div className="space-y-6 sm:space-y-5">
              <div>
                <h3 className="text-lg font-medium leading-6 text-gray-900">
                  Session Details
                </h3>
                {/* <p className="mt-1 max-w-2xl text-sm text-gray-500">
                  Please enter information related to the session
                </p> */}
              </div>
              <div className="space-y-6 sm:space-y-5">
                <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                  <label
                    htmlFor="username"
                    className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                  >
                    Grade level
                  </label>
                  <div className="mt-1 sm:col-span-2 sm:mt-0">
                    <Select
                      helper={errors.gradeId?.message}
                      className="block w-full max-w-lg border-gray-300 shadow-sm h-10 sm:max-w-xs text-sm"
                      {...register('gradeId', {
                        required: 'Please selsect your grade level',
                      })}
                    >
                      <option value="" selected disabled hidden>
                        Please select your grade level
                      </option>
                      {gradesItems
                        ?.sort(function (a, b) {
                          return a.title.localeCompare(b.title);
                        })
                        .map((item) => {
                          return (
                            <option
                              value={Number(item.id)}
                              key={`grade_${item.id}`}
                            >
                              {item.title.toUpperCase()}
                            </option>
                          );
                        })}
                    </Select>
                  </div>
                </div>
              </div>
              <div className="space-y-6 sm:space-y-5">
                <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                  <label
                    htmlFor="username"
                    className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                  >
                    Subject
                  </label>
                  <div className="mt-1 sm:col-span-2 sm:mt-0">
                    <Select
                      helper={errors.subjectId?.message}
                      className="block w-full max-w-lg  border-gray-300 shadow-sm h-10 sm:max-w-xs text-sm"
                      {...register('subjectId', {
                        required: 'Please select session subject',
                      })}
                    >
                      <option value="" selected disabled hidden>
                        Please select session subject
                      </option>
                      {id
                        ? // 멘토가 가르치는 항목만 필터링 해서 보여주는 부분
                          subjectsItems
                            ?.filter((item) =>
                              mentorSubjects?.find(
                                (option) => option.subjectId === item.id
                              )
                            )

                            .map((item) => {
                              return (
                                <option
                                  value={Number(item.id)}
                                  key={`subject_${item.id}`}
                                >
                                  {item.title.toUpperCase()}
                                </option>
                              );
                            })
                        : // 전체 Subject 표시 페이지 파라미터 id 없을때
                          subjectsItems?.map((item) => {
                            return (
                              <option
                                value={Number(item.id)}
                                key={`subject_${item.id}`}
                              >
                                {item.title.toUpperCase()}
                              </option>
                            );
                          })}
                    </Select>
                  </div>
                </div>
              </div>
              <div className="space-y-6 sm:space-y-5">
                <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                  <label
                    htmlFor="username"
                    className="block text-sm font-medium text-gray-700 sm:mt-px "
                  >
                    Your current time
                  </label>
                  <div className="mt-1 sm:col-span-2 sm:mt-0 flex gap-2 text-sm items-center text-gray-600">
                    <dt className="">
                      <span className="sr-only">Date</span>
                      <Icons.ClockIcon className="wh-4 " aria-hidden="true" />
                    </dt>
                    <dd>
                      <div>
                        {utcToLocalFormat(
                          currentTime,
                          MomentFormat.YYYYMMDDHmmss
                        )}{' '}
                        {moment.tz(moment.tz.guess()).zoneAbbr()}
                      </div>
                    </dd>
                  </div>
                </div>
              </div>
              <div className="space-y-6 sm:space-y-5">
                <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                  <label
                    htmlFor="username"
                    className="block text-sm font-medium text-gray-700 sm:mt-px"
                  >
                    Three times that work best for you
                  </label>
                  <div className="mt-1 sm:col-span-2 sm:mt-0 space-y-4 text-sm flex flex-col max-w-lg sm:max-w-xs">
                    <TextField
                      required
                      data-placeholder="YYYY. MM. DD hh:mm"
                      type="datetime-local"
                      min={moment().format('YYYY-MM-DD[T]HH:mm')}
                      className={`relative text-sm ${
                        !!watch('firstRequestTime') ? 'has-value' : ''
                      }`}
                      helper={errors.firstRequestTime?.message}
                      {...register('firstRequestTime', {
                        required: 'Please enter your best times at least one',
                        validate: (val: string) => {
                          if (moment(new Date(val)).isBefore(new Date())) {
                            return 'You cannot select earlier than the current time.';
                          }
                        },
                      })}
                    />

                    <TextField
                      type="datetime-local"
                      data-placeholder="YYYY. MM. DD hh:mm"
                      min={moment().format('YYYY-MM-DD[T]HH:mm')}
                      className={`text-sm ${
                        !!watch('secondRequestTime') ? 'has-value' : ''
                      }`}
                      name="secondRequestTime"
                      helper={errors.secondRequestTime?.message}
                      onChange={(e) => {
                        setValue('secondRequestTime', e.target.value);
                        if (
                          moment(new Date(e.target.value)).isBefore(new Date())
                        ) {
                          setError('secondRequestTime', {
                            type: 'manual',
                            message:
                              'You cannot select earlier than the current time.',
                          });
                        } else {
                          clearErrors('secondRequestTime');
                        }
                      }}
                    />
                    <TextField
                      type="datetime-local"
                      data-placeholder="YYYY. MM. DD hh:mm"
                      min={moment().format('YYYY-MM-DD[T]HH:mm')}
                      className={`text-sm ${
                        !!watch('thirdRequestTime') ? 'has-value' : ''
                      }`}
                      name="thirdRequestTime"
                      helper={errors.thirdRequestTime?.message}
                      onChange={(e) => {
                        setValue('thirdRequestTime', e.target.value);
                        if (
                          moment(new Date(e.target.value)).isBefore(new Date())
                        ) {
                          setError('thirdRequestTime', {
                            type: 'manual',
                            message:
                              'You cannot select earlier than the current time.',
                          });
                        } else {
                          clearErrors('thirdRequestTime');
                        }
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="space-y-6 pt-8 sm:space-y-5 sm:pt-10">
              <div>
                <h3 className="text-lg font-medium leading-6 text-gray-900">
                  Subject questions
                </h3>
              </div>
              <div className="space-y-6 sm:space-y-5">
                <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                  {fields.map((field, index) => {
                    return (
                      <div className=" col-span-3" key={field.questionId}>
                        <h4 className="block text-sm font-medium text-gray-700 sm:mt-px mb-3">
                          {subjectQuestions &&
                            subjectQuestions[index]?.question}
                        </h4>
                        <TextField
                          type="text"
                          placeholder="Write your answer here"
                          className="h-10 text-sm"
                          helper={
                            errors.questionAnswers &&
                            errors.questionAnswers[index]?.text?.message
                          }
                          {...register(
                            `questionAnswers.${index}.text` as const,
                            {
                              required:
                                'Please enter your answer to the question',
                            }
                          )}
                        />
                      </div>
                    );
                  })}
                  {!subjectQuestions && EmptyStateSection()}
                </div>
              </div>
            </div>
            <div className="space-y-6 pt-8 sm:space-y-5 sm:pt-10">
              <div className="space-y-3">
                <h3 className="text-lg font-medium leading-6 text-gray-900">
                  Message
                </h3>
                <p className="mt-1 max-w-2xl text-sm text-gray-500">
                  {`Please leave a detailed description of what you want in your session. See below:`}
                </p>
                <div className="rounded-md bg-red-50 p-4 ">
                  <div className="flex">
                    <div className="flex-shrink-0">
                      <Icons.XCircleIcon
                        className="h-5 w-5 text-red-400"
                        aria-hidden="true"
                      />
                    </div>
                    <div className="ml-3">
                      <h3 className="text-sm font-medium text-red-800">
                        Bad example
                      </h3>
                      <div className="mt-2 text-sm text-red-700">
                        <p className="whitespace-pre-line">
                          I need help with homework
                        </p>
                        <ul className="list-disc space-y-1 pl-5"></ul>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="rounded-md bg-green-50 p-4">
                  <div className="flex">
                    <div className="flex-shrink-0">
                      <Icons.CheckCircleIcon
                        className="h-5 w-5 text-green-400"
                        aria-hidden="true"
                      />
                    </div>
                    <div className="ml-3">
                      <h3 className="text-sm font-medium text-green-800">
                        Good example
                      </h3>
                      <div className="mt-2 text-sm text-green-700">
                        <p className="whitespace-pre-line">
                          {` I have to write a 3 page persuasive essay for a school assignment and I need help getting started. \nI also need help with grammar and editing before I submit the final draft.`}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="space-y-6 sm:space-y-5">
                <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                  <TextArea
                    // placeholder="ex. please help me with homework"
                    wrapperClassName="col-span-3 "
                    className="text-sm min-h-[100px]"
                    helper={errors.description?.message}
                    {...register('description', {
                      required: 'Please enter your description',
                      validate: (val: string) => {
                        if (val.length < 30) {
                          return 'Please enter at least 30 characters.';
                        }
                      },
                    })}
                  />
                  <div className="col-span-3">
                    <div className="rounded-md bg-blue-50 p-4 mb-3 md:mb-0 mt-3">
                      <div className="flex">
                        <div className="flex-shrink-0">
                          <Icons.InformationCircleIcon
                            className="h-5 w-5 text-blue-400"
                            aria-hidden="true"
                          />
                        </div>
                        <div className="ml-3 flex-1 md:flex md:justify-between">
                          <p className="text-sm text-blue-700 whitespace-pre-line">
                            {`Files other than images can be uploaded up to 10MB. \n Multiple files can be uploaded.`}
                          </p>
                        </div>
                      </div>
                    </div>
                    {files.length > 0 && (
                      <AttachedFiles
                        ctaText="Delete"
                        filesItems={files}
                        onClickCta={(item) => {
                          const newFiles = files.filter(
                            (f) => f.uuid !== item.uuid
                          );
                          setFiles(newFiles);
                        }}
                      />
                    )}

                    <button
                      type="button"
                      onClick={(e) => {
                        e.preventDefault();
                        inputRef.current?.click();
                      }}
                      className="mt-4 pl-2 pr-4 bg-white py-2 md:w-auto rounded-md text-brand-1 border text-sm font-semibold flex items-center gap-2"
                    >
                      <Icons.PaperClipIcon className="wh-4 text-brand-blue" />{' '}
                      Attach files
                    </button>
                    <input
                      type="file"
                      style={{ display: 'none' }}
                      ref={inputRef}
                      onChange={async (e) => {
                        const file = e.target.files?.item(0);
                        if (!file) {
                          return;
                        } else if (
                          !file.type.startsWith('image/') &&
                          file.size > 10e6
                        ) {
                          alert('Please upload a file smaller than 10 MB');
                          return;
                        }
                        setFiles((prev) => [
                          ...prev,
                          {
                            uuid: uniqueId(),
                            fileName: file.name,
                            file: file,
                          },
                        ]);
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="pt-5 text-center space-y-4">
            <p className="text-gray-400 text-sm">
              Your request will be shared with other qualified tutors.
            </p>
            <div className="flex flex-col md:flex-row justify-center gap-2">
              {/* <button
                type="button"
                className="rounded-md border border-gray-300 bg-white py-2 px-4 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
              >
                Cancel
              </button> */}
              <Button
                type="submit"
                text={isSubmitting ? 'Submitting' : 'Send a message'}
                isSubmitting={isSubmitting}
                disabled={isSubmitting}
                className="h-10 inline-flex justify-center rounded-md border border-transparent disabled:bg-gray-400  bg-brand-blue py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-brand-blue focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
              />
            </div>
          </div>
        </form>
      </>
    );
  };

  return (
    <>
      <HiddenHeadingMeta text="Quick Request | Linkstory" />
      <ReactHelmet
        keywords="request, session"
        title="Quick Request | Linkstory"
      />
      <div className="bg-[#F7F9FC] h-full">
        <div className="max-w-container py-4 lg:!py-8">
          <button
            onClick={() => push(locationState?.from?.pathname)}
            className="mb-4 pl-2 pr-4 bg-white py-2 md:w-auto rounded-md text-brand-1 border text-sm font-semibold hidden lg:flex items-center gap-2"
          >
            <Icons.ChevronLeftIcon className="wh-4 text-brand-blue" /> Back
          </button>
          <div className="min-w-0 flex-1">
            <h2 className="text-2xl font-bold leading-7 text-gray-900 sm:truncate sm:text-3xl sm:tracking-tight">
              {id ? 'Request session' : 'Quick Request'}
            </h2>
          </div>

          <div
            className={` flex ${mentor ? 'flex-col-reverse' : 'flex-col'}  ${
              mentor && 'lg:grid grid-cols-12'
            } gap-4 `}
          >
            {/* Back Button */}
            <div className="col-span-8 text-brand-blue"></div>
            <div className="col-span-8 space-y-4">
              {/* Left Section */}
              <StepsComponents
                steps={[
                  { id: '01', name: 'SEND QUICK REQUEST' },
                  { id: '02', name: 'GET OFFERS FROM TUTORS' },
                  { id: '03', name: 'ONLINE 1-ON-1 SESSION' },
                  { id: '04', name: 'PAY AFTER THE SESSION' },
                ]}
                currentStatusId={currentStatusId}
              />
              <div className=" space-y-4 lg:!mt-4">{formSection()}</div>
            </div>
            {/* Right Section */}
            {mentor && <div className="col-span-4 !mt-4">{profileCard()}</div>}
          </div>
        </div>
      </div>
    </>
  );
};
