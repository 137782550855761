import React, { FC, HTMLAttributes } from 'react';
import { User } from '../types';

interface VODMentorCardProps extends HTMLAttributes<HTMLDivElement> {
  mentor: User;
}

export const VODMentorCard: FC<VODMentorCardProps> = ({ mentor, ...props }) => {
  return (
    <div
      {...props}
      style={{
        backgroundImage: `url('${'https://source.unsplash.com/random'}')`,
      }}
      className="relative bg-cover bg-no-repeat rounded-md w-56 md:w-full h-56 flex flex-col justify-end items-center text-center cursor-pointer "
    >
      <h1 className="z-10 text-16 font-semibold text-white truncate-2-lines mx-2">
        {mentor.firstName} {mentor.lastName}
      </h1>
      <p className="z-10 mb-4 text-12 text-white truncate-2-lines">
        Electrical Engineering
      </p>
      <div className="absolute bg-gradient-to-t from-littleblack  bg-transparent w-full h-1/2 rounded-md" />
    </div>
  );
};
