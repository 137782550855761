import { LockClosedIcon, PlusIcon, TrashIcon } from '@heroicons/react/20/solid';
import { CreditCardIcon } from '@heroicons/react/24/outline';
import { fetcher } from '@src/plugins/react-query';
import React, { useState } from 'react';
import { useQuery } from 'react-query';
import { CreditCardPopUp } from '@components/CreditCardPopUp';
import { DeleteCreditCardPopUp } from '@components/DeleteCreditCardPopUp';
import creditCardBgImg from '@assets/img/credit-card-bg.png';
import { HiddenHeadingMeta } from '@src/components/HiddenHeadingMeta';
import { ReactHelmet } from '@src/components/ReactHelmet';
import { ReactComponent as LogoLinkstoryPlus } from '../assets/svg/Linkstory+Logo.svg';
import { ReactComponent as IconLock } from '@svg/icon-lock.svg';
import { ReactComponent as IconPowered } from '@svg/icon-stripe-power.svg';

export const MyCreditCardPage = () => {
  const [card, setCard] = useState<any>();
  const [creditCardPopup, setCreditCardPopup] = useState(false);
  const [deleteCreditCardPopup, setDeleteCreditCardPopup] = useState(false);

  const { data, isFetching } = useQuery<any>(`/credit-cards`, fetcher, {
    refetchOnWindowFocus: false,
    onSuccess(data) {
      if (data.data[0]) {
        setCard(data.data[0].card);
      }
    },
  });

  if (isFetching) return <></>;

  return (
    <>
      <HiddenHeadingMeta text="Payment method | Linkstory" />
      <ReactHelmet
        keywords="payment method"
        title="Payment method | Linkstory"
      />
      <CreditCardPopUp
        edit={card ? true : false}
        onClose={() => setCreditCardPopup(false)}
        open={creditCardPopup}
        card={card}
      />
      <DeleteCreditCardPopUp
        onClose={() => setDeleteCreditCardPopup(false)}
        open={deleteCreditCardPopup}
      />

      {data.data[0] ? (
        <>
          <div
            style={{ aspectRatio: '96 / 56', maxWidth: '512px' }}
            className="m-auto bg-gray-400 rounded-xl relative text-white shadow-2xl transition-transform transform hover:scale-105"
          >
            <img
              className="relative object-cover w-full h-full rounded-xl"
              src={creditCardBgImg}
              alt="card-background"
            />

            <div className="w-full absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 px-4 md:px-8">
              <div className="flex justify-end">
                <h1 className="text-2xl md:text-3xl font-bold ">
                  {card?.brand?.toUpperCase()}
                </h1>
              </div>
              <div className="">
                <p className="font-light text-sm md:text-base">Card Number</p>
                <p className="font-medium tracking-more-wider">
                  •••• •••• •••• {card?.last4}
                </p>
              </div>
              <div className="pt-3 md:pt-6">
                <div className="flex justify-between">
                  <div className="">
                    <p className="font-light text-xs">Expiry</p>
                    <p className="font-medium tracking-wider text-sm">
                      {card?.exp_month}/{card?.exp_year}
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <button
              className="bg-white absolute left-1/2 transform -translate-x-1/2 -bottom-4 p-2 rounded-full bg-opacity-70 hover:bg-opacity-100 hover:scale-110"
              onClick={() => setDeleteCreditCardPopup(true)}
            >
              <TrashIcon className="wh-4 text-gray-700" />
            </button>
          </div>
        </>
      ) : (
        <div className="text-center">
          <CreditCardIcon className="wh-8 mx-auto text-gray-600" />
          <h3 className="mt-2 text-sm font-medium text-gray-900">
            No registered payment method
          </h3>
          <p className="mt-1 text-sm text-gray-500">
            Get started by adding credit card info
          </p>
          <div className="mt-6">
            <button
              onClick={() => !card && setCreditCardPopup(true)}
              type="button"
              className="inline-flex items-center rounded-md border border-transparent bg-brand-blue px-4 py-2 text-sm font-medium text-white shadow-sm hover:brightness-95 focus:outline-none focus:ring-2 focus:ring-brand-blue focus:ring-offset-2"
            >
              <PlusIcon className="-ml-1 mr-2 h-5 w-5" aria-hidden="true" />
              Add
            </button>
          </div>
        </div>
      )}
      <div className="max-w-lg m-auto">
        <div className="ml-2 flex flex-wrap gap-2 items-center pt-4 mt-4 border-t text-[#32364E] justify-between">
          <div className="text-sm flex gap-2 ">
            <IconLock width={'15'} />
            <p>
              Guaranteed <strong>safe & secure</strong> payment
            </p>
          </div>
          <IconPowered width="100" />
        </div>
      </div>
    </>
  );
};
