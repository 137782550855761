import React, { FC, useEffect } from 'react';
import { fullModalState } from '../plugins/ridge';
import { Icon } from './icons';
import Modal from 'react-modal';
import { useOnClickOutside } from 'usehooks-ts';
import { useLocation } from 'react-router-dom';

const customStyles = {
  overlay: {
    backgroundColor: 'rgba(0, 0, 0, 0.25)',
    zIndex: 100,
  },
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    padding: 'unset',
    width: '90%',
    border: 'unset',
    borderRadius: 'unset',
    background: 'unset',
  },
};

// Make sure to bind modal to your appElement (https://reactcommunity.org/react-modal/accessibility/)
Modal.setAppElement('#root');

export const FullScreenModal: FC = () => {
  const modalState = fullModalState.useValue();
  const refPopup = React.useRef(null);
  const location = useLocation();

  function closeModal() {
    fullModalState.reset();
  }

  useEffect(() => {
    //  if route opens block body scroll
    if (modalState.open) {
      document.body.style.overflow = 'hidden';
    } else {
      return () => {
        document.body.style.overflow = 'auto';
      };
    }
  }, [modalState.open]);

  useEffect(() => {
    //  if route changes close modal
    closeModal();
  }, [location]);

  useOnClickOutside(refPopup, () => {
    closeModal();
  });

  if (!modalState.open) return <></>;

  return (
    <div>
      {/* <button onClick={openModal}>Open Modal</button> */}
      <Modal
        isOpen={modalState.open}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Example Modal"
      >
        <div
          ref={refPopup}
          className="flex flex-col pointer-events-auto overflow-hidden"
          style={{ maxHeight: 'calc(100vh - 100px)' }}
        >
          <button className="self-end" onClick={closeModal}>
            <Icon.X className="fill-current text-white" />
          </button>
          {/* <h2 ref={(_subtitle) => (subtitle = _subtitle)}>Hello</h2> */}
          {modalState.content}
        </div>
      </Modal>
    </div>
  );
};
