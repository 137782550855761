import { BanknotesIcon, CalendarIcon } from '@heroicons/react/24/outline';
import { EmptyState } from '@src/components/EmptyState';
import { HiddenHeadingMeta } from '@src/components/HiddenHeadingMeta';
import { Pagination } from '@src/components/Pagination';
import { ReactHelmet } from '@src/components/ReactHelmet';
import React from 'react';
import { useQuery } from 'react-query';
import { TransactionItem } from '@components/TransactionItem';
import { fetcher } from '@plugins/react-query';
import { Paginated, Payment } from '@src/types';

export const MyTransactionHistoryPage = () => {
  // 현재 <Paginated> 형태로 데이터가 넘어오지 않기 때문에 API 수정해서 Pagination 적용할 필요 있음
  const { data } = useQuery<Paginated<Payment>>(
    `/payments/paginated/me`,
    fetcher,
    {
      // onSuccess(data) {
      //   console.log('PAYMENT: ' + JSON.stringify(data));
      // },
    }
  );

  return (
    <>
      <HiddenHeadingMeta text="Transactions | Linkstory" />
      <ReactHelmet
        keywords="student, transactions"
        title="Transactions | Linkstory"
      />
      <div className="overflow-hidden bg-white shadow rounded-md max-w-[1100px]">
        <div className="border-b border-gray-200 bg-white px-4 py-5 sm:px-6">
          <div className="-ml-4 -mt-2 flex flex-wrap items-center justify-between sm:flex-nowrap">
            <div className="ml-4 mt-2 flex gap-2 items-center">
              <CalendarIcon className="wh-6 flex-shrink-0 self-center text-xs" />
              <h3 className="text-lg font-medium leading-6 text-gray-900">
                Transactions
              </h3>
            </div>
          </div>
        </div>
        <ul className="divide-y divide-gray-200">
          {data?.total === 0 ? (
            <div className="p-4">
              <EmptyState
                MainIcon={BanknotesIcon}
                title="No payment has been made yet."
                subTitle="Payment is made only when the session is finally completed."
              />
            </div>
          ) : (
            <>
              {data?.items
                ?.sort((a: any, b: any) => {
                  return a.createdAt < b.createdAt
                    ? -1
                    : a.createdAt > b.createdAt
                    ? 1
                    : 0;
                })
                .map((payment, index) => (
                  <TransactionItem payment={payment} index={index} />
                ))
                .reverse()}
            </>
          )}
        </ul>
        {data?.items && (
          <Pagination className="flex flex-wrap">
            <Pagination.Label count={data?.items.length} total={data?.total} />
            <Pagination.Nav
              basePath="/my/dashboard/transactions"
              total={data?.total}
            />
          </Pagination>
        )}
      </div>
    </>
  );
};
