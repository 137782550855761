import React, { FC } from 'react';
import { Subscription, User } from '../types';
import bottomBannerImg from '../assets/img/img-banner.jpg';
// import AboutImg04Small from '../assets/img/about-img-04@small.jpg';
import imgQuickRequest from '../assets/img/img-help-essays.png';
import { ButtonSubscription } from './ButtonSubscription';
import { Button } from '../components/Button';
import { Link, useHistory } from 'react-router-dom';

export interface CardBannerProps {
  authenticated?: boolean;
  subscription?: Subscription | undefined;
  me?: User | undefined;
  className?: string;
  cardClassName?: string;
  isEssay?: boolean;
  title: string;
  description: string;
}

export const CardBanner: FC<CardBannerProps> = ({
  authenticated,
  subscription,
  me,
  isEssay,
  title,
  description,
  className,
  cardClassName,
}) => {
  const now = new Date();
  const { push } = useHistory();

  return (
    <section className={` w-full bg-brand-black-bg pb-10 ${className}`}>
      <div className="max-w-container">
        <div
          className={`h-60 md:h-72 lg:h-[450px] overflow-hidden bg-center bg-cover bg-no-repeat relative flex justify-center rounded-lg  ${
            cardClassName ? cardClassName : 'bg-brand-orange'
          }`}
          // style={{ backgroundImage: `url('${bottomBannerImg}')` }}
        >
          <div className="p-6 md:p-10 w-full h-full flex flex-row justify-between items-center">
            <div className="w-full sm:px-6">
              <h2 className="text-2xl md:text-3xl lg:text-5xl font-bold">
                {title}
              </h2>
              <p className="mt-4 md:mt-12 text-sm md:text-lg font-normal whitespace-pre-line">
                {description}
              </p>

              {isEssay && (
                <Button
                  to="/student-request"
                  className={`mt-4 md:mt-12 text-white items-center rounded-full h-10 md:h-12 px-10 md:px-12 text-sm md:text-md bg-brand-black-bg`}
                >
                  Quick Request
                </Button>
              )}
            </div>
            <img
              className="hidden lg:block h-full rounded-md"
              src={isEssay ? imgQuickRequest : bottomBannerImg}
              alt={'girl'}
            />
          </div>
        </div>
      </div>
    </section>
  );
};
