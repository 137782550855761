import React from 'react';
import { Icon } from '../../../components/icons';
import { EssayCard } from '../../../components/EssayCard';
import { EssayItemType, Subscription, User } from '../../../types';
import { useAuth } from '../../../hooks';
import { Button } from '../../../components/Button';
import { useSubscriptionPopup } from '../../../hooks/subscription';
import { fetcher } from '../../../plugins/react-query';
import { useQuery } from 'react-query';
import { FREE_ESSAYS_ITEMS } from '../../../constants/freeEssays';
import { Link, useHistory, useLocation } from 'react-router-dom';

export const FreeEssaysPage = () => {
  const { push } = useHistory();
  const now = new Date();
  const { authenticated } = useAuth();
  const { pathname } = useLocation();

  const { data: me } = useQuery<User>('/users/me', fetcher, {
    enabled: !!authenticated,
  });
  const { data: subscription } = useQuery<Subscription>(
    `/subscriptions/valid`,
    fetcher,
    {
      enabled: !!authenticated,
    }
  );
  const { showSubsPopup, showSubscribedPopup } = useSubscriptionPopup(
    subscription,
    me
  );

  return (
    <div className="bg-brand-black-bg h-full text-white text-center py-28 ">
      <div className="max-w-container flex flex-col items-center">
        {/* start - header section */}
        <Icon.LinkstoryPlusLogo className="fill-current w-[130px] opacity-50" />
        <h2 className="text-3xl md:text-5xl font-bold mt-10">Free essays</h2>

        <div className="space-y-6 w-full mt-16">
          {FREE_ESSAYS_ITEMS.map((essay: EssayItemType, index: number) => {
            return (
              <>
                {!essay.locked && !authenticated ? (
                  <>
                    <EssayCard
                      essay={essay}
                      index={index}
                      authenticated={false}
                    />
                    {/* <div className="!my-16 flex items-center flex-col space-y-8">
                      <h3 className="text-xl lg:text-3xl whitespace-pre-line text-brand-orange font-semibold">
                        {`Sign up for free to read more essays. \nIt only takes 10 seconds.`}
                      </h3>
                      <Link
                        className="border-2 border-white text-white rounded-full h-10 md:h-12 px-5 md:px-8 text-normal tracking-wide flex items-center"
                        to={{
                          pathname: '/login',
                          state: { from: { pathname: pathname } },
                        }}
                      >
                        Sign up for free
                      </Link>
                    </div> */}
                  </>
                ) : (
                  <EssayCard
                    essay={essay}
                    index={index}
                    authenticated={authenticated}
                  />
                )}
              </>
            );
          })}
          {!authenticated && (
            <div className="!mt-20 space-y-4 flex flex-col items-center">
              <h4 className="font-semibold">WANT TO READ MORE?</h4>
              <h2 className="text-brand-orange text-xl lg:text-3xl !mb-10">
                Sign up free to read 10 new accepted essays every month
              </h2>
              <Button
                className="border-2 border-white text-white rounded-full h-10 md:h-12 px-5 md:px-8 text-normal tracking-wide"
                // onClick={() =>
                //   !subscription ||
                //   now.toISOString() > subscription?.current_period_end
                //     ? showSubsPopup()
                //     : showSubscribedPopup()
                // }
                onClick={() => push('/login')}
              >
                Sign up
              </Button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
