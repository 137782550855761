import { Pagination } from '@src/components/Pagination';
import { Link, useHistory } from 'react-router-dom';
import { Dropdown } from '@src/components/Dropdown';
import { Icon } from '@src/components/icons';
import { Checkbox } from '@src/components/Checkbox';
import {
  CalendarIcon,
  BriefcaseIcon,
  ListBulletIcon,
  AcademicCapIcon,
  MapPinIcon,
  MinusSmallIcon,
  PaperClipIcon,
  PlusSmallIcon,
  UserIcon,
  CheckCircleIcon,
  ClockIcon,
} from '@heroicons/react/24/outline';
import { BreadCrumbs } from '@src/components/BreadCrumbs';
import { Collapsible } from '@src/components/Collapsible';
import { useQueryString } from '@src/hooks';
import { useState } from 'react';
import {
  AppliedJobType,
  JobType,
  OrderType,
  Paginated,
  Subject,
} from '@src/types';
import { useQuery } from 'react-query';
import { fetcher } from '@src/plugins/react-query';
import {
  MomentFormat,
  utcToLocalFormat,
  utcToLocalFromNow,
} from '@src/plugins/moment';
import moment from 'moment';
import { HiddenHeadingMeta } from '@src/components/HiddenHeadingMeta';
import { ReactHelmet } from '@src/components/ReactHelmet';

const breadCrumbsPages = [
  { name: 'Jobs', path: '/my/dashboard/jobs', current: false },
  {
    name: 'My Applications',
    path: '/my/dashboard/jobs/applied',
  },
];

export const JobsApplied = () => {
  const { push } = useHistory();

  const [order, setOrder] = useState<OrderType>(OrderType.DESC);
  const queryString = useQueryString({ sort: order });

  const { data: subjectsItems } = useQuery<Subject[]>(`/v2/subjects`, fetcher, {
    keepPreviousData: true,
    refetchOnWindowFocus: false,
  });

  const { data: AppliedJobsItems, isFetching: AppliedJobsItemsFetching } =
    useQuery<Paginated<AppliedJobType>>(
      `/job-submits/my-submitted${queryString}`,
      fetcher,
      {
        keepPreviousData: true,
        refetchOnWindowFocus: false,
      }
    );

  const contentsSection = () => {
    return (
      <>
        <div className="flex justify-between items-center gap-4">
          <h2 className="text-base">
            <strong>{AppliedJobsItems?.total ?? '0'}</strong> Applied jobs
          </h2>
          <Dropdown placeholder="sort">
            <button className="flex space-x-2 items-center text-xs bg-white relative w-full border border-gray-200 rounded-md shadow-sm px-3 h-10 text-left cursor-pointer focus:outline-none focus:ring-1 focus:ring-brand-1 focus:border-brand-1">
              <div>{order === OrderType.DESC ? 'Newest' : 'Oldest'}</div>
              <Icon.ChevronDown className="wh-4" />
            </button>
            <Dropdown.View className="right-0 rounded-md  flex flex-col shadow-md overflow-hidden text-xs">
              <button
                onClick={() => setOrder(OrderType.DESC)}
                className="hover:bg-gray-100 text-black px-6 py-3 text-left"
              >
                Newest
              </button>
              <button
                onClick={() => setOrder(OrderType.ASC)}
                className="hover:bg-gray-100 text-black px-6 py-3 text-left"
              >
                Oldest
              </button>
            </Dropdown.View>
          </Dropdown>
        </div>
        <div className="overflow-hidden bg-white shadow sm:rounded-md">
          <ul className="divide-y divide-gray-200">
            {AppliedJobsItems?.items.map((item) => (
              <li key={item.id}>
                <div className="block hover:bg-gray-50 w-full">
                  <div className="px-4 py-4 sm:px-6 w-full">
                    <div className="sm:flex sm:justify-between items-center justify-between">
                      <Link
                        to={`/my/dashboard/jobs/applied/${item.id}`}
                        className="truncate text-base font-medium text-indigo-600"
                      >
                        {
                          subjectsItems?.find(
                            (o) => o.id === item.job.subjectId
                          )?.title
                        }
                      </Link>
                    </div>

                    <div className="mt-2 gap-2 sm:flex sm:justify-between flex-wrap lg:flex-nowrap">
                      <div className="sm:flex">
                        <div className="mt-2 sm:mt-0 flex flex-shrink-0">
                          <p className="inline-flex rounded-full bg-green-100 px-2 text-xs font-semibold leading-5 text-green-800 items-center">
                            <AcademicCapIcon
                              className="mr-1.5 wh-4 flex-shrink-0"
                              aria-hidden="true"
                            />
                            {
                              subjectsItems?.find(
                                (o) => o.id === item.job.subjectId
                              )?.title
                            }
                          </p>
                        </div>
                        <p className="flex items-center text-sm text-gray-500">
                          <UserIcon
                            className="mx-1.5 wh-4 flex-shrink-0 text-gray-400"
                            aria-hidden="true"
                          />
                          {item.student.firstName}
                        </p>
                        <p className="mt-2 flex items-center text-sm text-gray-500 sm:mt-0 sm:ml-4">
                          <MapPinIcon
                            className="mr-1.5 wh-4 flex-shrink-0 text-gray-400"
                            aria-hidden="true"
                          />
                          Online
                        </p>
                      </div>
                      <div className="mt-2 sm:mt-0 hidden sm:flex item-center">
                        <p className=" flex items-center text-sm text-gray-500">
                          <CheckCircleIcon
                            className="mr-1.5 wh-4 flex-shrink-0 text-green-400"
                            aria-hidden="true"
                          />
                          Applied on{' '}
                          <time
                            className="ml-1.5 text-gray-900"
                            dateTime={item.createdAt}
                          >
                            {utcToLocalFormat(
                              item.createdAt,
                              MomentFormat.YYYYMMDDhmma
                            )}
                          </time>
                        </p>
                      </div>
                    </div>

                    <div className="mt-2 text-sm text-left max-w-[700px] truncate-2-lines">
                      {item.job.description}
                    </div>

                    <Collapsible
                      closedButton={
                        <>
                          <PlusSmallIcon className="wh-4" />
                          <p>Show details</p>
                        </>
                      }
                      openedButton={
                        <>
                          <MinusSmallIcon className="wh-4" />
                          <p>Hide details</p>
                        </>
                      }
                      btnClassName="mt-3 text-sm font-medium text-brand-blue hover:text-indigo-500 flex items-center gap-1 border py-1.5 px-2.5 rounded-md shadow-xs"
                    >
                      <div className="mt-2 overflow-hidden bg-white shadow sm:rounded-lg text-left">
                        <div className="border-t border-gray-200 px-4 py-5 sm:px-6">
                          <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
                            <div className="sm:col-span-2">
                              <dt className="text-sm font-medium text-gray-500">
                                Availability
                              </dt>
                              <dd className="mt-1 text-sm text-gray-900 sm:col-span-1 space-y-3">
                                <dt className="text-sm flex items-center gap-2">
                                  <CalendarIcon
                                    className="wh-4 flex-shrink-0"
                                    aria-hidden="true"
                                  />
                                  <span>
                                    {utcToLocalFormat(
                                      item.job.firstRequestTime,
                                      MomentFormat.YYYYMMDDhmma
                                    )}
                                    {` `}
                                    {moment.tz(moment.tz.guess()).zoneAbbr()}
                                  </span>
                                </dt>
                                {item?.job.secondRequestTime && (
                                  <dt className="text-sm flex items-center gap-2">
                                    <CalendarIcon
                                      className="wh-4 flex-shrink-0"
                                      aria-hidden="true"
                                    />
                                    <span>
                                      {utcToLocalFormat(
                                        item?.job.secondRequestTime,
                                        MomentFormat.YYYYMMDDhmma
                                      )}
                                      {` `}
                                      {moment.tz(moment.tz.guess()).zoneAbbr()}
                                    </span>
                                  </dt>
                                )}
                                {item?.job.thirdRequestTime && (
                                  <dt className="text-sm flex items-center gap-2">
                                    <CalendarIcon
                                      className="wh-4 flex-shrink-0"
                                      aria-hidden="true"
                                    />
                                    <span>
                                      {utcToLocalFormat(
                                        item?.job.thirdRequestTime,
                                        MomentFormat.YYYYMMDDhmma
                                      )}
                                      {` `}
                                      {moment.tz(moment.tz.guess()).zoneAbbr()}
                                    </span>
                                  </dt>
                                )}
                              </dd>
                            </div>
                            {/* <div className="sm:col-span-2">
                              <dt className="text-sm font-medium text-gray-500">
                                Attachments
                              </dt>
                              <dd className="mt-1 text-sm text-gray-900">
                                <ul className="divide-y divide-gray-200 rounded-md border border-gray-200">
                                  <li className="flex items-center justify-between py-3 pl-3 pr-4 text-sm">
                                    <div className="flex w-0 flex-1 items-center">
                                      <PaperClipIcon
                                        className="h-5 w-5 flex-shrink-0 text-gray-400"
                                        aria-hidden="true"
                                      />
                                      <span className="ml-2 w-0 flex-1 truncate">
                                        essay_review_request.pdf
                                      </span>
                                    </div>
                                    <div className="ml-4 flex-shrink-0">
                                      <button className="font-medium text-indigo-600 hover:text-indigo-500">
                                        Download
                                      </button>
                                    </div>
                                  </li>
                                  <li className="flex items-center justify-between py-3 pl-3 pr-4 text-sm">
                                    <div className="flex w-0 flex-1 items-center">
                                      <PaperClipIcon
                                        className="h-5 w-5 flex-shrink-0 text-gray-400"
                                        aria-hidden="true"
                                      />
                                      <span className="ml-2 w-0 flex-1 truncate">
                                        appendix.pdf
                                      </span>
                                    </div>
                                    <div className="ml-4 flex-shrink-0">
                                      <button className="font-medium text-indigo-600 hover:text-indigo-500">
                                        Download
                                      </button>
                                    </div>
                                  </li>
                                </ul>
                              </dd>
                            </div> */}
                          </dl>
                        </div>
                      </div>
                    </Collapsible>
                    <div className="mt-4 gap-2 sm:flex sm:justify-between flex-wrap lg:flex-nowrap">
                      <div className="sm:flex">
                        <div className="mt-2 sm:mt-0 flex flex-shrink-0">
                          <div className="mt-2 flex items-center text-sm text-gray-500 sm:mt-0">
                            <ClockIcon
                              className="mr-1.5 wh-4 flex-shrink-0 text-gray-400 "
                              aria-hidden="true"
                            />
                            <p>
                              <time dateTime={item.createdAt}>
                                Posted {utcToLocalFromNow(item.job.createdAt)}
                              </time>
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </li>
            ))}
          </ul>
        </div>
      </>
    );
  };

  return (
    <>
      <HiddenHeadingMeta text="Applied Jobs | Linkstory" />
      <ReactHelmet keywords="applied, jobs" title="Applied Jobs | Linkstory" />
      <BreadCrumbs
        homePath="/my/dashboard"
        breadCrumbsPages={breadCrumbsPages}
      />
      <div className=" bg-white border w-full rounded-md overflow-hidden">
        <section className="whitespace-pre-line">
          <div className="border-b border-gray-200 bg-white px-4 py-5 sm:px-6">
            <div className="-ml-4 -mt-2 flex flex-wrap items-center justify-between sm:flex-nowrap gap-2">
              <div className="ml-4 mt-2 flex gap-2 items-center">
                <BriefcaseIcon className="wh-6 flex-shrink-0 self-center text-xs" />
                <h3 className="text-lg font-medium leading-6 text-gray-900">
                  My Job Applications
                </h3>
              </div>
              <div className="ml-4 mt-2 flex-shrink-0">
                <Link
                  to={'/my/dashboard/jobs'}
                  className="inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 gap-1"
                >
                  <ListBulletIcon className="wh-4 flex-shrink-0 self-center text-xs" />
                  <p>Jobs</p>
                </Link>
              </div>
            </div>
          </div>
          <div className="p-4 md:p-6 space-y-4">{contentsSection()}</div>
        </section>
      </div>
      {AppliedJobsItems && (
        <Pagination className="flex flex-wrap">
          <Pagination.Label
            count={AppliedJobsItems?.items.length}
            total={AppliedJobsItems?.total}
          />
          <Pagination.Nav
            basePath="/my/dashboard/jobs/applied"
            total={AppliedJobsItems?.total}
          />
        </Pagination>
      )}
    </>
  );
};
