import axios from 'axios';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useQuery } from 'react-query';
import { Link, useHistory, useLocation } from 'react-router-dom';
// import HeroImg from '../assets/img/heroimg-signup.png';
import { AvatarInput } from '@components/AvatarInput';
import { Button } from '@components/Button';
// import { Checkbox } from '../components/Checkbox';
import { Select } from '@components/Select';
import { TextField } from '@components/TextField';
import { useAuth } from '@src/hooks';
import { api } from '@plugins/axios';
import { fetcher } from '@plugins/react-query';
import {
  SocialSignUp,
  Role,
  Tag,
  TagType,
  MailChimpMemberStatus,
  SignUpType,
  StudentType,
  ReferralType,
  emailVerifiacationStatus,
  stibeeGroupId,
} from '@src/types';
import { resizeImage } from '@plugins/compress';
import { Label } from '@components/Label';
import { popupState } from '@plugins/ridge';
import { Icon } from '@components/icons';
import { ErrorMessage } from '@hookform/error-message';
import ReactTooltip from 'react-tooltip';
import { postMailChimpAddMember } from '@api/mailchimp';
import moment from 'moment';
import { ReactHelmet } from '@src/components/ReactHelmet';
import mixpanel from 'mixpanel-browser';

interface FormValues {
  firstName: string;
  lastName: string;
  email: string;
  password: string;
  confirmPassword: string;
  year: string;
  month: string;
  day: string;
  school?: number;
  major?: number;
  sat?: number;
  gpa?: number;
  role: Role;
  // studentType: StudentType;
  // referralType: ReferralType;
}

export const SignupStudentPage = () => {
  const { signup } = useAuth();
  const { state, pathname } = useLocation<SocialSignUp>();
  const { push } = useHistory();
  const [avatar, setAvatar] = useState<any>();
  const [googleIdToken, setGoogleIdToken] = useState('');
  const [facebookIdToken, setFacebookIdToken] = useState('');
  const [, setUploading] = useState(false);
  const [terms] = useState(true);
  const [marketing] = useState(true);

  const {
    register,
    watch,
    setValue,
    handleSubmit,
    setError,
    clearErrors,
    trigger,
    formState: { errors, isDirty, isValid },
  } = useForm<FormValues>({
    mode: 'onChange',
    //@ts-ignore
    defaultValues: { role: Role.STUDENT, sat: 0, gpa: 0 },
  });
  const [isSubmitting, setIsSubmitting] = useState(false);

  const school = watch('school');
  const y = watch('year');
  const m =
    watch('month')?.length === 1 ? `0${watch('month')}` : watch('month');
  const d = watch('day')?.length === 1 ? `0${watch('day')}` : watch('day');

  const { data: schoolTags } = useQuery<Tag[]>(
    `/tags?type=${TagType.SCHOOL}`,
    fetcher
  );
  const { data: majorTags } = useQuery<Tag[]>(
    `/tags?type=${TagType.MAJOR}`,
    fetcher
  );

  const generateRandomPassword = (length: number) => {
    let result = '';
    const characterSet = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz';
    const characterSetLength = characterSet.length;
    const numberSet = '0123456789';
    const numberSetLength = numberSet.length;

    for (let i = 0; i < length; i++) {
      result += characterSet.charAt(
        Math.floor(Math.random() * characterSetLength)
      );
      result += numberSet.charAt(Math.floor(Math.random() * numberSetLength));
    }

    return result;
  };

  const getAge = () => {
    const today = new Date();
    const dob: string = watch('year') + m + d;
    const year = +dob.substring(0, 4);
    const month = +dob.substring(4, 6);
    const day = +dob.substring(6, 8);
    const birthDate = new Date(year, month - 1, day);

    let age = today.getFullYear() - birthDate.getFullYear();
    const calcMonth = today.getMonth() - birthDate.getMonth();
    if (
      calcMonth < 0 ||
      (calcMonth === 0 && today.getDate() < birthDate.getDate())
    ) {
      age--;
    }
    // console.log(age);
    return age;
  };

  const getBirthDay = () => {
    const dob: string = watch('year') + m + d;
    const year = +dob.substring(0, 4);
    const month = +dob.substring(4, 6);
    const day = +dob.substring(6, 8);
    const birthDate = moment(new Date(year, month - 1, day)).format(
      'YYYY-MM-DD'
    );
    return birthDate;
  };

  // 13세 이상인지 확인 후 아닐 경우 에러 반환
  useEffect(() => {
    if (y && m && d && getAge() < 13) {
      setError('year', {
        type: 'manual',
        message: 'You must be at least 13 years old to use Linkstory',
      });
    } else {
      clearErrors(['year']);
    }
  }, [y, m, d]);

  useEffect(() => {
    setValue('school', schoolTags?.[0]?.id);
  }, [setValue, schoolTags]);

  useEffect(() => {
    setValue('major', majorTags?.find((m) => m.parentId === school)?.id);
  }, [setValue, school, majorTags]);

  useEffect(() => {
    if (state) {
      if (state.google) {
        if (state.google.birthDate) {
          setValue('year', state.google.birthDate.year.toString());
          setValue('month', state.google.birthDate.month.toString());
          setValue('day', state.google.birthDate.day.toString());
        }
        setValue('firstName', state.google.firstName);
        setValue('lastName', state.google.lastName);
        setValue('email', state.google.email);
        setAvatar(state.google.picture);
        setGoogleIdToken(state.google.idToken);
      } else if (state.facebook) {
        if (state.facebook.email) {
          setValue('email', state.facebook.email);
        }
        setValue('firstName', state.facebook.first_name);
        setValue('lastName', state.facebook.last_name);
        setAvatar(state.facebook.picture?.data.url);
        setFacebookIdToken(state.facebook.id);
      }
      setValue('password', generateRandomPassword(4));
      setValue('confirmPassword', watch('password'));
      trigger();
    }
  }, [state]);

  // console.log(avatar);

  const onSubmit = handleSubmit((data) => {
    setIsSubmitting(true);
    const dob = watch('year') + m + d;

    if (getAge() < 13) {
      popupState.set({
        icon: false,
        title: 'You must be at least 13 years old to use LinkStory.',
        description: `You entered ${
          watch('year') + '/' + m + '/' + d
        } for your date of birth, which would make you you younger than 13.`,
        onClick: () => {},
      });
      return;
    }

    const { school, major, ...user } = data;

    signup({
      dob: dob,
      ...user,
      // studentType: user.studentType,
      googleId: googleIdToken,
      facebookId: facebookIdToken,
    })
      .then(async () => {
        // mixpannel register user
        mixpanel.alias(user.email);
        mixpanel.people.set({
          $first_name: user.firstName,
          $last_name: user.lastName,
          $email: user.email,
          'Email Verification': emailVerifiacationStatus.PENDING,
          Role: user.role,
          'Account type': googleIdToken
            ? SignUpType.GOOGLE
            : facebookIdToken
            ? SignUpType.FACEBOOK
            : SignUpType.EMAIL,
        });
        mixpanel.people.set_once({
          'Sign Up Date': new Date().toISOString(),
        });
        mixpanel.track('Sign Up');

        // mixpanel.identify must be called in order to associate the profile properties you set
        // with that user. You only need to call it once per page load for a given user.
        mixpanel.identify(user.email);

        // 뉴스레터 관리 서비스 Stibee API 구독 연동
        // https://api.stibee.com/docs/ -> 상세 API 문서는 해당 링크에서 확인하세요.
        await axios
          .post(
            `https://api.stibee.com/v1/lists/${process.env.REACT_APP_STIBEE_LIST_ID}/subscribers`,
            {
              eventOccuredBy: 'MANUAL',
              confirmEmailYN: 'N',
              groupIds: [
                process.env.REACT_APP_WEB_ENV === 'prod'
                  ? stibeeGroupId.PROD_STUDENT_GROUP_ID
                  : stibeeGroupId.STAGE_STUDENT_GROUP_ID,
              ],
              subscribers: [
                {
                  $ad_agreed: 'Y',
                  email: user.email,
                  firstName: user.firstName,
                  lastName: user.lastName,
                  birthday: getBirthDay(),
                  role: user.role,
                  signUpType: googleIdToken
                    ? SignUpType.GOOGLE
                    : facebookIdToken
                    ? SignUpType.FACEBOOK
                    : SignUpType.EMAIL,
                },
              ],
            },
            {
              headers: {
                AccessToken: process.env.REACT_APP_STIBEE_ACCESS_TOKEN,
              },
            }
          )
          .catch((err) => console.log(err));

        // mailchimp add member
        postMailChimpAddMember({
          email_address: user.email,
          status: MailChimpMemberStatus.SUBSCRIBED,
          merge_fields: {
            FNAME: user.firstName,
            LNAME: user.lastName,
            BIRTHDAY: getBirthDay(),
            ROLE: user.role,
            // TYPE: user.studentType,
            // REFERRAL: user.referralType,
            SIGNUPTIME: new Date().toDateString(),
            SIGNUPTYPE: googleIdToken
              ? SignUpType.GOOGLE
              : facebookIdToken
              ? SignUpType.FACEBOOK
              : SignUpType.EMAIL,
          },
        });

        // 마케팅 수신 동의
        await api
          .post(`/marketing-agreements/`, {
            agreement: marketing,
          })
          .catch((err) => {
            throw console.log(err);
          });
        if (!avatar) return;
        setUploading(true);

        await api.post(`/tag-links/add`, {
          school: school,
          major: major,
        });

        if (avatar.name) {
          const { data: post } = await api.get(
            `/s3/presignedPost?filename=${avatar.name}`
          );
          const formData = new FormData();
          Object.entries(post.fields).forEach(([name, value]) =>
            formData.append(name, value as any)
          );
          formData.append('file', await resizeImage(avatar));
          await axios.post(post.url, formData, {
            headers: { 'Content-Type': 'multipart/form-data' },
          });
          api.patch('/users/me', { avatar: post.fields.key });
        } else {
          api.patch('/users/me', { avatar: avatar }).then(async () => {
            push('/');
          });
        }
      })
      .then(() => {
        gtmSignup();
        if (facebookIdToken || googleIdToken) {
          mixpanel.identify(data.email);
          mixpanel.people.set({
            'Email Verification': emailVerifiacationStatus.VERIFIED,
          });
          mixpanel.track('Email Validated');
          push('/');
        } else {
          localStorage.clear();
          push('/signup/student/pending', {
            email: data.email,
          });
        }
        setIsSubmitting(false);
      })
      .catch((err) => {
        console.log(err);
        alert(
          'This email cannot be used or is already connected to an account. '
        );
        setIsSubmitting(false);
      });
  });

  const maxOffset = 70;
  const thisYear = new Date().getFullYear();
  const allYears = [];
  for (let x = 0; x <= maxOffset; x++) {
    allYears.push(thisYear - x);
  }

  // const studentTypeList = [
  //   {
  //     id: 1,
  //     value: StudentType.HIGH_SCHOOL_STUDENT,
  //     title: 'High School Student',
  //   },
  //   {
  //     id: 2,
  //     value: StudentType.PARENT,
  //     title: 'Parent',
  //   },
  //   {
  //     id: 3,
  //     value: StudentType.TRANSFER_STUDENT,
  //     title: 'Transfer Student',
  //   },
  //   {
  //     id: 4,
  //     value: StudentType.HIGH_SCHOOL_COUNSELOR,
  //     title: 'High School Counselor',
  //   },
  //   {
  //     id: 5,
  //     value: StudentType.INDEPENDENT_EDUCATIONAL_COUNSELOR,
  //     title: 'Independent Educational Counselor (IEC)',
  //   },
  // ].map((item) => {
  //   return (
  //     <>
  //       <option key={item.value} value={item.value}>
  //         {item.title}
  //       </option>
  //     </>
  //   );
  // });

  // const referralTypeList = [
  //   {
  //     id: 1,
  //     value: ReferralType.INSTAGRAM,
  //     title: 'Instagram',
  //   },
  //   {
  //     id: 2,
  //     value: ReferralType.FACEBOOK,
  //     title: 'Facebook',
  //   },
  //   {
  //     id: 3,
  //     value: ReferralType.YOUTUBE,
  //     title: 'Youtube',
  //   },
  //   {
  //     id: 4,
  //     value: ReferralType.TIKTOK,
  //     title: 'Tiktok',
  //   },
  //   {
  //     id: 5,
  //     value: ReferralType.TWITTER,
  //     title: 'Twitter',
  //   },
  //   {
  //     id: 6,
  //     value: ReferralType.EMAIL,
  //     title: 'Email',
  //   },
  //   {
  //     id: 7,
  //     value: ReferralType.GOOGLE,
  //     title: 'Google',
  //   },
  //   {
  //     id: 8,
  //     value: ReferralType.WORD_OF_MOUTH,
  //     title: 'Word of mouth',
  //   },
  //   {
  //     id: 9,
  //     value: ReferralType.OTHER,
  //     title: 'Other',
  //   },
  // ].map((item) => {
  //   return (
  //     <>
  //       <option key={item.value} value={item.value}>
  //         {item.title}
  //       </option>
  //     </>
  //   );
  // });

  const yearList = allYears.map((y) => {
    return (
      <>
        <option key={y} value={y}>
          {y}
        </option>
      </>
    );
  });
  const monthList = Array.from({ length: 12 }, (_, i) => i + 1).map((m) => {
    return (
      <>
        <option key={m} value={m}>
          {m}
        </option>
      </>
    );
  });
  const days = new Date(
    Number(watch('year')),
    Number(watch('month')),
    0
  ).getDate();
  const dayList = Array.from({ length: days }, (_, i) => i + 1).map((d) => {
    return (
      <>
        <option key={d} value={d}>
          {d}
        </option>
      </>
    );
  });

  const gtmSignup = () => {
    window.dataLayer.push({ ecommerce: null });
    window.dataLayer.push({
      event: 'sign_up',
      ecommerce: { method: 'student' },
    });
  };

  return (
    <>
      <ReactHelmet
        keywords="signup, student"
        description="Sign up as a student and get started."
        title="Student Sign Up | Linkstory"
      />
      <div
        className="flex-1"
        // style={{ backgroundImage: `url('${HeroImg}')` }}
      >
        <div className="max-w-screen-xl mx-auto flex items-center justify-center px-4">
          <div className="max-w-lg bg-white rounded-md py-8 px-4 md:px-10 my-20 w-full">
            <h1 className="text-3xl font-bold text-center pb-6">
              {!state ? 'Sign up as a Student' : 'Continue Sign Up'}
            </h1>
            <form
              className="p-4 flex flex-col space-y-4"
              onSubmit={handleSubmit(() => {
                try {
                  onSubmit();
                } catch (e) {
                  alert('sign up error');
                }
              })}
            >
              {/* <AvatarInput
                onChange={(file) => setAvatar(file)}
                avatarUrl={!avatar?.name && avatar}
              />
              <text className="text-center text-sm text-gray-600">
                Please upload a profile picture (.jpg or .png)
              </text> */}

              {/* <div>
                <div className="flex items-center flex-wrap space-y-1">
                  <Select
                    label="Sign up as a...*"
                    placeholder="User type*"
                    {...register('studentType', {
                      required: 'Please select option for this field',
                    })}
                    wrapClassName="w-full"
                  >
                    <option value="" selected disabled hidden>
                      Please select from below
                    </option>
                    {studentTypeList}
                  </Select>
                </div>
              </div>
              <div>
                <div className="flex items-center flex-wrap space-y-1">
                  <Select
                    label="How did you hear about us?*"
                    placeholder="Referral type*"
                    {...register('referralType', {
                      required: 'Please select option for this field',
                    })}
                    wrapClassName="w-full"
                  >
                    <option value="" selected disabled hidden>
                      Please select from below
                    </option>
                    {referralTypeList}
                  </Select>
                </div>
              </div> */}

              <TextField
                label="First name*"
                placeholder="Enter your first name"
                helper={errors.firstName?.message}
                {...register('firstName', {
                  required: 'Please enter your first name.',
                  validate: {
                    alphabetWithSpace: (value) =>
                      (value && /^[a-zA-Z ]*$/.test(value)) ||
                      'Only alphabets and spaces are allowed.',
                  },
                })}
              />

              <TextField
                label="Last name*"
                placeholder="Enter your last name"
                helper={errors.lastName?.message}
                {...register('lastName', {
                  required: 'Please enter your last name.',
                  validate: {
                    alphabetWithSpacesAndPunctuation: (value) =>
                      (value && /^[a-zA-Z .]*$/.test(value)) ||
                      'Only alphabets, spaces and periods are allowed.',
                  },
                })}
              />
              <>
                {!state && (
                  <>
                    <TextField
                      type="email"
                      label="Email*"
                      placeholder="Enter your email"
                      helper={errors.email?.message}
                      {...register('email', {
                        // pattern:
                        //   /^(([^<>()\[\].,;:\s@"]+(\.[^<>()\[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i,
                        required: 'Please enter your email.',
                        validate: {
                          email: (value: string) => {
                            const regex =
                              /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
                            if (!regex.test(value)) {
                              return 'Please enter valid email format.';
                            }
                          },
                        },
                      })}
                    />
                  </>
                )}

                {!state && (
                  <>
                    <div>
                      <div className="flex justify-start items-center space-x-2 w-full mb-1">
                        <Label text="Password*" />
                        <p
                          data-for="password-tooltip"
                          data-tip="Use 8 or more characters with a mix of letters, numbers & symbols"
                        >
                          <Icon.InfoOutlined
                            width="16"
                            height="16"
                            className={`fill-current text-gray-500`}
                          />
                        </p>
                        <ReactTooltip
                          id="password-tooltip"
                          className="max-w-[80vw]"
                          delayHide={1000}
                          effect="solid"
                        />
                      </div>
                      <TextField
                        autoComplete="off"
                        isPassword={true}
                        placeholder="Enter your password"
                        helper={errors.password?.message}
                        {...register('password', {
                          required: 'Please enter your password.',
                          validate: {
                            banned: (value: string) => {
                              const regex =
                                /^[~`!@#$%^&*()_+=[\]\{}|;':",.\/<>?a-zA-Z0-9-]+$/;
                              if (!regex.test(value)) {
                                return 'Only use letters, numbers, and common punctuation characters';
                              }
                            },
                            length: (value) =>
                              (value && value.length >= 8) ||
                              'Use 8 characters or more for your password',
                          },
                        })}
                      />
                    </div>
                    <>
                      <TextField
                        autoComplete="off"
                        isPassword={true}
                        label="Confirm password"
                        placeholder="Enter your password"
                        helper={errors.confirmPassword?.message}
                        {...register('confirmPassword', {
                          required: 'Please enter your password.',
                          validate: (val: string) => {
                            if (watch('password') !== val) {
                              return 'Those passwords didn’t match. Try again.';
                            }
                          },
                        })}
                      />
                    </>
                  </>
                )}
              </>

              <div>
                <div className="flex justify-start items-center space-x-2 w-full mb-1">
                  <Label text="Date of birth*" />
                  <p
                    data-for="date-of-birth-tooltip"
                    data-tip={`You must be at least 13 years \nold to use Linkstory`}
                  >
                    <Icon.InfoOutlined
                      width="16"
                      height="16"
                      className={`fill-current text-gray-500`}
                    />
                  </p>
                  <ReactTooltip
                    id="date-of-birth-tooltip"
                    className="max-w-[80vw]"
                    delayHide={1000}
                    effect="solid"
                  />
                </div>

                <div className="flex space-x-3.5 mt-0">
                  <div className="w-full">
                    <Select
                      placeholder="Year"
                      {...register('year', {
                        required: 'Please select your year.',
                      })}
                    >
                      <option value="" selected disabled hidden>
                        YYYY
                      </option>
                      {yearList}
                    </Select>
                  </div>
                  <div className="w-full">
                    <Select
                      placeholder="Month"
                      {...register('month', {
                        required: 'Please select your month.',
                      })}
                    >
                      <option value="" selected disabled hidden>
                        MM
                      </option>
                      {monthList}
                    </Select>
                  </div>
                  <div className="w-full">
                    <Select
                      placeholder="Day"
                      {...register('day', {
                        required: 'Please select your day.',
                      })}
                    >
                      <option value="" selected disabled hidden>
                        DD
                      </option>
                      {dayList}
                    </Select>
                  </div>
                </div>
              </div>
              <ErrorMessage
                errors={errors}
                name="year"
                render={({ message }) => (
                  <p className="!mt-1 text-sm text-error">{message}</p>
                )}
              />

              <div className="flex items-center">
                <p className="text-sm">
                  {`By signing up, you agree to Linkstory's \n`}
                  <Link
                    target="_blank"
                    rel="noopener noreferrer"
                    to="/termsandconditions"
                    className="underline font-semibold"
                  >
                    Terms & Conditions
                  </Link>
                  {` and `}
                  <Link
                    target="_blank"
                    rel="noopener noreferrer"
                    to="/privacy"
                    className="underline font-semibold"
                  >
                    Privacy Policy
                  </Link>
                </p>
              </div>
              <div className="flex flex-col space-y-4 pt-8">
                <Button
                  text={isSubmitting ? 'Submitting' : 'Sign Up'}
                  className="filled-brand-1 rounded-full disabled:bg-gray-400"
                  isSubmitting={isSubmitting}
                  disabled={!isDirty || !isValid || !terms || isSubmitting}
                />
                <p className="text-center">
                  Already have an account?
                  <Link to="/login" className="underline mx-1">
                    Log in
                  </Link>
                </p>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};
