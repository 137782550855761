import React, { useEffect, useState } from 'react';
import { FC } from 'react';
import { api } from '../plugins/axios';
import { Button } from './Button';
import { Icon } from './icons';
import ClickAwayListener from 'react-click-away-listener';
import { Checkbox } from '../components/Checkbox';
import { useLocation } from 'react-router-dom';
import { User } from '@src/types';
import mixpanel from 'mixpanel-browser';

interface CreditCardPopUpProps {
  open: boolean;
  onClose: () => void;
}

export const DeleteCreditCardPopUp: FC<CreditCardPopUpProps> = ({
  onClose,
  open,
}) => {
  const [confirm, setConfirm] = useState(false);
  const location = useLocation();

  useEffect(() => {
    //  if route opens block body scroll
    if (open) {
      document.body.style.overflow = 'hidden';
    } else {
      return () => {
        document.body.style.overflow = 'auto';
      };
    }
  }, [open]);

  const handleOnClose = () => {
    document.body.style.overflow = 'auto';
    onClose();
  };

  useEffect(() => {
    //  if route changes close modal
    setConfirm(false);
    handleOnClose();
  }, [location]);

  if (!open) return <></>;
  return (
    <div className="z-50 fixed inset-0 bg-littleblack flex items-center justify-center px-4">
      <ClickAwayListener onClickAway={handleOnClose}>
        <div className="w-full max-w-md flex flex-col items-center bg-white rounded-xl overflow-hidden p-6 space-y-4">
          <div className="flex w-full justify-end">
            <button onClick={handleOnClose}>
              <Icon.X />
            </button>
          </div>
          <p className="pb-5 text-center">
            Remove this payment method? <br />
            Removing it means you will not be able to use this payment method
            for other services.
          </p>
          <Checkbox
            label={'I understand'}
            checked={confirm}
            onClick={() => setConfirm(!confirm)}
          />
          <Button
            text={'Remove'}
            className={` bg-brand-1 text-white rounded-full w-full mt-5 `}
            onClick={() => {
              api
                .delete(`/credit-cards`)
                .then(async () => {
                  alert('Your card has been succesfully deleted.');
                  const { data: me } = await api.get<User>('/users/me');
                  mixpanel.track('Payment Info - Deleted');
                  window.dataLayer.push({
                    event: 'delete_payment_info',
                    userId: me.id,
                    userEmail: me.email,
                    userName: me.firstName + ' ' + me.lastName,
                    userRole: me.role,
                  });
                  window.location.reload();
                })
                .catch(async () => {
                  const { data: me } = await api.get<User>('/users/me');
                  mixpanel.track('Payment Info - Deleting Error');
                  window.dataLayer.push({
                    event: 'delete_error_payment_info',
                    userId: me.id,
                    userEmail: me.email,
                    userName: me.firstName + ' ' + me.lastName,
                    userRole: me.role,
                  });
                  alert(
                    'You cannot delete a payment method if there is an upcoming or scheduled session. For more details, contact customer service.'
                  );
                });
            }}
            disabled={!confirm ? true : false}
          />
        </div>
      </ClickAwayListener>
    </div>
  );
};
