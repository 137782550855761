import { useState, useEffect, CSSProperties } from 'react';
import { useQuery } from 'react-query';
import { Checkbox } from '@components/Checkbox';
import { FilterDropdown } from '@components/FilterDropdown';
import { Search } from '@components/Search';
import { VODCard } from '@components/VODCard';
import { fetcher } from '@src/plugins/react-query';
import { Tag, TagType } from '@src/types';
import FadeLoader from 'react-spinners/BeatLoader';
import twConfig from '@src/tailwindResolver';
import { useVods } from '@src/hooks/vods';
import { useInView } from 'react-intersection-observer';
import { HiddenHeadingMeta } from '@src/components/HiddenHeadingMeta';
import { ReactHelmet } from '@src/components/ReactHelmet';
import mixpanel from 'mixpanel-browser';

const override: CSSProperties = {
  display: 'flex',
};

export const VODsPage = () => {
  // const lastRef = useRef<HTMLDivElement>(null);
  const [initialLoading, setInitialLoading] = useState<boolean>(true);
  const [tagIds, setTagIds] = useState<number[]>([0]);
  const [q, setQ] = useState('');
  const { data: tags, remove: removeTags } = useQuery<Tag[]>(
    `/tags?type=${TagType.VOD}`,
    fetcher,
    { keepPreviousData: true, refetchOnWindowFocus: false }
  );

  const { ref: lastRef, inView } = useInView({ threshold: 0.3 });

  /* LINA ADDED */
  const {
    data: vods,
    remove: removeVods,
    isPreviousData: isDataLoading,
    isFetchingNextPage,
    fetchNextPage,
    hasNextPage,
  } = useVods({
    page: 1,
    limit: 9,
    tagIds: tagIds.join(','),
    q,
  });

  useEffect(() => {
    // hasNextPage 다음 페이지가 있는지 여부, Boolean (getNextPageParam 리턴값에 의해서)
    if (vods && inView && hasNextPage) {
      // fetchNextPage fetch callback 함수를 실행
      fetchNextPage();
    }
  }, [vods, inView]);

  useEffect(() => {
    mixpanel.track('Page Viewed', {
      item_category: 'VOD List',
    });
    return () => {
      removeTags();
      removeVods();
    };
  }, []);

  useEffect(() => {
    if (isDataLoading) {
      setInitialLoading(false);
    }
  }, [isDataLoading]);

  const filterPanel = (
    <div className="divide-y">
      {/* <div className="p-4 space-y-1">
        <p className="pb-2 font-medium">Free Videos</p>
        {tags?.map((tag) => (
          <div>
            {tag.id === 174 ? (
              <Checkbox
                key={tag.id}
                label={tag.name}
                checked={tagIds.includes(tag.id)}
                onChange={(e) =>
                  e.target.checked
                    ? setTagIds((prev) => [...prev, tag.id])
                    : setTagIds((prev) => prev.filter((id) => id !== tag.id))
                }
              />
            ) : null}
          </div>
        ))}
      </div> */}
      <div className="p-4 space-y-1">
        <p className="pb-2 font-medium">Lesson Topics</p>
        {tags?.map((tag) => (
          <div>
            {/* {tag.id !== 174 ? ( */}
            <Checkbox
              key={tag.id}
              label={tag.name}
              checked={tagIds.includes(tag.id)}
              onChange={(e) =>
                e.target.checked
                  ? setTagIds((prev) => [...prev, tag.id])
                  : setTagIds((prev) => prev.filter((id) => id !== tag.id))
              }
            />
            {/* ) : null} */}
          </div>
        ))}
      </div>
    </div>
  );

  const skeleton = () => {
    const result = [];
    for (let i = 0; i < 6; i++) {
      result.push(
        <div key={i} className="animate-pulse flex flex-col space-y-3">
          <div
            className="rounded-sm bg-gray-200 w-full"
            style={{ aspectRatio: '16/9' }}
          ></div>
          <div className="flex-1 space-y-3 py-1">
            <div className="grid grid-cols-4 gap-4">
              <div className="h-2 bg-gray-200 rounded col-span-1"></div>
              <div className="h-2 bg-gray-200 rounded col-span-1"></div>
              <div className="h-2 bg-gray-200 rounded col-span-1"></div>
              <div className="h-2 bg-gray-200 rounded col-span-1"></div>
            </div>
            <div className="grid grid-cols-4 gap-5">
              <div className="h-2 bg-gray-200 rounded col-span-3"></div>
            </div>
            <div className="h-2 bg-gray-200 rounded"></div>
          </div>
        </div>
      );
    }
    return result;
  };

  return (
    <>
      <HiddenHeadingMeta text="Videos | Linkstory" />
      <ReactHelmet
        keywords="videos"
        description="Free pre-recorded lessons."
        title="Videos | Linkstory"
      />
      <div className="bg-brand-2 h-56 grid place-content-center text-center space-y-4  md:mb-14">
        <h2 className="font-header text-21 font-bold text-white">
          Check out our video lessons
        </h2>
      </div>

      <div className="max-w-container flex flex-col space-y-2 md:flex-row md:space-x-8 md:space-y-0 mb-20">
        {/* filter - mobile */}
        <FilterDropdown placeholder="Category" className="md:hidden mb-1">
          {filterPanel}
        </FilterDropdown>

        {/* filter - desktop */}
        <div className="hidden md:block p-4 w-64">
          <h1 className="font-semibold text-20 px-4 mb-4">
            Filter your search
          </h1>
          <div className="border border-gray-100 rounded-lg">{filterPanel}</div>
        </div>

        <div className="flex-1 flex flex-col">
          <div className="flex flex-col md:flex-row-reverse mb-6">
            <Search
              placeholder="Search"
              value={q}
              onChange={(e) => {
                setQ(e.target.value);
                window.dataLayer.push({
                  event: 'search',
                  ecommerce: {
                    search_term: e.target.value,
                  },
                });
              }}
            />
          </div>
          {!isDataLoading && vods ? (
            <div className="grid md:grid-cols-3 gap-8 whitespace-pre h-full relative">
              {vods.pages.length > 0 ? (
                vods.pages?.map((page: any) =>
                  page.data.items.map((vod: any) => (
                    <VODCard key={vod.id} vod={vod} />
                  ))
                )
              ) : (
                <div className="col-span-3 flex justify-center h-full items-center w-full whitespace-pre-wrap">
                  There are no results that match your search.
                </div>
              )}
              <div
                ref={lastRef}
                className="absolute bottom-[50vh] invisible w-full h-px"
              />
              <FadeLoader
                className="md:col-span-3 flex w-full justify-center h-full items-center"
                cssOverride={override}
                color={twConfig.theme.colors['brand-1']}
                loading={isFetchingNextPage}
                size={10}
              />
            </div>
          ) : (
            <div className="grid md:grid-cols-3 gap-8 whitespace-pre h-full">
              {initialLoading && !vods ? (
                skeleton()
              ) : (
                <FadeLoader
                  className="col-span-3 flex justify-center h-full items-center"
                  cssOverride={override}
                  color={twConfig.theme.colors['brand-1']}
                  loading={true}
                  size={10}
                />
              )}
            </div>
          )}

          {/* <FrontPagination
            basePath="/vods"
            total={data.total}
            defaultLimit={9}
          /> */}
        </div>
      </div>
    </>
  );
};
