import React, { FC, HTMLAttributes } from 'react';

interface HorizontalScrollViewProps extends HTMLAttributes<HTMLDivElement> {}

export const HorizontalScrollView: FC<HorizontalScrollViewProps> = ({
  children,
  ...props
}) => {
  return (
    <div
      className={`overflow-x-auto`}
      style={{ msOverflowStyle: 'none', scrollbarWidth: 'none' }}
      {...props}
    >
      <div className="inline-block">
        <ul className={`flex items-start space-x-4 `}>{children}</ul>
      </div>
    </div>
  );
};
