import React, { ButtonHTMLAttributes, FC, HTMLAttributes } from 'react';
import { useHistory, useLocation } from 'react-router';

export interface SidebarProps extends HTMLAttributes<HTMLDivElement> {}

/**
 * @example
 * <Sidebar>
 *   <Sidebar.Title>Admin</Sidebar.Title>
 *   <Sidebar.Menu>
 *     <Sidebar.Menu.Item text="Users" to="/admin/users" />
 *   </Sidebar.Menu>
 * </Sidebar>
 */

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(' ');
}

const Sidebar: FC<SidebarProps> & {
  Title: FC<SidebarTitleProps>;
  Menu: FC<SidebarMenuProps> & { Item: FC<SidebarMenuItemProps> };
} = ({ children, className = '', ...props }) => {
  return (
    <div className={`sidebar ${className}`} {...props}>
      {children}
    </div>
  );
};

export interface SidebarTitleProps extends HTMLAttributes<HTMLDivElement> {}

const SidebarTitle: FC<SidebarTitleProps> = ({
  children,
  className = '',
  ...props
}) => {
  return (
    <div className={`sidebar-title ${className}`} {...props}>
      {children}
    </div>
  );
};

export interface SidebarMenuProps extends HTMLAttributes<HTMLDivElement> {}

const SidebarMenu: FC<SidebarMenuProps> & {
  Item: FC<SidebarMenuItemProps>;
} = ({ children, className = '', ...props }) => {
  return (
    <div className={`sidebar-menu ${className}`} {...props}>
      {children}
    </div>
  );
};

export interface SidebarMenuItemProps
  extends ButtonHTMLAttributes<HTMLButtonElement> {
  text?: string;
  to?: string;
  selected?: boolean;
  exact?: boolean;
  Icon?: (
    props: React.SVGProps<SVGSVGElement> & {
      title?: string | undefined;
      titleId?: string | undefined;
    }
  ) => JSX.Element;
}

const SidebarMenuItem: FC<SidebarMenuItemProps> = ({
  children,
  className = '',
  text,
  Icon,
  to,
  selected = false,
  exact = false,
  onClick,
  ...props
}) => {
  const { push } = useHistory();
  const { pathname } = useLocation();
  let _selected = selected;
  if (to) {
    // exact True 인 Path 의 경우 완전 일치해야지만 Selected 처리함
    if (exact && pathname === to) {
      _selected = true;
    } else if (!exact && pathname.startsWith(to)) {
      _selected = true;
    }
  }

  return (
    <button
      className={`sidebar-menu-item group ${
        _selected
          ? 'bg-gray-100 text-gray-900'
          : 'text-gray-600 hover:bg-gray-50 hover:text-gray-900'
      } ${className}`}
      onClick={to ? () => push(to) : onClick}
      {...props}
    >
      {Icon && (
        <Icon
          className={classNames(
            selected
              ? 'text-gray-500'
              : 'text-gray-400 group-hover:text-gray-500',
            'mr-3 flex-shrink-0 h-6 w-6'
          )}
          aria-hidden="true"
        />
      )}
      {text ?? children}
    </button>
  );
};

Sidebar.Title = SidebarTitle;
Sidebar.Menu = SidebarMenu;
SidebarMenu.Item = SidebarMenuItem;

export { Sidebar };
