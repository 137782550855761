import React, { FC, ImgHTMLAttributes, useState } from 'react';

export interface AvatarProps extends ImgHTMLAttributes<HTMLImageElement> {}

/**
 * @example
 * <Avatar
 *   className="wh-10"
 *   src="https://source.unsplash.com/random/40x40"
 * />
 */
export const Avatar: FC<AvatarProps> = ({
  className = '',
  src,
  alt = 'avatar',
  ...props
}) => {
  const [error, setError] = useState<boolean>(false);
  return src && !error ? (
    <img
      className={`avatar flex-shrink-0 ${className}`}
      src={src}
      alt={alt}
      {...props}
      onError={() => setError(true)}
    />
  ) : (
    <span className={`flex-shrink-0 avatar-default ${className}`}>
      <svg className=" text-gray-300" fill="currentColor" viewBox="0 0 24 24">
        <path d="M24 20.993V24H0v-2.996A14.977 14.977 0 0112.004 15c4.904 0 9.26 2.354 11.996 5.993zM16.002 8.999a4 4 0 11-8 0 4 4 0 018 0z" />
      </svg>
    </span>
  );
};
