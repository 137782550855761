import { TutorType, WebibarItemType } from '../types';
// import AboutImg02Small from '@img/about-img-02@small.jpg';
import imgMentor01 from '@img/temp/webinar_thumb_01.jpg';
import imgMentor02 from '@img/temp/webinar_thumb_02.jpg';
import imgMentor03 from '@img/temp/webinar_thumb_03.jpg';
import imgMentor04 from '@img/temp/webinar_thumb_04.jpg';

export const WEBINAR_ITEMS: WebibarItemType[] = [
  {
    id: 1,
    tutorId: 506,
    featured: true,
    type: TutorType.MENTOR,
    webinar: {
      img: imgMentor01,
      title: 'Strategizing Extracurriculars for Your College Application',
      summary:
        'Get an insight to what kind of extracurriculars you should do and hack the college application processes with your extracurriculars. Find your true colors and passion and develop it. \n\nTable of Contents: \n1. Extracurriculars overview from an admissions standpoint \n2. My High School Extracurriculars \n3. Key traits to focus on \n4. Creating an identity through extracurriculars \n5. Developing your Path',
      date: '2022-09-15T02:00:00.000Z',
      endDate: '2022-09-15T02:30:00.000Z',
      timezone: 'Local Time Zone',
      duration: '30 mins',
      mc_tag: 'wbn_220914_jackier',
      video_url: 'https://vimeo.com/751555962',
    },
  },
  {
    id: 2,
    tutorId: 916,
    featured: true,
    type: TutorType.MENTOR,
    webinar: {
      img: imgMentor02,
      title: 'An Insider’s Guide to Duke University',
      summary:
        'Get an insight into how you could get the most out of your college experience, where this webinar session will focus on Duke University.\n\nTable of Contents:\n\nIntroduction\n• The First-Year Experience\n• The First Year Experience (FIRs)\n• The First-Year Experience (FOCUS)\n•The First-Year Experience (Student Activities Fair)\n\nAccess to Opportunity\n• Research\n• Civic Engagement\n• Professional\n\nResources\n• Advisory Network\n• Alumni Network\n• Post-Grad',
      date: '2022-09-16T01:00:00.000Z',
      endDate: '2022-09-16T01:30:00.000Z',
      timezone: 'Local Time Zone',
      duration: '30 mins',
      mc_tag: 'wbn_220915catherinem',
      video_url: 'https://vimeo.com/751549817',
    },
  },
  {
    id: 3,
    tutorId: 371,
    featured: true,
    type: TutorType.ADVISOR,
    webinar: {
      img: imgMentor03,
      title: 'Writing a Scholarship Essay',
      summary:
        'Almost every scholarship application requires an essay. So how do you write an essay that convinces the scholarship board that you’re the right candidate for the scholarship? If done correctly, your scholarship essay should make you memorable and earn you that much--needed scholarship. \n\nIn this seminar, you’ll learn:\n• How to outline your scholarship essay application\n• Tips for writing winning scholarship essay applications\n• Things to leave out of your scholarship essay',
      date: '2022-09-26T01:00:00.000Z',
      endDate: '2022-09-26T01:30:00.000Z',
      timezone: 'Local Time Zone',
      duration: '30 mins',
      mc_tag: 'wbn_220925_Maryam G',
      video_url: 'https://vimeo.com/753700673',
    },
  },
  {
    id: 4,
    tutorId: 490,
    featured: true,
    type: TutorType.ADVISOR,
    webinar: {
      img: imgMentor04,
      title: 'Preparing for an Engineering Major',
      summary:
        'In this session I will draw on my experience as an Electrical Engineering major at Princeton and how AP courses can prepare you for college courses. I’ll go through what an intro Engineering course schedule might look like and what to expect as far as class format, exams, and assignments are concerned. I will provide advice on how to stay on top of your course load and answer any questions you have about majoring in Engineering at Princeton. \n\nTable of contents:\n• My experience\n• High school courses to prepare\n• Freshman year as an engineering major\n• Tips & tricks Q&A',
      date: '2022-09-30T01:00:00.000Z',
      endDate: '2022-09-30T01:30:00.000Z',
      timezone: 'Local Time Zone',
      duration: '30 mins',
      mc_tag: 'wbn_220929_Chloe W',
      video_url: 'https://vimeo.com/757015638',
    },
  },
];
