import React, { useEffect, useMemo, useState } from 'react';
import { useQuery } from 'react-query';
import { ReactComponent as RectBgSvg } from '@svg/figure-landing-rect.svg';
import AboutImg01Small from '@img/howto-img-01@small.jpg';
import AboutImg01Medium from '@img/howto-img-01@medium.jpg';
import AboutImg02Small from '@img/howto-img-02@small.jpg';
import AboutImg02Medium from '@img/howto-img-02@medium.jpg';
import AboutImg03Small from '@img/howto-img-03@small.jpg';
import AboutImg03Medium from '@img/howto-img-03@medium.jpg';
import { Button } from '@components/Button';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { Icon } from '@components/icons';
import { Link, useHistory } from 'react-router-dom';
import {
  AcademicCapIcon,
  CheckBadgeIcon,
  StarIcon,
} from '@heroicons/react/24/outline';
import { useTranslation } from 'react-i18next';
import { HiddenHeadingMeta } from '@src/components/HiddenHeadingMeta';
import { ReactHelmet } from '@src/components/ReactHelmet';
import mixpanel from 'mixpanel-browser';

export const StudentHowTo = () => {
  // const { authenticated } = useAuth();
  const { t, i18n } = useTranslation('studentHowTo');

  // Animation on scoll plugin initial settings
  useEffect(() => {
    mixpanel.track('Page Viewed', {
      item_category: 'Student How It Works',
    });
    AOS.init({
      duration: 1800,
      // delay: 500,
      offset: 100,
      anchorPlacement: 'top-bottom',
      once: true,
    });
    AOS.refresh();
    // console.log(parseInt(twConfig.theme.screens.md), width);
  }, []);

  const aboutInfoItems = [
    {
      cateicon: (
        <Icon.DegreeHat className="w-5 md:w-6 fill-current text-brand-1" />
      ),
      title: t('aboutItems.title1'),
      desc: t('aboutItems.desc1'),
      mainimg: { small: AboutImg01Small, medium: AboutImg01Medium },
      imgdesc: null,
    },
    {
      cateicon: (
        <Icon.ProfileFilled className="w-4 md:w-5 fill-current text-brand-1" />
      ),
      title: t('aboutItems.title2'),
      desc: t('aboutItems.desc2'),
      mainimg: { small: AboutImg02Small, medium: AboutImg02Medium },
      imgdesc: null,
    },
    {
      cateicon: (
        <Icon.ProfileFilled className="w-4 md:w-5 fill-current text-brand-1" />
      ),
      title: t('aboutItems.title3'),
      desc: t('aboutItems.desc3'),
      mainimg: { small: AboutImg03Small, medium: AboutImg03Medium },
      imgdesc: null,
    },
  ];

  const heroContents = () => {
    return (
      <div
        data-aos="fade-in"
        className="w-full lg:w-none sm:px-6 lg:px-0 space-y-8 md:space-y-8  text-center"
      >
        <h1 className="leading-snug text-2xl sm:leading-[3.5rem] sm:text-3xl md:leading-snug md:text-4xl lg:leading-snug  font-bold md:whitespace-pre-line">
          {t('hero.title')}
        </h1>
        <div>
          <h3 className="mx-auto max-w-2xl text-lg tracking-tight text-slate-700 whitespace-pre-line">
            {t('hero.subtitle')}
          </h3>
          <div className="flex justify-center w-full">
            <Link
              to="/student-request"
              className="mt-4 inline-flex items-center rounded-full border border-transparent bg-indigo-600 px-6 py-3 text-base font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
            >
              {t('hero.ctaText')}
            </Link>
          </div>
        </div>
      </div>
    );
  };

  const inquirySection = () => {
    return (
      <section
        data-aos="fade-in"
        className="bg-[#0F1833] flex items-center w-full overflow-hidden box-content  py-12"
        // style={{
        //   background: '#F2F5FF',
        // }}
      >
        <div className="!py-8 max-w-container px-0 md:px4 flex justify-between items-center gap-4">
          <div className="space-y-8 ">
            <h2 className=" text-white text-[1.5rem] md:text-4xl font-bold">
              {t('bannerSection.title')}
            </h2>
            <p className="whitespace-pre-line text-white">
              {t('bannerSection.desc')}
            </p>
            <Button
              to="/mentors"
              className="bg-brand-1 rounded-full text-white"
            >
              {t('bannerSection.ctaText')}
            </Button>
          </div>
          <svg
            className="hidden md:block opacity-80"
            xmlns="http://www.w3.org/2000/svg"
            width="350"
            height="350"
            fill="none"
            viewBox="0 0 350 350"
          >
            <g opacity=".25">
              <path
                fill="#E5EBFF"
                d="M240.624 73.281V54.687a32.814 32.814 0 0 0-32.812-32.812h-21.875a32.81 32.81 0 0 0-32.813 32.813V73.28a131.252 131.252 0 0 0-66.281 51.953 10.987 10.987 0 0 0-1.563 8.259A10.984 10.984 0 0 0 98.273 142a10.982 10.982 0 0 0 6.945-4.734 109.377 109.377 0 0 1 163.659-22.726 109.375 109.375 0 0 1-117.612 181.757 10.94 10.94 0 1 0-9.078 19.906 131.247 131.247 0 0 0 177.5-69.478 131.244 131.244 0 0 0-2.215-103.732 131.238 131.238 0 0 0-76.848-69.712Zm-65.625-5.578V54.687a10.939 10.939 0 0 1 10.938-10.937h21.875a10.937 10.937 0 0 1 10.937 10.938v12.906a131.243 131.243 0 0 0-21.875-1.969 132.33 132.33 0 0 0-21.875 2.078Z"
              />
              <path
                fill="#E5EBFF"
                d="M260.094 146.343a10.937 10.937 0 0 0-15.313-1.75l-30.734 24.5a31.61 31.61 0 0 0-17.172-5.031 32.809 32.809 0 0 0-30.315 20.256 32.816 32.816 0 0 0 7.113 35.758 32.813 32.813 0 0 0 56.015-23.202 32.069 32.069 0 0 0-1.969-10.937l30.625-24.5a10.94 10.94 0 0 0 1.75-15.094Zm-63.219 61.469a10.942 10.942 0 0 1-10.727-13.071 10.937 10.937 0 1 1 18.461 9.867 10.934 10.934 0 0 1-7.734 3.204Zm-76.563-43.75H54.688a10.939 10.939 0 0 0 0 21.876h65.624a10.938 10.938 0 0 0 0-21.876Zm0 43.75h-87.5a10.939 10.939 0 0 0 0 21.876h87.5a10.938 10.938 0 0 0 0-21.876Zm0 43.75H54.688a10.939 10.939 0 0 0 0 21.876h65.624a10.938 10.938 0 0 0 0-21.876Z"
              />
            </g>
          </svg>
        </div>
      </section>
    );
  };

  const reasonsItems = [
    {
      title: t('reasonsItems.title1'),
      desc: t('reasonsItems.desc1'),
      icon: CheckBadgeIcon,
      iconForeground: 'text-sky-700',
      iconBackground: 'bg-sky-50',
    },
    {
      title: t('reasonsItems.title2'),
      desc: t('reasonsItems.desc2'),
      icon: AcademicCapIcon,
      iconForeground: 'text-purple-700',
      iconBackground: 'bg-purple-50',
    },

    {
      title: t('reasonsItems.title3'),
      desc: t('reasonsItems.desc3'),
      icon: StarIcon,
      iconForeground: 'text-yellow-700',
      iconBackground: 'bg-yellow-50',
    },
  ];

  function classNames(...classes: string[]) {
    return classes.filter(Boolean).join(' ');
  }

  const reasonsSections = () => {
    return (
      <div className="max-w-[1200px] my-4 sm:my-40  mx-auto px-4 grid grid-cols-1 gap-4 sm:grid-cols-3">
        <h2 className="sm:col-span-3 mb-4 sm:mb-8  text-center text-[1.5rem] md:text-[2rem] font-bold md:whitespace-pre-line">
          {t('reasonsItems.heading')}
        </h2>
        {reasonsItems.map((item) => (
          <div
            key={item.title}
            className="relative flex flex-col rounded-lg border bg-white px-6 py-5 shadow-sm focus-within:ring-2 focus-within:ring-indigo-500 focus-within:ring-offset-2 "
          >
            <div>
              <span
                className={classNames(
                  item.iconBackground,
                  item.iconForeground,
                  'rounded-lg inline-flex p-3 ring-4 ring-white'
                )}
              >
                <item.icon className="h-6 w-6" aria-hidden="true" />
              </span>
            </div>
            <div className="mt-8">
              <h3 className="text-lg font-medium">
                <div className="focus:outline-none">
                  {/* Extend touch target to entire panel */}
                  <span className="" aria-hidden="true" />
                  {item.title}
                </div>
              </h3>
              <p className="mt-2 text-sm text-gray-500">{item.desc}</p>
            </div>
          </div>
        ))}
      </div>
    );
  };

  return (
    <>
      <HiddenHeadingMeta text="For students | Linkstory" />
      <ReactHelmet
        keywords="student, how it works"
        title="For students | Linkstory"
      />
      {/* Hero Section */}
      <section className=" w-full overflow-hidden relative flex justify-center">
        <div
          data-aos="fade-in"
          className="max-w-container !py-20 h-full object-cover object-center flex flex-col justify-center"
        >
          {heroContents()}
        </div>
      </section>

      {/* About section */}
      <section className=" bg-white w-full my-6 md:my-20  space-y-14 md:space-y-20 flex flex-col items-center">
        {aboutInfoItems.map((item: any, index: any) => {
          return (
            <div
              key={`homeAbout_${index}`}
              data-aos="fade-in"
              className="max-w-container flex flex-col-reverse md:grid grid-cols-10 md:items-center gap-5 md:gap-10"
            >
              <>
                <div className="space-y-4 md:space-y-8 md:col-span-6">
                  <div
                    style={{ aspectRatio: '1 / 1' }}
                    className="flex space-x-3 items-center text-center text-3xl md:text-4xl text-white font-bold rounded-full bg-brand-blue max-w-[40px] md:max-w-[50px]"
                  >
                    <span className="mx-auto">{index + 1}</span>
                  </div>
                  <p className="text-3xl md:text-4xl md:leading-[3.6rem] font-semibold text-brand-blue whitespace-pre-line">
                    {item.title}
                  </p>
                  <p className=" text-18 text-black leading-7 whitespace-pre-line">
                    {item.desc}
                  </p>
                </div>
                <div className="block text-right space-y-1 md:col-span-4">
                  <picture>
                    <source
                      media={'(max-width:400px)'}
                      srcSet={item.mainimg.small}
                    />
                    <source
                      media="(min-width:401px)"
                      srcSet={item.mainimg.medium}
                    />
                    <img
                      src={item.mainimg.small}
                      alt="lading-img"
                      className="md:max-h-[500px]"
                      style={{ width: 'auto' }}
                    />
                  </picture>
                  {item.imgdesc ? <p>{item.imgdesc}</p> : null}
                </div>
              </>
            </div>
          );
        })}
      </section>

      {inquirySection()}
      {reasonsSections()}
    </>
  );
};
