import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router';
import { Button } from '@components/Button';
import { TextField } from '@components/TextField';
import { api } from '@plugins/axios';

interface FormValues {
  email: string;
}

export const FindPasswordPage = () => {
  const { push } = useHistory();
  const [clicked, setClicked] = useState(false);
  const {
    register,
    watch,
    formState: { errors },
  } = useForm<FormValues>();

  return (
    <div className="max-w-screen-xl mx-auto md:h-screen-15 justify-center flex items-center px-5 py-20">
      <div className="space-y-8 flex flex-col">
        <h1 className="font-bold text-center text-20 mb-5">Reset Password</h1>
        <h2 className="font-semibold">
          Please enter the email you used to sign up.
        </h2>
        <form>
          <TextField
            label="Email"
            autoFocus={true}
            type="email"
            placeholder="you@example.com"
            disabled={clicked === true}
            helper={errors.email?.message}
            {...register('email', {
              pattern:
                /^(([^<>()\[\].,;:\s@"]+(\.[^<>()\[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i,
              required: 'Please enter your email address.',
            })}
          />
          <Button
            text={clicked === true ? 'Loading' : 'Send'}
            disabled={!watch('email') || clicked === true}
            onClick={() => {
              setClicked(true);
              api
                .patch('users/find-password', watch())
                .then(() => push('/passwords/send'))
                .catch(() => {
                  alert('User does not exist. Please try again.');
                  setClicked(false);
                });
            }}
            className="rounded-full filled-brand-1 w-full mt-10"
          />
        </form>
      </div>
    </div>
  );
};
