import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import { useQuery } from 'react-query';
import { Button } from '../../components/Button';
import { Label } from '../../components/Label';
import { Select } from '../../components/Select';
import { TextField } from '../../components/TextField';
import { UploadFile } from '../../components/UploadFile';
import { api } from '../../plugins/axios';
import { resizeImage } from '../../plugins/compress';
import { fetcher } from '../../plugins/react-query';
import { Tag, TagType, User, Role, TagLink } from '../../types';
import { AvatarInput } from '../../components/AvatarInput';
import { getS3Uri } from '../../utils';
import styled from 'styled-components';
import { EditorView } from '@src/plugins/froala';
import { HiddenHeadingMeta } from '@src/components/HiddenHeadingMeta';
import { ReactHelmet } from '@src/components/ReactHelmet';
import { MultiDropdownSubjects } from '@src/components/MultiDropdownSubjects';

interface FormValues {
  firstName: string;
  lastName: string;
  email: string;
  payPalEmail: string;
  dob: string;
  school: number;
  major: number;
  sat?: number;
  gpa?: number;
  act?: number;
  ap?: number;
  extra?: number;
}

export const EditMyProfilePage = () => {
  const {
    setValue,
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm<FormValues>({
    mode: 'onChange',
  });

  const [proofOfEducationName, setProofOfEducationName] = useState('');
  const [proofOfEducation, setProofOfEducation] = useState<File>();
  const [uploading, setUploading] = useState(false);
  const [year, setYear] = useState('');
  const [month, setMonth] = useState('');
  const [day, setDay] = useState('');
  const { push } = useHistory();
  const { data: me, refetch } = useQuery<User>('/users/me', fetcher, {
    onSuccess(data) {
      console.log(
        '🚀 ~ file: EditMyProfilePage.tsx:52 ~ const{data:me,refetch}=useQuery<User> ~ data',
        data
      );
    },
  });
  const { data: schoolTags } = useQuery<Tag[]>(
    `/tags?type=${TagType.SCHOOL}`,
    fetcher
  );
  const { data: majorTags } = useQuery<Tag[]>(
    `/tags?type=${TagType.MAJOR}`,
    fetcher
  );

  const { data: subjects } = useQuery<Tag[]>(`/v2/subjects`, fetcher);

  const { data: subjectLinks, refetch: refetchSubjectLinks } = useQuery<
    TagLink[]
  >(`/v2/subject-link?mentorId=${me?.id}`, fetcher, {
    enabled: !!me && !!me.id,
  });

  useEffect(() => {
    if (!me) return;
    if (me && me.role === Role.STUDENT)
      return push('/my/dashboard/account/profile');
    api.get(`/tag-links/my`).then(({ data }) => {
      const schoolTag = data.find(
        (tagLink: any) => tagLink.tag.type === TagType.SCHOOL
      );
      const majorTag = data.find(
        (tagLink: any) => tagLink.tag.type === TagType.MAJOR
      );
      if (schoolTag) {
        setValue('school', schoolTag.tagId);
      }
      if (majorTag) {
        setValue('major', majorTag.tagId);
      }
    });

    const thisMonth =
      me?.dob?.substring(4, 5) === '0'
        ? me?.dob?.substring(4, 6).split('0')[1]
        : me?.dob?.substring(4, 6);

    const thisDay =
      me?.dob?.substring(6, 7) === '0'
        ? me?.dob?.substring(6, 8).split('0')[1]
        : me?.dob?.substring(6, 8);

    setValue('firstName', me.firstName);
    setValue('lastName', me.lastName);
    setValue('payPalEmail', me.payPalEmail);
    setValue('dob', me.dob);
    setValue('sat', me.sat);
    setValue('gpa', me.gpa);
    setValue('act', me.act);
    setValue('ap', me.ap);
    setValue('extra', me.extra);
    setProofOfEducationName(me.proofOfEducation);
    setYear(me?.dob?.substring(0, 4));
    setMonth(thisMonth);
    setDay(thisDay);
  }, [me, setValue]);

  const m = month?.length === 1 ? `0${month}` : month;
  const d = day?.length === 1 ? `0${day}` : day;

  const maxOffset = 70;
  const thisYear = new Date().getFullYear();
  const allYears = [];
  for (let x = 0; x <= maxOffset; x++) {
    allYears.push(thisYear - x);
  }

  const yearList = allYears.map((y) => (
    <option key={y} value={y}>
      {y}
    </option>
  ));

  const monthList = Array.from({ length: 12 }, (_, i) => i + 1).map((m) => (
    <option key={m} value={m}>
      {m}
    </option>
  ));

  const days = new Date(Number(year), Number(month), 0).getDate();
  const dayList = Array.from({ length: days }, (_, i) => i + 1).map((d) => (
    <option key={d} value={d}>
      {d}
    </option>
  ));

  async function uploadFile(file?: File) {
    if (!file) return;
    const { data: post } = await api.get(
      `/s3/presignedPost?filename=${file.name}`
    );
    const formData = new FormData();
    Object.entries(post.fields).forEach(([name, value]) =>
      formData.append(name, value as any)
    );
    formData.append('file', await resizeImage(file));

    await axios.post(post.url, formData, {
      headers: { 'Content-Type': 'multipart/form-data' },
    });
    api.patch('/users/me', { proofOfEducation: post.fields.key });
    return post.fields.key;
  }

  const avatarOnchange = async (file: File) => {
    const { data: post } = await api.get(
      `/s3/presignedPost?filename=${file.name}`
    );
    const formData = new FormData();
    Object.entries(post.fields).forEach(([name, value]) =>
      formData.append(name, value as any)
    );
    formData.append('file', await resizeImage(file));
    await axios.post(post.url, formData, {
      headers: { 'Content-Type': 'multipart/form-data' },
    });
    await api.patch('/users/me', { avatar: post.fields.key });
    refetch();
  };

  return (
    <>
      <HiddenHeadingMeta text="Account | Linkstory" />
      <ReactHelmet keywords="account" title="Account | Linkstory" />
      <div className="max-w-lg mx-auto bg-white rounded-md px-4 md:px-10 ">
        <form
          className="p-4 flex flex-col space-y-6"
          onSubmit={handleSubmit(async (data) => {
            if (year && m && d) {
              //@ts-ignore
              data.dob = year + m + d;
            }
            if (!proofOfEducation) {
              await api
                .patch('/users/me', { ...data })
                .then(() => alert('Your profile has been updated.'));
            } else {
              setUploading(true);
              const promises = [proofOfEducation].map((file) =>
                uploadFile(file)
              );
              const [proofOfEducationKey] = await Promise.all(promises);
              await api
                .patch('/users/me', {
                  proofOfEducation: proofOfEducationKey,
                  ...data,
                })
                .then(() => {
                  refetch();
                  setUploading(false);
                })
                .then(() => {
                  refetch();
                  alert('Your profile has been updated.');
                });
            }
          })}
        >
          <AvatarInput
            onChange={(file) => avatarOnchange(file)}
            avatarUrl={getS3Uri(me?.avatar)}
          />
          <text className="text-center text-sm text-gray-600">
            Please upload a profile picture (.jpg or .png)
          </text>
          <TextField
            label="First name*"
            placeholder="Please enter your firstName."
            helper={errors.firstName?.message}
            {...register('firstName', {
              required: 'Please enter your firstName.',
              validate: {
                alphabetWithSpace: (value) =>
                  (value && /^[a-zA-Z ]*$/.test(value)) ||
                  'Only alphabets and spaces are allowed.',
              },
            })}
            disabled
          />
          {me?.id && (
            <>
              <div>
                <h3 className="mb-1 text-[#333d4b] text-sm">Subjects</h3>
                <MultiDropdownSubjects
                  id={me?.id.toString()}
                  options={subjects}
                  selectedOptions={subjectLinks}
                  placeholder={`Select subject`}
                  onToggle={() => refetchSubjectLinks()}
                />
              </div>
            </>
          )}

          <TextField
            label="Last name*"
            placeholder="Please enter your lastName."
            helper={errors.lastName?.message}
            {...register('lastName', {
              required: 'Please enter your lastName.',
              validate: {
                alphabetWithSpacesAndPunctuation: (value) =>
                  (value && /^[a-zA-Z .]*$/.test(value)) ||
                  'Only alphabets, spaces and periods are allowed.',
              },
            })}
            disabled
          />

          <TextField label="Email*" value={me?.email} disabled />

          <div>
            <Label text="Date of Birth*" />
            <div className="flex space-x-3.5">
              <div className="w-full">
                <Select
                  placeholder="Year"
                  value={year}
                  onChange={(e) => setYear(e.target.value)}
                >
                  <option value="" selected disabled hidden>
                    YYYY
                  </option>
                  {yearList}
                </Select>
              </div>
              <div className="w-full">
                <Select
                  placeholder="Month"
                  value={month}
                  onChange={(e) => setMonth(e.target.value)}
                >
                  <option value="" selected disabled hidden>
                    MM
                  </option>
                  {monthList}
                </Select>
              </div>
              <div className="w-full">
                <Select
                  placeholder="Day"
                  value={day}
                  onChange={(e) => setDay(e.target.value)}
                >
                  <option value="" selected disabled hidden>
                    DD
                  </option>
                  {dayList}
                </Select>
              </div>
            </div>
          </div>

          <Select
            readOnly
            label="Tutor Type*"
            className={watch('school') ? '' : 'text-gray-400'}
            {...register('school')}
          >
            {schoolTags?.map((tag) => (
              <>
                <option
                  label="Please select your education."
                  value=""
                  disabled
                  selected={watch('school') ? false : true}
                  hidden
                />
                <option
                  label={tag.name}
                  value={tag.id}
                  key={tag.id}
                  selected={watch('school') !== tag.id ? false : true}
                />
              </>
            ))}
          </Select>

          <Select
            label="School"
            readOnly
            className={watch('major') ? '' : 'text-gray-400'}
            {...register('major')}
          >
            {majorTags?.map((tag) => (
              <>
                <option
                  label="Please select your major."
                  value=""
                  disabled
                  selected={watch('major') ? false : true}
                  hidden
                />
                <option
                  label={tag.name}
                  value={tag.id}
                  key={tag.id}
                  selected={watch('major') !== tag.id ? false : true}
                />
              </>
            ))}
          </Select>

          {/* <UploadFile
          label="Proof of education*"
          fileName={proofOfEducationName.split('_')[1]}
          onChange={(file) => setProofOfEducation(file)}
        /> */}

          {/* <TextField
          label="GPA"
          placeholder="Please enter your GPA."
          helper={errors.gpa?.message}
          {...register('gpa')}
        />
        <TextField
          label="SAT"
          placeholder="Please enter your SAT."
          helper={errors.sat?.message}
          {...register('sat')}
        />
        <TextField
          label="ACT"
          placeholder="Please enter your ACT."
          helper={errors.act?.message}
          {...register('act')}
        />
        <TextField
          label="AP"
          placeholder="Please enter your AP."
          helper={errors.ap?.message}
          {...register('ap')}
        />
        <TextField
          label="Extra curricular"
          placeholder="Please enter your extra curricular."
          helper={errors.extra?.message}
          {...register('extra')}
        />

        <TextField
          label="PayPal email*"
          placeholder="Please enter your Paypal Email."
          helper={errors.payPalEmail?.message}
          {...register('payPalEmail', {
            required: 'Please enter your  Paypal Email.',
          })}
        /> */}

          <StyledFroalaContainer>
            <label className="label mb-1">Description</label>
            <div className="border rounded-md bg-gray-100 p-4">
              <EditorView model={me?.description} />
            </div>
          </StyledFroalaContainer>

          <div className="flex flex-col space-y-4 pt-8">
            <Button
              text="Save"
              className="filled-brand-1 rounded-full"
              disabled={uploading}
            />
          </div>
        </form>
      </div>
    </>
  );
};

const StyledFroalaContainer = styled.div`
  @media (max-width: 768px) {
    .fr-view img.fr-dii.fr-fil {
      display: none;
    }
  }
`;
