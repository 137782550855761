import { parse } from 'qs';
import { useEffect, CSSProperties } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useAuth } from '@src/hooks';
import FadeLoader from 'react-spinners/BeatLoader';
import twConfig from '@src/tailwindResolver';

const override: CSSProperties = {
  display: 'flex',
  justifyContent: 'center',
  height: '100vh',
  alignItems: 'center',
};

export const LoadingPage = () => {
  const { googleLogin, authenticated } = useAuth();
  const { push } = useHistory();
  const { search, pathname } = useLocation();
  const { code } = parse(search, { ignoreQueryPrefix: true });

  useEffect(() => {
    if (code) {
      googleLogin({ code: code.toString(), from: pathname });
    } else {
      push('/');
    }
  }, [code, !authenticated]);

  return (
    <>
      <div
        className="fixed inset-0 w-full h-screen z-100 bg-white opacity-50 grid grid-1 place-items-center"
        style={{ margin: 0 }}
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
        }}
      >
        <FadeLoader
          cssOverride={override}
          color={twConfig.theme.colors['brand-1']}
          loading={true}
          size={10}
        />
      </div>
    </>
  );
};
