import { Button } from 'antd';
import { useEffect } from 'react';
import { useAuth } from '../hooks';
import { Icon } from './icons';

import React, { FC } from 'react';

export interface FaceBookLoginButtonProps {
  from: string;
}

export const FaceBookLoginButton: FC<FaceBookLoginButtonProps> = ({ from }) => {
  const { facebookAuth } = useAuth();

  useEffect(() => {
    setFBAsyncInit();
    loadSdkAsynchronously();
  }, []);

  function setFBAsyncInit() {
    (window as any).fbAsyncInit = () => {
      (window as any).FB.init({
        version: `v14.0`,
        appId: process.env.REACT_APP_FACEBOOK_ID,
        xfbml: true,
        cookie: true,
      });
    };
  }

  function loadSdkAsynchronously() {
    ((document, string, id) => {
      const element = document.getElementsByTagName(string)[0];
      const fjs = element as Element;
      let js = element as any;
      if (document.getElementById(id)) {
        return;
      }
      js = document.createElement(string);
      js.id = id;
      js.src = `https://connect.facebook.net/en_US/sdk.js`;
      fjs.parentNode!.insertBefore(js, fjs);
    })(document, 'script', 'facebook-jssdk');
  }

  function checkLogin() {
    (window as any).FB.login(
      (response: Response) => {
        getUser(response.authResponse?.accessToken);
      },
      { scope: 'email' }
    );
  }

  function getUser(token?: string) {
    (window as any).FB.api(
      `/me`,
      { fields: ['email', 'picture', 'last_name', 'first_name', 'id'] },
      (response: any) => {
        facebookAuth({ ...response, from: from });
      }
    );
  }

  interface Response {
    status: 'connected' | 'not_authorized' | 'unknown';
    authResponse: null | AuthResponse;
  }

  interface AuthResponse {
    accessToken: string;
    expiresIn: number;
    signedRequest: string;
    userId: string;
    data_access_expiration_time: number;
    graphDomain: string;
  }

  return (
    <Button
      className="w-full group h-12 px-6 border border-gray-300 rounded-full transition duration-300 hover:border-brand-blue focus:bg-blue-50 active:bg-blue-100 mb-4"
      onClick={checkLogin}
    >
      <div className="relative flex items-center space-x-4 justify-center">
        <Icon.Facebook className="w-5" />
        <span className="block w-max font-semibold tracking-wide text-gray-700 text-sm transition duration-300 group-hover:text-brand-blue sm:text-base">
          Continue with Facebook
        </span>
      </div>
    </Button>
  );
};
