import { ComponentType, FC, CSSProperties } from 'react';
import { Redirect, Route } from 'react-router';
import { useAuth } from '../hooks';
import { Role, User, Subscription } from '../types';
import { useQuery } from 'react-query';
import { fetcher } from '../plugins/react-query';
import FadeLoader from 'react-spinners/BeatLoader';
import twConfig from '../tailwindResolver';
import { useLocation } from 'react-router-dom';
export interface AuthRouteProps {
  path: string | string[];
  component: ComponentType;
  guestOnly?: boolean;
  allowedRole?: Role;
  onlyNonSub?: boolean;
}

const override: CSSProperties = {
  display: 'flex',
  justifyContent: 'center',
  height: '100vh',
  alignItems: 'center',
};
interface LocationState {
  from: {
    pathname: string;
  };
}

export const AuthRoute: FC<AuthRouteProps> = ({
  path,
  component: Component,
  guestOnly = false,
  allowedRole,
  onlyNonSub = false,
}) => {
  const { authenticated } = useAuth();
  const location = useLocation<LocationState>();

  const { data: subscription, isLoading: subIsLoading } =
    useQuery<Subscription>(`/subscriptions/valid`, fetcher, {
      enabled: (!!allowedRole || onlyNonSub) && !!authenticated,
    });

  const { data: me, isLoading: meIsLoading } = useQuery<User>(
    '/users/me',
    fetcher,
    {
      enabled: (!!allowedRole || onlyNonSub) && !!authenticated,
    }
  );

  if ((allowedRole || onlyNonSub) && (meIsLoading || subIsLoading))
    return (
      <>
        <FadeLoader
          cssOverride={override}
          color={twConfig.theme.colors['brand-1']}
          loading={true}
          size={10}
        />
      </>
    );

  return (
    <Route
      path={path}
      render={() => {
        if (
          (guestOnly && authenticated) ||
          (authenticated &&
            allowedRole &&
            !meIsLoading &&
            me &&
            me.role !== allowedRole) ||
          (onlyNonSub && authenticated && !subIsLoading && subscription)
        ) {
          return <Redirect to="/" />;
        }
        if (!guestOnly && !authenticated) {
          return (
            <Redirect
              to={{
                pathname: '/login',
                state: { from: { pathname: location.pathname } },
              }}
            />
          );
        }
        return <Component />;
      }}
    />
  );
};
