import React, { useEffect, useMemo, useState } from 'react';
import { useQuery } from 'react-query';
// import landingImg from '@img/homepage-landing-img.png';
import landingImg from '@img/img-homepage.png';
import { CollegeLogosMarquee } from '@components/CollgeLogosMarquee';
import { fetcher } from '@plugins/react-query';
import { Lesson, Paginated, Role, Subscription, User } from '@src/types';
import { useAuth, useQueryString } from '@src/hooks';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { Link, useHistory } from 'react-router-dom';
import styled, { css } from 'styled-components';
import { ReactComponent as RectBgSvg } from '@svg/figure-landing-rect.svg';
import imgQuickRequest from '@img/Quick request form.png';
import { useMediaQuery } from 'usehooks-ts';
import Slider from '@components/Slider';
import { SwiperSlide } from 'swiper/react';
import { MentorCard } from '@src/components/MentorCard';
import { useTranslation } from 'react-i18next';
import * as Icon from '@heroicons/react/24/outline';
import { LANG_KOR_TOP_MENTORS_IDS } from '@src/constants/topMentors';

const CSSOverride = css`
  .swiper-slide {
    height: auto;
  }
  .swiper-container {
    padding-bottom: 60px;
  }
`;

const SliderBreakPoints = {
  960: {
    slidesPerView: 3.2,
    slidesPerGroup: 3,
    spaceBetween: 25,
  },
  640: {
    slidesPerView: 2.2,
    slidesPerGroup: 2,
    spaceBetween: 20,
  },
  320: {
    slidesPerView: 1.2,
    slidesPerGroup: 1,
    spaceBetween: 20,
  },
};

export const HomePage = () => {
  const { t, i18n } = useTranslation('landingPage');
  const matches = useMediaQuery('(min-width: 768px)');
  const { push } = useHistory();
  const { authenticated } = useAuth();
  const queryString = useQueryString({}, { defaultLimit: 100 });
  const [q, setQ] = useState('');

  const onSearch = (value?: string) => {
    push({
      pathname: '/mentors',
      search: `?searchterm=${value ? value : q}`,
    });
    window.dataLayer.push({
      event: 'search',
      ecommerce: {
        search_term: q,
      },
    });
  };

  const { data: me } = useQuery<User>('/users/me', fetcher, {
    enabled: !!authenticated,
  });

  const { data: firstSessionLessons } = useQuery<Lesson[]>(
    `/lessons/first-sessions`,
    fetcher,
    {
      enabled: me?.role === Role.STUDENT,
    }
  );

  const isNotFirstSession =
    firstSessionLessons &&
    firstSessionLessons?.length > 0 &&
    me?.firstSessionCompleted;

  const { data: homePageMentors } = useQuery<Paginated<User>>(
    `/users/mentors${queryString}`,
    fetcher,
    { cacheTime: 3000000, staleTime: 3000000, refetchOnWindowFocus: false }
  );

  useEffect(() => {
    console.log(me);
  }, [me]);

  // Animation on scoll plugin initial settings
  useEffect(() => {
    AOS.init({
      duration: 1800,
      // delay: 500,
      offset: 100,
      anchorPlacement: 'top-bottom',
      once: true,
    });
    AOS.refresh();
  }, []);

  const randomizeArr = (data: any): any => {
    const mentors = data?.items.sort(() => Math.random() - 0.5) ?? [];
    return mentors;
  };

  const mentorsRandomized: any = useMemo(() => {
    return randomizeArr(homePageMentors);
  }, [homePageMentors]);

  const HeroSection = () => {
    return (
      <section>
        <div className="w-full mx-auto bg-[#F8F9FF]">
          <section
            data-aos="fade-in"
            className={`relative  !pb-0 flex flex-col  items-center overflow-hidden`}
          >
            <div className="max-w-container z-10 flex flex-col lg:flex-row justify-between md:py-20 items-center w-full">
              <div className="text-center md:text-left">
                <h1 className="md:whitespace-pre-line font-bold text-2xl lg:text-5xl mt-8 !leading-tight">
                  {/* {`Your first mentoring \nsession is free of charge.`} */}
                  {t('mainbanner.title')}
                </h1>
                <div className="mt-8 mb-6 font-semibold text-sm md:text-base whitespace-pre-line opacity-50">
                  {t('mainbanner.subtitle')}
                </div>
                {/* <div
                  className="w-full max-w-[450px] mb-10"
                  style={{ zIndex: 1 }}
                >
                  <SearchMentoring
                    placeholder="Search for subjects, majors, or universities"
                    value={q}
                    className="text-black font-semibold"
                    onChange={(e) => {
                      setQ(e.target.value);
                    }}
                    onSearch={() => onSearch()}
                  />
                </div> */}
                <Link
                  to="/signup"
                  className="inline-flex items-center rounded-full border border-transparent bg-indigo-600 px-6 py-3 text-base font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 mb-6"
                >
                  {t('mainbanner.ctaText')}
                </Link>

                <svg
                  style={{ zIndex: -1 }}
                  className=" absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-full"
                  xmlns="http://www.w3.org/2000/svg"
                  width="1280"
                  height="640"
                  fill="none"
                  viewBox="0 0 1280 640"
                >
                  <g filter="url(#a)">
                    <path
                      stroke="#04F"
                      stroke-opacity=".3"
                      stroke-width="17"
                      d="M-32 459.353c148.586 37.005 535.124 157.319 709.771 44.503C896.079 362.836 1013.3 71.796 1280 89.798"
                    />
                  </g>
                  <defs>
                    <filter
                      id="a"
                      width="1474.63"
                      height="639.001"
                      x="-114.055"
                      y=".499"
                      color-interpolation-filters="sRGB"
                      filterUnits="userSpaceOnUse"
                    >
                      <feFlood flood-opacity="0" result="BackgroundImageFix" />
                      <feGaussianBlur
                        in="BackgroundImageFix"
                        stdDeviation="25"
                      />
                      <feComposite
                        in2="SourceAlpha"
                        operator="in"
                        result="effect1_backgroundBlur_6188_7311"
                      />
                      <feBlend
                        in="SourceGraphic"
                        in2="effect1_backgroundBlur_6188_7311"
                        result="shape"
                      />
                      <feGaussianBlur
                        result="effect2_foregroundBlur_6188_7311"
                        stdDeviation="40"
                      />
                    </filter>
                  </defs>
                </svg>
              </div>

              <img
                src={landingImg}
                alt="mentors-imgs"
                className="max-w-[400px] md:max-w-[500px] w-full"
              />
            </div>
            {/*  */}
          </section>
        </div>
      </section>
    );
  };

  const QuickRequestIntroductionItems = [
    {
      title: t('quickRequestIntroductionItems.title1'),
      desc: t('quickRequestIntroductionItems.desc1'),
      icon: Icon.IdentificationIcon,
      iconForeground: 'text-sky-700',
      iconBackground: 'bg-sky-50',
    },
    {
      title: t('quickRequestIntroductionItems.title2'),
      desc: t('quickRequestIntroductionItems.desc2'),
      icon: Icon.BellAlertIcon,
      iconForeground: 'text-purple-700',
      iconBackground: 'bg-purple-50',
    },
    {
      title: t('quickRequestIntroductionItems.title3'),
      desc: t('quickRequestIntroductionItems.desc3'),
      icon: Icon.AcademicCapIcon,
      iconForeground: 'text-indigo-700',
      iconBackground: 'bg-indigo-50',
    },
  ];

  function classNames(...classes: string[]) {
    return classes.filter(Boolean).join(' ');
  }

  const QuickRequestIntroduction = () => {
    return (
      <section className="bg-[#F8F9FF]">
        <div className="max-w-container py-4 sm:py-16  mx-auto px-4 flex flex-col">
          <div className="sm:col-span-3 space-y-4 my-8 md:my-20">
            <h3 className="sm:col-span-3  text-center text-xl md:text-[2rem] font-bold md:whitespace-pre-line">
              {t('quickRequestSection.title')}
            </h3>
            <h2 className="sm:col-span-3 mb-4 sm:mb-8  text-center text-base md:text-xl md:whitespace-pre-line">
              {t('quickRequestSection.subtitle')}
            </h2>
          </div>

          <div
            data-aos="fade-in"
            className=" flex flex-col-reverse md:grid grid-cols-10 md:items-center gap-5 md:gap-10"
          >
            <div className="space-y-4 md:space-y-7 md:col-span-5">
              {QuickRequestIntroductionItems.map((item) => (
                <div
                  key={item.title}
                  className="bg-[#ffffff] hover:scale-115 relative flex flex-col rounded-lg border  px-6 py-5 shadow-lg focus-within:ring-2 focus-within:ring-indigo-500 focus-within:ring-offset-2 "
                >
                  <div className="flex flex-col md:flex-row gap-4">
                    <span
                      style={{ height: 'fit-content', width: 'fit-content' }}
                      className={classNames(
                        item.iconBackground,
                        item.iconForeground,
                        'rounded-lg inline-flex p-2 ring-4 ring-white'
                      )}
                    >
                      <item.icon className="wh-4" aria-hidden="true" />
                    </span>
                    <h3 className="text-lg font-medium">
                      <div className="focus:outline-none">
                        {/* Extend touch target to entire panel */}
                        <span className="" aria-hidden="true" />
                        {item.title}
                      </div>
                    </h3>
                  </div>
                  <div className="mt-4">
                    <p className=" text-sm text-gray-500">{item.desc}</p>
                  </div>
                </div>
              ))}
              <div className="flex justify-center w-full !my-8">
                <Link
                  to="/student-request"
                  className="inline-flex items-center rounded-full border border-transparent bg-indigo-600 px-6 py-3 text-base font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                >
                  {t('quickRequestSection.ctaText')}
                </Link>
              </div>
            </div>
            <div className="flex md:justify-end text-right space-y-1 md:col-span-5">
              <picture>
                <source media={'(max-width:400px)'} srcSet={imgQuickRequest} />
                <source media="(min-width:401px)" srcSet={imgQuickRequest} />
                <img
                  src={imgQuickRequest}
                  alt="lading-img"
                  className="md:max-h-[600px]"
                  style={{ width: 'auto' }}
                />
              </picture>
            </div>
          </div>
        </div>
      </section>
    );
  };

  const TestiMonialsItems = [
    {
      description: t('testimonialItems.desc1'),
      name: 'Sainzaya',
      location: 'Mongolia',
    },
    {
      description: t('testimonialItems.desc3'),
      name: 'Emilie',
      location: 'South Korea',
    },
    {
      description: t('testimonialItems.desc2'),
      name: 'Biraj ',
      location: 'Nepal',
    },
  ];

  const TestimonialSection = () => {
    return (
      <section className="max-w-[1000px] text-center space-y-12 py-16 m-auto w-full px-4">
        <div className="space-y-2">
          <h4 className="font-medium text-sm">
            {t('testimonialSection.title')}
          </h4>
          <h2 className="font-bold text-[1.5rem] md:text-[2rem] lg:text-4xl">
            {t('testimonialSection.subtitle')}
          </h2>
        </div>
        <div className="grid grid-cols-1 lg:grid-cols-3 gap-4 text-center">
          {TestiMonialsItems.map((item) => {
            return (
              <div className="text-sm py-6 px-8 rounded-lg shadow-lg border border-gray-100 flex flex-col items-center space-y-6">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="25"
                  height="21"
                  fill="none"
                  viewBox="0 0 25 21"
                  className="text-brand-orange wh-5"
                >
                  <path
                    fill="currentColor"
                    d="M24.955 11.33h-5.96c.101-4.772 1.333-5.314 2.88-5.468l.598-.081V.532l-.688.04c-2.021.126-4.255.526-5.746 2.548-1.307 1.772-1.883 4.667-1.883 9.112v8.235h10.8v-9.136Zm-13.778 9.137v-9.136H5.296c.102-4.773 1.294-5.315 2.842-5.47l.557-.08V.532l-.648.04C6.026.699 3.77 1.099 2.28 3.12.974 4.892.378 7.787.378 12.232v8.235h10.8Z"
                  />
                </svg>
                <p>{item.description}</p>
                <div className="max-w-[30px] w-full border-t-2 border-brand-orange"></div>
                <div>
                  <h4 className="font-bold">{item.name}</h4>
                  <p>{item.location}</p>
                </div>
              </div>
            );
          })}
        </div>
      </section>
    );
  };

  const inquirySection = () => {
    return (
      <section
        data-aos="fade-in"
        className="relative  w-full overflow-hidden box-content bg-cover bg-no-repeat md:mt-0"
        style={{
          background: '#F2F5FF',
        }}
      >
        {/* <div className="">
          <RectBgSvg />
        </div> */}
        <div className="!py-20 max-w-container px-0 md:px4 flex justify-between items-center gap-4">
          <div className="space-y-8 ">
            <div className="flex gap-2 items-center ">
              <Icon.ChatBubbleOvalLeftEllipsisIcon className="wh-6 font-semibold" />
              <p>{t('inquiryBanner.badge')}</p>
            </div>
            <h2 className=" text-brand-navy2 text-[1.5rem] md:text-[2rem] font-bold">
              {t('inquiryBanner.title')}
            </h2>
            <p className="whitespace-pre-line text-gray-600">
              {t('inquiryBanner.desc1')}
            </p>
          </div>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="350"
            height="350"
            fill="none"
            viewBox="0 0 350 350"
            className="hidden md:block opacity-80"
          >
            <path
              fill="#B6CAFF"
              d="M175 212.5a87.36 87.36 0 0 1-41.379-10.379 87.366 87.366 0 0 1-31.533-28.733l20.825-13.838a62.5 62.5 0 0 0 104.174 0l20.825 13.838a87.366 87.366 0 0 1-31.533 28.733A87.36 87.36 0 0 1 175 212.5ZM225 75a24.998 24.998 0 0 0-23.097 15.433 25.006 25.006 0 0 0 5.419 27.245 25.006 25.006 0 0 0 38.465-3.789A24.998 24.998 0 0 0 250 100a24.749 24.749 0 0 0-25-25Zm-100 0a24.998 24.998 0 0 0-23.097 15.433 25.004 25.004 0 0 0 5.419 27.245 25.006 25.006 0 0 0 38.465-3.789A24.998 24.998 0 0 0 150 100a24.749 24.749 0 0 0-25-25Z"
            />
            <path
              fill="#B6CAFF"
              d="M196.7 350 175 337.5l50-87.5h75a24.955 24.955 0 0 0 17.689-7.311A24.955 24.955 0 0 0 325 225V50a24.954 24.954 0 0 0-7.311-17.689A24.974 24.974 0 0 0 300 25H50a24.961 24.961 0 0 0-25 25v175a24.962 24.962 0 0 0 25 25h112.5v25H50a50 50 0 0 1-50-50V50A49.987 49.987 0 0 1 50 0h250a49.985 49.985 0 0 1 50 50v175a49.999 49.999 0 0 1-50 50h-60.437L196.7 350Z"
            />
          </svg>
        </div>
      </section>
    );
  };

  const sectionMentorList = () => {
    return (
      <section
        // data-aos="fade-in"
        className="relative  w-full overflow-hidden box-content bg-cover bg-no-repeat md:mt-0"
        style={{
          background: '#FFF',
        }}
      >
        <div className="">
          <RectBgSvg />
        </div>
        <div className="max-w-screen-xl mx-auto px-0 md:px4 mb-10 md:mb-16 flex flex-col">
          <h2 className="py-10 md:py-16 self-center text-brand-navy2 text-[1.5rem] md:text-[2rem] font-bold text-center">
            {t('ourMentorsSection.title')}
          </h2>

          <Slider
            stylesOverride={CSSOverride}
            slidesPerView={'auto'}
            breakpoints={SliderBreakPoints}
            initialSlide={matches ? 1 : 0}
          >
            {mentorsRandomized.map((mentor: any, index: number) => (
              <SwiperSlide key={`homeMentors_Slide_${index}`}>
                <MentorCard key={mentor.id} mentor={mentor} />
              </SwiperSlide>
            ))}
          </Slider>
        </div>
      </section>
    );
  };

  if (authenticated && !me) return <></>;
  return (
    <div className="overflow-x-hidden">
      {/* {i18n.language === 'ko' && <ModalComp />} */}

      {/* Hero Section */}
      {HeroSection()}
      {/* College logos marquee Section */}
      <CollegeLogosMarquee bgColor="FFFFFF" withContainer />
      {QuickRequestIntroduction()}
      {/* Mentor Slide Section */}
      {sectionMentorList()}
      {TestimonialSection()}
      {/* Start 문의 섹션 */}
      {inquirySection()}
      {/* End 문의 섹션 */}
    </div>
  );
};

const StyledPlyrWrapper = styled.div`
  position: relative;
  padding-top: 56.25%; /* 720 / 1280 = 0.5625 */
  width: 100%;
  height: 100%;

  iframe {
    width: 100%;
    height: 100%;
  }

  .react-player {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
`;
