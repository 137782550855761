import React, { FC } from 'react';
import { useQuery } from 'react-query';
import { Link, useLocation } from 'react-router-dom';
import { fetcher } from '@plugins/react-query';
import {
  // ChatState,
  // Role,
  Tag,
  TagLinkTargetType,
  TagType,
  User,
} from '../types';
// import { useAuth } from '../hooks';
import { getS3Uri } from '../utils';
import Highlighter from 'react-highlight-words';
import { StarIcon } from '@heroicons/react/20/solid';
import { UserIcon } from '@heroicons/react/24/outline';
interface MentorCardProps {
  mentor?: User;
  skeleton?: boolean;
  isAdvisor?: boolean;
  className?: string;
  query?: string;
}

export const MentorCard: FC<MentorCardProps> = ({
  mentor,
  isAdvisor,
  skeleton,
  className,
  query,
}) => {
  const { pathname } = useLocation();
  const { data: tags } = useQuery<Tag[]>(
    `/tag-links/tags?type=${TagLinkTargetType.USER}&id=${mentor?.id}`,
    fetcher,
    {
      enabled: !!mentor?.id,
      keepPreviousData: true,
      refetchOnWindowFocus: false,
    }
  );

  const { data: mentorSubjects } = useQuery<any[]>(
    `/v2/subject-link?mentorId=${mentor?.id}`,
    fetcher,
    {
      enabled: !!mentor?.id,
    }
  );

  // const { data: me } = useQuery<User>('/users/me', fetcher, {
  //   enabled: !!authenticated,
  // });
  // const { data: chat } = useQuery<Chat>(`/chats/mentor/${id}`, fetcher, {
  //   enabled: me?.role === Role.STUDENT,
  // });
  const schoolTag = tags?.find((tag) => tag.type === TagType.SCHOOL);
  const majorTag = tags?.find((tag) => tag.type === TagType.MAJOR);

  return (
    <Link
      to={{
        pathname: `/mentors/${mentor?.id}`,
        state: { from: { pathname: pathname } },
      }}
      className="col-span-12 md:col-span-6 lg:col-span-4"
    >
      <div className={`card-square mb-0 h-full bg-white ${className}`}>
        <div
          className={` h-[120px] w-full 
          ${
            skeleton
              ? 'bg-gray-400'
              : // : schoolTag?.name.toLowerCase().includes('consultant') ||
                //   schoolTag?.name.toLowerCase().includes('tutor') ||
                //   schoolTag?.name.toLowerCase().includes('advisor')
                // ? 'bg-brand-navy2 bg-opacity-[87%]'
                'bg-brand-navy2 bg-opacity-[85%]'
          }
          `}
        />
        <div className="flex flex-col flex-auto p-[1rem] lg:p-[1.5rem] gap-3">
          <div className="flex justify-between items-center">
            <div
              className="relative avatar-xl lg:avatar-xxl mt-[-7.5rem] mb-2 overflow-hidden rounded-lg bg-gray-200 border-2 border-white shadow bg-center bg-cover bg-no-repeat swiper-lazy"
              style={{
                backgroundImage: `url(${getS3Uri(mentor?.avatar)})`,
              }}
            >
              {!mentor?.avatar && (
                <div className="flex space-x-3 items-center justify-center">
                  <div
                    className="rounded-lg bg-gray-200 w-full md:w-44 flex justify-center items-center"
                    style={{ aspectRatio: '1/1' }}
                  >
                    {!skeleton && (
                      <UserIcon className="text-gray-400 max-w-[100px] w-full p-4" />
                    )}
                  </div>
                  {/* {!skeleton && <div className="swiper-lazy-preloader"></div>} */}
                </div>
              )}
            </div>
            <span
              className={`mt-[-5.5rem] mb-2 font-semibold text-[10px] bg-gray-100 rounded-md text-center px-3 py-1 truncate`}
              style={{ height: 'fit-content' }}
            >
              {schoolTag?.name}
            </span>
          </div>
          <div className="flex justify-between items-center">
            {mentor && query ? (
              <Highlighter
                className="font-semibold text-20 mt-2"
                searchWords={query.split(' ')}
                autoEscape={true}
                textToHighlight={`${mentor?.firstName} ${mentor?.lastName}`}
              />
            ) : (
              <h3 className="font-semibold text-sm mt-2">
                {mentor?.firstName} {mentor?.lastName}
              </h3>
            )}
            {Number(mentor?.totalReviewCount) >= 1 && (
              <div className="flex gap-1 items-center text-sm">
                <StarIcon className="wh-4 text-brand-orange" />
                <p>{`${mentor?.avgRating ?? 'N/A'} (${
                  mentor?.totalReviewCount
                })`}</p>
              </div>
            )}
          </div>
          {/* <div className="text-xs">{schoolTag?.name}</div> */}
          {majorTag?.name && query ? (
            <Highlighter
              className="text-xs truncate font-semibold"
              // highlightClassName="YourHighlightClass"
              searchWords={query.split(' ')}
              autoEscape={true}
              textToHighlight={majorTag?.name}
            />
          ) : (
            <div className="text-xs truncate font-semibold">
              {majorTag?.name}
            </div>
          )}

          {mentor?.introduction && query ? (
            <Highlighter
              className="text-xs font-light truncate-2-lines whitespace-pre-line"
              // highlightClassName="YourHighlightClass"
              searchWords={query.split(' ')}
              autoEscape={true}
              textToHighlight={mentor?.introduction}
            />
          ) : (
            <p className="text-xs font-light truncate-2-lines whitespace-pre-line">
              {mentor?.introduction}
            </p>
          )}

          <div className="flex flex-wrap gap-2 mt-4">
            {mentorSubjects
              ?.filter((i) => i.subject.isHidden === false)
              .map((item) => {
                return (
                  <span
                    className={`font-semibold text-[10px] bg-gray-100 rounded-md text-center px-3 py-1 text-[#64789A]`}
                    style={{ height: 'fit-content' }}
                  >
                    {item.subject.title}
                  </span>
                );
              })}
          </div>
        </div>
      </div>
    </Link>
    // <Link
    //   className="flex flex-col lg:flex-row lg:space-x-6 cursor-pointer space-y-4"
    //   to={`/mentors/${mentor.id}`}
    // >
    // {mentor.avatar ? (
    //   <div className="relative aspect-w-1 aspect-h-1 md:aspect-none md:h-60 md:w-56 rounded-lg">
    //     <img
    //       src={getS3Uri(mentor.avatar)}
    //       alt="avatar"
    //       className="absolute w-full h-full md:h-60 md:w-56 object-cover rounded-lg bg-gray-200"
    //     />
    //   </div>
    // ) : (
    //   <div className="flex space-x-3 items-center">
    //     <div
    //       className="rounded-lg bg-gray-200 w-full md:w-44"
    //       style={{ aspectRatio: '1/1' }}
    //     ></div>
    //   </div>
    // )}

    //   <div className="flex-1">
    //     <div className="md:flex md:space-x-4 items-center">
    //       <div>
    //         <div className="space-x-2 flex mb-2">
    //           <h1 className="font-semibold text-20">
    //             {mentor.firstName} {mentor.lastName}
    //           </h1>

    //           {/* {mentor.chatState === ChatState.AVAILABLE && (
    //           <span className="flex items-center text-sm text-available bg-available-light rounded-full text-center px-3 py-1.2">
    //             Available
    //           </span>
    //         )} */}

    //           <span className="font-medium text-13 bg-gray-100 rounded-md text-center px-3 py-1.5">
    //             {schoolTag?.name}
    //           </span>
    //         </div>
    //       </div>

    //       {/* {mentor.chatState === ChatState.NOTAVAILABLE && (
    //         <span className="flex items-center  text-sm text-unavailable bg-unavailable-light rounded-full text-center px-3 py-1.2">
    //           Coming Soon
    //         </span>
    //       )} */}
    //     </div>

    //     <div className="md:flex items-center md:space-x-4 mt-1">
    //       <div className="mt-2 md:mt-0 ">
    //         <span className="whitespace-pre-line">
    //           {mentor.adminMemo ? mentor.adminMemo : majorTag?.name}
    //         </span>
    //       </div>
    //     </div>
    //     {!isAdvisor && (
    //       <p className="text-14 mt-4 md:mt-6 whitespace-pre-line break-word truncate-5-lines">
    //         {mentor.introduction}
    //       </p>
    //     )}
    //   </div>
    // </Link>
  );
};
