import i18n, { Resource } from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import * as en from './en';
import * as ko from './ko';
import i18next from 'i18next';

const resources: Resource = {
  en: {
    ...en,
  },
  ko: {
    ...ko,
  },
} as const;

const options = {
  order: [
    'querystring',
    // 'cookie',
    'localStorage',
    'sessionStorage',
    'navigator',
    'htmlTag',
    'path',
    'subdomain',
  ],

  // keys or params to lookup language from
  lookupQuerystring: 'lng',
  // lookupCookie: 'i18next',
  lookupLocalStorage: 'i18nextLng',
  lookupSessionStorage: 'i18nextLng',
  lookupFromPathIndex: 0,
  lookupFromSubdomainIndex: 0,

  // cache user language on
  caches: ['localStorage', 'cookie'],
  excludeCacheFor: ['cimode'], // languages to not persist (cookie, localStorage)

  // optional expire and domain for set cookie
  // cookieMinutes: 10,
  // cookieDomain: 'myDomain',

  // optional htmlTag with lang attribute, the default is:
  htmlTag: document.documentElement,

  // optional set cookie options, reference:[MDN Set-Cookie docs](https://developer.mozilla.org/en-US/docs/Web/HTTP/Headers/Set-Cookie)
  //  cookieOptions: { path: '/', sameSite: 'strict' }
};

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources,
    // lng: 'en' // <--- turn off for detection to work
    fallbackLng: 'en',
    // fallbackLng: {
    //   'en-US': ['en-US'],
    //   default: ['ko-KR'],
    // },
    detection: options,
    keySeparator: '.', // we do not use keys in form messages.welcome
    // interpolation: { // not needed for react!!
    //   escapeValue: false,
    // },
    debug: process.env.NODE_ENV === 'development', // 디버거로 오류 내역 콘솔에 나옴
    react: {
      useSuspense: false,
    },
  });

export default i18n;
