import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Button } from '@components/Button';
import { TextField } from '@components/TextField';
import { useAuth } from '@src/hooks';
import HeroImg from '@assets/img/heroimg-signup.png';
import { Checkbox } from '@components/Checkbox';
import { Link, useHistory } from 'react-router-dom';
import { Role, Tag, TagType } from '@src/types';
import { AvatarInput } from '@components/AvatarInput';
import { api } from '@plugins/axios';
import axios from 'axios';
import { Select } from '@components/Select';
import { UploadFile } from '@components/UploadFile';
import { useQuery } from 'react-query';
import { fetcher } from '@src/plugins/react-query';
import { resizeImage } from '@src/plugins/compress';
import { Label } from '@components/Label';

interface FormValues {
  firstName: string;
  lastName: string;
  password: string;
  confirmPassword: string;
  role: Role;
  email: string;
  payPalEmail: string;
  year: string;
  month: string;
  day: string;
  school?: number;
  major?: number | string;
  sat?: number;
  gpa?: number;
  act?: number;
  ap?: number;
  extra?: number;
}

export const SignupMentorPage = () => {
  const { signup } = useAuth();
  const { push } = useHistory();
  const [avatar, setAvatar] = useState<File>();
  const [proofOfEducation, setProofOfEducation] = useState<File>();
  const [uploading, setUploading] = useState(false);
  const [terms, setTerms] = useState(false);

  const {
    register,
    watch,
    setValue,
    handleSubmit,
    formState: { errors },
  } = useForm<FormValues>({
    mode: 'onChange',
    //@ts-ignore
    defaultValues: {
      role: Role.MENTOR,
      major: 'default',
      sat: 0,
      gpa: 0,
      act: 0,
      ap: 1,
      extra: 0,
    },
  });

  const school = watch('school');
  const m = watch('month') ? `0${watch('month')}` : watch('month');
  const d = watch('day') ? `0${watch('day')}` : watch('day');

  const { data: schoolTags } = useQuery<Tag[]>(
    `/tags?type=${TagType.SCHOOL}`,
    fetcher
  );
  const { data: majorTags } = useQuery<Tag[]>(
    `/tags?type=${TagType.MAJOR}`,
    fetcher
  );

  useEffect(() => {
    setValue('school', schoolTags?.[0]?.id);
  }, [setValue, schoolTags]);

  // useEffect(() => {
  //   setValue('major', majorTags?.find((m) => m.parentId === school)?.id);
  // }, [setValue, school, majorTags]);

  async function uploadFile(file?: File) {
    if (!file) return;
    const { data: post } = await api.get(
      `/s3/presignedPost?filename=${file.name}`
    );
    const formData = new FormData();
    Object.entries(post.fields).forEach(([name, value]) =>
      formData.append(name, value as any)
    );
    formData.append('file', await resizeImage(file));

    await axios.post(post.url, formData, {
      headers: { 'Content-Type': 'multipart/form-data' },
    });

    return post.fields.key;
  }

  const onSubmit = handleSubmit((data) => {
    if (!avatar) {
      alert('Please upload a profile picture.');
      return;
    }
    if (!terms) {
      alert('Please agree to our Terms & Conditions and Privacy Policy.');
      return;
    }
    if (!proofOfEducation) {
      alert('Please upload your proof of enrollment.');
      return;
    }

    const dob = watch('year') + m + d;

    const { school, major, ...user } = data;

    signup({ dob: dob, ...user })
      .then(async () => {
        if (!avatar || !proofOfEducation) return;
        setUploading(true);

        if (major === 'default') {
          await api.post(`/tag-links/add`, {
            school: school,
          });
        } else {
          await api.post(`/tag-links/add`, {
            school: school,
            major: major,
          });
        }

        const promises = [avatar, proofOfEducation].map((file) =>
          uploadFile(file)
        );
        const [avatarKey, proofOfEducationKey] = await Promise.all(promises);

        await api
          .patch('/users/me', {
            avatar: avatarKey,
            proofOfEducation: proofOfEducationKey,
          })
          .then(async () => {
            gtmSignup();
            push('/signup/mentor/pending');
          });
      })
      .catch(() =>
        alert(
          'This email cannot be used or is already connected to an account.'
        )
      );
  });

  const gtmSignup = () => {
    window.dataLayer.push({ ecommerce: null });
    window.dataLayer.push({
      event: 'sign_up',
      ecommerce: { method: 'mentor' },
    });
  };

  const maxOffset = 70;
  const thisYear = new Date().getFullYear();
  const allYears = [];
  for (let x = 0; x <= maxOffset; x++) {
    allYears.push(thisYear - x);
  }

  const yearList = allYears.map((y) => {
    return (
      <>
        <option key={y} value={y}>
          {y}
        </option>
      </>
    );
  });
  const monthList = Array.from({ length: 12 }, (_, i) => i + 1).map((m) => {
    return (
      <>
        <option key={m} value={m}>
          {m}
        </option>
      </>
    );
  });
  const days = new Date(
    Number(watch('year')),
    Number(watch('month')),
    0
  ).getDate();
  const dayList = Array.from({ length: days }, (_, i) => i + 1).map((d) => {
    return (
      <>
        <option key={d} value={d}>
          {d}
        </option>
      </>
    );
  });

  const majorTest = watch('major');
  useEffect(() => {
    console.log('test!!!', majorTest);
  }, [majorTest]);

  return (
    <div
      className="absolute inset-0 bg-center bg-cover bg-no-repeat overflow-y-auto"
      style={{ backgroundImage: `url('${HeroImg}')` }}
    >
      <div className="max-w-screen-xl mx-auto flex items-center px-4">
        <div className="max-w-lg bg-white rounded-md py-8 px-4 md:px-10 my-20 ">
          <h1 className="text-30 font-bold text-center pb-6">
            Sign up as a Tutor
          </h1>
          <form className="p-4 flex flex-col space-y-4" onSubmit={onSubmit}>
            <AvatarInput onChange={(file) => setAvatar(file)} />
            <TextField
              label="First name*"
              placeholder="Enter your first name"
              helper={errors.firstName?.message}
              {...register('firstName', {
                required: 'Please enter your firstName.',
                validate: {
                  alphabetWithSpace: (value) =>
                    (value && /^[a-zA-Z ]*$/.test(value)) ||
                    'Only alphabets and spaces are allowed.',
                },
              })}
            />
            <TextField
              label="Last name*"
              placeholder="Enter your last name"
              helper={errors.lastName?.message}
              {...register('lastName', {
                required: 'Please enter your lastName.',
                validate: {
                  alphabetWithSpacesAndPunctuation: (value) =>
                    (value && /^[a-zA-Z .]*$/.test(value)) ||
                    'Only alphabets, spaces and periods are allowed.',
                },
              })}
            />
            <TextField
              type="email"
              label="University email address*"
              placeholder="Enter your email"
              helper={errors.email?.message}
              {...register('email', {
                pattern:
                  /^(([^<>()\[\].,;:\s@"]+(\.[^<>()\[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i,
                required: 'Please enter your email.',
              })}
            />
            <TextField
              autoComplete="off"
              isPassword={true}
              label="Password* (Use 8 or more characters with a mix of letters, numbers & symbols)"
              placeholder="Enter your password"
              helper={errors.password?.message}
              {...register('password', {
                required: 'Please enter your password.',
                validate: {
                  banned: (value: string) => {
                    const regex =
                      /^[~`!@#$%^&*()_+=[\]\{}|;':",.\/<>?a-zA-Z0-9-]+$/;
                    if (!regex.test(value)) {
                      return 'Only use letters, numbers, and common punctuation characters';
                    }
                  },
                  length: (value) =>
                    (value && value.length >= 8) ||
                    'Use 8 characters or more for your password',
                },
              })}
            />
            <TextField
              autoComplete="off"
              isPassword={true}
              label="Confirm password"
              placeholder="Enter your password"
              helper={errors.confirmPassword?.message}
              {...register('confirmPassword', {
                required: 'Please enter your password.',
                validate: (val: string) => {
                  if (watch('password') !== val) {
                    return 'Those passwords didn’t match. Try again.';
                  }
                },
              })}
            />

            <div>
              <Label text="Date of Birth*" />
              <div className="flex space-x-3.5 mt-0">
                <div className="w-full">
                  <Select
                    placeholder="Year"
                    helper={errors.year?.message}
                    {...register('year', {
                      required: 'Please select your year.',
                    })}
                  >
                    <option value="" selected disabled hidden>
                      YYYY
                    </option>
                    {yearList}
                  </Select>
                </div>
                <div className="w-full">
                  <Select
                    placeholder="Month"
                    helper={errors.month?.message}
                    {...register('month', {
                      required: 'Please select your month.',
                    })}
                  >
                    <option value="" selected disabled hidden>
                      MM
                    </option>
                    {monthList}
                  </Select>
                </div>
                <div className="w-full">
                  <Select
                    placeholder="Day"
                    helper={errors.day?.message}
                    {...register('day', {
                      required: 'Please select your day.',
                    })}
                  >
                    <option value="" selected disabled hidden>
                      DD
                    </option>
                    {dayList}
                  </Select>
                </div>
              </div>
            </div>

            <Select
              label="School*"
              className={watch('school') ? '' : 'text-gray-400'}
              {...register('school', {
                setValueAs: (value) => Number(value),
                required: 'Please select your school.',
              })}
            >
              {schoolTags?.map((tag) => (
                <option key={tag.id} value={tag.id}>
                  {tag.name}
                </option>
              ))}
            </Select>

            <Select
              label="Major"
              className={watch('major') !== 'default' ? '' : 'text-gray-400'}
              {...register('major', {
                setValueAs: (value) =>
                  value === 'default' ? value : Number(value),
                required: 'Please select your major.',
              })}
            >
              <option
                label="Please select your major."
                key={`defaultMajorOption`}
                value={'default'}
                defaultChecked={true}
                className="text-gray-400"
              />

              {majorTags
                ?.filter((tag) => tag.parentId === school)
                .map((tag) => (
                  <option label={tag.name} key={tag.id} value={tag.id} />
                ))}
            </Select>

            <UploadFile
              label="Proof of enrollment*"
              onChange={(file) => setProofOfEducation(file)}
            />

            <TextField
              type="number"
              label="Unweighted high school GPA (out of 4.0)"
              min={0}
              max={4}
              helper={errors.gpa?.message}
              {...register('gpa', {
                min: { value: 0, message: 'Enter 0 or more' },
                max: { value: 4, message: 'Enter 4 or less' },
              })}
            />
            <TextField
              type="number"
              label="SAT composite score (out of 1600)"
              min={0}
              max={1600}
              helper={errors.sat?.message}
              {...register('sat', {
                min: { value: 0, message: 'Enter 0 or more' },
                max: { value: 1600, message: 'Enter 1600 or less' },
              })}
            />
            <TextField
              type="number"
              label="ACT composite score (out of 36)"
              min={0}
              max={1600}
              helper={errors.act?.message}
              {...register('act', {
                min: { value: 0, message: 'Enter 0 or more' },
                max: { value: 1600, message: 'Enter 1600 or less' },
              })}
            />
            <TextField
              type="number"
              label="Number of AP exams taken"
              min={1}
              max={5}
              helper={errors.ap?.message}
              {...register('ap', {
                min: { value: 1, message: 'Enter 1 or more' },
                max: { value: 5, message: 'Enter 5 or less' },
              })}
            />
            <TextField
              type="number"
              label="Avg. hours spent on extracurriculars per week"
              min={0}
              max={100}
              helper={errors.extra?.message}
              {...register('extra', {
                min: { value: 0, message: 'Enter 0 or more' },
                max: { value: 100, message: 'Enter 100 or less' },
              })}
            />
            <TextField
              type="email"
              label="PayPal Email*"
              placeholder="Enter your paypal email"
              helper={errors.payPalEmail?.message}
              {...register('payPalEmail', {
                pattern:
                  /^(([^<>()\[\].,;:\s@"]+(\.[^<>()\[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i,
                required: 'Please enter your paypal email.',
              })}
            />
            <div className="flex items-center pt-8">
              <Checkbox
                className="rounded-full"
                onChange={(e) => setTerms(e.target.checked)}
              />
              <p>
                I have read and agree to Linkstory’s{' '}
                <Link to="/termsandconditions" className="underline">
                  Terms & Conditions
                </Link>
                {` and `}
                <Link to="/privacy" className="underline">
                  Privacy Policy
                </Link>
              </p>
            </div>
            <div className="flex flex-col space-y-4 pt-8">
              <Button
                text="Sign Up"
                className="filled-brand-1 rounded-full"
                disabled={
                  !watch('firstName') ||
                  !watch('lastName') ||
                  !watch('password') ||
                  !watch('email') ||
                  !watch('payPalEmail') ||
                  !watch('year') ||
                  !watch('month') ||
                  !watch('day') ||
                  !avatar ||
                  !terms
                }
              />
            </div>
            <p className="text-gray-600 text-center text-15">
              Already have an account?
              <Link to="/login" className="underline pl-1">
                Log in
              </Link>
            </p>
          </form>
        </div>
      </div>
    </div>
  );
};
