import moment from 'moment';
import React, { FC, useEffect, useState } from 'react';
import { api } from '../plugins/axios';
import { Button } from './Button';
import { Icon } from './icons';
import { TextField } from './TextField';
import { Checkbox } from '../components/Checkbox';
import { useForm } from 'react-hook-form';
import { Subject, User } from '@src/types';
import { ClockIcon, StarIcon } from '@heroicons/react/24/outline';
import { getS3Uri } from '@src/utils';
import { Avatar } from './Avatar';
import { Select } from './Select';
import { useQuery } from 'react-query';
import { fetcher } from '@src/plugins/react-query';
import { useLocation, useParams } from 'react-router-dom';
import { MomentFormat, utcToLocalFormat } from '@src/plugins/moment';
import { PriceField } from './PriceField';
import { parse } from 'qs';
import { ErrorMessage } from '@hookform/error-message';
import mixpanel from 'mixpanel-browser';

interface MentorSchedulePopupProps {
  title: string;
  buttonTitle: string;
  chatId: number;
  mentor: User | undefined;
  student: User | undefined;
  open: boolean;
  onClose: () => void;
}

interface FormValues {
  subjectId: number;
  title: string;
  startedAt: Date;
  endedAt: Date;
  price: number;

  terms: boolean;
}

export const MentorSchedulePopup: FC<MentorSchedulePopupProps> = ({
  title,
  onClose,
  chatId,
  mentor,
  student,
  open,
  buttonTitle,
}) => {
  const {
    register,
    reset: resetForm,
    handleSubmit,
    watch,
    formState: { isSubmitting, errors, isDirty, isValid },
  } = useForm<FormValues>({
    mode: 'onChange',
  });

  const { data: subjectsItems } = useQuery<Subject[]>(`/v2/subjects`, fetcher, {
    keepPreviousData: true,
    refetchOnWindowFocus: false,
  });

  const { data: mentorSubjects } = useQuery<any[]>(
    `/v2/subject-link?mentorId=${Number(mentor?.id)}`,
    fetcher,
    {
      enabled: !!mentor?.id,
    }
  );
  const [currentTime, setCurrentTime] = useState<string>(
    moment().toISOString()
  );

  useEffect(() => {
    const timerID = setInterval(() => tick(), 1000);
    return () => {
      clearInterval(timerID);
    };
  }, []);

  const tick = () => {
    setCurrentTime(moment().toISOString());
  };

  useEffect(() => {
    //  if route opens block body scroll
    if (open) {
      document.body.style.overflow = 'hidden';
    } else {
      return () => {
        document.body.style.overflow = 'auto';
      };
    }
  }, [open]);

  const handleOnClose = () => {
    console.log('reset');
    resetForm();
    document.body.style.overflow = 'auto';
    onClose();
  };

  const { data: isStudentCreditCard } = useQuery<any>(
    `/credit-cards/student/${student?.id}/find-mentor`,
    fetcher,

    {
      enabled: !!student?.id,
      refetchOnWindowFocus: false,
    }
  );

  if (!open || !mentor) return <></>;

  const mentorProfileSection = () => {
    return (
      <>
        <Avatar
          className={`flex-none !wh-8 md:!wh-10 `}
          src={
            student?.avatar.startsWith('https')
              ? student?.avatar
              : getS3Uri(student?.avatar)
          }
        />
        <div className="flex-col w-full">
          <div className="flex flex-col flex-wrap gap-1 truncate">
            <h4
              className={` text-sm md:text-base font-semibold text-ellipsis overflow-hidden whitespace-nowrap text-left `}
            >
              {student?.firstName} {student?.lastName}
            </h4>
            <div className="flex gap-1 items-center text-sm">
              <div>Payment method:</div>
              <span
                className={`inline-flex items-center rounded-full ${
                  isStudentCreditCard
                    ? 'bg-green-100 text-green-800'
                    : 'bg-pink-100 text-pink-800'
                }  px-2.5 py-0.5 text-xs font-medium `}
              >
                {isStudentCreditCard ? 'Confirmed' : 'Not yet added'}
              </span>
            </div>
          </div>
        </div>
      </>
    );
  };

  const formSection = () => {
    return (
      <>
        <Select
          label="Subject"
          helper={errors.subjectId?.message}
          className="block w-full max-w-lg  border-gray-300 shadow-sm h-10  text-sm"
          {...register('subjectId', {
            required: 'Please select session subject',
          })}
        >
          <option value="" selected disabled hidden>
            Please select session subject
          </option>
          {subjectsItems
            ?.filter((item) =>
              mentorSubjects?.find((option) => option.subjectId === item.id)
            )
            .map((item) => {
              return (
                <option value={Number(item.id)} key={`subject_${item.id}`}>
                  {item.title.toUpperCase()}
                </option>
              );
            })}
        </Select>
        <TextField
          label="Session title"
          placeholder="Please give the session a title."
          helper={errors.title?.message}
          {...register('title', {
            required: 'Please enter section title.',
          })}
        />
        <div>
          <label
            htmlFor="currentTime"
            className="block text-sm  text-gray-700 sm:mt-px "
          >
            Your current time
          </label>
          <div
            className="mt-1 sm:col-span-2 flex gap-2 text-base items-center text-gray-600"
            id="currentTime"
          >
            <dt className="">
              <span className="sr-only">Date</span>
              <ClockIcon className="wh-5 " aria-hidden="true" />
            </dt>
            <dd>
              <div>
                {utcToLocalFormat(currentTime, MomentFormat.YYYYMMDDHmmss)}{' '}
                {moment.tz(moment.tz.guess()).zoneAbbr()}
              </div>
            </dd>
          </div>
        </div>
        <div>
          <TextField
            id="date1"
            label={`Start time`}
            type="datetime-local"
            data-placeholder="YYYY. MM. DD hh:mm"
            min={moment().format('YYYY-MM-DD[T]HH:mm')}
            className={`text-sm h-10 ${
              !!watch('startedAt') ? 'has-value' : ''
            }`}
            helper={errors.startedAt?.message}
            {...register('startedAt', {
              required: 'Please enter your prefered time',
              validate: (value: Date) => {
                if (moment(value).isBefore(moment())) {
                  return 'The session start time cannot be earlier than the current time.';
                }
                if (moment(value).isAfter(watch('endedAt'))) {
                  return 'The session start time cannot be later than the end time.';
                }
              },
            })}
          />
        </div>
        <div>
          <TextField
            id="date1"
            label={`End time`}
            type="datetime-local"
            data-placeholder="YYYY. MM. DD hh:mm"
            min={moment(watch('startedAt')).format('YYYY-MM-DD[T]HH:mm')}
            className={`text-sm h-10 ${!!watch('endedAt') ? 'has-value' : ''}`}
            helper={errors.endedAt?.message}
            {...register('endedAt', {
              required: 'Please enter your prefered time',
              validate: (value: Date) => {
                if (moment(value).isBefore(moment())) {
                  return 'The session end time cannot be earlier than the current time.';
                }
                if (moment(value).isBefore(watch('startedAt'))) {
                  return 'Session end time cannot be earlier than start time.';
                }
              },
            })}
          />
        </div>
        <PriceField
          label="Price"
          helper={errors.price?.message}
          step=".01"
          {...register('price', {
            setValueAs: (value) => Number(value),
            required: 'Please input price',
            validate: (price: number) => {
              if (price < 1) {
                return 'The minimum price you can set is $1';
              }
            },
          })}
        />
        <div>
          <p className="text-12 text-gray-400 pb-6">
            Plase remember to check the subject, date, time, and price before
            you create a session
          </p>
          <div className="pb-6">
            <Checkbox
              label="I understand"
              {...register('terms', {
                required: 'This is required',
              })}
            />
          </div>
        </div>
        <Button
          text={isSubmitting ? 'Submitting' : buttonTitle}
          className={`text-sm md:text-xl disabled:bg-gray-400 bg-brand-1 text-white rounded-full w-full `}
          type="submit"
          isSubmitting={isSubmitting}
          disabled={!isDirty || !isValid || isSubmitting}
        />
      </>
    );
  };

  return (
    <div className="z-50 fixed inset-0 top-[3.5rem] md:top-0 bg-littleblack flex items-center justify-center px-4">
      <div
        className="max-w-md flex flex-col  bg-white rounded-xl overflow-hidden p-6 space-y-4 overflow-y-scroll"
        style={{ maxHeight: 'calc(100vh - 10rem)' }}
      >
        <div className="flex justify-between">
          <h1 className="text-md md:text-20 font-bold flex-1">{title}</h1>
          <button className="" onClick={handleOnClose}>
            <Icon.X />
          </button>
        </div>
        <div className="flex items-center gap-2">{mentorProfileSection()}</div>
        <form
          className="flex flex-col gap-4"
          onSubmit={handleSubmit(async (data) => {
            if (isStudentCreditCard) {
              await api
                .post('/lessons/v2/lessons', {
                  price: Number(Number(data.price.toFixed(2))),
                  studentId: student?.id,
                  startedAt: moment(data.startedAt).toISOString(),
                  endedAt: moment(data.endedAt).toISOString(),
                  title: data.title,
                  subjectId: data.subjectId,
                })
                .then(() => {
                  mixpanel.track('Session Scheduled');
                  window.dataLayer.push({ event: 'schedule_session' });
                  handleOnClose();
                });
            } else {
              alert(
                'Session cannot be created because the student has not registered a payment method. Ask students to register a payment method.'
              );
            }
          })}
        >
          {formSection()}
        </form>
      </div>
    </div>
  );
};
