import React, { useMemo } from 'react';
import { css } from 'styled-components';
import { SwiperSlide } from 'swiper/react';
import Slider from '@components/Slider';
import { WebinarFeaturedCard } from '@components/WebinarFeaturedCard';
import { WebinarSlideCard } from '@components/WebinarSlideCard';
import { WEBINAR_ITEMS } from '@constants/webinar';
import { TutorType, User } from '@src/types';
import { TopMentorsSlider } from '@src/components/TopMentorsSlider';
import { TOP_MENTORS_IDS } from '@src/constants/topMentors';
import { useQuery } from 'react-query';
import { fetcher } from '@src/plugins/react-query';
import { HiddenHeadingMeta } from '@src/components/HiddenHeadingMeta';
import { ReactHelmet } from '@src/components/ReactHelmet';
import { CardBanner } from '@src/components/CardBanner';

export const Webinar = () => {
  const CSSOverride = css`
    .swiper-container {
      padding-left: 0px;
      padding-right: 0px;
    }
  `;

  const SliderBreakPoints = {
    960: {
      slidesPerView: 3,
      slidesPerGroup: 3,
      spaceBetween: 25,
    },
    640: {
      slidesPerView: 2,
      slidesPerGroup: 2,
      spaceBetween: 20,
    },
    320: {
      slidesPerView: 1,
      slidesPerGroup: 1,
      spaceBetween: 20,
    },
  };

  const { data: homePageMentors } = useQuery<User[]>(
    `/users/mentors/${TOP_MENTORS_IDS}`,
    fetcher,
    {
      keepPreviousData: true,
      enabled: !!TOP_MENTORS_IDS,
      cacheTime: 3000000,
      staleTime: 3000000,
      refetchOnWindowFocus: false,
    }
  );

  const randomizeArr = (data: any): any => {
    const mentors = data?.sort(() => Math.random() - 0.5) ?? [];
    return mentors;
  };

  const mentorsRandomized: any = useMemo(() => {
    return randomizeArr(homePageMentors);
  }, [homePageMentors]);

  return (
    <div className="bg-[#F2F4FF] flex-1">
      <HiddenHeadingMeta text="Live Webinars | Linkstory" />
      <ReactHelmet
        keywords="webinars"
        description="Interactive webinars with top tutors to answer all your questions."
        title="Live Webinars | Linkstory"
      />
      <div className="max-w-container py-8 lg:!py-12">
        {WEBINAR_ITEMS.filter(
          (item) => item.featured && new Date(item.webinar.endDate) > new Date()
        ).length >= 1 && (
          <h2 className="mt-8 text-3xl lg:text-4xl font-bold text-brand-navy2">
            Featured Webinars
          </h2>
        )}
        {WEBINAR_ITEMS.filter(
          (item) => item.featured && new Date(item.webinar.date) > new Date()
        ).map((item) => {
          return <WebinarFeaturedCard item={item} />;
        })}
        {/* Upcoming by peer mentors */}
        <div>
          {WEBINAR_ITEMS.filter(
            (item) =>
              new Date(item.webinar.endDate) > new Date() &&
              item.type === TutorType.MENTOR &&
              !item.featured
          ).length >= 1 && (
            <h2 className="mt-4 lg:mt-8 mb-4 lg:mb-6  text-xl lg:text-2xl font-bold text-brand-navy2">
              Upcoming by peer tutors
            </h2>
          )}
          <Slider
            slidesPerView={'auto'}
            breakpoints={SliderBreakPoints}
            stylesOverride={CSSOverride}
          >
            {WEBINAR_ITEMS.filter(
              (item) =>
                new Date(item.webinar.endDate) > new Date() &&
                item.type === TutorType.MENTOR &&
                !item.featured
            )
              ?.sort(
                (a, b) =>
                  new Date(b.webinar.endDate).getTime() -
                  new Date(a.webinar.endDate).getTime()
              )
              .map((item: any, index: number) => (
                <SwiperSlide key={`Webinar_Slide_${index}`}>
                  <WebinarSlideCard item={item} />
                </SwiperSlide>
              ))}
          </Slider>
        </div>
        {/* Upcoming by advisors */}
        <div>
          {WEBINAR_ITEMS.filter(
            (item) =>
              new Date(item.webinar.endDate) > new Date() &&
              item.type === TutorType.ADVISOR &&
              !item.featured
          ).length >= 1 && (
            <h2 className="mt-4 lg:mt-8 mb-4 lg:mb-6  text-xl lg:text-2xl font-bold text-brand-navy2">
              Upcoming by advisors
            </h2>
          )}
          <Slider
            slidesPerView={'auto'}
            breakpoints={SliderBreakPoints}
            stylesOverride={CSSOverride}
          >
            {WEBINAR_ITEMS.filter(
              (item) =>
                new Date(item.webinar.endDate) > new Date() &&
                item.type === TutorType.ADVISOR &&
                !item.featured
            )
              ?.sort(
                (a, b) =>
                  new Date(b.webinar.endDate).getTime() -
                  new Date(a.webinar.endDate).getTime()
              )
              .map((item: any, index: number) => (
                <SwiperSlide key={`Webinar_Slide_${index}`}>
                  <WebinarSlideCard item={item} />
                </SwiperSlide>
              ))}
          </Slider>
        </div>
        {/* Watch past webinars */}
        <div>
          {WEBINAR_ITEMS.filter(
            (item) => new Date(item.webinar.endDate) < new Date()
          ).length >= 1 && (
            <h2 className="mt-4 lg:mt-8 mb-4 lg:mb-6  text-xl lg:text-2xl font-bold text-brand-navy2">
              Watch past webinars
            </h2>
          )}
          <Slider
            slidesPerView={'auto'}
            breakpoints={SliderBreakPoints}
            stylesOverride={CSSOverride}
          >
            {WEBINAR_ITEMS.filter(
              (item) => new Date(item.webinar.endDate) < new Date()
            )
              ?.sort(
                (a, b) =>
                  new Date(b.webinar.endDate).getTime() -
                  new Date(a.webinar.endDate).getTime()
              )
              .map((item: any, index: number) => (
                <SwiperSlide key={`Webinar_Slide_${index}`}>
                  <WebinarSlideCard item={item} isExpired={true} />
                </SwiperSlide>
              ))}
          </Slider>
        </div>

        <CardBanner
          isEssay
          title="Need help now?"
          description="Submit a Quick Request to get help on anything from college essays to subject tutoring."
          className="!bg-transparent !py-8 !px-0"
          cardClassName="bg-indigo-600 text-white"
        />
      </div>
    </div>
  );
};
