import React, { useEffect } from 'react';
import { useQuery } from 'react-query';
import { fetcher } from '@plugins/react-query';
import { Subscription, User } from '@src/types';
import { useAuth } from '@src/hooks';
import { useWindowSize } from '@react-hook/window-size';
import { SectionEssays } from '@src/pages/about/resources/SectionEssays';
import { SectionHero } from '@src/pages/about/resources/SectionHero';
import { CardBanner } from '@components/CardBanner';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { HiddenHeadingMeta } from '@src/components/HiddenHeadingMeta';
import { ReactHelmet } from '@src/components/ReactHelmet';

export const AboutResources = () => {
  const { authenticated } = useAuth();

  const { data: subscription } = useQuery<Subscription>(
    `/subscriptions/valid`,
    fetcher,
    { enabled: !!authenticated }
  );

  const { data: me } = useQuery<User>('/users/me', fetcher, {
    enabled: !!authenticated,
  });

  const [width] = useWindowSize();

  useEffect(() => {
    AOS.init({
      duration: 1800,
      offset: 100,
      anchorPlacement: 'top-bottom',
      once: true,
    });
    AOS.refresh();
  }, []);

  return (
    <>
      <HiddenHeadingMeta text="About Essays & Videos | Linkstory" />
      <ReactHelmet
        keywords="essays, videos"
        description="About free resources including accepted college essays and pre-recorded lessons."
        title="About Essays & Videos | Linkstory"
      />
      {/* Hero Section */}
      {/* <SectionHero
        authenticated={authenticated}
        subscription={subscription}
        me={me}
      /> */}

      {/* College logos marquee Section */}
      {/* <CollegeLogosMarquee
        isDarkTheme={true}
        title={'Lessons and essays by 100 students from 40+ universities'}
        withContainer={true}
      /> */}

      {/* Essays section */}
      <SectionEssays
        authenticated={authenticated}
        subscription={subscription}
        width={width}
        me={me}
      />

      {/* Banner section */}
      <CardBanner
        title="Need help now?"
        description="Submit a Quick Request to get help on anything from college essays to subject tutoring."
        isEssay
        cardClassName="bg-indigo-600 text-white"
      />
    </>
  );
};
