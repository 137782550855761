import { Pagination } from '@src/components/Pagination';
import { Table } from '@src/components/Table';
import { Link, useHistory, useLocation } from 'react-router-dom';
import CountUp from 'react-countup';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faRefresh } from '@fortawesome/free-solid-svg-icons';
import { BreadCrumbs } from '@src/components/BreadCrumbs';
import {
  ArrowPathIcon,
  CalendarIcon,
  ChatBubbleBottomCenterIcon,
  VideoCameraIcon,
} from '@heroicons/react/24/outline';
import { StarIcon } from '@heroicons/react/20/solid';
import { useQuery } from 'react-query';
import { LessonState, Paginated, Role, SessionType, User } from '@src/types';
import { fetcher } from '@src/plugins/react-query';
import {
  MomentFormat,
  utcToDiffMinutes,
  utcToLocalFormat,
} from '@src/plugins/moment';
import { useAuth, useQueryString } from '@src/hooks';
import { useForm } from 'react-hook-form';
import moment from 'moment';
import { TextField } from '@src/components/TextField';
import { Avatar } from '@src/components/Avatar';
import { getS3Uri } from '@src/utils';
import Modal from '@src/components/Modal';
import { useState } from 'react';
import { HiddenHeadingMeta } from '@src/components/HiddenHeadingMeta';
import { ReactHelmet } from '@src/components/ReactHelmet';

interface FormValues {
  from: Date;
  to: Date;
}

export const DashboardLessons = () => {
  const { push } = useHistory();

  const {
    register,
    reset: resetForm,
    watch,
  } = useForm<FormValues>({
    mode: 'onChange',
  });
  const history = useHistory();
  const location = useLocation();
  const { authenticated } = useAuth();
  const { data: me } = useQuery<User>('/users/me', fetcher, {
    enabled: !!authenticated,
  });

  const queryString = useQueryString({
    ...(watch('from') && {
      from: moment(watch('from')).startOf('day').toISOString(),
    }),
    ...(watch('to') && {
      to: moment(watch('to')).endOf('day').toISOString(),
    }),
  });

  const { data: sessionHistoryItems } = useQuery<Paginated<SessionType>>(
    `/lessons/my/histories${queryString}&status=${LessonState.COMPLETED},${LessonState.PENDING},${LessonState.CANCEL}`,
    fetcher
  );

  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [reviewItem, setReviewItem] = useState<any>();

  const filterSection = () => {
    return (
      <form>
        <div date-rangepicker="" className={`flex items-center space-x-4 `}>
          <div className="relative">
            <TextField
              type="date"
              data-placeholder="YYYY. MM. DD"
              className={`text-sm h-10 ${!!watch('from') ? 'has-value' : ''}`}
              {...(register('from'),
              {
                onChange: () => {
                  const params = new URLSearchParams({
                    page: '1',
                    limit: '10',
                  });
                  history.replace({
                    pathname: location.pathname,
                    search: params.toString(),
                  });
                },
              })}
            />
          </div>
          <span className="mx-4 text-gray-500">to</span>
          <div className="relative">
            <TextField
              type="date"
              data-placeholder="YYYY. MM. DD"
              min={moment().format('YYYY-MM-DD[T]HH:mm')}
              className={`text-sm h-10 ${!!watch('to') ? 'has-value' : ''}`}
              {...(register('to'),
              {
                onChange: () => {
                  const params = new URLSearchParams({
                    page: '1',
                    limit: '10',
                  });
                  history.replace({
                    pathname: location.pathname,
                    search: params.toString(),
                  });
                },
              })}
            />
          </div>
          <button
            type="button"
            onClick={() => resetForm()}
            className="group bg-brand-blue hover:brightness-105 rounded-lg py-2.5 px-3 text-white"
          >
            <ArrowPathIcon className="wh-4 transition duration-300 group-hover:rotate-90" />
          </button>
        </div>
      </form>
    );
  };

  const contentsSection = () => {
    return (
      <>
        <div className="bg-white border rounded-md overflow-hidden">
          <Table>
            <Table.Head>
              <Table.Row>
                <Table.Th>STATUS</Table.Th>
                <Table.Th>
                  {me?.role === Role.MENTOR ? 'STUDENT' : 'MENTOR'}
                </Table.Th>
                <Table.Th>Subject</Table.Th>
                <Table.Th>Price</Table.Th>
                <Table.Th>Start</Table.Th>
                <Table.Th>End</Table.Th>
                <Table.Th>Duration</Table.Th>
                <Table.Th>Rating</Table.Th>
                <Table.Th>Review</Table.Th>
              </Table.Row>
            </Table.Head>
            <Table.Body>
              {sessionHistoryItems?.items?.map((item) => (
                <Table.Row key={item.mentorName}>
                  <Table.Td>
                    <span
                      className={`inline-flex items-center rounded-full ${
                        item.status === LessonState.COMPLETED
                          ? 'bg-green-100 text-green-800'
                          : item.status === LessonState.CANCEL
                          ? 'bg-pink-100 text-pink-800'
                          : 'bg-yellow-100 text-yellow-800'
                      } px-2.5 py-0.5 text-xs font-medium `}
                    >
                      {item.status}
                    </span>
                  </Table.Td>
                  <Table.Td className="flex items-center">
                    {me?.role === Role.MENTOR ? (
                      <Avatar
                        className="wh-7"
                        src={
                          item.student?.avatar.startsWith('https')
                            ? item.student?.avatar
                            : getS3Uri(item.student?.avatar)
                        }
                      />
                    ) : (
                      <Avatar
                        className="wh-7"
                        src={
                          item.mentor?.avatar.startsWith('https')
                            ? item.mentor?.avatar
                            : getS3Uri(item.mentor?.avatar)
                        }
                      />
                    )}

                    <div className="ml-3">
                      <p className="text-sm font-medium text-gray-700 group-hover:text-gray-900">
                        {me?.role === Role.MENTOR
                          ? item.student.firstName
                          : item.mentor.firstName}
                      </p>
                    </div>
                  </Table.Td>
                  <Table.Td>
                    {item.subjectTitle === '' ? 'N/A' : item.subjectTitle}
                  </Table.Td>
                  <Table.Td>
                    {item.price ? '$' + Number(item.price).toFixed(2) : 'N/A'}
                  </Table.Td>
                  <Table.Td>
                    {item.startedAt
                      ? utcToLocalFormat(
                          item.startedAt,
                          MomentFormat.YYYYMMDDhmma
                        )
                      : 'N/A'}
                  </Table.Td>
                  <Table.Td>
                    {item.endedAt
                      ? utcToLocalFormat(
                          item.endedAt,
                          MomentFormat.YYYYMMDDhmma
                        )
                      : 'N/A'}
                  </Table.Td>

                  <Table.Td>
                    {item.startedAt && item.endedAt
                      ? utcToDiffMinutes(item.startedAt, item.endedAt) + 'mins'
                      : 'N/A'}
                  </Table.Td>

                  <Table.Td>
                    <div className="flex gap-1 items-center ">
                      <StarIcon className="wh-4 text-brand-orange" />
                      {item.lessonReviews[0]?.rating ?? 'N/A'}
                    </div>
                  </Table.Td>

                  <Table.Td>
                    <button
                      disabled={
                        item.lessonReviews[0]?.title ||
                        item.lessonReviews[0]?.description
                          ? false
                          : true
                      }
                      onClick={() => {
                        setReviewItem({
                          title: item.lessonReviews[0]?.title,
                          description: item.lessonReviews[0]?.description,
                        });
                        setModalOpen(true);
                      }}
                      type="button"
                      className="disabled:bg-gray-100 disabled:text-gray-400 inline-flex items-center rounded border border-transparent bg-indigo-100 px-2.5 py-1.5 text-xs font-medium text-indigo-700 hover:bg-indigo-200 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                    >
                      Read Review
                    </button>
                  </Table.Td>
                </Table.Row>
              ))}
            </Table.Body>
          </Table>
        </div>
      </>
    );
  };
  return (
    <>
      <HiddenHeadingMeta text="Session history | Linkstory" />
      <ReactHelmet
        keywords="session, history"
        title="Session history | Linkstory"
      />
      {/* {modalOpen && ( */}
      <Modal
        onClose={() => setModalOpen(false)}
        open={modalOpen}
        icon={<ChatBubbleBottomCenterIcon className="wh-8 text-gray-400" />}
        title={reviewItem?.title}
        subTitle={reviewItem?.description}
      />
      {/* )} */}

      <BreadCrumbs
        homePath="/my/dashboard"
        breadCrumbsPages={[
          {
            name: 'Session History',
            path: '/my/dashboard/sessions',
          },
        ]}
      />
      <div className="space-y-4 max-w-[1100px] w-full ">
        <section className="bg-white border whitespace-pre-line rounded-md overflow-hidden">
          <div className="border-b border-gray-200 bg-white px-4 py-5 sm:px-6 flex items-center justify-between gap-4">
            <div className="-ml-4 -mt-2 flex flex-wrap items-center justify-between sm:flex-nowrap">
              <div className="ml-4 mt-2 flex gap-2">
                <CalendarIcon
                  className={`wh-6 flex-shrink-0 self-center `}
                  aria-hidden="true"
                />
                <h3 className="text-lg font-medium leading-6 text-gray-900">
                  Session History
                </h3>
              </div>
            </div>
            {/* <div className="ml-4 flex-shrink-0">
              <Link
                to={'/my/dashboard/recordings'}
                className="inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 gap-1"
              >
                <VideoCameraIcon className="wh-4 flex-shrink-0 self-center text-xs" />
                <p>Session recordings</p>
              </Link>
            </div> */}
          </div>
          <div className="px-4 py-5 sm:px-6 space-y-6">
            {filterSection()}
            {contentsSection()}
          </div>
          {sessionHistoryItems && (
            <Pagination className="flex flex-wrap">
              <Pagination.Label
                count={sessionHistoryItems?.items.length}
                total={sessionHistoryItems?.total}
              />
              <Pagination.Nav
                basePath="/my/dashboard/sessions"
                total={sessionHistoryItems?.total}
              />
            </Pagination>
          )}
        </section>
      </div>
    </>
  );
};
