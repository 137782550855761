import { User } from '@src/types';
import React, { FC } from 'react';
import Slider from '@components/Slider';
import { SwiperSlide } from 'swiper/react';
import { MentorCard } from './MentorCard';
import { useMediaQuery } from 'usehooks-ts';
import { css } from 'styled-components';
import { String } from 'lodash';

export interface TopMentorsSliderProps {
  mentors: User[];
  className?: string;
  wrapperClassName?: string;
}

export const TopMentorsSlider: FC<TopMentorsSliderProps> = ({
  mentors,
  className,
  wrapperClassName,
}) => {
  const matches = useMediaQuery('(min-width: 768px)');
  return (
    <section
      className={`bg-[#F2F5FF] relative w-full overflow-hidden box-content bg-cover bg-no-repeat mt-12 md:mt-0 ${wrapperClassName}`}
    >
      <div
        className={` mx-auto px-0 mb-10 md:mb-16 flex flex-col ${className}`}
      >
        <h1 className="py-10 md:py-16 self-center text-brand-navy2 text-[1.2rem] md:text-[2rem] lg:text-48 font-bold text-center whitespace-pre-line">
          {`Need additional help? \nGet one-on-one mentoring from these top mentors.`}
        </h1>

        <Slider
          stylesOverride={css`
            .swiper-slide {
              height: auto;
            }
            .swiper-container {
              padding-bottom: 60px;
              padding-left: 0px;
              padding-right: 0px;
            }
          `}
          slidesPerView={'auto'}
          breakpoints={{
            960: {
              slidesPerView: 3.2,
              slidesPerGroup: 3,
              spaceBetween: 25,
            },
            640: {
              slidesPerView: 2.2,
              slidesPerGroup: 2,
              spaceBetween: 20,
            },
            320: {
              slidesPerView: 1.2,
              slidesPerGroup: 1,
              spaceBetween: 20,
            },
          }}
          initialSlide={matches ? 1 : 0}
        >
          {mentors.map((mentor: any, index: number) => (
            <SwiperSlide key={`homeMentors_Slide_${index}`}>
              <MentorCard key={mentor.id} mentor={mentor} />
            </SwiperSlide>
          ))}
        </Slider>
      </div>
    </section>
  );
};
