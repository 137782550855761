import React, { forwardRef, InputHTMLAttributes } from 'react';
import { useId } from 'react-id-generator';
import { Icon } from './icons';
import { Label } from './Label';

export interface CheckboxProps extends InputHTMLAttributes<HTMLInputElement> {
  label?: string;
  labelClassName?: string;
  wrapperClassName?: string;
  helper?: string;
  arrow?: boolean;
  arrowOnClick?: () => void;
}

/**
 * @example
 * <Checkbox
 *   label="Checkbox"
 *   onChange={(e) => console.log(e.target.checked)}
 * />
 */
export const Checkbox = forwardRef<HTMLInputElement, CheckboxProps>(
  (
    {
      className = '',
      label,
      labelClassName = '',
      wrapperClassName = '',
      arrow,
      arrowOnClick,
      helper,
      ...props
    },
    ref
  ) => {
    const [id] = useId(1, 'checkbox');

    return (
      <div className={`label-col ${wrapperClassName}`}>
        <div className="label-row justify-between ">
          <div className="label-row">
            <input
              ref={ref}
              id={id}
              type="checkbox"
              className={`checkbox ${className}`}
              {...props}
            />
            {label && (
              <Label
                htmlFor={id}
                text={label}
                className={`text-15 ${labelClassName}`}
              />
            )}
          </div>
          <button onClick={arrowOnClick} className="">
            {arrow && <Icon.ChevronDown />}
          </button>
        </div>
        {helper && <p className="text-sm text-error">{helper}</p>}
      </div>
    );
  }
);
