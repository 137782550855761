import React, {
  useEffect,
  useState,
  Fragment,
  CSSProperties,
  useCallback,
} from 'react';
import { useQuery } from 'react-query';
import { Link, useLocation, useHistory } from 'react-router-dom';
import { ReactComponent as Logo } from '@svg/logo.svg';
import { useAuth } from '@src/hooks';
import { fetcher } from '@plugins/react-query';
import { Chat, Role, User } from '@src/types';
import { Avatar } from '@components/Avatar';
import UnsupportedBrowser from '@components/UnsupportedBrowser';
import { Dropdown } from '@components/Dropdown';
import { HamburgerButton } from '@components/HamburgerButton';
import { ReactComponent as ChatIcon } from '@assets/icons/icon-chat-outlined.svg';
import { ReactComponent as VideoIcon } from '@assets/icons/icon-video-outlined.svg';
import { Icon } from './icons';
import { Menu, Transition, Disclosure } from '@headlessui/react';
import styled from 'styled-components';
import gsap from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';
import FadeLoader from 'react-spinners/BeatLoader';
import twConfig from '@src/tailwindResolver';
import { getS3Uri } from '@src/utils';
import { GNB_MENUS } from '@constants/gnb';
import { isMobile } from 'react-device-detect';
import { useOnClickOutside } from 'usehooks-ts';
import * as Sentry from '@sentry/react';
import { useTranslation } from 'react-i18next';
import getUnicodeFlagIcon from 'country-flag-icons/unicode';
import { useWindowWidth } from '@react-hook/window-size';
import {
  ChevronDownIcon,
  HomeIcon,
  MegaphoneIcon,
  XMarkIcon,
} from '@heroicons/react/24/outline';
import path from 'path';
import Marquee from 'react-fast-marquee';

const override: CSSProperties = {
  display: 'flex',
  justifyContent: 'center',
  height: '4rem',
  alignItems: 'center',
};

const transParentBgList: string[] = [
  // '/mentors',
  // '/mentors/:mentorId',
  // '/advisors',
  // '/giveaway-event',
  // '/about-linkstoryplus',
  '/free-essays',
  // '/howitworks/student',
];

export const TopNavBar = () => {
  const { t, i18n } = useTranslation(['gnb', 'general']);
  const width = useWindowWidth();
  const [gnbItems, setGnbItems] = useState<any>(
    GNB_MENUS.filter((item) => item.mainmenu.showBeforeLogin)
  );
  const [isFeatureBanner, setIsFeatureBanner] = useState<boolean>(true);
  const toggleLocales = useCallback(
    (locale: string) => {
      i18n.changeLanguage(locale);
    },
    [i18n]
  );
  const { logout } = useAuth();
  const authenticated = !!localStorage.getItem('token');
  const { data: me, isLoading: meIsLoading } = useQuery<User>(
    '/users/me',
    fetcher,
    {
      enabled: !!authenticated,
      refetchOnWindowFocus: false,
      onSuccess: (data: any) => {
        Sentry.setUser({
          id: data.id.toString(),
          username: data.firstName + ' ' + data.lastName,
          email: data.email,
          role: data.role,
        });

        const FilteredGnb = GNB_MENUS.filter((item) =>
          item.mainmenu.showAfterLoginAuth?.includes(data.role)
        );
        setGnbItems(FilteredGnb);
      },
    }
  );

  const { data: inboxCount } = useQuery<any>('/chats/v2/chat-count', fetcher, {
    refetchInterval: 10000,
    enabled: !!authenticated,
  });

  // const { data } = useQuery('/chats', fetcher, {
  //   refetchInterval: 10000,
  //   enabled: !!authenticated,
  // });
  // const chats = data?.chats;

  const { pathname } = useLocation();
  // const match = useRouteMatch();
  const { push } = useHistory();
  const [dropdown, setDropdown] = useState(false);
  const [browserSupported, setBrowserSupported] = useState<boolean>(true);
  const [browserName, setBrowserName] = useState('');
  const [isGNBActivated, setIsGNBActivated] = useState(false);
  const refBurgerMenu = React.useRef<HTMLDivElement>(null);
  const buttonRef = React.useRef(null);
  const [isDropShowing, setIsDropShowing] = useState(false);

  function classNames(...classes: any) {
    return classes.filter(Boolean).join(' ');
  }

  useEffect(() => {
    const { detect } = require('detect-browser');
    const browser = detect();

    if (!isMobile && browser) {
      switch (browser && browser.name) {
        case 'chrome':
        case 'safari':
          setBrowserSupported(true);
          break;

        default:
          setBrowserSupported(false);
      }
      setBrowserName(browser.name);
    }

    gsap.registerPlugin(ScrollTrigger);
    ScrollTrigger.create({
      // markers: true,
      start: 'top -65px',
      trigger: 'body',
      endTrigger: 'body',
      end: 'bottom top',
      toggleClass: { targets: '.nav', className: 'nav-active' },
      onToggle: (scrollTrigger) => {
        // refresh because height start changes
        scrollTrigger.refresh();
      },
      onEnter: () => {
        setIsGNBActivated(true);
      },
      onLeaveBack: () => {
        setIsGNBActivated(false);
      },
    });
  }, []);

  useOnClickOutside(buttonRef, (event) => {
    const isDropdownClick: boolean | null =
      refBurgerMenu.current &&
      refBurgerMenu.current.contains(event.target as Node);

    if (!isDropdownClick) {
      setDropdown(false);
    }
  });

  const hexToRgb = (hex: string): [number, number, number] => {
    const aRgbHex = hex.match(/.{1,2}/g);
    const aRgb: [number, number, number] = aRgbHex
      ? [
          parseInt(aRgbHex[0], 16),
          parseInt(aRgbHex[1], 16),
          parseInt(aRgbHex[2], 16),
        ]
      : [0, 0, 0];
    return aRgb;
  };

  const newFeatureBannerSection = () => {
    return (
      <div className="bg-indigo-600">
        <div className="max-w-container ">
          <div className="flex flex-wrap items-center justify-between">
            <div className="flex w-0 flex-1 items-center">
              <span className="hidden md:flex rounded-lg bg-indigo-800 p-2">
                <MegaphoneIcon
                  className="h-6 w-6 text-white"
                  aria-hidden="true"
                />
              </span>
              <p className="md:ml-3 truncate font-medium text-white">
                <div className="w-full md:hidden">
                  <Marquee
                    loop={0}
                    // pauseOnHover={true}
                    // gradient={false}
                    gradientWidth={width <= 680 ? 20 : 100}
                    gradientColor={[79, 70, 229]}
                  >
                    <span className="mr-3">
                      {t('gnb:newFeatureBanner.mobile')}
                    </span>
                  </Marquee>
                </div>
                <span className="hidden md:inline">
                  {t('gnb:newFeatureBanner.desktop')}
                </span>
              </p>
            </div>
            <div className="order-3 mt-2 w-full flex-shrink-0 sm:order-2 sm:mt-0 sm:w-auto">
              <Link
                to="/howitworks/student"
                className="flex items-center justify-center rounded-md border border-transparent bg-white px-4 py-2 text-sm font-medium text-indigo-600 shadow-sm hover:bg-indigo-50"
              >
                Learn more
              </Link>
            </div>
            <div className="order-2 flex-shrink-0 sm:order-3 sm:ml-3">
              <button
                type="button"
                onClick={() => setIsFeatureBanner(false)}
                className="-mr-1 flex rounded-md p-2 hover:bg-indigo-500 focus:outline-none focus:ring-2 focus:ring-white sm:-mr-2"
              >
                <span className="sr-only">Dismiss</span>
                <XMarkIcon className="h-6 w-6 text-white" aria-hidden="true" />
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <>
      <StyledContainer
        matchPath={pathname}
        brandBlue={twConfig.theme.colors['brand-1']}
        isGNBActivated={isGNBActivated}
      >
        {isFeatureBanner &&
          !pathname.startsWith('/my/chats') &&
          newFeatureBannerSection()}
        <div
          className={`transition duration-300 ease-in-out ${
            isGNBActivated ? 'hidden' : 'block'
          }`}
        >
          <UnsupportedBrowser
            browserSupported={browserSupported}
            browserName={browserName}
          />
        </div>
        <div
          className={`nav transition duration-300 ease-in-out z-50 w-full h-14 md:h-[4.2rem] items-center flex relative`}
          style={{ borderBottom: '0.7px solid rgba(0,0,0,0.3)' }}
        >
          <div className="max-w-container mx-auto px-4 w-full flex h-full items-center justify-between ">
            <div className="flex space-x-14 text-inherit items-center md:w-4/5">
              <div>
                <Link to="/" className="relative flex items-center">
                  <Logo
                    className={`gnb-logo 
                  transition duration-300 ease-in-out
                fill-current
                md:w-32 w-24`}
                    width="100%"
                  />
                  {/* <div
                    className="text-2 bg-white border-brand-blue text-brand-blue border rounded-full px-2"
                    style={{ transform: 'scale(.60)', width: 'fit-content' }}
                  >
                    Beta
                  </div> */}
                </Link>
              </div>
              <div className="hidden lg:flex w-full space-x-3 lg:space-x-10 items-center whitespace-nowrap">
                {authenticated && (
                  <Link
                    className={`flex text-15 text-inherit font-semibold py-2 gap-2`}
                    to={'/my/dashboard'}
                  >
                    <HomeIcon className=" wh-5" />
                    <span>{t('dashboard')}</span>
                  </Link>
                )}
                {/* Desktop */}
                {!meIsLoading &&
                  gnbItems &&
                  gnbItems
                    .filter((item: any) => !item.mainmenu.mobileOnly)
                    .map((menu: any) => {
                      if (!authenticated && !menu.mainmenu.showBeforeLogin) {
                        return null; // skip
                      } else {
                        return (
                          <div
                            className="group relative inline-block text-left"
                            onMouseEnter={() => setIsDropShowing(true)}
                            onMouseLeave={() => setIsDropShowing(false)}
                          >
                            <div
                              // as="div"
                              className="relative inline-block text-left "
                            >
                              <>
                                {menu.mainmenu.to ? (
                                  <Link
                                    className={`flex text-15 text-inherit font-semibold py-2`}
                                    to={menu.mainmenu.to}
                                  >
                                    {t(menu.mainmenu.i18nkey)}
                                  </Link>
                                ) : (
                                  <button
                                    className={`flex text-15 text-inherit font-semibold py-2 items-center gap-1`}
                                  >
                                    <span>{t(menu.mainmenu.i18nkey)}</span>
                                    <ChevronDownIcon className="wh-4" />
                                  </button>
                                )}

                                <Transition
                                  as={Fragment}
                                  enter="transition ease-out duration-100"
                                  enterFrom="transform opacity-0 scale-95"
                                  enterTo="transform opacity-100 scale-100"
                                  leave="transition ease-in duration-75"
                                  leaveFrom="transform opacity-100 scale-100"
                                  leaveTo="transform opacity-0 scale-95"
                                  show={isDropShowing}
                                >
                                  <div className="hidden group-hover:block origin-top-left absolute w-fit rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                                    {menu.submenu &&
                                      menu.submenu.map((submenu: any) => {
                                        return (
                                          <div className="flex items-center gap-2 hover:rounded-md hover:bg-gray-100 hover:text-gray-900 text-gray-700 px-4 py-2 text-sm">
                                            {submenu.to.startsWith(
                                              'https://'
                                            ) ? (
                                              <a
                                                href={submenu.to}
                                                target="_blank"
                                                rel="noopener noreferrer"
                                                className={classNames(
                                                  `relative `
                                                )}
                                              >
                                                {t(submenu.i18nkey)}
                                              </a>
                                            ) : (
                                              <Link
                                                to={submenu.to}
                                                className={classNames(
                                                  `relative `
                                                )}
                                              >
                                                {t(submenu.i18nkey)}
                                              </Link>
                                            )}
                                            {submenu.isNew && (
                                              <div
                                                className="hidden rounded-full bg-indigo-500 px-1.5 py-0.5 text-xs text-white sm:block"
                                                style={{
                                                  transform: 'scale(.7)',
                                                }}
                                              >
                                                New
                                              </div>
                                            )}
                                          </div>
                                        );
                                      })}
                                  </div>
                                </Transition>
                              </>
                            </div>
                          </div>
                        );
                      }
                    })}
              </div>
            </div>

            <div className="flex space-x-4 lg:space-x-4 items-center md:ml-auto flex-shrink-0 whitespace-nowrap">
              {authenticated ? (
                <>
                  <Link
                    to={
                      me && me.role === Role.STUDENT ? '/my/chats' : '/my/chats'
                    }
                    className="relative inline-block w-fit"
                  >
                    <ChatIcon
                      className={`gnb-icon transition duration-300 ease-in-out w-fit h-fit 
                    stroke-current`}
                    />
                    {inboxCount > 0 && (
                      <span className="absolute top-1 right-1 inline-block w-2 h-2 transform translate-x-1/2 -translate-y-1/2 bg-red-600 rounded-full"></span>
                    )}
                  </Link>
                  {/* <Link
                  to={
                    me && me.role === Role.STUDENT
                      ? '/my/mentoring'
                      : '/mentor/my/mentoring'
                  }
                  className="relative inline-block w-fit"
                >
                  <VideoIcon
                    className={`gnb-icon transition duration-300 ease-in-out w-fit h-fit
                    stroke-current`}
                  />
                </Link> */}
                </>
              ) : (
                <>
                  <Link
                    to={{
                      pathname: '/login',
                      state: { from: { pathname: pathname } },
                    }}
                    className="hidden md:inline-flex  items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                  >
                    {t('general:logIn')}
                  </Link>
                </>
              )}

              {authenticated ? (
                <div className="hidden lg:block">
                  <Dropdown>
                    <button className="flex items-center space-x-2">
                      <Avatar
                        src={
                          me?.avatar.startsWith('https')
                            ? me?.avatar
                            : getS3Uri(me?.avatar)
                        }
                        className="!wh-8"
                      />
                      <Icon.ChevronDown />
                    </button>
                    <Dropdown.View className="right-0 rounded-md w-30 flex flex-col shadow-md overflow-hidden text-sm">
                      {!meIsLoading ? (
                        <>
                          {me && me.role === Role.ADMIN && (
                            <button
                              onClick={() => push('/admin')}
                              className="hover:bg-gray-100 text-black hover:text-black px-6 py-3 text-left"
                            >
                              Admin
                            </button>
                          )}
                          <Link
                            to="/my/dashboard/account"
                            className="hover:bg-gray-100 text-black px-6 py-3 text-left"
                          >
                            {t('general:account')}
                          </Link>
                          <button
                            className="hover:bg-gray-100 text-black px-6 py-3 text-left"
                            onClick={() => me && logout(me.id)}
                          >
                            {t('general:logOut')}
                          </button>
                        </>
                      ) : (
                        <FadeLoader
                          cssOverride={override}
                          color={twConfig.theme.colors['brand-1']}
                          loading={true}
                          size={10}
                        />
                      )}
                    </Dropdown.View>
                  </Dropdown>
                </div>
              ) : (
                <Link
                  to="/signup"
                  className="hidden md:inline-flex items-center rounded-md border border-transparent bg-indigo-100 px-4 py-2 text-sm font-medium text-indigo-700 hover:bg-indigo-200 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                >
                  {t('general:signUp')}
                </Link>
              )}
              {/* 언어 선택 Select */}
              {/* <select
                className="hidden lg:block w-full rounded-md border-gray-300 py-2 pl-3 pr-8 text-xs focus:border-brand-blue focus:outline-none focus:ring-brand-blue max-w-[90px] text-black"
                style={{ height: '38px' }}
                value={i18n.language}
                onChange={(e) => {
                  toggleLocales(e.target.value);
                }}
              >
                <option label={`${getUnicodeFlagIcon('US')} EN`} value="en" />
                <option label={`${getUnicodeFlagIcon('KR')} KR`} value="ko" />
              </select> */}
              {/* <-- Mobile Hamburger Menu ---> */}
              <div ref={buttonRef} className="ignore-onOutsideClick lg:hidden">
                <HamburgerButton
                  className="ignore-onOutsideClick lg:hidden items-center flex"
                  dropdown={dropdown}
                  onClick={() => setDropdown(!dropdown)}
                  me={me}
                  // chats={chats}
                />
              </div>
            </div>
          </div>
        </div>
        {dropdown && (
          <div
            ref={refBurgerMenu}
            className={`z-49 lg:hidden absolute w-full space-y-4 px-4 pt-4 pb-6 bg-brand-navy3 text-white flex flex-col transition transform 
                    ${
                      dropdown
                        ? 'ease-in-out duration-500 opacity-100 translate-y-0 '
                        : 'ease-in duration-250 opacity-0 pointer-events-none -translate-y-5 '
                    }`}
          >
            <>
              {/* 언어 선택 Select */}
              {/* <div className="flex gap-4 items-center">
                <button
                  className={`flex items-center gap-2 ${
                    i18n.language === 'en' ? 'font-bold' : 'opacity-50'
                  }`}
                  onClick={() => {
                    toggleLocales('en');
                  }}
                >
                  <span>{`${getUnicodeFlagIcon('US')}`}</span>
                  <span className={`text-xs`}>US</span>
                </button>
                <button
                  className={`flex items-center gap-2 ${
                    i18n.language === 'ko' ? 'font-bold' : 'opacity-50'
                  }`}
                  onClick={() => {
                    toggleLocales('ko');
                  }}
                >
                  <span>{`${getUnicodeFlagIcon('KR')}`}</span>
                  <span className={`text-xs`}>KR</span>
                </button>
              </div> */}
              <Link
                className="flex w-full space-x-2 items-center rounded-lg text-left font-normal text-white focus:outline-none focus-visible:ring focus-visible:ring-white focus-visible:ring-opacity-30"
                to={'/my/dashboard'}
                onClick={() => setDropdown(!dropdown)}
              >
                <span
                  className={`font-normal ${
                    pathname.startsWith('/my/dashboard') ||
                    pathname.startsWith('/my/dashboard')
                      ? 'text-brand-1'
                      : ''
                  }`}
                >
                  {t('dashboard')}
                </span>
              </Link>
              {!meIsLoading &&
                gnbItems &&
                gnbItems.map((menu: any) => {
                  if (!authenticated && !menu.mainmenu.showBeforeLogin) {
                    return null; // skip
                  } else {
                    return (
                      <div className="relative inline-block text-left">
                        <Menu
                          as="div"
                          className="relative inline-block text-left "
                        >
                          <div>
                            <>
                              <Disclosure>
                                {({ open }) => (
                                  <>
                                    {menu.mainmenu.to ? (
                                      <Link
                                        className="flex w-full space-x-2 items-center rounded-lg text-left font-normal text-white focus:outline-none focus-visible:ring focus-visible:ring-white focus-visible:ring-opacity-30"
                                        to={menu.mainmenu.to}
                                        onClick={() => setDropdown(!dropdown)}
                                      >
                                        <span
                                          className={`font-normal ${
                                            menu.submenu &&
                                            (pathname.startsWith(
                                              menu.submenu[0].to
                                            ) ||
                                              pathname.startsWith(
                                                menu.submenu[1].to
                                              ))
                                              ? 'text-brand-1'
                                              : ''
                                          }`}
                                        >
                                          {t(menu.mainmenu.i18nkey)}
                                        </span>
                                      </Link>
                                    ) : (
                                      <Disclosure.Button className="flex w-full space-x-2 items-center rounded-lg text-left font-normal text-white focus:outline-none focus-visible:ring focus-visible:ring-white focus-visible:ring-opacity-30">
                                        <span
                                          className={`font-normal ${
                                            menu.submenu &&
                                            (pathname.startsWith(
                                              menu.submenu[0].to
                                            ) ||
                                              pathname.startsWith(
                                                menu.submenu[1].to
                                              ))
                                              ? 'text-brand-1'
                                              : ''
                                          }`}
                                        >
                                          {t(menu.mainmenu.i18nkey)}
                                        </span>
                                        <Icon.ChevronDown
                                          className={`${
                                            open ? 'rotate-180 transform' : ''
                                          } h-5 w-5 text-white`}
                                        />
                                      </Disclosure.Button>
                                    )}
                                    <Disclosure.Panel className="px-4 pt-4 pb-2 text-sm text-gray-300 flex flex-col space-y-4">
                                      {menu.submenu &&
                                        menu.submenu.map((submenu: any) => {
                                          return (
                                            <div className="flex gap-2 items-center">
                                              <Link
                                                to={submenu.to}
                                                onClick={() =>
                                                  setDropdown(!dropdown)
                                                }
                                                className={`font-normal ${
                                                  pathname.startsWith(
                                                    submenu.to
                                                  )
                                                    ? 'text-brand-1'
                                                    : ''
                                                }`}
                                              >
                                                {t(submenu.i18nkey)}
                                              </Link>
                                              {submenu.isNew && (
                                                <div
                                                  className="rounded-full bg-indigo-500 px-1.5 py-0.5 text-xs text-white sm:block"
                                                  style={{
                                                    transform: 'scale(.7)',
                                                  }}
                                                >
                                                  New
                                                </div>
                                              )}
                                            </div>
                                          );
                                        })}
                                    </Disclosure.Panel>
                                  </>
                                )}
                              </Disclosure>
                            </>
                          </div>
                        </Menu>
                      </div>
                    );
                  }
                })}
            </>

            {!authenticated && (
              <Link
                to="/signup"
                onClick={() => setDropdown(!dropdown)}
                className="font-medium text-gray-300"
              >
                {`${t('general:logIn')} / ${t('general:signUp')}`}
              </Link>
            )}

            {authenticated && (
              <>
                <Link
                  to="/my/dashboard/account"
                  className=""
                  onClick={() => setDropdown(!dropdown)}
                >
                  {t('general:account')}
                </Link>

                <button
                  className="text-left"
                  onClick={() => {
                    me && logout(me.id);
                    setDropdown(!dropdown);
                  }}
                >
                  {t('general:logOut')}
                </button>
              </>
            )}
          </div>
        )}
      </StyledContainer>
    </>
  );
};

const StyledContainer = styled.div<{
  matchPath: any;
  brandBlue: any;
  isGNBActivated: boolean;
}>`
  position: ${(props) =>
    !transParentBgList.includes(props.matchPath) ? 'sticky' : 'fixed'};
  width: 100%;
  top: 0;
  z-index: 100;

  .nav {
    background-color: ${(props) =>
      transParentBgList.includes(props.matchPath)
        ? 'transparent'
        : 'rgba(255, 255, 255, 0.9)'};

    color: ${(props) =>
      transParentBgList.includes(props.matchPath) ? 'white' : 'black'};
  }

  .gnb-logo {
    color: ${(props) =>
      props.isGNBActivated && transParentBgList.includes(props.matchPath)
        ? props.brandBlue
        : !transParentBgList.includes(props.matchPath)
        ? props.brandBlue
        : 'white'};
  }
  .gnb-icon {
    color: ${(props) =>
      props.isGNBActivated && transParentBgList.includes(props.matchPath)
        ? 'black'
        : !transParentBgList.includes(props.matchPath)
        ? 'black'
        : 'white'};
  }

  @media (max-width: 1024px) {
    position: 'sticky';
    width: 100%;
    top: 0;
    z-index: 100;

    .nav {
      background-color: rgba(255, 255, 255, 0.9);
    }

    .gnb-logo {
      color: ${(props) => props.brandBlue};
    }
    .gnb-icon {
      color: black;
    }
  }

  @media (min-width: 1025px) {
    .nav {
      background-color: ${(props) =>
        transParentBgList.includes(props.matchPath)
          ? 'transparent'
          : 'rgba(255, 255, 255, 0.9)'};

      color: ${(props) =>
        transParentBgList.includes(props.matchPath) ? 'white' : 'black'};
    }
  }

  .nav-active {
    background-color: rgba(255, 255, 255, 0.9);
    color: black;
  }
`;
