import { HomeIcon } from '@heroicons/react/24/outline';
import React, { FC } from 'react';
import { Link } from 'react-router-dom';

export interface BreadCrumbsProps {
  homePath: string;
  breadCrumbsPages?: breadCrumbsPagesType[];
  className?: string;
}

export interface breadCrumbsPagesType {
  name: string;
  path: string;
  hide?: boolean;
}

export const BreadCrumbs: FC<BreadCrumbsProps> = ({
  homePath,
  breadCrumbsPages,
  className,
}) => {
  return (
    <nav aria-label="Breadcrumb" className={`flex ${className}`}>
      <ol className="flex items-center space-x-4">
        <li>
          <div>
            <Link to={homePath} className="text-gray-400 hover:text-gray-500">
              <HomeIcon className="h-5 w-5 flex-shrink-0" aria-hidden="true" />
              <span className="sr-only">Home</span>
            </Link>
          </div>
        </li>
        {breadCrumbsPages
          ?.filter((item) => !item.hide)
          .map((page) => (
            <li key={page.name}>
              <div className="flex items-center">
                <svg
                  className="h-5 w-5 flex-shrink-0 text-gray-300"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  aria-hidden="true"
                >
                  <path d="M5.555 17.776l8-16 .894.448-8 16-.894-.448z" />
                </svg>
                <Link
                  to={page.path}
                  className="ml-4 text-sm font-medium text-gray-500 hover:text-gray-700"
                  // aria-current={page.current ? 'page' : undefined}
                >
                  {page.name}
                </Link>
              </div>
            </li>
          ))}
      </ol>
    </nav>
  );
};
