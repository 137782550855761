import { useForm } from 'react-hook-form';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { Button } from '../../components/Button';
import { TextField } from '../../components/TextField';
import { useAuth } from '../../hooks';
import { Icon } from '../../components/icons';
import { GOOGLE_SOCIAL_URL } from '../../types';
import { FaceBookLoginButton } from '../../components/FaceBookLoginButton';
import { useEffect, useState } from 'react';

interface FormValues {
  email: string;
  password: string;
}

// Declare GTM dataLayer array.
declare global {
  interface Window {
    dataLayer: any[];
  }
}

interface LocationState {
  from: {
    pathname: string;
  };
}

export const SigninPage = () => {
  const { login } = useAuth();
  const location = useLocation<LocationState>();
  // 로그인 전 페이지 기억
  const [locationState] = useState<LocationState>(
    location.state || { from: { pathname: '/' } }
  );

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<FormValues>();

  return (
    <>
      <div
        className="w-full bg-center bg-cover bg-no-repeat "
        // style={{ backgroundImage: `url('${HeroImg}')` }}
      >
        <div className="max-w-screen-xl mx-auto h-screen flex items-center justify-center px-5">
          <div className="max-w-lg bg-white rounded-md py-8 px-2 md:px-10 w-full">
            <h1 className="text-30 font-bold text-center pb-6 text-brand-navy3">
              Log in
            </h1>
            <div className="p-4">
              <Button
                className="w-full group h-12 px-6 border border-gray-300 rounded-full transition duration-300 hover:border-brand-blue focus:bg-blue-50 active:bg-blue-100 mb-4"
                onClick={() => window.location.replace(GOOGLE_SOCIAL_URL)}
              >
                <div className="relative flex items-center space-x-4 justify-center">
                  <Icon.GoogleLogo className="w-5" />
                  <span className="block w-max font-semibold tracking-wide text-gray-700 text-sm transition duration-300 group-hover:text-brand-blue sm:text-base">
                    Continue with Google
                  </span>
                </div>
              </Button>
              <FaceBookLoginButton from={locationState.from.pathname} />
              <div className="relative flex py-5 items-center">
                <div className="flex-grow border-t border-gray-400 border-opacity-60"></div>
                <span className="flex-shrink mx-4 text-xs text-gray-400">
                  OR
                </span>
                <div className="flex-grow border-t border-gray-400 border-opacity-60"></div>
              </div>
              <form
                className="pt-6 flex flex-col space-y-4 "
                onSubmit={handleSubmit((data) => {
                  try {
                    login({
                      ...data,
                      from: locationState.from.pathname,
                    });
                  } catch (e) {
                    // handle your error state here
                    alert('The password or email is incorrect.');
                  }
                })}
              >
                <TextField
                  label="Email"
                  autoFocus={true}
                  type="email"
                  placeholder="you@example.com"
                  helper={errors.email?.message}
                  {...register('email', {
                    required: 'Please enter your email address.',
                  })}
                />

                <TextField
                  isPassword={true}
                  label="Password"
                  onKeyDownHandle={false}
                  placeholder="Please enter your password."
                  helper={errors.password?.message}
                  {...register('password', {
                    required: 'Please enter your password.',
                  })}
                />
                <Link
                  to="/passwords/find"
                  className="text-right text-12 text-brand-1"
                >
                  Forgot your password?
                </Link>
                <div className="flex flex-col space-y-4 pt-8">
                  <Button
                    text="Log in"
                    className="filled-brand-1 rounded-full "
                    type="submit"
                  />
                  <Link
                    to="/signup"
                    className="outlined-brand-1 text-center rounded-full py-3"
                  >
                    Sign up
                  </Link>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
