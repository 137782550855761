import React, { useEffect, useState } from 'react';
import CountUp from 'react-countup';
import ReactTooltip from 'react-tooltip';
import {
  ClockIcon,
  ChatBubbleOvalLeftEllipsisIcon,
  EnvelopeIcon,
  ChevronRightIcon,
  CalendarIcon,
  LightBulbIcon,
  InboxIcon,
  ListBulletIcon,
  BriefcaseIcon,
  CurrencyDollarIcon,
  MagnifyingGlassIcon,
  PresentationChartLineIcon,
  ReceiptPercentIcon,
  BanknotesIcon,
  Square3Stack3DIcon,
  PaperAirplaneIcon,
  NoSymbolIcon,
  AcademicCapIcon,
} from '@heroicons/react/24/outline';
import { StarIcon } from '@heroicons/react/20/solid';
import { Link, useHistory } from 'react-router-dom';
import { ToggleSwitch } from '@src/components/ToggleSwitch';
import { api } from '@src/plugins/axios';
import { useAuth } from '@src/hooks';
import { useQuery } from 'react-query';
import { fetcher } from '@src/plugins/react-query';
import {
  User,
  PaymentDetails,
  Paginated,
  SessionType,
  LessonState,
  ChatState,
} from '@src/types';
import {
  MomentFormat,
  utcToDiffMinutes,
  utcToLocalFormat,
} from '@src/plugins/moment';
import moment from 'moment';
import { EmptyState } from '@src/components/EmptyState';
import { Avatar } from '@src/components/Avatar';
import { getS3Uri } from '@src/utils';
import { HiddenHeadingMeta } from '@src/components/HiddenHeadingMeta';
import { ReactHelmet } from '@src/components/ReactHelmet';
import mixpanel from 'mixpanel-browser';

export const MentorDashboardDefault = () => {
  const { authenticated } = useAuth();
  const { push } = useHistory();
  const { data: me, isFetching: meIsFetching } = useQuery<User>(
    '/users/v2/me',
    fetcher,
    {
      enabled: !!authenticated,
    }
  );

  useEffect(() => {
    mixpanel.track('Page Viewed', {
      item_category: 'Dashboard - Mentor',
    });
  }, []);

  const { data: inboxCount } = useQuery<any>('/chats/v2/chat-count', fetcher, {
    enabled: !!authenticated,
  });

  const calcMinutesTohour = (): number => {
    const result: number =
      Number(me?.avgResponseTime) > 60
        ? Number((Number(me?.avgResponseTime) / 60).toFixed(0))
        : Number(me?.avgResponseTime);
    return result;
  };

  const mentorStats = [
    {
      id: 1,
      name: 'Avg. Response Rate',
      stat: me?.avgResponseRate ?? 0,
      icon: ChatBubbleOvalLeftEllipsisIcon,
      suffix: '%',
    },
    {
      id: 2,
      name: 'Avg. Response Time',
      stat: calcMinutesTohour(),
      icon: ClockIcon,
      suffix:
        Number(me?.avgResponseTime) > 60
          ? `hour${calcMinutesTohour() > 1 ? 's' : ''}`
          : `min${calcMinutesTohour() > 1 ? 's' : ''}`,
    },
  ];

  const { data: PaymentDetails } = useQuery<PaymentDetails>(
    `/v2/receipts/mentor-dashboard/detail`,
    fetcher,
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
    }
  );

  const paymentStats = [
    {
      id: 1,
      name: 'Total earnings',
      stat: Number(PaymentDetails?.totalEarnings),
      icon: CurrencyDollarIcon,
    },
    {
      id: 2,
      name: 'Amount paid',
      stat: PaymentDetails?.amountPaid ?? 0,
      icon: BanknotesIcon,
    },
    {
      id: 3,
      name: 'Amount owed',
      stat: PaymentDetails?.amountOwed ?? 0,
      icon: Square3Stack3DIcon,
    },
    {
      id: 4,
      name: 'Pay percent',
      stat: PaymentDetails?.payPercent ?? 0,
      icon: ReceiptPercentIcon,
    },
  ];

  const [mySubjectIds, setMySubjectIds] = useState<number[]>([]);

  const { data: mentorSubjects } = useQuery<any[]>(
    `/v2/subject-link?mentorId=${Number(me?.id)}`,
    fetcher,
    {
      enabled: !!me?.id,
      onSuccess(data) {
        const result = [];
        for (let i = 0; i < data.length; i++) {
          if (data[i].subject.isHidden === false) {
            result.push(data[i].subjectId);
          }
        }
        setMySubjectIds(result);
      },
    }
  );

  const { data: myJobsCount } = useQuery<number>(
    `/v2/jobs/counts?subjectIds=${mySubjectIds.join(',')}`,
    fetcher,
    {
      enabled: !!mySubjectIds.length,
    }
  );

  const { data: upcomingSessionItems, isFetching: upcomingFetching } = useQuery<
    Paginated<SessionType>
  >(`/lessons/my/histories?status=${LessonState.UPCOMING}`, fetcher);

  const { data: recentSessionItems, isFetching: recentFetching } = useQuery<
    Paginated<SessionType>
  >(
    `/lessons/my/histories?status=${LessonState.INCOMPLETE},${LessonState.CHECKING},${LessonState.PENDING},${LessonState.COMPLETED}`,
    fetcher
  );

  const InboxSection = () => {
    return (
      <div className=" bg-white border w-full rounded-md overflow-hidden">
        <div className="border-b border-gray-200 bg-white px-4 py-5 sm:px-6">
          <div className="-ml-4 -mt-2 flex flex-wrap items-center justify-between sm:flex-nowrap">
            <div className="ml-4 mt-2 flex gap-2">
              <EnvelopeIcon
                className={`wh-6 flex-shrink-0 self-center `}
                aria-hidden="true"
              />
              <h3 className="text-lg font-medium leading-6 text-gray-900">
                Messages
              </h3>
              <button className="border inline-flex items-center rounded-full bg-gray-100 px-2.5 py-0.5 text-xs font-medium text-gray-800 gap-1 whitespace-pre-line">
                <p
                  data-for="tip-tooltip"
                  data-tip={
                    'Students expect a prompt response to their inquiries. \nWe recommend you respond in 1 hour or less.'
                  }
                  className="flex items-center gap-1"
                >
                  <LightBulbIcon
                    className={`wh-3 flex-shrink-0 self-center text-xs `}
                    aria-hidden="true"
                  />
                  <p>Tip</p>
                </p>
                <ReactTooltip
                  id="tip-tooltip"
                  className="max-w-[80vw]"
                  delayHide={1000}
                  effect="solid"
                  place="bottom"
                />
              </button>
            </div>
            <div className="ml-4 mt-2 flex-shrink-0 space-x-4 flex items-center">
              <Link
                to={'/my/chats'}
                className="relative inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 gap-1"
              >
                <InboxIcon className="wh-4 flex-shrink-0 self-center text-xs" />
                <p>Inbox</p>
                {inboxCount !== 0 && (
                  <span className="absolute right-[-10px] top-[-10px] inline-flex items-center rounded-full bg-red-100 px-1.5 py-0.5 text-xs font-medium text-red-800 gap-1">
                    <p>{inboxCount > 9 ? '9+' : inboxCount}</p>
                  </span>
                )}
              </Link>
            </div>
          </div>
        </div>
        <div className="p-6 space-y-4">
          <dl className=" grid grid-cols-1 gap-5 sm:grid-cols-2">
            {mentorStats.map((item, index) => (
              <div
                key={item.id}
                className="relative overflow-hidden rounded-lg bg-white px-4 py-5 shadow sm:px-6 sm:py-6"
              >
                <dt>
                  <div className="absolute rounded-md bg-indigo-500 p-3">
                    <item.icon
                      className="wh-5 md:wh-6 text-white"
                      aria-hidden="true"
                    />
                  </div>
                </dt>
                <div className="ml-14 md:ml-16 ">
                  <dt>
                    <p className="truncate text-sm font-medium text-gray-500">
                      {item.name}
                    </p>
                  </dt>
                  <dd className="flex items-baseline">
                    <p className="text-lg md:text-xl font-semibold text-gray-900">
                      <CountUp
                        end={Number(item.stat) ?? 0}
                        suffix={item.suffix}
                        separator=","
                        decimal="."
                        decimals={item.id === 1 ? 2 : 0}
                        duration={1}
                      />
                    </p>
                  </dd>
                </div>
              </div>
            ))}
          </dl>
        </div>
      </div>
    );
  };

  const JobsBoardSection = () => {
    return (
      <div className=" bg-white border w-full rounded-md overflow-hidden">
        <div className="border-b border-gray-200 bg-white px-4 py-5 sm:px-6">
          <div className="-ml-4 -mt-2 flex flex-wrap items-center justify-start sm:flex-nowrap gap-2">
            <div className="ml-4 mt-2 flex gap-2 items-center">
              <BriefcaseIcon className="wh-6 flex-shrink-0 self-center text-xs" />
              <h3 className="text-lg font-medium leading-6 text-gray-900">
                Jobs board
              </h3>
            </div>
            <button className="border mt-2 inline-flex items-center rounded-full bg-gray-100 px-2.5 py-0.5 text-xs font-medium text-gray-800 gap-1 whitespace-pre-line">
              <p
                data-for="tip-tooltip"
                data-tip={
                  'Student requests for mentoring on your available subjects are listed here. \nSend them an application to get started.'
                }
                className="flex items-center gap-1"
              >
                <LightBulbIcon
                  className={`wh-3 flex-shrink-0 self-center text-xs `}
                  aria-hidden="true"
                />
                <p>Tip</p>
              </p>
              <ReactTooltip
                id="tip-tooltip"
                className="max-w-[80vw]"
                delayHide={1000}
                effect="solid"
                place="bottom"
              />
            </button>
          </div>
        </div>
        <div className="p-6 space-y-4">
          <div className="text-center ">
            {me?.chatState === ChatState.AVAILABLE || meIsFetching ? (
              <>
                <EmptyState
                  MainIcon={BriefcaseIcon}
                  onClick={() => push('/my/dashboard/jobs')}
                  ctaText="List"
                  title={`${myJobsCount ?? '0'} online jobs available`}
                  subTitle="in your subjects"
                />
              </>
            ) : (
              <>
                <NoSymbolIcon
                  className="wh-8 text-gray-500 m-auto"
                  aria-hidden="true"
                />
                <h3 className="mt-2 text-lg font-medium text-gray-900">
                  You are currently not accepting new students 🥲
                </h3>
                <p className="mt-1 text-sm text-gray-500">
                  Show students you're open to work
                </p>
                <div className="mt-6">
                  <ToggleSwitch
                    checked={
                      me?.chatState === ChatState.NOTAVAILABLE ? false : true
                    }
                    onChange={async (checked: boolean) => {
                      await api
                        .patch(`/users/update-mentor-availability`, {
                          availability: checked,
                        })
                        .then(() => {
                          alert('Successfully saved!');
                        });
                    }}
                  />
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    );
  };

  const PaymentsSection = () => {
    return (
      <div className=" bg-white border max-w-[1100px] w-full rounded-md overflow-hidden">
        <div className="border-b border-gray-200 bg-white px-4 py-5 sm:px-6">
          <div className="-ml-4 -mt-2 flex flex-wrap items-center justify-between sm:flex-nowrap">
            <div className="ml-4 mt-2 flex gap-2">
              <div className="flex gap-2 items-center">
                <CurrencyDollarIcon className="wh-6 flex-shrink-0 self-center text-xs" />
                <h3 className="text-lg font-medium leading-6 text-gray-900">
                  Payments
                </h3>
              </div>
              <button className="border inline-flex items-center rounded-full bg-gray-100 px-2.5 py-0.5 text-xs font-medium text-gray-800 gap-1 whitespace-pre-line">
                <p
                  data-for="tip-tooltip"
                  data-tip={`Total earnings: Total earnings from Linkstory
                  Amount paid: Amount paid out from total earnings
                  Amount owed: Earnings to be disbursed on next pay day 
                  Pay percent: Percentage of total earnings paid out`}
                  className="flex items-center gap-1"
                >
                  <LightBulbIcon
                    className={`wh-3 flex-shrink-0 self-center text-xs `}
                    aria-hidden="true"
                  />
                  <p>Tip</p>
                </p>
                <ReactTooltip
                  id="tip-tooltip"
                  className="max-w-[80vw]"
                  delayHide={1000}
                  effect="solid"
                  place="bottom"
                />
              </button>
            </div>
            <div className="ml-4 mt-2 flex-shrink-0">
              <Link
                to={'/my/dashboard/payments'}
                className="inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 gap-1"
              >
                <MagnifyingGlassIcon className="wh-4 flex-shrink-0 self-center text-xs" />
                <p>Details</p>
              </Link>
            </div>
          </div>
        </div>
        <div className="p-6 space-y-4">
          <dl className=" grid gap-5 sm:grid-cols-2 lg:grid-cols-4">
            {paymentStats.map((item, index) => (
              <div
                key={item.id}
                className="relative overflow-hidden rounded-lg bg-white px-4 py-5 shadow sm:px-6 sm:py-6"
              >
                <div className="absolute rounded-md bg-brand-orange p-3">
                  <item.icon
                    className="wh-5 md:wh-6 text-white"
                    aria-hidden="true"
                  />
                </div>
                <div className="ml-14 md:ml-16 ">
                  <dt>
                    <p className="truncate text-sm font-medium text-gray-500">
                      {item.name}
                    </p>
                  </dt>
                  <dd className="flex items-baseline">
                    <p className="text-lg md:text-xl font-semibold text-gray-900">
                      <CountUp
                        end={Number(item.stat) ?? 0}
                        prefix={index !== paymentStats.length - 1 ? '$' : ''}
                        suffix={index === paymentStats.length - 1 ? '%' : ''}
                        separator=","
                        decimal="."
                        decimals={2}
                        duration={1}
                      />
                    </p>
                  </dd>
                </div>
              </div>
            ))}
          </dl>
        </div>
      </div>
    );
  };

  const ScheduledSessionsSection = () => {
    return (
      <div className="overflow-hidden bg-white shadow sm:rounded-md max-w-[1100px]">
        <div className="border-b border-gray-200 bg-white px-4 py-5 sm:px-6">
          <div className="-ml-4 -mt-2 flex flex-wrap items-center justify-between sm:flex-nowrap">
            <div className="ml-4 mt-2 flex gap-2 items-center">
              <CalendarIcon className="wh-6 flex-shrink-0 self-center text-xs" />
              <h3 className="text-lg font-medium leading-6 text-gray-900">
                Upcoming Sessions
              </h3>
            </div>
          </div>
        </div>
        <ul className="divide-y divide-gray-200">
          {upcomingSessionItems?.items
            ?.sort((a: any, b: any) => {
              return a.startedAt > b.startedAt
                ? 0
                : b.startedAt > a.startedAt
                ? -1
                : 1;
            })
            .map((session) => (
              <li key={session.id}>
                <div className="block hover:bg-gray-50 w-full">
                  <div className="flex items-center px-4 py-4 sm:px-6 w-full justify-between">
                    <div className="min-w-0 flex-1 sm:flex sm:items-center sm:justify-between">
                      <div className="truncate">
                        <div className="flex text-sm flex-wrap">
                          <div className="flex items-center gap-2">
                            <Link
                              to={`/my/dashboard/session/${session.id}`}
                              className="truncate text-base font-medium text-indigo-600 hover:text-brand-1"
                            >
                              {session.title !== ''
                                ? session.title
                                : 'Untitled'}
                            </Link>
                            <span className="inline-flex items-center rounded-full bg-green-100 px-2.5 py-0.5 text-xs font-medium text-green-800">
                              {utcToDiffMinutes(
                                session.startedAt,
                                session.endedAt
                              )}{' '}
                              mins
                            </span>
                          </div>
                          <div className="hidden mt-2 xl:flex items-start space-x-3 xl:mt-0 xl:ml-3 xl:border-l xl:border-gray-400 xl:border-opacity-50 xl:pl-3 text-gray-500">
                            <dt className="flex items-center text-sm gap-1 ">
                              <CurrencyDollarIcon
                                className="wh-4  text-gray-400"
                                aria-hidden="true"
                              />
                              <dd>
                                {session.price &&
                                  Number(session.price).toFixed(2)}
                              </dd>
                            </dt>
                          </div>
                        </div>
                        <div className="mt-2 flex gap-2">
                          <div className="flex items-center text-sm text-gray-500">
                            <CalendarIcon
                              className="mr-1.5 wh-4 flex-shrink-0 text-gray-400"
                              aria-hidden="true"
                            />
                            <p>
                              <time dateTime={session.startedAt}>
                                {utcToLocalFormat(
                                  session.startedAt,
                                  MomentFormat.LL
                                )}
                              </time>
                            </p>
                          </div>
                          <div className="flex items-center text-sm text-gray-500 gap-1.5">
                            <ClockIcon
                              className="wh-4 flex-shrink-0 text-gray-400"
                              aria-hidden="true"
                            />
                            <p>
                              <time dateTime={session.startedAt}>
                                {utcToLocalFormat(
                                  session.startedAt,
                                  MomentFormat.hmma
                                )}
                              </time>
                            </p>
                          </div>
                        </div>
                        <div className="mt-2 xl:hidden flex gap-2">
                          <div className="flex items-start space-x-3 text-gray-500">
                            <dt className="flex items-center text-sm gap-1 ">
                              <CurrencyDollarIcon
                                className="wh-4  text-gray-400"
                                aria-hidden="true"
                              />
                              <dd>{Number(session.price).toFixed(2)}</dd>
                            </dt>
                          </div>
                        </div>
                      </div>
                      <div className="mt-4 flex-shrink-0 sm:mt-0 sm:ml-5">
                        <div className="flex overflow-hidden items-center gap-3 ">
                          <p className="text-sm text-gray-500">
                            {session.student.firstName
                              ? session.student.firstName +
                                ' ' +
                                session.student.lastName
                              : 'N/A'}
                          </p>
                          <Avatar
                            src={getS3Uri(session.student?.avatar)}
                            alt="avatar"
                            className="!wh-8 object-cover rounded-full"
                          />
                        </div>
                      </div>
                    </div>
                    {/* <div className="ml-5 flex-shrink-0">
                    <ChevronRightIcon
                      className="h-5 w-5 text-gray-400"
                      aria-hidden="true"
                    />
                  </div> */}
                  </div>
                </div>
              </li>
            ))}
          {!upcomingSessionItems?.total && !upcomingFetching && (
            <EmptyState
              className="py-12"
              title="No upcoming sessions"
              subTitle="You must create a session in the chat for sessions to appear here"
            />
          )}
        </ul>
      </div>
    );
  };

  const SubjectUpdatedSection = () => {
    return (
      <div className="bg-green-600 rounded-md">
        <div className="mx-auto max-w-7xl py-3 px-3 sm:px-6 lg:px-8">
          <div className="flex flex-wrap items-center justify-between">
            <div className="flex flex-1 items-center">
              <span className="flex rounded-lg bg-green-700 p-2">
                <AcademicCapIcon
                  className="h-6 w-6 text-white"
                  aria-hidden="true"
                />
              </span>

              <p className="ml-3 truncate font-medium text-white">
                {/* <span className="md:hidden whitespace-normal">
                  Our subjects have been updated! To see the updated jobs board,
                  please go to your “Account” tab and select the subjects you
                  are qualified to teach.
                </span> */}
                <span className="whitespace-pre-line">
                  {`You can now choose which subjects to teach by going to the 'Account' tab`}
                </span>
              </p>
            </div>
            <div className="order-3 mt-2 w-full flex-shrink-0 sm:order-2 sm:mt-0 sm:w-auto">
              <Link
                to="/my/dashboard/account"
                className="flex items-center justify-center rounded-md border border-transparent bg-white px-4 py-2 text-sm font-medium text-green-600 shadow-sm hover:bg-indigo-50"
              >
                Go to account
              </Link>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const PastSessionsSection = () => {
    return (
      <div className="overflow-hidden bg-white shadow sm:rounded-md max-w-[1100px]">
        <div className="border-b border-gray-200 bg-white px-4 py-5 sm:px-6">
          <div className="-ml-4 -mt-2 flex flex-wrap items-center justify-between sm:flex-nowrap">
            <div className="ml-4 mt-2 flex gap-2 items-center">
              <CalendarIcon className="wh-6 flex-shrink-0 self-center text-xs" />
              <h3 className="text-lg font-medium leading-6 text-gray-900">
                Recent Sessions
              </h3>
            </div>
            <div className="flex items-center">
              <div className="ml-4 mt-2 flex-shrink-0">
                <Link
                  to={'/my/dashboard/sessions/submit'}
                  className="inline-flex items-center rounded-md border bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700  focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 gap-1"
                >
                  <PaperAirplaneIcon className="wh-4 flex-shrink-0 self-center text-xs" />
                  <p>Submit a session</p>
                </Link>
              </div>
            </div>
          </div>
        </div>
        <ul className="divide-y divide-gray-200">
          {recentSessionItems?.items
            .sort((a: any, b: any) => {
              return b.startedAt > a.startedAt
                ? 0
                : a.startedAt > b.startedAt
                ? -1
                : 1;
            })
            .map((session) => (
              <li key={session.id}>
                <div className="block hover:bg-gray-50 w-full">
                  <div className="flex items-center px-4 py-4 sm:px-6 w-full justify-between">
                    <div className="min-w-0 flex-1 sm:flex sm:items-center sm:justify-between">
                      <div className="truncate">
                        <div className="flex text-sm flex-wrap">
                          <div className="flex items-center gap-2">
                            <Link
                              to={
                                session.status !== LessonState.INCOMPLETE
                                  ? `/my/dashboard/session/${session.receipts[0]?.id}/submitted`
                                  : `/my/dashboard/session/${session.id}`
                              }
                              className="truncate text-base font-medium text-indigo-600 hover:text-brand-1"
                            >
                              {session.title !== ''
                                ? session.title
                                : 'Untitled'}
                            </Link>
                            <span className="inline-flex items-center rounded-full bg-green-100 px-2.5 py-0.5 text-xs font-medium text-green-800">
                              {utcToDiffMinutes(
                                session.startedAt,
                                session.endedAt
                              )}{' '}
                              mins
                            </span>
                          </div>
                          <div className="hidden mt-2 xl:flex items-start space-x-3 xl:mt-0 xl:ml-3 xl:border-l xl:border-gray-400 xl:border-opacity-50 xl:pl-3 text-gray-500">
                            <dt className="flex items-center text-sm gap-1 ">
                              <CurrencyDollarIcon
                                className="wh-4  text-gray-400"
                                aria-hidden="true"
                              />
                              <dd>
                                {session.price &&
                                  Number(session.price).toFixed(2)}
                              </dd>
                            </dt>
                          </div>
                        </div>
                        <div className="mt-2 flex gap-2">
                          <div className="flex items-center text-sm text-gray-500">
                            <CalendarIcon
                              className="mr-1.5 wh-4 flex-shrink-0 text-gray-400"
                              aria-hidden="true"
                            />
                            <p>
                              <time dateTime={session.startedAt}>
                                {utcToLocalFormat(
                                  session.startedAt,
                                  MomentFormat.LL
                                )}
                              </time>
                            </p>
                          </div>
                          <div className="flex items-center text-sm text-gray-500 gap-1.5">
                            <ClockIcon
                              className="wh-4 flex-shrink-0 text-gray-400"
                              aria-hidden="true"
                            />
                            <p>
                              <time dateTime={session.startedAt}>
                                {utcToLocalFormat(
                                  session.startedAt,
                                  MomentFormat.hmma
                                )}
                              </time>
                            </p>
                          </div>
                        </div>
                        <div className="mt-2 xl:hidden flex gap-2">
                          <div className="flex items-start space-x-3 text-gray-500">
                            <dt className="flex items-center text-sm gap-1 ">
                              <CurrencyDollarIcon
                                className="wh-4  text-gray-400"
                                aria-hidden="true"
                              />
                              <dd>
                                {session.price &&
                                  Number(session.price).toFixed(2)}
                              </dd>
                            </dt>
                          </div>
                        </div>
                      </div>
                      <div className="mt-4 flex-shrink-0 sm:mt-0 sm:ml-5">
                        <div className="flex overflow-hidden items-center gap-3 ">
                          <p className="text-sm text-gray-500">
                            {session.student.firstName
                              ? session.student.firstName +
                                ' ' +
                                session.student.lastName
                              : 'N/A'}
                          </p>
                          <Avatar
                            src={getS3Uri(session.student?.avatar)}
                            alt="avatar"
                            className="!wh-8 object-cover rounded-full"
                          />
                        </div>
                      </div>
                    </div>
                    {session.status === LessonState.INCOMPLETE ? (
                      <div className="ml-5 flex-shrink-0">
                        <Link
                          to={`/my/dashboard/sessions/submit/${session.id}`}
                          type="button"
                          className="inline-flex items-center rounded border border-transparent bg-indigo-100 px-2.5 py-1.5 text-xs font-medium text-indigo-700 hover:bg-indigo-200 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                        >
                          Submit
                        </Link>
                      </div>
                    ) : session.lessonReviews[0]?.rating ? (
                      <div className="ml-5 flex gap-1 items-center text-sm">
                        <StarIcon className="wh-4 text-brand-orange" />
                        {session.lessonReviews[0]?.rating}
                      </div>
                    ) : (
                      <div className="ml-5 flex-shrink-0 text-xs text-gray-500">
                        NO RATING
                      </div>
                    )}
                  </div>
                </div>
              </li>
            ))}

          {!recentSessionItems?.total && !recentFetching && (
            <EmptyState
              className="py-12"
              title="No recent sessions"
              subTitle="Recently completed sessions will appear here"
            />
          )}

          <div className="px-4 py-4 sm:px-6">
            <Link
              to={'/my/dashboard/sessions'}
              className="inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 gap-1"
            >
              <ListBulletIcon className="wh-4 flex-shrink-0 self-center text-xs" />
              <p>All submitted sessions</p>
            </Link>
          </div>
        </ul>
      </div>
    );
  };

  return (
    <>
      {SubjectUpdatedSection()}
      {InboxSection()}
      {JobsBoardSection()}
      {PaymentsSection()}
      {ScheduledSessionsSection()}
      {PastSessionsSection()}
      <HiddenHeadingMeta text="Tutor Dashboard | Linkstory" />
      <ReactHelmet
        keywords="tutor, dashboard"
        title="Tutor Dashboard | Linkstory"
      />
    </>
  );
};
