import React, { useEffect, useState } from 'react';
import { Link, useHistory, useParams } from 'react-router-dom';
import { BreadCrumbs } from '@src/components/BreadCrumbs';
import {
  CalendarIcon,
  CheckBadgeIcon,
  ClockIcon,
  CurrencyDollarIcon,
  MegaphoneIcon,
  PencilSquareIcon,
  ShieldCheckIcon,
} from '@heroicons/react/24/outline';
import { LessonState, Role, SessionType, Subject, User } from '@src/types';
import { useAuth } from '@src/hooks';
import { useQuery } from 'react-query';
import { fetcher } from '@src/plugins/react-query';
import { useForm } from 'react-hook-form';
import { Select } from '@src/components/Select';
import { TextField } from '@src/components/TextField';
import moment from 'moment';
import { PriceField } from '@src/components/PriceField';
import { api } from '@src/plugins/axios';
import { HiddenHeadingMeta } from './HiddenHeadingMeta';
import { ReactHelmet } from './ReactHelmet';
import mixpanel from 'mixpanel-browser';
interface FormValues {
  price: number;
}

export const SessionRateEdit = () => {
  const { push, goBack } = useHistory();
  const { id } = useParams<{ id: string }>();
  const { authenticated } = useAuth();
  const { data: me } = useQuery<User>('/users/me', fetcher, {
    enabled: !!authenticated,
  });
  const [isSent, setIsSent] = useState<boolean>(false);

  const {
    register,
    reset,
    handleSubmit,
    watch,
    formState: { isSubmitting, errors, isDirty, isValid },
  } = useForm<FormValues>({
    mode: 'onChange',
  });

  const { data: sessionItem, refetch: refetchSession } = useQuery<SessionType>(
    `/lessons/detail/${id}`,
    fetcher,
    {
      enabled: !!authenticated,
      onSuccess: (item) => {
        let defaultValues = {
          price: Number(Number(item.price).toFixed(2)),
        };
        reset({ ...defaultValues });
      },
    }
  );

  useEffect(() => {
    if (
      sessionItem?.status === LessonState.COMPLETED ||
      sessionItem?.status === LessonState.CANCEL ||
      sessionItem?.status === LessonState.PENDING
    )
      goBack();
  }, [sessionItem?.status]);

  const FormSection = () => {
    return (
      <>
        <div className="border-b border-gray-200 bg-white px-4 py-5 sm:px-6">
          <div className="-ml-4 -mt-2 flex flex-col  justify-between sm:flex-nowrap">
            <div className="ml-4 mt-2 flex gap-2 items-center">
              <PencilSquareIcon className="wh-6 flex-shrink-0 self-center text-xs" />
              <h3 className="text-lg font-medium leading-6 text-gray-900">
                Price change request
              </h3>
            </div>
            <p className="ml-4 mt-1 text-sm text-gray-500 whitespace-pre-line">
              {`You can change the price per session on a per session basis. \nYou must discuss with your student detailing the reasons for the price change before requesting a change.`}
            </p>
          </div>
        </div>
        <form
          className="flex flex-col gap-4"
          onSubmit={handleSubmit(async (data) => {
            await api
              .patch(`/lessons/v2/lessons/change-rate/${id}`, {
                price: !data.price ? 0 : Number(Number(data.price.toFixed(2))),
              })
              .then(() => {
                setIsSent(true);
                mixpanel.track('Session Rate Changed', {
                  sessionId: id,
                  price: !data.price
                    ? 0
                    : Number(Number(data.price.toFixed(2))),
                });
                refetchSession();
              })
              .catch((e) => {
                mixpanel.track('Session Rate Changing Error', {
                  sessionId: id,
                  price: !data.price
                    ? 0
                    : Number(Number(data.price.toFixed(2))),
                });
                alert(
                  'An error occurred during transmission. Contact customer service'
                );
              });
          })}
        >
          <div className=" border-gray-200">
            <dl>
              <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                <dt className="text-sm font-medium text-gray-500">Readme</dt>
                <dd className="mt-1 text-sm text-gray-500 sm:col-span-2 sm:mt-0 whitespace-pre-line">
                  {`Use this page to change the price of the ${sessionItem?.title} session you charge to ${sessionItem?.studentName}. \nWhen you make a change to the price, we will send an email to the student so the change can be confirmed. \nThe new price will not become effective until it is accepted by the student. \nYou will be notified by email as soon as the student confirms the change.`}
                </dd>
              </div>
              <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                <dt className="text-sm font-medium text-gray-500">Student</dt>
                <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                  {sessionItem?.studentName}
                </dd>
              </div>

              <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                <dt className="text-sm font-medium text-gray-500">Price</dt>
                <PriceField
                  className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0"
                  helper={errors.price?.message}
                  disabled={isSent}
                  step=".01"
                  {...register('price', {
                    required: 'Please input price',
                    setValueAs: (value) => Number(value),
                    validate: (value: number) => {
                      if (value < 1) {
                        return 'The minimum price you can set is $1';
                      } else if (value === Number(sessionItem?.price)) {
                        return 'There is no change from the original price.';
                      }
                    },
                  })}
                />
              </div>

              <div className="bg-gray-50 px-4 py-3 text-right sm:px-6 flex justify-end gap-3">
                {isSent ? (
                  <button
                    type="button"
                    onClick={goBack}
                    className="inline-flex justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                  >
                    Go back
                  </button>
                ) : (
                  <button className="inline-flex justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">
                    Request Price Change
                  </button>
                )}
              </div>
            </dl>
          </div>
        </form>
      </>
    );
  };

  return (
    <>
      <HiddenHeadingMeta text="Session rate edit | Linkstory" />
      <ReactHelmet
        keywords="session, rate, edit"
        title="Session rate edit | Linkstory"
      />
      <BreadCrumbs
        homePath="/my/dashboard"
        breadCrumbsPages={[
          {
            name: 'Session Details',
            path: `/my/dashboard/session/${id}`,
          },
          {
            name: 'Price Change',
            path: `/my/dashboard/session/rate-edit/${id}`,
          },
        ]}
      />
      {isSent && (
        <div className="bg-green-500 rounded-md">
          <div className="mx-auto max-w-7xl py-3 px-3 sm:px-6 lg:px-8">
            <div className="flex flex-wrap items-center justify-between">
              <div className="flex flex-1 items-center">
                <span className="flex rounded-lg bg-green-700 p-2">
                  <CheckBadgeIcon
                    className="h-6 w-6 text-white"
                    aria-hidden="true"
                  />
                </span>
                <p className="ml-3 font-medium text-white text-sm">
                  <span className="md:hidden">
                    Your request has been processed.
                  </span>
                  <span className="hidden md:inline whitespace-pre-line leading-tight">
                    {` Your request to change ${
                      sessionItem?.student.firstName
                    }’s rate to $${
                      sessionItem &&
                      (Number(sessionItem.newPrice) ?? 0).toFixed(2)
                    } has been processed. \nWe will automatically change the rate upon ${
                      sessionItem?.student.firstName
                    }’s approval.`}
                  </span>
                </p>
              </div>
              <div className="order-3 mt-2 w-full flex-shrink-0 sm:order-2 sm:mt-0 sm:w-auto">
                <button
                  onClick={() => goBack()}
                  className="flex items-center justify-center rounded-md border border-transparent bg-white px-4 py-2 text-sm font-medium  shadow-sm hover:bg-indigo-50"
                >
                  Go Back
                </button>
              </div>
            </div>
          </div>
        </div>
      )}

      <div
        className={`space-y-4 bg-white border w-full rounded-md overflow-hidden ${
          isSent && 'invisible'
        }`}
      >
        <section className="whitespace-pre-line">{FormSection()}</section>
      </div>
    </>
  );
};
