import {
  ChatBubbleLeftIcon,
  ChatBubbleOvalLeftEllipsisIcon,
} from '@heroicons/react/24/outline';
import { RequestListItem } from '@src/components/RequestListItem';
import { parse } from 'qs';
import { useQuery } from 'react-query';
import { Link, useLocation } from 'react-router-dom';
import { ChatListItem } from '../../components/ChatListItem';
import { ChatRoom } from '../../components/ChatRoom';
import { fetcher } from '../../plugins/react-query';
import { Chat, Role, User } from '../../types';
import { useSize } from '../../utils';
import twConfig from '../../tailwindResolver';
import FadeLoader from 'react-spinners/BeatLoader';
import { useEffect } from 'react';
import { ZendeskAPI } from '@src/ZendexConfig';

export const ChatPage = () => {
  const { search } = useLocation();
  const { id } = parse(search, { ignoreQueryPrefix: true });

  const { data: me } = useQuery<User>('/users/me', fetcher);
  const size = useSize(document.body);

  useEffect(() => {
    // zendesk 위젯 감추는 코드
    ZendeskAPI('messenger:set', 'zIndex', -9999);

    return () => {
      // zendesk 위젯 다시 보이도록 처리
      ZendeskAPI('messenger:set', 'zIndex', 0);
    };
  }, []);

  const {
    data: chatsRawData,
    isLoading: chatIsLoading,
    refetch: refetchChats,
  } = useQuery('/chats', fetcher, {
    refetchInterval: 10000,
  });

  const { data: requestsRawData, refetch: refetchRequests } = useQuery(
    '/chats/v2/requests',
    fetcher,
    {
      // enabled: me?.role === Role.MENTOR,
      refetchInterval: 10000,
    }
  );

  const refetchChatList = () => {
    refetchChats();
    refetchRequests();
  };

  const requests = requestsRawData;

  const requestList = (
    <>
      <div className="bg-[#FAFAFA] border-b">
        <h1 className="text-base font-semibold py-3 px-4">Request</h1>
      </div>
      <div className="space-y-3 md:p-4 w-full overflow-y-auto">
        {(
          requests
            ?.filter((request: any) => +new Date(request.newMessageDate))
            ?.sort((a: any, b: any) => {
              return (
                new Date(b.newMessageDate).getTime() -
                new Date(a.newMessageDate).getTime()
              );
            }) ?? []
        ).map((request: Chat) => (
          <RequestListItem key={request.id} chat={request} />
        ))}
      </div>
    </>
  );

  const chatList = (
    <>
      <div
        className={`bg-[#FAFAFA] ${
          !requests || (requests?.length !== 0 && 'border-t')
        } border-b`}
      >
        <h1 className="text-base font-semibold py-3 px-4">Messages</h1>
      </div>
      <div className="space-y-3 md:p-4 w-full overflow-y-auto">
        {!chatIsLoading && chatsRawData.chats.length > 0 ? (
          (
            chatsRawData.chats
              ?.filter((chat: any) => +new Date(chat.newMessageDate))
              ?.sort((a: any, b: any) => {
                return (
                  new Date(b.newMessageDate).getTime() -
                  new Date(a.newMessageDate).getTime()
                );
              }) ?? []
          ).map((chat: Chat) => <ChatListItem key={chat.id} chat={chat} />)
        ) : chatIsLoading ? (
          <>
            <FadeLoader
              cssOverride={{
                display: 'flex',
                justifyContent: 'center',
                height: '100vh',
                alignItems: 'center',
              }}
              color={twConfig.theme.colors['brand-1']}
              loading={true}
              size={10}
            />
          </>
        ) : (
          <div className="space-y-6 flex flex-col justify-center items-center rounded-md h-full py-4 self-center">
            <div className="text-center">
              <ChatBubbleOvalLeftEllipsisIcon className="mx-auto h-12 w-12 text-gray-400" />
              <h3 className="mt-2 text-sm font-medium text-gray-900">
                No chat
              </h3>
              <p className="mt-1 text-sm text-gray-500 whitespace-pre-line">
                {` ${
                  me && me?.role === Role.STUDENT
                    ? 'Start a conversation \nwith your Tutor now'
                    : 'Find students on our Jobs page`'
                }`}
              </p>
            </div>
            <Link
              to={
                me && me?.role === Role.STUDENT
                  ? '/mentors'
                  : '/my/dashboard/jobs'
              }
              type="button"
              style={{ width: 'fit-content' }}
              className="inline-flex items-center rounded border border-transparent bg-indigo-600 px-2.5 py-1.5 text-xs font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
            >
              {me && me?.role === Role.STUDENT ? 'Search' : 'Jobs board'}
            </Link>
          </div>
        )}
      </div>
    </>
  );

  return (
    <>
      {/* desktop */}
      <div
        className="hidden md:flex mb-5  w-full h-full overflow-hidden "
        style={{ maxHeight: 'calc(100vh - 100px)' }}
      >
        <div className="md:w-[300px] p-4 md:p-0 bg-white md:mr-4 md:border md:shadow-sm md:rounded-md h-full overflow-y-auto">
          {requests && requests?.length !== 0 && requestList}
          {chatList}
        </div>
        {!search ? (
          <div className="bg-white border shadow-sm flex-1 p-6 space-y-6 grid place-content-center rounded-md">
            <div className="text-center">
              <ChatBubbleOvalLeftEllipsisIcon className="mx-auto h-12 w-12 text-gray-400" />
              <h3 className="mt-2 text-sm font-medium text-gray-900">
                No selected chat
              </h3>
              <p className="mt-1 text-sm text-gray-500">
                Please select from the list
              </p>
            </div>
          </div>
        ) : (
          <ChatRoom refetchChatList={() => refetchChatList()} />
        )}
      </div>

      {/* mobile */}
      {size && size.width !== 0 && size.width < 768 && (
        <div className="md:hidden h-full">
          {!id && (
            <div className="m-4 bg-white md:mr-4 border shadow-sm rounded-md  overflow-y-auto">
              {!id && requests && requests?.length !== 0 && requestList}
              {!id && chatList}
            </div>
          )}

          {id && <ChatRoom refetchChatList={() => refetchChatList()} />}
        </div>
      )}
    </>
  );
};
