import React, { FC, HTMLAttributes, useState } from 'react';
import { Chat, User } from '../types';
interface HamburgerButtonProps extends HTMLAttributes<HTMLDivElement> {
  dropdown: boolean;
  me?: User;
  chats?: Chat[];
}

export const HamburgerButton: FC<HamburgerButtonProps> = ({
  className,
  dropdown,
  me,
  chats,
  ...props
}) => {
  const [open, setOpen] = useState(false);

  return (
    <div className={`relative py-3 ${className}`} {...props}>
      <button
        className="text-gray-700 w-10 h-10 relative focus:outline-none "
        onClick={() => setOpen(!open)}
      >
        <div className="block w-5 text-white absolute left-1/2 top-1/2   transform  -translate-x-1/2 -translate-y-1/2">
          <span
            className={`block absolute h-0.5 w-5 bg-black transform transition duration-500 ease-in-out ${
              dropdown ? 'rotate-45' : '-translate-y-1.5'
            }`}
          ></span>
          <span
            className={`block absolute  h-0.5 w-5 bg-black   transform transition duration-500 ease-in-out ${
              dropdown ? 'opacity-0' : ''
            }`}
          ></span>
          <span
            className={`block absolute h-0.5 w-5 bg-black transform transition duration-500 ease-in-out ${
              dropdown ? '-rotate-45' : 'translate-y-1.5'
            }`}
          ></span>
        </div>
      </button>
    </div>
  );
};
