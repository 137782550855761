import { stringify } from 'qs';
import { api } from '../../plugins/axios';
import { GetAdvisorsDto } from './type';

export function getAdvisors(getAdvisorsDto: GetAdvisorsDto) {
  const queryString = stringify(getAdvisorsDto, {
    addQueryPrefix: true,
    encode: false,
  });
  return api.get(`/users/mentors${queryString}`);
}
