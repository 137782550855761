import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import '@src/plugins/iframeCORS.css';

export const TermsAndConditions = () => {
  const { search } = useLocation<any>();

  useEffect(() => {
    // get all iframes that were parsed before this tag
    var iframes: any = document.getElementsByTagName('iframe');
    if (iframes) {
      for (let i = 0; i < iframes.length; i++) {
        var url = iframes[i].getAttribute('src');
        if (url?.startsWith('https://docs.google.com/document/d/')) {
          // create div and replace iframe
          let d = document.createElement('div');
          d.classList.add('embedded-google-doc'); // optional
          iframes[i].parentElement.replaceChild(d, iframes[i]);

          // CORS request
          var xhr = new XMLHttpRequest();
          xhr.open('GET', url, true);
          // eslint-disable-next-line no-loop-func
          xhr.onload = function () {
            // display response
            d.innerHTML = xhr.responseText;
          };
          xhr.send();
        }
      }
    }
  }, []);

  useEffect(() => {
    if (search === '?marketing') {
      setTimeout(() => {
        // const iframe = document.getElementsByClassName('embedded-google-doc');
        const xpath = "//span[text()='Marketing Agreement (Optional)']";
        const matchingElement: any = document.evaluate(
          xpath,
          document,
          null,
          XPathResult.FIRST_ORDERED_NODE_TYPE,
          null
        ).singleNodeValue;

        matchingElement?.scrollIntoView({
          behavior: 'auto',
          block: 'center',
          inline: 'center',
        });
      }, 1000);
    }
  }, [search]);

  return (
    <div className="py-10 w-full h-full bg-gray-100 text-center overflow-auto">
      <iframe
        style={{
          display: 'block',
          width: '100vw',
          maxWidth: '832px',
          height: '100vh',
          margin: 'auto',
        }}
        frameBorder="0"
        className="rounded-lg"
        title="termsAndConditions"
        src="https://docs.google.com/document/d/e/2PACX-1vQ4bPg-1fUzjAk4ZBO1c2Pxop13IIP8QlBrIMTOrLSfFrqpU6aN3pBDfx--_Wgnwj_0WGXWLq983qQI/pub?embedded=true"
      ></iframe>
    </div>
  );
};
