import { api } from '../../plugins/axios';
import { AddMailChimpMemberType, UpdateMailChimpMemberType } from './type';

export const postMailChimpAddMember = async (
  addMailChimpMemberType: AddMailChimpMemberType
): Promise<string> => {
  let result = '';
  await api
    .post('/mailchimp/add', addMailChimpMemberType)
    .then((res) => {
      console.log('response : ', res);
      return (result = 'success');
    })
    .catch((err) => {
      if (err.response && err.response.data) {
        console.log(err.response.data.message); // some reason error message
        result = err.response.data.message;
      }
    });
  return result;
};
export const patchMailChimpUpdateMember = async (
  updateMailChimpMemberType: UpdateMailChimpMemberType
): Promise<string> => {
  let result = '';
  await api
    .patch('/mailchimp/update', updateMailChimpMemberType)
    .then((res) => {
      console.log('response : ', res);
      return (result = 'success');
    })
    .catch((err) => {
      if (err.response && err.response.data) {
        console.log(err.response.data.message); // some reason error message
        result = err.response.data.message;
      }
    });
  return result;
};
