import useResizeObserver from '@react-hook/resize-observer';
import React, { useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { S3_LINK_URI } from './constants';
import { Cookies } from 'react-cookie';

export function range(length: number, start = 0) {
  return Array.from({ length }, (_, i) => i + start);
}

export function getS3Uri(uri: string | null | undefined) {
  if (!uri) {
    return '';
  }
  return S3_LINK_URI + uri;
}

export function useSize<T extends HTMLElement>(
  target: React.RefObject<T> | T | null
) {
  const [size, setSize] = useState<DOMRectReadOnly>();

  React.useLayoutEffect(() => {
    setSize(
      target && 'current' in target && target.current
        ? target.current.getBoundingClientRect()
        : new DOMRect()
    );
  }, [target]);

  useResizeObserver(target, (entry) =>
    setSize(entry.target.getBoundingClientRect())
  );
  return size;
}

export const useBasePath = () => {
  const location = useLocation();
  const params = useParams<Record<string, string>>();

  return Object.values(params).reduce(
    (path, param) => path.replace('/' + param, ''),
    location.pathname
  );
};

const cookies = new Cookies();

export const setCookie = (
  name: string,
  value: boolean | string,
  option?: any
) => {
  return cookies.set(name, value, { ...option });
};

export const getCookie = (name: string) => {
  return cookies.get(name);
};
