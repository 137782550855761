import { parse, stringify } from 'qs';
import { useLocation } from 'react-router';
import { api } from './plugins/axios';
import { accessTokenState, idState, socketState } from './plugins/ridge';
import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import { FormEvent } from 'react';
import {
  CreditCard,
  Subscription,
  User,
  LessonState,
  emailVerifiacationStatus,
  paymentMethodStatus,
} from './types';
import { useHistory } from 'react-router-dom';
import { PRICE_YEAR } from './constants/price';
import axios from 'axios';
import * as Sentry from '@sentry/react';
import { patchMailChimpUpdateMember } from './api/mailchimp';
import { Mixpanel } from './plugins/mixpannel';
import mixpanel from 'mixpanel-browser';

export const useAuth = () => {
  const { push } = useHistory();
  const [socket] = socketState.use();
  const [, setId] = idState.use();
  const [, setAccessToken] = accessTokenState.use();
  const authenticated = localStorage.getItem('token') !== null;

  const signup = (data: any) =>
    api
      .post('/auth/signup', data)
      .then(({ data: { token, id, accessToken } }) => {
        setId(id);
        setAccessToken(accessToken);
        localStorage.setItem('token', token);
      });

  const verifyEmail = (data: any) => {
    api
      .patch('/users/verify-email', { code: data })
      .then(({ data: { token, id, email, accessToken } }) => {
        setId(id);
        setAccessToken(accessToken);
        localStorage.setItem('token', token);
        mixpanel.identify(email);
        mixpanel.people.set({
          'Email Verification': emailVerifiacationStatus.VERIFIED,
        });
        mixpanel.track('Email Validated');
      })
      .catch((err) => {
        alert('Improper approach');
        push('/');
      });
  };

  const resendEmail = (data: string) => {
    api
      .post('/users/resend-verify-email', { email: data })
      .then(() => {
        mixpanel.track('Email verification mail re-sent');
      })
      .catch(() => {
        alert('Improper approach');
        push('/');
      });
  };

  const gtmLogin = async () => {
    const { data: me } = await api.get<User>('/users/me');
    mixpanel.identify(me.email);
    mixpanel.track('Log In');
    // register a super property for the first time only
    mixpanel.register_once({
      'First Login Date': new Date().toISOString(),
    });
    window.dataLayer.push({
      event: 'login',
      userId: me.id,
      userEmail: me.email,
      userName: me.firstName + ' ' + me.lastName,
      userRole: me.role,
    });
  };

  const gtmLogout = () => {
    mixpanel.track('Log Out');
    mixpanel.reset();

    window.dataLayer.push({ event: 'logout' });
  };

  const googleLogin = async (data: { code: string; from: string }) => {
    const response = await api.post('auth/google', {
      code: data.code,
      redirectUrl: process.env.REACT_APP_GOOGLE_REDIRECT_URI,
    });

    await api
      .post('auth/login/google', { idToken: response.data.token })
      .then((res) => {
        if (res.data.id) {
          setId(res.data.id);
          setAccessToken(res.data.accessToken);
          localStorage.setItem('token', res.data.token);
          gtmLogin();
          if (data.from) {
            // 로그인전 페이지로 페이지 이동
            push(data.from);
          } else {
            push('/');
          }
        } else {
          push(`/signup/student`, {
            google: {
              idToken: res.data.idToken,
              email: response.data.email,
              firstName: response.data.firstName,
              lastName: response.data.lastName,
              birthDate: response.data.birthDate,
              picture: response.data.picture,
            },
          });
        }
      })
      .catch((err) => {
        console.log(err);
        alert('Please valid your email first');
        push('/');
      });
  };

  const facebookAuth = async (data: {
    name: string;
    id: string;
    from: string;
  }) => {
    await api
      .post('auth/login/facebook', data)
      .then((response) => {
        if (response.data.token) {
          setId(response.data.id);
          setAccessToken(response.data.accessToken);
          localStorage.setItem('token', response.data.token);
          gtmLogin();

          if (data.from) {
            // 로그인전 페이지로 페이지 이동
            push(data.from);
          } else {
            push('/');
          }
        } else {
          console.log(response.data);
          if (response.data.email) {
            push('/signup/student', { facebook: response.data });
          } else {
            push('/signup/error');
          }
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const login = (data: any) =>
    api
      .post('/auth/login', data)
      .then(({ data: { token, id, accessToken } }) => {
        setId(id);
        setAccessToken(accessToken);
        localStorage.setItem('token', token);
        gtmLogin();
        if (data.from && typeof data.from === 'string') {
          // 로그인전 페이지로 페이지 이동
          push(data.from);
        }
      })
      .catch((err) => {
        if (err.response.status === 403) {
          alert('Please valid your email first');
          push('/signup/student/pending', {
            email: data.email,
            from: data.from,
          });
        } else {
          alert('Please check your email and password again.');
        }
      });

  const logout = (userId: number) => {
    socket?.emit('logout', { userId: userId });
    socket?.close();

    const i18nextLng = localStorage.getItem('i18nextLng'); // 설정되어 있는 언어값 가져오기
    localStorage.clear();
    i18nextLng && localStorage.setItem('i18nextLng', i18nextLng); // 설정되어 있는 언어값 다시 저장

    accessTokenState.reset();
    Sentry.configureScope((scope) => scope.setUser(null));
    gtmLogout();
    push('/');
    window.location.reload();
  };
  return {
    authenticated,
    signup,
    verifyEmail,
    resendEmail,
    googleLogin,
    facebookAuth,
    login,
    logout,
  };
};

export function useQueryString(queryObject: any = {}, options: any = {}) {
  const { search } = useLocation();
  const parsed = parse(search, { ignoreQueryPrefix: true });
  const searchObject = {
    page: '1',
    limit: options.defaultLimit ?? '1000',
    sort: { id: 'DESC' },
    ...parsed,
    ...queryObject,
  };
  return stringify(searchObject, { addQueryPrefix: true, encode: false });
}

export const useSubscriptionPaymentForm = (
  card: any,
  setLoading: (loading: boolean) => void,
  coupon?: string
) => {
  const stripe = useStripe();
  const elements = useElements();
  const { push } = useHistory();

  const getPaymentMethodId = async () => {
    const cardElement = elements?.getElement(CardElement);

    if (!stripe || !elements || !cardElement) {
      return;
    }

    const stripeResponse = await stripe?.createPaymentMethod({
      type: 'card',
      card: cardElement,
    });

    const { error, paymentMethod } = stripeResponse;

    if (error || !paymentMethod) {
      return alert(error?.message);
    }

    return paymentMethod.id;
  };

  const handlePayment = async (event: FormEvent) => {
    event.preventDefault();
    setLoading(true);

    const { data: me } = await api.get<User>('/users/me');
    const { data: creditCard } = await api.get<CreditCard>('/credit-cards/my');

    const paymentMethodId = card
      ? creditCard.paymentMethodId
      : await getPaymentMethodId();

    if (!paymentMethodId) {
      setLoading(false);
      return alert('Please check your credit card information again.');
    }

    if (!card) {
      const createCreditCard = await api.post<any>(`/credit-cards`, {
        paymentMethodId,
      });
      const clientSecret = createCreditCard.data.client_secret;
      const confirmResult = await stripe?.confirmCardSetup(clientSecret);
      if (confirmResult?.error) {
        await api.delete('/credit-cards');
        const { error } = confirmResult;
        setLoading(false);
        return alert(error.message);
      }
    } else {
      const result = await api
        .patch<any>(`/credit-cards`, {
          paymentMethodId,
        })
        .catch((err) => {
          setLoading(false);
          console.log(err);
        });

      if (!result) {
        alert('Please check your credit card information again.');
        return;
      }
    }

    const setDefaultCardResult = await api
      .post<any>(`/credit-cards/default`, {
        paymentMethodId,
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });

    if (!setDefaultCardResult) {
      return alert('Please check your credit card information again.');
    }

    const getYearlyUserSubscription = await api.get<any>(
      `/subscriptions/yearly`
    );

    if (
      getYearlyUserSubscription.data.status === 404 ||
      !getYearlyUserSubscription.data
    ) {
      await api
        .post<any>(`/subscriptions/yearly`, {
          coupon,
        })
        .then(async (res) => {
          const result = res.data;

          const getYearlySubscription = await api
            .get<any>(`/subscriptions/yearly`)
            .catch((err) => {
              setLoading(false);
              alert('Payment Not Successful');
            });

          if (getYearlySubscription?.data?.latest_invoice?.status === 'paid') {
            const current_period_start = new Date(
              getYearlySubscription.data.current_period_start * 1000
            );

            const current_period_end = new Date(
              getYearlySubscription.data.current_period_end * 1000
            );

            const gtmSubscribe = () => {
              window.dataLayer.push({
                event: 'subscribe',
                ecommerce: {
                  method: 'card',
                  subscription_start: current_period_start,
                  subscription_end: current_period_end,
                  transaction_id: getYearlySubscription.data.id,
                },
              });
              window.dataLayer.push({
                event: 'purchase',
                ecommerce: {
                  method: 'card',
                  transaction_id: getYearlySubscription.data.id,
                  affiliation: 'Yearly Subscription',
                  value: PRICE_YEAR,
                  currency: 'USD',
                  items: [
                    {
                      item_id: getYearlySubscription.data.id,
                      item_name: 'subscriptions',
                    },
                  ],
                },
              });
            };

            await api
              .post<Subscription>(`/subscriptions/add`, {
                price: !!result.discount?.coupon
                  ? !!result.discount?.coupon.amount_off
                    ? result.plan.amount - result?.discount?.coupon?.amount_off
                    : result.plan.amount *
                      (result?.discount?.coupon?.percent_off / 100)
                  : result?.plan.amount,
                userId: me?.id,
                stripeSubscriptionId: getYearlySubscription.data.id,
                current_period_start: current_period_start,
                current_period_end: current_period_end,
              })
              .then(() => {
                alert('Thank you for subscribing to Linkstory+!');
                gtmSubscribe();
                patchMailChimpUpdateMember({
                  email_address: me?.email,
                  merge_fields: {
                    VODSUBS: 'Subscribed',
                  },
                });
                push('/my/page/subscriptions');
              })
              .catch((err) => {
                setLoading(false);
                alert('Payment Error');
              });
          }
        })
        .catch((err) => {
          console.log('err : ', err);
          console.log(err.message);
          setLoading(false);
          alert('Invalid Coupon');
        });
    } else {
      setLoading(false);
      alert('You are already subscribed.');
      push('/my/page/subscriptions');
    }
  };

  return {
    handlePayment,
  };
};

export const useChatPaymentForm = (
  productId: number,
  mentorId: number,
  chatId: number,
  setLoading: (loading: boolean) => void,
  onClose: () => void
) => {
  const stripe = useStripe();
  const elements = useElements();
  const { push } = useHistory();

  const getPaymentMethodId = async () => {
    const cardElement = elements?.getElement(CardElement);

    if (!stripe || !elements || !cardElement) {
      return;
    }

    const stripeResponse = await stripe?.createPaymentMethod({
      type: 'card',
      card: cardElement,
    });

    const { error, paymentMethod } = stripeResponse;

    if (error || !paymentMethod) {
      return alert(error?.message);
    }
    return paymentMethod.id;
  };

  const handleSubmit = async (event: FormEvent) => {
    event.preventDefault();
    setLoading(true);

    // eslint-disable-next-line no-restricted-globals
    const check = confirm('Please confirm your payment.');

    if (check) {
      const paymentMethodId = await getPaymentMethodId();

      if (!paymentMethodId) {
        setLoading(false);
        return;
      }

      const creditCard = await api
        .post<any>(`/credit-cards`, {
          paymentMethodId,
        })
        .catch((err) => {
          setLoading(false);
          alert('Please check your credit card information again.');
        });

      const clientSecret = creditCard?.data.client_secret;
      const cardConfirm = await stripe?.confirmCardSetup(clientSecret);

      if (cardConfirm?.error) {
        await api.delete(`/credit-cards`);
        alert('Please check your payment information again.');
        setLoading(false);
        onClose();
        return false;
      }

      await api
        .post<any>(`/credit-cards/default`, {
          paymentMethodId,
        })
        .catch((err) => {
          console.log(err);
          setLoading(false);
          alert('Please check your credit card information again.');
          window.location.reload();
          return false;
        });

      await api
        .post(`/payments`, {
          paymentMethodId,
          mentorId,
          productId,
          chatId,
        })
        .then(async () => {
          setLoading(false);
          await alert('Payment Success');
          push('/my/mentoring/upcoming');
          window.dataLayer.push({
            event: 'mentoring_payment_success',
          });
        })
        .catch(() => {
          setLoading(false);
          alert(
            'Please go to your account and check your payment information again under your payment method. Please make sure your browser is not in private mode.'
          );
        });
    } else {
      setLoading(false);
      onClose();
      return false;
    }
  };

  return {
    handleSubmit,
  };
};

export const useCreateCreditCard = () => {
  const stripe = useStripe();
  const elements = useElements();

  const getPaymentMethodId = async () => {
    const cardElement = elements?.getElement(CardElement);

    if (!stripe || !elements || !cardElement) {
      return;
    }

    const stripeResponse = await stripe?.createPaymentMethod({
      type: 'card',
      card: cardElement,
    });

    const { error, paymentMethod } = stripeResponse;

    if (error || !paymentMethod) {
      return alert(error?.message);
    }
    return paymentMethod.id;
  };

  const createCreditCard = async (event: FormEvent, onClose: () => void) => {
    event.preventDefault();

    const paymentMethodId = await getPaymentMethodId();

    if (!paymentMethodId) {
      return;
    }

    const creditCard = await api
      .post<any>(`/credit-cards`, {
        paymentMethodId,
      })
      .catch(async (err) => {
        console.log(err);
        const { data: me } = await api.get<User>('/users/me');
        mixpanel.track('Payment Info - Adding Error');
        window.dataLayer.push({
          event: 'add_error_payment_info',
          userId: me.id,
          userEmail: me.email,
          userName: me.firstName + ' ' + me.lastName,
          userRole: me.role,
        });
        alert('Please check your payment information again.');
      });

    const clientSecret = creditCard?.data.client_secret;
    const cardConfirm = await stripe?.confirmCardSetup(clientSecret);

    if (cardConfirm?.error) {
      await api.delete(`/credit-cards`);
      await onClose();
      mixpanel.track('Payment Info - Adding Error');
      alert('Please check your payment information again.');
      window.location.reload();
      return false;
    }

    await api
      .post<any>(`/credit-cards/default`, {
        paymentMethodId,
      })
      .then(() => {
        onClose();
      })
      .then(async () => {
        alert('Your card has been successfully added to your account.');
        const { data: me } = await api.get<User>('/users/me');
        mixpanel.track('Payment Info - Added');
        mixpanel.identify(me.email);
        mixpanel.people.set({
          'Payment Info': paymentMethodStatus.ADDED,
        });
        window.dataLayer.push({
          event: 'add_payment_info',
          userId: me.id,
          userEmail: me.email,
          userName: me.firstName + ' ' + me.lastName,
          userRole: me.role,
        });
        window.location.reload();
      });
  };

  return {
    createCreditCard,
  };
};

export const useUpdateCreditCard = (card: any) => {
  const stripe = useStripe();
  const elements = useElements();

  const getPaymentMethodId = async () => {
    const cardElement = elements?.getElement(CardElement);

    if (!stripe || !elements || !cardElement) {
      return;
    }

    return stripe
      ?.createPaymentMethod({
        type: 'card',
        card: cardElement,
      })
      .then(async (stripeResponse) => {
        const { error, paymentMethod } = stripeResponse;
        const cardInfo = stripeResponse.paymentMethod?.card;
        const cardOverlapCheck =
          cardInfo?.exp_month === card.exp_month &&
          cardInfo?.exp_year === card.exp_year &&
          cardInfo?.last4 === card.last4;

        if (cardOverlapCheck) {
          return 'overlap';
        } else {
          if (error || !paymentMethod) {
            return alert(error?.message);
          }
          return paymentMethod.id;
        }
      });
  };

  const updateCreditCard = async (event: FormEvent, onClose: () => void) => {
    event.preventDefault();

    const paymentMethodId = await getPaymentMethodId();

    if (!paymentMethodId) {
      return;
    }

    if (paymentMethodId === 'overlap') {
      mixpanel.track('Payment Info - Adding Error');
      alert('Please check your payment information again.');
      return;
    }

    await api.patch<any>(`/credit-cards`, {
      paymentMethodId,
    });

    await api
      .post<any>(`/credit-cards/default`, {
        paymentMethodId,
      })
      .then(() => {
        onClose();
      })
      .then(async () => {
        const { data: me } = await api.get<User>('/users/me');
        mixpanel.track('Payment Info - Added');
        mixpanel.identify(me.email);
        mixpanel.people.set({
          'Payment Info': paymentMethodStatus.ADDED,
        });
        alert('Your card info has been successfully updated to your account.');

        window.dataLayer.push({
          event: 'add_payment_info',
          userId: me.id,
          userEmail: me.email,
          userName: me.firstName + ' ' + me.lastName,
          userRole: me.role,
        });
        window.location.reload();
      });
  };

  return {
    updateCreditCard,
  };
};

export const usePageCall = () => {
  const { push } = useHistory();

  const terminatePageCall = async (roomId: number, lessonId: number) => {
    if (!roomId) {
      mixpanel.track('Page Call - Terminating Error');
      alert('There is no video chat room created. Contact customer service');
    } else {
      const options = {
        headers: {
          Authorization: ` Bearer ${process.env.REACT_APP_PAGE_CALL_API_KEY}`,
        },
      };
      await axios
        .put(
          `https://api.pagecall.net/v1/rooms/${roomId}`,
          {
            is_terminated: true,
          },
          options
        )
        .then(() => {
          mixpanel.track('Page Call - Terminated');
        })
        .catch((err) => console.log(err));
    }
  };

  return {
    terminatePageCall,
  };
};
