import { parse } from 'qs';
import { FC } from 'react';
import { useQuery } from 'react-query';
import { useHistory, useLocation } from 'react-router-dom';
import { fetcher } from '../plugins/react-query';
import { Chat, ChatState, Role, User } from '../types';
import { getS3Uri } from '../utils';
import { Avatar } from './Avatar';
import { utcToLocalFromNow } from '../plugins/moment';
import { LockClosedIcon } from '@heroicons/react/24/outline';
interface ChatListItemProps {
  chat: Chat;
}

export const ChatListItem: FC<ChatListItemProps> = ({ chat }) => {
  const { push } = useHistory();
  const { search } = useLocation();
  const { id } = parse(search, { ignoreQueryPrefix: true });
  const chatId = Number(id);
  const selected = chatId === chat.id;

  const { data: me } = useQuery<User>('/users/me', fetcher);
  const user = me?.role === Role.STUDENT ? chat.mentor : chat.student;
  const { refetch: refetchInboxCount } = useQuery<number>(
    '/chats/v2/chat-count',
    fetcher,
    {}
  );

  if (!me) return <></>;

  return (
    <div
      className={` ${
        selected ? 'md:bg-gray-50 md:rounded-md md:border' : ''
      }  cursor-pointer py-3 px-4 flex items-center space-x-2`}
      onClick={() => {
        refetchInboxCount();
        push(`/my/chats?id=${chat.id}`);
      }}
    >
      <Avatar
        src={
          user?.avatar.startsWith('https')
            ? user?.avatar
            : getS3Uri(user?.avatar)
        }
        className="wh-10"
      />

      <p className="flex-1 truncate text-sm">
        {user?.firstName} {user?.lastName}
      </p>

      <div className="flex space-x-2 items-center">
        {me.role === Role.STUDENT &&
          chat.mentor?.chatState === ChatState.NOTAVAILABLE && (
            <LockClosedIcon className="wh-5 text-red-400" />
          )}
        {/* 채팅방만 클릭한 경우에도 newMessageDate Default 값으로 1970.mm.dd 형태로 넘어오 따라서 해당 값인 경우 감추 도록 처리함 */}
        {new Date(chat.newMessageDate).getFullYear() !==
          new Date('1970').getFullYear() && (
          <time className="text-12 text-gray-500">
            {utcToLocalFromNow(chat.newMessageDate)}
          </time>
        )}

        {(me.role === Role.MENTOR ? chat.mentorNew : chat.studentNew) && (
          <span className="inline-block w-2 h-2 mr-2 bg-brand-1 rounded-full"></span>
        )}
      </div>
    </div>
  );
};
