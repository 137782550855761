import React, { FC, useState } from 'react';
import { useId } from 'react-id-generator';
import { Avatar } from './Avatar';
import { Icon } from './icons';

export interface AvatarInputProps {
  onChange: (file: File) => void;
  avatarUrl?: string;
}

export const AvatarInput: FC<AvatarInputProps> = ({ onChange, avatarUrl }) => {
  const [id] = useId(1, 'avatarinput');
  const [file, setFile] = useState<File>();

  const handleAvatarChangeFile = (e: any) => {
    const file = e.target.files?.item(0);
    // const sizeInMB = (file.size / (1024 * 1024)).toFixed(2); // 보낼때 압축하는 형태로 구현해서 불필요
    if (
      file.type !== 'image/png' &&
      file.type !== 'image/jpeg' &&
      file.type !== 'image/jpg'
    ) {
      alert('Only jpeg and png extension files are supported.');
      return;
    } else {
      setFile(file);
      onChange(file);
    }
  };

  const onInputClick = (
    event: React.MouseEvent<HTMLInputElement, MouseEvent>
  ) => {
    const element = event.target as HTMLInputElement;
    element.value = '';
  };

  return (
    <label htmlFor={id} className="relative wh-24 cursor-pointer mx-auto">
      {file || avatarUrl ? (
        <img
          src={file ? URL.createObjectURL(file) : avatarUrl}
          alt="avatar"
          className="rounded-full wh-24 object-cover"
        />
      ) : (
        <Avatar className="w-full h-full" />
      )}
      <div className="absolute right-0 bottom-0 bg-brand-1 text-white font-bold wh-8 text-center rounded-full grid place-items-center">
        <Icon.Plus />
      </div>
      <input
        id={id}
        type="file"
        accept="image/jpeg,image/png,image/jpg"
        className="sr-only"
        onChange={(e) => handleAvatarChangeFile(e)}
        onClick={onInputClick}
      />
    </label>
  );
};
