import React, { ButtonHTMLAttributes, FC } from 'react';
import { useHistory } from 'react-router';
import loadingLogo from '../assets/img/loading.gif';

export interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  text?: string;
  to?: string;
  isSubmitting?: boolean;
}

export const Button: FC<ButtonProps> = ({
  children,
  className = '',
  text,
  to,
  isSubmitting,
  onClick,
  ...props
}) => {
  const { push } = useHistory();

  return (
    <button
      className={`button hover:brightness-110 disabled:brightness-100 disabled:cursor-not-allowed ${className} flex items-center justify-center`}
      onClick={to ? () => push(to) : onClick}
      {...props}
    >
      {text ?? children}
      {isSubmitting && (
        <img src={loadingLogo} className="wh-6" alt="loading..." />
      )}
    </button>
  );
};
