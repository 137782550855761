// Packages
import React, { useEffect } from 'react';
import { Route, Switch, useHistory } from 'react-router';
import { Redirect } from 'react-router-dom';
import { io } from 'socket.io-client';
import { socketState } from '../plugins/ridge';
import { useQuery } from 'react-query';
import { fetcher } from '../plugins/react-query';
import { useAuth } from '../hooks';
import { Role } from '../types';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import mixpanel from 'mixpanel-browser';

// Auth components
import { AuthRoute } from '../components/AuthRoute';
import { AuthSubRoute } from '../components/AuthSubRoute';
import { SigninPage } from './auth/SigninPage';
import { SignupPage } from './auth/SignupPage';
import { SignupPendingPage } from './auth/SignupPendingPage';
import { SignupStudentPage } from './auth/SignupStudentPage';
import { SignupMentorPage } from './auth/SignupMentorPage';
import { SignupStudentPendingPage } from './auth/SignupStudentPendingPage';
import { SignupStudentValidationPage } from './auth/SignupStudentValidationPage';
import { FindPasswordPage } from './auth/FindPasswordPage';
import { PasswordSendPage } from './auth/PasswordSendPage';
import { PasswordResetPage } from './auth/PasswordResetPage';

// Other pages components
import { HomePage } from './HomePage';
import { TopNavBar } from '../components/TopNavBar';
import { MentorsPage } from './tutors/MentorsPage';
import { Footer } from '../components/Footer';
import { MentorPage } from './tutors/MentorPage';
import { VODsPage } from './vod/VODsPage';
import { VODPage } from './vod/VODPage';
import { MyLessonsPage } from './MyLessonsPage';
import { Webinar } from './webinar/Webinar';
import { WebinarDetails } from './webinar/WebinarDetails';
import { Dashboard } from './dashboard/Dashboard';
import { StudentRequest } from './request/StudentRequest';
import { RequestSentPage } from './request/RequestSentPage';
import { StudentHowTo } from './howItWorks/StudentHowTo';
import { TutorHowTo } from './howItWorks/TutorHowTo';
import { AboutResources } from './about/resources/AboutResources';
import { FreeEssaysPage } from './essays/freeEssays/FreeEssaysPage';
import { FreeEssayDetailPage } from './essays/freeEssays/FreeEssayDetailPage';
// import { EssaysPage } from './essays/EssaysPage';
// import { EssayDetailsPage } from './essays/EssayDetailsPage';

// Test Pages
import { MbtiTestPage } from './mbti/MbtiTestPage';
import { MbtiResultPage } from './mbti/MbtiResultPage';
// import { CalculatorAnalysisPage } from './calculator/CalculatorAnalysisPage';
// import { CalculatorPage } from './calculator/CalculatorPage';
// import { SubscriptionPayment } from './SubscriptionPayment';

// Other components
import { PopUp } from '../components/PopUp';
import { Error404 } from './utils/Error404';
import { ErrorPage } from './utils/ErrorPage';
import { LoadingPage } from './utils/LoadingPage';
import { FullScreenModal } from '../components/FullScreenModal';
import { ModalDiscount } from '../components/ModalDiscount';
import { Privacy } from './policies/Privacy';

// Policies components
import { TermsAndConditions } from './policies/TermsAndConditions';
import { RefundAndPenaltyFeeTable } from './policies/RefundAndPenaltyFeeTable';

const stripePromise = loadStripe(
  `${process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY}`,
  { locale: 'en' }
);

export const Router = () => {
  const { push } = useHistory();
  const { authenticated } = useAuth();
  const [socket, setSocket] = socketState.use();
  const { data: me } = useQuery('/users/me', fetcher, {
    enabled: authenticated,
  });

  const token = localStorage.getItem('token');
  const accessToken = localStorage.getItem('accessToken');

  useEffect(() => {
    if (token && process.env.REACT_APP_WEB_ENV === 'prod') {
      const socket = io(`${process.env.REACT_APP_API_URL}/validAuth`);
      setSocket(socket, () =>
        socket.emit('login', { userId: me?.id, accessToken: accessToken })
      );
      return () => {
        socket.close();
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token, me]);

  useEffect(() => {
    if (
      process.env.REACT_APP_WEB_ENV === 'prod' &&
      me &&
      me.role &&
      me.role !== Role.ADMIN
    ) {
      socket?.on('accessToken', (accessToken: string) => {
        const localAccessToken = localStorage.getItem('accessToken');
        if (accessToken !== localAccessToken) {
          socket.close();
          localStorage.clear();
          mixpanel.track('Log Out');
          mixpanel.reset();

          window.dataLayer.push({ event: 'logout' });
          push('/');
          alert('Login detected from a different IP address');
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [socket]);

  return (
    <>
      <PopUp />
      <FullScreenModal />
      <ModalDiscount />

      <Elements stripe={stripePromise}>
        <div className="font-header flex flex-col min-h-screen">
          <TopNavBar />

          <main className="flex-1 relative flex flex-col">
            <Switch>
              <AuthRoute path="/my/dashboard" component={Dashboard} />
              <AuthRoute path="/my" component={MyLessonsPage} />
              <Route
                exact
                path={`/about-resources`}
                component={AboutResources}
              />
              {/* <Route
                exact
                path={`/about-mentoring`}
                component={AboutMentoring}
              /> */}
              <Route exact path={`/free-essays`} component={FreeEssaysPage} />
              <Route
                exact
                path={`/free-essays/:id`}
                component={FreeEssayDetailPage}
              />

              <Route path="/mentors/:id" component={MentorPage} />
              <Route path="/mentors" component={MentorsPage} />

              <Route path="/vods/:id" component={VODPage} />
              <Route path="/vods" component={VODsPage} />
              {/* <AuthRoute path="/essays/:id" component={EssayDetailsPage} />
              <Route path="/essays" component={EssaysPage} /> */}

              <AuthRoute
                path="/request-sent/:id"
                component={RequestSentPage}
                allowedRole={Role.STUDENT}
              />
              <AuthRoute
                path="/request-sent"
                component={RequestSentPage}
                allowedRole={Role.STUDENT}
              />
              <AuthRoute
                path="/student-request/:id"
                component={StudentRequest}
                allowedRole={Role.STUDENT}
              />
              <AuthRoute
                path="/student-request"
                component={StudentRequest}
                allowedRole={Role.STUDENT}
              />
              {/* <AuthRoute
                path="/calculator/studentanalysis"
                component={CalculatorAnalysisPage}
              />
              <AuthRoute path="/studentanalysis" component={CalculatorPage} /> */}
              <Route
                path="/termsandconditions"
                component={TermsAndConditions}
                exact
              />
              <Route
                path="/refundandpenaltyfee"
                component={RefundAndPenaltyFeeTable}
                exact
              />
              <Route path="/privacy" component={Privacy} exact />

              <Route path="/webinar/:id" component={WebinarDetails} />
              <Route path="/webinar" component={Webinar} exact />

              {/* <AuthRoute
                path="/student/dashboard"
                component={StudentDashboard}
                allowedRole={Role.STUDENT}
              /> */}
              {/* <AuthRoute
                path="/subscription/payment"
                component={SubscriptionPayment}
                allowedRole={Role.STUDENT}
                onlyNonSub={true}
              /> */}
              {/* <Route
                path="/type-test/result/:typeName"
                component={MbtiResultPage}
              /> */}
              {/* <Route path="/type-test" component={MbtiTestPage} /> */}
              {/* <Route path="/pricing" component={PricingPage} exact /> */}
              <Route
                path="/howitworks/student"
                component={StudentHowTo}
                exact
              />
              <Route path="/howitworks/tutor" component={TutorHowTo} exact />

              <AuthRoute
                path="/passwords/reset/:token"
                component={PasswordResetPage}
                guestOnly
              />
              <AuthRoute
                path="/passwords/send"
                component={PasswordSendPage}
                guestOnly
              />
              <AuthRoute
                path="/passwords/find"
                component={FindPasswordPage}
                guestOnly
              />
              <Route
                path="/signup/mentor/pending"
                component={SignupPendingPage}
              />
              <AuthRoute
                path="/signup/mentor"
                component={SignupMentorPage}
                guestOnly
              />
              <Route
                path="/signup/student/verify-email"
                component={SignupStudentValidationPage}
              />
              <Route
                path="/signup/student/pending"
                component={SignupStudentPendingPage}
              />
              <AuthRoute
                path="/signup/student"
                component={SignupStudentPage}
                guestOnly
              />
              <Route path="/signup/error" component={ErrorPage} />
              <AuthRoute path="/signup" component={SignupPage} guestOnly />
              <AuthRoute path="/login" component={SigninPage} guestOnly />

              <Route path="/loading" component={LoadingPage} />
              {authenticated && me ? (
                <Route path="/">
                  <Redirect to="/my/dashboard" />
                </Route>
              ) : (
                <Route path="/" component={HomePage} exact />
              )}

              <Route path="/404" component={Error404} />
              <Redirect from="*" to="/404" />
            </Switch>
          </main>

          <Switch>
            <Route
              // Disable Footer paths
              path={[
                '/signup',
                '/login',
                '/my/chats',
                '/my/mentoring',
                '/my/dashboard',
                // '/mentor/my/chat',
                '/type-test',
                '/mentor/my/mentoring',
              ]}
            />
            <Route path="/" component={Footer} />
          </Switch>
        </div>
      </Elements>
    </>
  );
};
