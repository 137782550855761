export const TOP_MENTORS_IDS: Number[] = [
  // 정리된 Top mentors : https://docs.google.com/spreadsheets/d/1wYtE7ni2pqPKCmrunPBv8bmIepJo34Xwzj4rV3EqZxs/edit#gid=0
  4, //	    Annie L.
  49, //	Lisa A.
  371, //   Maryam G.
  490, //	Chloe W.
  1310, //	Sarah M.
  1311, //  Jeremy H.
  1299, //	Dayana A.
  119, //	Charlie L.
  166, //	William W.
  506, // Jackie R.
  372, // Caroline S.
];

export const LANG_KOR_TOP_MENTORS_IDS: any[] = [
  285, // Marissa V..
  132, //	Ryan D.
  724, // Philip L.
  372, // Caroline S.
];
