import { Pagination } from '@src/components/Pagination';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { Dropdown } from '@src/components/Dropdown';
import { Icon } from '@src/components/icons';
import { Checkbox } from '@src/components/Checkbox';
import { FilterDropdown } from '@src/components/FilterDropdown';
import { ToastContainer, toast, ToastContentProps } from 'react-toastify';
import { useEffect, useState } from 'react';
import * as Icons from '@heroicons/react/24/outline';
import { BreadCrumbs } from '@src/components/BreadCrumbs';
import { Collapsible } from '@src/components/Collapsible';
import { useQuery } from 'react-query';
import { fetcher } from '@src/plugins/react-query';
import * as Type from '@src/types';
import moment from 'moment';
import {
  MomentFormat,
  utcToLocalFormat,
  utcToLocalFromNow,
} from '@src/plugins/moment';
import { useAuth, useQueryString } from '@src/hooks';
import { RadioGroup } from '@headlessui/react';
import { ToggleSwitch } from '@src/components/ToggleSwitch';
import { api } from '@src/plugins/axios';
import { HiddenHeadingMeta } from '@src/components/HiddenHeadingMeta';
import { ReactHelmet } from '@src/components/ReactHelmet';

const Msg = ({ closeToast, toastProps }: Partial<ToastContentProps>) => {
  return (
    <div>
      {`Success! Your Application for {{Sudent.FirstName}} has been submitted. `}
      <button onClick={closeToast} className="underline text-blue">
        View Application
      </button>
    </div>
  );
};

const breadCrumbsPages = [{ name: 'Jobs', path: '/my/dashboard/jobs' }];

export const Jobs = () => {
  const { authenticated } = useAuth();
  const { data: me } = useQuery<Type.User>('/users/me', fetcher, {
    enabled: !!authenticated,
  });

  const [order, setOrder] = useState<Type.OrderType>(Type.OrderType.DESC);
  const history = useHistory();
  const location = useLocation();
  const queryString = useQueryString({ sort: order });
  const [mySubjectIds, setMySubjectIds] = useState<number[]>([]);

  const { data: mentorSubjects, isFetching: mySubjectIsFetching } = useQuery<
    any[]
  >(`/v2/subject-link?mentorId=${Number(me?.id)}`, fetcher, {
    enabled: !!me?.id,
    onSuccess(data) {
      const result = [];
      for (let i = 0; i < data.length; i++) {
        if (data[i].subject.isHidden === false) {
          result.push(data[i].subjectId);
        }
      }
      setMySubjectIds(result);
      if (selectedSubjects?.length === 0) {
        setSelectedSubjects(result);
      }
    },
  });

  const [allSubjectIds, setAllSubjectIds] = useState<number[]>([]);

  const { data: subjectsItems } = useQuery<Type.Subject[]>(
    `/v2/subjects`,
    fetcher,
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      onSuccess(data) {
        const result = [];
        for (let i = 0; i < data.length; i++) {
          result.push(data[i].id);
        }
        setAllSubjectIds(result);
      },
    }
  );

  const [selectedSubjectLabel, setSelectedSubjectLabel] =
    useState<string>('My Subjects');
  const [selectedSubjects, setSelectedSubjects] = useState<number[]>([]);

  const { data: JobsItems, isFetching: JobIsFetching } = useQuery<
    Type.Paginated<Type.JobType>
  >(`/v2/jobs${queryString}&subjectIds=${selectedSubjects}`, fetcher, {
    enabled: !!selectedSubjects.length && !!me?.id,
    keepPreviousData: true,
    refetchOnWindowFocus: false,
  });

  const alertSuccess = () => {
    toast.success(<Msg />);
  };

  useEffect(() => {
    alertSuccess();
  }, []);

  function classNames(...classes: string[]) {
    return classes.filter(Boolean).join(' ');
  }

  const filterPanel = (
    <div className="divide-y">
      <div className="py-4 space-y-2">
        <RadioGroup
          value={selectedSubjects}
          onChange={(value: any) => {
            setSelectedSubjectLabel(value.text);
            setSelectedSubjects(value.subjectIds);
            const params = new URLSearchParams({ page: '1', limit: '10' });
            history.replace({
              pathname: location.pathname,
              search: params.toString(),
            });
          }}
          className="divide-y"
        >
          <div className="mb-4 grid grid-cols-1 gap-y-3 sm:gap-x-4">
            <RadioGroup.Option
              key={`my_subject_option`}
              value={{ text: 'My Subjects', subjectIds: mySubjectIds }}
              className={({ checked, active }) =>
                classNames(
                  'relative flex cursor-pointer focus:outline-none text-xs px-4'
                )
              }
            >
              {({ checked, active }) => (
                <>
                  <span className="flex flex-1">
                    <span className="flex flex-col">
                      <RadioGroup.Label
                        as="span"
                        className="block text-xs text-gray-700"
                      >
                        My Subjects
                      </RadioGroup.Label>
                    </span>
                  </span>
                </>
              )}
            </RadioGroup.Option>
            <RadioGroup.Option
              key={`my_subject_option`}
              value={{ text: 'All Subjects', subjectIds: allSubjectIds }}
              className={({ checked, active }) =>
                classNames(
                  'relative flex cursor-pointer focus:outline-none text-xs px-4'
                )
              }
            >
              {({ checked, active }) => (
                <>
                  <span className="flex flex-1">
                    <span className="flex flex-col">
                      <RadioGroup.Label
                        as="span"
                        className="block text-xs text-gray-700"
                      >
                        All Subjects
                      </RadioGroup.Label>
                    </span>
                  </span>
                </>
              )}
            </RadioGroup.Option>
          </div>

          <div>
            {/* <RadioGroup.Label className="text-sm font-medium text-gray-900">
              Subjects
            </RadioGroup.Label> */}
            <div className="mt-4 grid grid-cols-1 gap-y-3 sm:gap-x-4">
              {subjectsItems?.map((subject) => (
                <RadioGroup.Option
                  key={subject.id}
                  value={{ text: subject.title, subjectIds: [subject.id] }}
                  className={({ checked, active }) =>
                    classNames(
                      'relative flex cursor-pointer focus:outline-none text-xs px-4'
                    )
                  }
                >
                  {({ checked, active }) => (
                    <>
                      <span className="flex flex-1">
                        <span className="flex flex-col">
                          <RadioGroup.Label
                            as="span"
                            className="block text-xs text-gray-700"
                          >
                            {subject.title}
                          </RadioGroup.Label>
                        </span>
                      </span>
                    </>
                  )}
                </RadioGroup.Option>
              ))}
            </div>
          </div>
        </RadioGroup>
      </div>
    </div>
  );

  const filterSection = () => {
    return (
      <div>
        <div
          date-rangepicker=""
          className="flex items-center gap-4 justify-between flex-wrap"
        >
          <div className="flex items-center space-x-4 ">
            <div className="min-w-[200px]">
              <FilterDropdown
                //   checkedLength={tagIds.length - 1}
                isRadioButton
                placeholder={selectedSubjectLabel}
                className="w-full lg:max-w-[300px]  !mt-0"
              >
                {filterPanel}
              </FilterDropdown>
            </div>
            {/* <button className="group bg-brand-blue hover:brightness-105 rounded-lg py-2.5 px-3 text-white h-10">
              <ArrowPathIcon className="wh-4 transition duration-300 group-hover:rotate-90" />
            </button> */}
          </div>
          <Dropdown placeholder="sort">
            <button className="flex space-x-2 items-center text-xs bg-white relative w-full border border-gray-200 rounded-md shadow-sm px-3 h-10 text-left cursor-pointer focus:outline-none focus:ring-1 focus:ring-brand-1 focus:border-brand-1">
              <div>{order === Type.OrderType.DESC ? 'Newest' : 'Oldest'}</div>
              <Icon.ChevronDown className="wh-4" />
            </button>
            <Dropdown.View className="right-0 rounded-md  flex flex-col shadow-md overflow-hidden text-xs">
              <button
                onClick={() => setOrder(Type.OrderType.DESC)}
                className="hover:bg-gray-100 text-black px-6 py-3 text-left"
              >
                Newest
              </button>
              <button
                onClick={() => setOrder(Type.OrderType.ASC)}
                className="hover:bg-gray-100 text-black px-6 py-3 text-left"
              >
                Oldest
              </button>
            </Dropdown.View>
          </Dropdown>
        </div>
      </div>
    );
  };

  const NoResultSection = () => {
    return (
      <div className="flex flex-col items-center py-8">
        <Icons.InboxIcon className="wh-8 text-gray-600" />
        <h3 className="mt-2 text-base font-medium text-gray-900">
          Sorry, no jobs fit your current filters
        </h3>
        <p className="mt-1 text-sm ">
          Try adjusting your filters or check back later.
        </p>
      </div>
    );
  };

  const NotAllowedToApplySection = () => {
    return (
      <div className="mt-4 mx-4 rounded-md bg-yellow-50 p-4">
        <div className="flex">
          <div className="flex-shrink-0">
            <Icons.ExclamationTriangleIcon
              className="h-5 w-5 text-yellow-400"
              aria-hidden="true"
            />
          </div>
          <div className="ml-3">
            <h3 className="text-sm font-medium text-yellow-800">
              You're not qualified to apply to this job at this time.
            </h3>
          </div>
        </div>
      </div>
    );
  };

  const AlreadyAppliedBanner = () => {
    return (
      <div className="mt-4 mx-4 rounded-md bg-green-50 p-4">
        <div className="flex">
          <div className="flex-shrink-0">
            <Icons.CheckCircleIcon
              className="h-5 w-5 text-green-400"
              aria-hidden="true"
            />
          </div>
          <div className="ml-3">
            <h3 className="text-sm font-medium text-green-800">
              This is a job you have already applied for.
            </h3>
          </div>
        </div>
      </div>
    );
  };

  const notAllowedSection = () => {
    return (
      <div className="p-4 flex flex-col items-center">
        <Icons.NoSymbolIcon
          className="wh-8 text-gray-500 m-auto"
          aria-hidden="true"
        />
        <h3 className="mt-2 text-lg font-medium text-gray-900">
          You're currently not accepting new students 🥲
        </h3>
        <p className="mt-1 text-sm text-gray-500">
          Show students you're open to work
        </p>
        <div className="mt-6">
          <ToggleSwitch
            checked={me?.chatState === Type.ChatState.AVAILABLE ? true : false}
            onChange={async (checked: boolean) => {
              await api
                .patch(`/users/update-mentor-availability`, {
                  availability: checked,
                })
                .then(() => {
                  alert('Successfully saved!');
                });
            }}
          />
        </div>
      </div>
    );
  };

  const qualifiedSection = () => {
    return (
      <div className="rounded-md bg-green-50 p-4">
        <div className="flex">
          <div className="flex-shrink-0">
            <Icons.CheckBadgeIcon
              className="h-5 w-5 text-green-400"
              aria-hidden="true"
            />
          </div>
          <div className="ml-3">
            <p className="text-sm font-medium text-green-800">
              You're qualified to teach
            </p>
            <div className="flex flex-wrap gap-2 mt-4">
              {mentorSubjects
                ?.filter((i) => i.subject.isHidden === false)
                ?.map((item) => {
                  return (
                    <span
                      className={`font-semibold text-[12px] bg-gray-200 rounded-md text-center px-3 py-1 text-[#64789A]`}
                      style={{ height: 'fit-content' }}
                    >
                      {item.subject.title}
                    </span>
                  );
                })}
            </div>
          </div>
        </div>
      </div>
    );
  };

  const contentsSection = () => {
    return (
      <>
        {me?.chatState === Type.ChatState.AVAILABLE ? (
          <>
            <h2 className="text-base">
              <strong>{JobsItems?.total ?? '0'}</strong> Tutoring Jobs
            </h2>

            <div className="overflow-hidden bg-white shadow sm:rounded-md">
              <ul className="divide-y divide-gray-200">
                {JobsItems?.items.map((item) => (
                  <li key={`job_item_${item.id} hover:bg-gray-50`}>
                    <div className="text-left block w-full">
                      {/* start Not allowed to apply section */}
                      {!mySubjectIds.includes(item.subjectId) &&
                        NotAllowedToApplySection()}
                      {/* end Not allowed to apply section */}

                      {/* start Already applied banner */}
                      {item.jobSubmits.length > 0 && AlreadyAppliedBanner()}
                      {/* end Already applied banner */}
                      <div className="px-4 py-4 sm:px-6 w-full">
                        <div className="sm:flex sm:justify-between items-center justify-between">
                          <Link
                            to={`/my/dashboard/jobs/${item.id}`}
                            className="truncate text-base font-medium text-indigo-600"
                          >
                            {
                              subjectsItems?.find(
                                (o) => o.id === item.subjectId
                              )?.title
                            }
                          </Link>
                          <div className="mt-2 flex items-center text-sm text-gray-500 sm:mt-0">
                            <div className="flex items-center gap-2">
                              <div className="text-xs">Applications</div>
                              <span className="inline-flex items-center rounded-full bg-gray-200 px-3 py-0.5 text-xs font-medium text-gray-800 mr-3">
                                {`${item.jobSubmitCount} / 5`}
                              </span>
                            </div>

                            <Icons.ClockIcon
                              className="mr-1.5 wh-4 flex-shrink-0 text-gray-400 "
                              aria-hidden="true"
                            />
                            <p>
                              <time dateTime={item.createdAt}>
                                {utcToLocalFromNow(item.createdAt)}
                              </time>
                            </p>
                          </div>
                        </div>
                        <div className="mt-2 sm:flex sm:justify-between">
                          <div className="sm:flex">
                            <p className="mr-1.5 inline-flex rounded-full bg-green-100 px-2 text-xs font-semibold leading-5 text-green-800 items-center">
                              <Icons.AcademicCapIcon
                                className="mr-1.5 wh-4 flex-shrink-0"
                                aria-hidden="true"
                              />
                              {
                                subjectsItems?.find(
                                  (o) => o.id === item.subjectId
                                )?.title
                              }
                            </p>
                            <p className="flex items-center text-sm text-gray-500">
                              <Icons.UserIcon
                                className="mr-1.5 wh-4 flex-shrink-0 text-gray-400"
                                aria-hidden="true"
                              />
                              {item.writer.firstName}
                            </p>
                            {/* {item.isOnline && (
                              <p className="mt-2 flex items-center text-sm text-gray-500 sm:mt-0 sm:ml-4">
                                <MapPinIcon
                                  className="mr-1.5 wh-4 flex-shrink-0 text-gray-400"
                                  aria-hidden="true"
                                />
                                Online
                              </p>
                            )} */}
                          </div>
                        </div>
                        <div className="mt-2 text-sm text-left max-w-[700px] truncate-2-lines">
                          {item.description}
                        </div>
                        <Collapsible
                          closedButton={
                            <>
                              <Icons.PlusSmallIcon className="wh-4" />
                              <p>Show details</p>
                            </>
                          }
                          openedButton={
                            <>
                              <Icons.MinusSmallIcon className="wh-4" />
                              <p>Hide details</p>
                            </>
                          }
                          btnClassName="mt-3 text-sm font-medium text-brand-blue hover:text-indigo-500 flex items-center gap-1 border py-1.5 px-2.5 rounded-md shadow-xs"
                        >
                          <div className="mt-2 overflow-hidden bg-white shadow sm:rounded-lg text-left">
                            <div className="border-t border-gray-200 px-4 py-5 sm:px-6">
                              <dl className="grid grid-cols-1 gap-x-4 gap-y-6 sm:grid-cols-2">
                                <div className="sm:col-span-2">
                                  <dt className="text-sm font-medium text-gray-500">
                                    Availability
                                  </dt>
                                  <dd className="mt-1 text-sm text-gray-900 sm:col-span-1 space-y-3">
                                    <dt className="text-sm flex items-center gap-2">
                                      <Icons.CalendarIcon
                                        className="wh-4 flex-shrink-0"
                                        aria-hidden="true"
                                      />
                                      <span>
                                        {utcToLocalFormat(
                                          item.firstRequestTime,
                                          MomentFormat.YYYYMMDDhmma
                                        )}
                                        {` `}
                                        {moment
                                          .tz(moment.tz.guess())
                                          .zoneAbbr()}
                                      </span>
                                    </dt>
                                    {item?.secondRequestTime && (
                                      <dt className="text-sm flex items-center gap-2">
                                        <Icons.CalendarIcon
                                          className="wh-4 flex-shrink-0"
                                          aria-hidden="true"
                                        />
                                        <span>
                                          {utcToLocalFormat(
                                            item?.secondRequestTime,
                                            MomentFormat.YYYYMMDDhmma
                                          )}
                                          {` `}
                                          {moment
                                            .tz(moment.tz.guess())
                                            .zoneAbbr()}
                                        </span>
                                      </dt>
                                    )}
                                    {item?.thirdRequestTime && (
                                      <dt className="text-sm flex items-center gap-2">
                                        <Icons.CalendarIcon
                                          className="wh-4 flex-shrink-0"
                                          aria-hidden="true"
                                        />
                                        <span>
                                          {utcToLocalFormat(
                                            item?.thirdRequestTime,
                                            MomentFormat.YYYYMMDDhmma
                                          )}
                                          {` `}
                                          {moment
                                            .tz(moment.tz.guess())
                                            .zoneAbbr()}
                                        </span>
                                      </dt>
                                    )}
                                  </dd>
                                </div>

                                {mySubjectIds.includes(item.subjectId) &&
                                  item.jobSubmits.length === 0 && (
                                    <div className="sm:col-span-2 justify-self-end">
                                      <Link
                                        to={`/my/dashboard/jobs/application/${item.id}`}
                                        className="inline-flex items-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                                      >
                                        Apply
                                      </Link>
                                    </div>
                                  )}
                              </dl>
                            </div>
                          </div>
                        </Collapsible>
                      </div>
                    </div>
                  </li>
                ))}
              </ul>
            </div>
          </>
        ) : (
          notAllowedSection()
        )}
      </>
    );
  };

  return (
    <>
      <HiddenHeadingMeta text="Jobs | Linkstory" />
      <ReactHelmet keywords="job, jobs" title="Jobs | Linkstory" />
      <BreadCrumbs
        homePath="/my/dashboard"
        breadCrumbsPages={breadCrumbsPages}
      />
      {qualifiedSection()}
      <div className="space-y-4  border rounded-md  ">
        <section className="whitespace-pre-line">
          <div className="border-b border-gray-200 bg-white rounded-t md  w-full px-4 py-5 sm:px-6">
            <div className="-ml-4 -mt-2 flex flex-wrap items-center justify-between sm:flex-nowrap gap-2">
              <div className="ml-4 mt-2 flex gap-2 items-center">
                <Icons.BriefcaseIcon className="wh-6 flex-shrink-0 self-center text-xs" />
                <h3 className="text-lg font-medium leading-6 text-gray-900">
                  Jobs
                </h3>
              </div>
              <div className="ml-4 mt-2 flex-shrink-0">
                <Link
                  to={'/my/dashboard/jobs/applied'}
                  className="inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 gap-1"
                >
                  <Icons.ListBulletIcon className="wh-4 flex-shrink-0 self-center text-xs" />
                  <p>My Applications</p>
                </Link>
              </div>
            </div>
          </div>
          <div className="p-4 md:p-6 space-y-4 bg-white">
            {filterSection()}
            {JobsItems?.items.length
              ? contentsSection()
              : !JobIsFetching && NoResultSection()}
          </div>
          {JobsItems && me?.chatState === Type.ChatState.AVAILABLE && (
            <Pagination className="flex flex-wrap bg-white rounded-b-md">
              <Pagination.Label
                count={JobsItems?.items.length}
                total={JobsItems?.total}
              />
              <Pagination.Nav
                basePath="/my/dashboard/jobs/"
                total={JobsItems?.total}
              />
            </Pagination>
          )}
        </section>
      </div>
    </>
  );
};
