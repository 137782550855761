import { CardElement } from '@stripe/react-stripe-js';
import { FC, FormEvent, useState, useEffect } from 'react';
import { useCreateCreditCard, useUpdateCreditCard } from '../hooks';
import { Button } from './Button';
import { Icon } from './icons';
import ClickAwayListener from 'react-click-away-listener';
import loadingLogo from '../assets/img/loading.gif';
interface CreditCardPopUpProps {
  open: boolean;
  edit: boolean;
  card?: any;
  onClose: () => void;
}

export const CreditCardPopUp: FC<CreditCardPopUpProps> = ({
  onClose,
  open,
  card,
  edit,
}) => {
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    //  if route opens block body scroll
    if (open) {
      document.body.style.overflow = 'hidden';
    } else {
      return () => {
        document.body.style.overflow = 'auto';
      };
    }
  }, [open]);

  const handleOnClose = () => {
    document.body.style.overflow = 'auto';
    setLoading(false);
    onClose();
  };

  const [clicked, setClicked] = useState(false);
  const { createCreditCard } = useCreateCreditCard();
  const { updateCreditCard } = useUpdateCreditCard(card);

  const handleCreateCreditCard = (e: FormEvent) => {
    setLoading(true);
    createCreditCard(e, onClose)
      .then(() => setLoading(false))
      .catch(() => setLoading(false));
  };

  const handleUpdateCreditCard = (e: FormEvent) => {
    setLoading(true);
    updateCreditCard(e, onClose)
      .then(() => setLoading(false))
      .catch(() => setLoading(false));
  };

  if (!open) return <></>;
  return (
    <div className="z-50 fixed inset-0 bg-littleblack flex items-center justify-center px-4">
      <ClickAwayListener onClickAway={handleOnClose}>
        <div className="w-full max-w-md flex flex-col  bg-white rounded-xl overflow-hidden p-6 space-y-4">
          <div className="flex justify-end">
            <button className="" onClick={handleOnClose}>
              <Icon.X />
            </button>
          </div>
          <form
            onSubmit={edit ? handleUpdateCreditCard : handleCreateCreditCard}
            className="p-4 border rounded-md my-8"
          >
            <CardElement />
          </form>
          <div>
            {loading ? (
              <div className="button hover:brightness-110 bg-brand-1 text-white rounded-full w-full flex items-center justify-center">
                <p>loading</p>
                <img src={loadingLogo} className=" wh-9" alt="loading..." />
              </div>
            ) : (
              <Button
                text={edit ? 'Save' : 'Confirm card'}
                className={`${
                  loading && 'bg-black'
                } bg-brand-1 text-white rounded-full w-full `}
                onClick={(e) =>
                  edit ? handleUpdateCreditCard(e) : handleCreateCreditCard(e)
                }
                disabled={loading}
              />
            )}
          </div>
        </div>
      </ClickAwayListener>
    </div>
  );
};
