import React, { FC, ReactElement, useState } from 'react';

export interface CollapsibleProps {
  closedButton: ReactElement<any, any>;
  openedButton: ReactElement<any, any>;
  btnSiblings?: ReactElement<any, any>;
  btnClassName?: string;
  wrapperClassName?: string;
}

export const Collapsible: FC<CollapsibleProps> = ({
  closedButton,
  openedButton,
  btnSiblings,
  btnClassName,
  wrapperClassName,
  children,
}) => {
  const [open, setOPen] = useState(false);
  return (
    <div className={`${wrapperClassName}`}>
      <div className="flex gap-2 justify-between items-center">
        <button className={`${btnClassName}`} onClick={() => setOPen(!open)}>
          {open ? openedButton : closedButton}
        </button>
        {btnSiblings}
      </div>

      {open && children}
    </div>
  );
};
