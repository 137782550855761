import moment from 'moment';
// import 'moment/locale/ko';

// example utc: '2021-05-28T08:21:28.475Z'

// moment.updateLocale('en', {
//   relativeTime: {
//     future: 'in %s',
//     past: '%s',
//     s: 'a few seconds',
//     ss: '%d h',
//     m: 'a minute',
//     mm: '%d m',
//     h: 'an hour',
//     hh: '%d h',
//     d: 'a day',
//     dd: '%d d',
//     M: 'a month',
//     MM: '%d m',
//     y: 'a year',
//     yy: '%d y',
//   },
// });

export enum MomentFormat {
  ddddLL = 'dddd LL',
  // Thursday July 21, 2022

  LL = 'LL',
  // Thursday July 21, 2022

  ddddLLHmma = 'dddd LL, h:mm a',
  // Thursday July 21, 2022, 5:21 오후

  /** 17:21 */
  HHmm = 'HH:mm',
  /** 2021년 5월 28일 (금) */
  LLdd = 'LL (dd)',
  /** 2021년 5월 28일 17:21 */
  LLHHmm = 'LL HH:mm',
  /** 2021.05.28 5:21 오후 */
  YYYYMMDDhmma = 'YYYY.MM.DD h:mm A',
  /** 2021.05.28 17:21 */
  YYYYMMDDHmm = 'YYYY.MM.DD H:mm',
  /** 2021.05.28 17:21:22 */
  YYYYMMDDHmmss = 'YYYY.MM.DD H:mm:ss',
  /** 2021.05.28 */
  YYYYMMDD = 'YYYY.MM.DD',
  /** 05.28 5:21 오후 */
  MMDDhmma = 'MM.DD h:mm a',
  /** 오후 */
  a = 'a',
  /** 5:21 */
  hmm = 'h:mm',
  /** 5:21 오후 */
  hmma = 'h:mm a',
}

export function utcToLocalFormat(utc: string, format?: MomentFormat) {
  return moment.utc(utc).local().format(format);
}

export function utcToDiffMinutes(utcStartTime: string, utcEndTime: string) {
  return moment
    .duration(
      moment.utc(utcEndTime).local().diff(moment.utc(utcStartTime).local())
    )
    .asMinutes();
}

/** 3시간 전 */
export function utcToLocalFromNow(utc: string) {
  return moment.utc(utc).local().fromNow();
}
