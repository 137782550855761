import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendar } from '@fortawesome/free-regular-svg-icons';
import { Pagination } from '@src/components/Pagination';
import { Link, useHistory, useParams } from 'react-router-dom';
import { AttachedFiles } from '@src/components/AttachedFiles';
import { BreadCrumbs } from '@src/components/BreadCrumbs';
import {
  BriefcaseIcon,
  CalendarIcon,
  CheckIcon,
  ClockIcon,
  CurrencyDollarIcon,
  ListBulletIcon,
  UsersIcon,
} from '@heroicons/react/24/outline';
import { LessonState, Paginated, SessionType } from '@src/types';
import { fetcher } from '@src/plugins/react-query';
import { useQuery } from 'react-query';
import { EmptyState } from '@src/components/EmptyState';
import { Avatar } from '@src/components/Avatar';
import { getS3Uri } from '@src/utils';
import {
  MomentFormat,
  utcToDiffMinutes,
  utcToLocalFormat,
} from '@src/plugins/moment';
import { StarIcon } from '@heroicons/react/20/solid';
import { HiddenHeadingMeta } from '@src/components/HiddenHeadingMeta';
import { ReactHelmet } from '@src/components/ReactHelmet';

export const InCompleteSessions = () => {
  const { push, goBack } = useHistory();
  const { id } = useParams<{ id: string }>();

  const { data: recentSessionItems, isFetching: recentFetching } = useQuery<
    Paginated<SessionType>
  >(
    `/lessons/my/histories?status=${LessonState.UPCOMING},${LessonState.INCOMPLETE}`,
    fetcher
  );

  const sessionsItems = [
    {
      id: 1,
      title: 'Extracurricular Advice',
      subject: 'Extracurricular',
      closeDate: '2020-01-07',
      closeDateFull: 'January 7, 2020',
      time: '11:30 PM',
      price: '$6.56',
      student: {
        name: 'Dries Vincent',
        email: 'dries.vincent@example.com',
        imageUrl:
          'https://images.unsplash.com/photo-1506794778202-cad84cf45f1d?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
      },
    },
    {
      id: 2,
      title: 'Preliminary session',
      subject: 'Orientation',
      closeDate: '2020-01-07',
      closeDateFull: 'January 7, 2020',
      time: '11:30 PM',
      price: '$6.56',
      student: {
        name: 'Whitney Francis',
        email: 'whitney.francis@example.com',
        imageUrl:
          'https://images.unsplash.com/photo-1517365830460-955ce3ccd263?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
      },
    },
    {
      id: 3,
      title: 'Supplemental Essay Review',
      subject: 'Essay',
      closeDate: '2020-01-14',
      closeDateFull: 'January 14, 2020',
      time: '11:30 PM',
      price: '$6.56',
      student: {
        name: 'Emily Selman',
        email: 'emily.selman@example.com',
        imageUrl:
          'https://images.unsplash.com/photo-1502685104226-ee32379fefbe?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
      },
    },
  ];

  const steps = [
    { id: '01', name: 'Select session', status: 'current' },
    { id: '02', name: 'Enter details', status: 'upcoming' },
    { id: '03', name: 'Review & Submit', status: 'upcoming' },
  ];

  const StepsSection = () => {
    return (
      <nav aria-label="Progress">
        <ol className="bg-white divide-y divide-gray-300 rounded-md border border-gray-300 md:flex md:divide-y-0">
          {steps.map((step, stepIdx) => (
            <li key={step.name} className="relative md:flex md:flex-1">
              {step.status === 'complete' ? (
                <div className="group flex w-full items-center">
                  <span className="flex items-center px-6 py-4 text-sm font-medium">
                    <span className="flex h-10 w-10 flex-shrink-0 items-center justify-center rounded-full bg-indigo-600 group-hover:bg-indigo-800">
                      <CheckIcon
                        className="h-6 w-6 text-white"
                        aria-hidden="true"
                      />
                    </span>
                    <span className="ml-4 text-sm font-medium text-gray-900">
                      {step.name}
                    </span>
                  </span>
                </div>
              ) : step.status === 'current' ? (
                <div
                  className="flex items-center px-6 py-4 text-sm font-medium"
                  aria-current="step"
                >
                  <span className="flex h-10 w-10 flex-shrink-0 items-center justify-center rounded-full border-2 border-indigo-600">
                    <span className="text-indigo-600">{step.id}</span>
                  </span>
                  <span className="ml-4 text-sm font-medium text-indigo-600">
                    {step.name}
                  </span>
                </div>
              ) : (
                <div className="group flex items-center">
                  <span className="flex items-center px-6 py-4 text-sm font-medium">
                    <span className="flex h-10 w-10 flex-shrink-0 items-center justify-center rounded-full border-2 border-gray-300 group-hover:border-gray-400">
                      <span className="text-gray-500 group-hover:text-gray-900">
                        {step.id}
                      </span>
                    </span>
                    <span className="ml-4 text-sm font-medium text-gray-500 group-hover:text-gray-900">
                      {step.name}
                    </span>
                  </span>
                </div>
              )}

              {stepIdx !== steps.length - 1 ? (
                <>
                  {/* Arrow separator for lg screens and up */}
                  <div
                    className="absolute top-0 right-0 hidden h-full w-5 md:block"
                    aria-hidden="true"
                  >
                    <svg
                      className="h-full w-full text-gray-300"
                      viewBox="0 0 22 80"
                      fill="none"
                      preserveAspectRatio="none"
                    >
                      <path
                        d="M0 -2L20 40L0 82"
                        vectorEffect="non-scaling-stroke"
                        stroke="currentcolor"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </div>
                </>
              ) : null}
            </li>
          ))}
        </ol>
      </nav>
    );
  };

  const PastSessionsSection = () => {
    return (
      <>
        <div className=" border-gray-200 border-b bg-white px-4 py-5 sm:px-6">
          <div className="-ml-4 -mt-2 flex flex-wrap items-center justify-between sm:flex-nowrap gap-2">
            <div className="ml-4 mt-2 flex gap-2 items-center">
              <BriefcaseIcon className="wh-6 flex-shrink-0 self-center text-xs" />
              <h3 className="text-lg font-medium leading-6 text-gray-900">
                Unsubmitted sessions
              </h3>
            </div>
          </div>
        </div>
        <ul className="divide-y divide-gray-200">
          {recentSessionItems?.items
            .sort((a: any, b: any) => {
              return b.startedAt > a.startedAt
                ? 0
                : a.startedAt > b.startedAt
                ? -1
                : 1;
            })
            .map((session) => (
              <li key={session.id}>
                <div className="block hover:bg-gray-50 w-full">
                  <div className="flex items-center px-4 py-4 sm:px-6 w-full justify-between">
                    <div className="min-w-0 flex-1 sm:flex sm:items-center sm:justify-between">
                      <div className="truncate">
                        <div className="flex text-sm flex-wrap">
                          <div className="flex items-center gap-2">
                            <Link
                              to={`/my/dashboard/session/${session.id}`}
                              className="truncate text-base font-medium text-indigo-600 hover:text-brand-1"
                            >
                              {session.title !== ''
                                ? session.title
                                : 'Untitled'}
                            </Link>
                            <span className="inline-flex items-center rounded-full bg-green-100 px-2.5 py-0.5 text-xs font-medium text-green-800">
                              {utcToDiffMinutes(
                                session.startedAt,
                                session.endedAt
                              )}{' '}
                              mins
                            </span>
                          </div>
                          <div className="hidden mt-2 xl:flex items-start space-x-3 xl:mt-0 xl:ml-3 xl:border-l xl:border-gray-400 xl:border-opacity-50 xl:pl-3 text-gray-500">
                            <dt className="flex items-center text-sm gap-1 ">
                              <CurrencyDollarIcon
                                className="wh-4  text-gray-400"
                                aria-hidden="true"
                              />
                              <dd>
                                {session.price &&
                                  Number(session.price).toFixed(2)}
                              </dd>
                            </dt>
                          </div>
                        </div>
                        <div className="mt-2 flex gap-2">
                          <div className="flex items-center text-sm text-gray-500">
                            <CalendarIcon
                              className="mr-1.5 wh-4 flex-shrink-0 text-gray-400"
                              aria-hidden="true"
                            />
                            <p>
                              <time dateTime={session.startedAt}>
                                {utcToLocalFormat(
                                  session.startedAt,
                                  MomentFormat.LL
                                )}
                              </time>
                            </p>
                          </div>
                          <div className="flex items-center text-sm text-gray-500 gap-1.5">
                            <ClockIcon
                              className="wh-4 flex-shrink-0 text-gray-400"
                              aria-hidden="true"
                            />
                            <p>
                              <time dateTime={session.startedAt}>
                                {utcToLocalFormat(
                                  session.startedAt,
                                  MomentFormat.hmma
                                )}
                              </time>
                            </p>
                          </div>
                        </div>
                        <div className="mt-2 xl:hidden flex gap-2">
                          <div className="flex items-start space-x-3 text-gray-500">
                            <dt className="flex items-center text-sm gap-1 ">
                              <CurrencyDollarIcon
                                className="wh-4  text-gray-400"
                                aria-hidden="true"
                              />
                              <dd>
                                {session.price &&
                                  Number(session.price).toFixed(2)}
                              </dd>
                            </dt>
                          </div>
                        </div>
                      </div>
                      <div className="mt-4 flex-shrink-0 sm:mt-0 sm:ml-5">
                        <div className="flex overflow-hidden items-center gap-3 ">
                          <p className="text-sm text-gray-500">
                            {session.student.firstName
                              ? session.student.firstName +
                                ' ' +
                                session.student.lastName
                              : 'N/A'}
                          </p>
                          <Avatar
                            src={getS3Uri(session.student?.avatar)}
                            alt="avatar"
                            className="!wh-8 object-cover rounded-full"
                          />
                        </div>
                      </div>
                    </div>

                    {session.status === LessonState.INCOMPLETE ||
                    session.status === LessonState.UPCOMING ? (
                      <div className="ml-5 flex-shrink-0">
                        <Link
                          to={`/my/dashboard/sessions/submit/${session.id}`}
                          type="button"
                          className="inline-flex items-center rounded border border-transparent bg-indigo-100 px-2.5 py-1.5 text-xs font-medium text-indigo-700 hover:bg-indigo-200 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                        >
                          Submit
                        </Link>
                      </div>
                    ) : session.lessonReviews[0]?.rating ? (
                      <div className="ml-5 flex gap-1 items-center text-sm">
                        <StarIcon className="wh-4 text-brand-orange" />
                        {session.lessonReviews[0]?.rating}
                      </div>
                    ) : (
                      <div className="ml-5 flex-shrink-0 text-xs text-gray-500">
                        NO RATING
                      </div>
                    )}
                  </div>
                </div>
              </li>
            ))}

          {!recentSessionItems?.total && !recentFetching && (
            <EmptyState
              className="py-12"
              title="No recent session"
              subTitle="There are no recent sessions."
            />
          )}
        </ul>
      </>
    );
  };

  const StudentListSection = () => {
    return (
      <>
        <div className=" border-gray-200 border-b bg-white px-4 py-5 sm:px-6">
          <div className="-ml-4 -mt-2 flex flex-wrap items-center justify-between sm:flex-nowrap gap-2">
            <div className="ml-4 mt-2 flex gap-2 items-center">
              <UsersIcon className="wh-6 flex-shrink-0 self-center text-xs" />
              <h3 className="text-lg font-medium leading-6 text-gray-900">
                My Students
              </h3>
            </div>
          </div>
        </div>
        <ul className="divide-y divide-gray-200">
          {sessionsItems.map((session) => (
            <li key={session.id}>
              <div className="block hover:bg-gray-50 w-full">
                <div className="flex items-center px-4 py-4 sm:px-6 w-full justify-between">
                  <div className="min-w-0 flex-1 sm:flex sm:items-center sm:justify-between">
                    <div className="flex items-center space-x-5">
                      <div className="flex-shrink-0">
                        <div className="relative">
                          <img
                            key={session.student.email}
                            className="inline-block h-8 rounded-full ring-2 ring-white"
                            src={session.student.imageUrl}
                            alt={session.student.name}
                          />
                          <span
                            className="absolute inset-0 rounded-full shadow-inner"
                            aria-hidden="true"
                          />
                        </div>
                      </div>
                      {/*
                          Use vertical padding to simulate center alignment when both lines of text are one line,
                          but preserve the same layout if the text wraps without making the image jump around.
                        */}
                      <div className="pt-1.5">
                        <h1 className="text-sm text-gray-800 font-medium">
                          Ricardo Cooper
                        </h1>
                        <p className="text-sm  text-gray-500 flex gap-2 flex-wrap">
                          <span className="">Last lesson :</span>
                          <div className="flex items-center text-sm text-gray-500">
                            <CalendarIcon
                              className="mr-1.5 wh-4 flex-shrink-0 text-gray-400"
                              aria-hidden="true"
                            />
                            <p>
                              <time dateTime={session.closeDate}>
                                {session.closeDateFull} {session.time}
                              </time>
                            </p>
                          </div>
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="ml-5 flex-shrink-0">
                    <Link
                      to={`/my/dashboard/sessions/submit/${session.id}`}
                      className="inline-flex items-center rounded border border-transparent bg-indigo-100 px-2.5 py-1.5 text-xs font-medium text-indigo-700 hover:bg-indigo-200 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                    >
                      Submit
                    </Link>
                  </div>
                </div>
              </div>
            </li>
          ))}
        </ul>
      </>
    );
  };

  return (
    <>
      <HiddenHeadingMeta text="Submit a sesison | Linkstory" />
      <ReactHelmet
        keywords="session, submit"
        title="Submit a session | Linkstory"
      />
      <BreadCrumbs
        homePath="/my/dashboard"
        breadCrumbsPages={[
          {
            name: 'sessions',
            path: `/my/dashboard/sessions/submit`,
          },
        ]}
      />
      <div className="min-w-0 flex-1">
        <h2 className="text-2xl font-bold leading-7 text-gray-900 sm:truncate sm:text-2xl sm:tracking-tight">
          Submit a session
        </h2>
      </div>
      {StepsSection()}
      <div className="space-y-4 bg-white border w-full rounded-md overflow-hidden">
        <section className="whitespace-pre-line">
          {PastSessionsSection()}
        </section>
      </div>
      {/* <div className="space-y-4 bg-white border w-full rounded-md overflow-hidden">
        <section className="whitespace-pre-line">
          {StudentListSection()}
        </section>
      </div> */}
    </>
  );
};
