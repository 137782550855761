import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendar } from '@fortawesome/free-regular-svg-icons';
import { Pagination } from '@src/components/Pagination';
import { useHistory, useParams } from 'react-router-dom';
import { AttachedFiles } from '@src/components/AttachedFiles';
import {
  BriefcaseIcon,
  CalendarIcon,
  ExclamationTriangleIcon,
} from '@heroicons/react/24/outline';
import { BreadCrumbs } from '@src/components/BreadCrumbs';
import { fetcher } from '@src/plugins/react-query';
import { useQuery } from 'react-query';
import { AppliedJobType, JobType, Paginated } from '@src/types';
import { MomentFormat, utcToLocalFormat } from '@src/plugins/moment';
import moment from 'moment';
import { DownloadFiles } from '@src/components/DownloadFiles';
import { HiddenHeadingMeta } from '@src/components/HiddenHeadingMeta';
import { ReactHelmet } from '@src/components/ReactHelmet';

export const JobsAppliedDetails = () => {
  const { push, goBack } = useHistory();
  const { id } = useParams<{ id: string }>();

  const [myApplication, setMyApplication] = useState<AppliedJobType>();

  const { data: AppliedJobsItems } = useQuery<Paginated<AppliedJobType>>(
    `/job-submits/my-submitted`,
    fetcher,
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      onSuccess(data) {
        const filteredData = data.items.find((item) => item.id === Number(id));
        setMyApplication(filteredData);
      },
    }
  );

  const { data: JobItem, isFetching } = useQuery<JobType>(
    `/v2/jobs/${myApplication?.jobId}`,
    fetcher,
    {
      enabled: !!myApplication?.jobId,
      keepPreviousData: true,
    }
  );

  const contentsSection = () => {
    return (
      <>
        <div className="overflow-hidden bg-white shadow rounded-lg">
          <div className=" border-gray-200 bg-white px-4 py-5 sm:px-6">
            <div className="-ml-4 -mt-2 flex flex-wrap items-center justify-between sm:flex-nowrap gap-2">
              <div className="ml-4 mt-2 flex gap-2 items-center">
                <BriefcaseIcon className="wh-6 flex-shrink-0 self-center text-xs" />
                <h3 className="text-lg font-medium leading-6 text-gray-900">
                  Job Details
                </h3>
              </div>
            </div>
          </div>
          <div className="border-t border-gray-200">
            <dl>
              <div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                <dt className="text-sm font-medium text-gray-500">Student</dt>
                <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                  {JobItem?.writer?.firstName}
                </dd>
              </div>
              <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                <dt className="text-sm font-medium text-gray-500">
                  Availability
                </dt>
                <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0 space-y-3">
                  {JobItem?.firstRequestTime && (
                    <dt className="text-sm flex gap-2 items-center">
                      <CalendarIcon
                        className="wh-4 flex-shrink-0"
                        aria-hidden="true"
                      />
                      <div>
                        {utcToLocalFormat(
                          JobItem?.firstRequestTime,
                          MomentFormat.YYYYMMDDhmma
                        )}
                      </div>

                      <div>{moment.tz(moment.tz.guess()).zoneAbbr()}</div>
                    </dt>
                  )}
                  {JobItem?.secondRequestTime && (
                    <dt className="text-sm flex gap-2 items-center">
                      <CalendarIcon
                        className="wh-4 flex-shrink-0"
                        aria-hidden="true"
                      />
                      <div>
                        {utcToLocalFormat(
                          JobItem?.secondRequestTime,
                          MomentFormat.YYYYMMDDhmma
                        )}
                      </div>

                      <div>{moment.tz(moment.tz.guess()).zoneAbbr()}</div>
                    </dt>
                  )}
                  {JobItem?.thirdRequestTime && (
                    <dt className="text-sm flex gap-2 items-center">
                      <CalendarIcon
                        className="wh-4 flex-shrink-0"
                        aria-hidden="true"
                      />
                      <div>
                        {utcToLocalFormat(
                          JobItem?.thirdRequestTime,
                          MomentFormat.YYYYMMDDhmma
                        )}
                      </div>

                      <div>{moment.tz(moment.tz.guess()).zoneAbbr()}</div>
                    </dt>
                  )}
                </dd>
              </div>
              <div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                <dt className="text-sm font-medium text-gray-500">
                  Education level
                </dt>
                <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0 truncate-2-lines">
                  {JobItem?.grade?.title}
                </dd>
              </div>
              <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                <dt className="text-sm font-medium text-gray-500">Subject</dt>
                <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                  {JobItem?.subject?.title}
                </dd>
              </div>
              <div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                <dt className="text-sm font-medium text-gray-500">
                  Description
                </dt>
                <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                  {JobItem?.description}
                </dd>
              </div>
              <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                <dt className="text-sm font-medium text-gray-500">
                  Tell us more
                </dt>
                <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0 space-y-3">
                  {JobItem?.questionAnswers?.map((item) => {
                    return (
                      <div>
                        <h4 className="font-semibold">
                          {` Q. ${item.question.question}`}
                        </h4>
                        <p> {` A. ${item.text}`}</p>
                      </div>
                    );
                  })}
                </dd>
              </div>
              {JobItem?.jobFiles && JobItem?.jobFiles?.length > 0 && (
                <div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                  <dt className="text-sm font-medium text-gray-500">
                    Attachments
                  </dt>
                  <DownloadFiles filesItems={JobItem.jobFiles} />
                </div>
              )}
            </dl>
          </div>
        </div>
      </>
    );
  };

  const applicationSection = () => {
    return (
      <>
        {myApplication && (
          <div className="overflow-hidden bg-white shadow rounded-lg">
            <div className=" border-gray-200 bg-white px-4 py-5 sm:px-6">
              <div className="-ml-4 -mt-2 flex flex-wrap items-center justify-between sm:flex-nowrap gap-2">
                <div className="ml-4 mt-2 flex gap-2 items-center">
                  <BriefcaseIcon className="wh-6 flex-shrink-0 self-center text-xs" />
                  <h3 className="text-lg font-medium leading-6 text-gray-900">
                    Appication Details
                  </h3>
                </div>
              </div>
            </div>
            <div className="border-t border-gray-200">
              <dl>
                <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                  <dt className="text-sm font-medium text-gray-500">
                    Appication date
                  </dt>
                  <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0 space-y-3">
                    <dt className="text-sm flex gap-2 items-center">
                      <CalendarIcon
                        className="wh-4 flex-shrink-0"
                        aria-hidden="true"
                      />
                      <div>
                        {utcToLocalFormat(
                          myApplication?.createdAt,
                          MomentFormat.YYYYMMDDhmma
                        )}
                      </div>

                      <div>{moment.tz(moment.tz.guess()).zoneAbbr()}</div>
                    </dt>
                  </dd>
                </div>
                <div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                  <dt className="text-sm font-medium text-gray-500">
                    Session price
                  </dt>
                  <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                    $ {Number(Number(myApplication?.price).toFixed(2))}
                  </dd>
                </div>

                <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                  <dt className="text-sm font-medium text-gray-500">Message</dt>
                  <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                    {myApplication.message}
                  </dd>
                </div>
              </dl>
            </div>
          </div>
        )}
      </>
    );
  };

  return (
    <>
      <HiddenHeadingMeta text="Applied job details | Linkstory" />
      <ReactHelmet
        keywords="job, details"
        title="Applied job details | Linkstory"
      />
      <BreadCrumbs
        homePath="/my/dashboard"
        breadCrumbsPages={[
          {
            name: 'Jobs',
            path: '/my/dashboard/jobs/',
          },
          {
            name: 'Applications',
            path: `/my/dashboard/jobs/applied${id}`,
          },
          {
            name: 'Details',
            path: `/my/dashboard/jobs/applied${id}`,
          },
        ]}
      />
      <div className="space-y-4 max-w-[1100px] w-full rounded-md">
        <section className="space-y-6 whitespace-pre-line">
          {applicationSection()}
          {contentsSection()}
        </section>
      </div>
    </>
  );
};
