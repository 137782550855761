import React, { useEffect, useMemo, useState } from 'react';
import { useQuery } from 'react-query';
import { ReactComponent as RectBgSvg } from '@svg/figure-landing-rect.svg';
import AboutImg01Small from '@img/img_tutor_howitworks_01@small.png';
import AboutImg01Medium from '@img/img_tutor_howitworks_01@medium.png';
import AboutImg02Small from '@img/img_tutor_howitworks_02@small.png';
import AboutImg02Medium from '@img/img_tutor_howitworks_02@medium.png';
import AboutImg03Small from '@img/img_tutor_howitworks_03@small.png';
import AboutImg03Medium from '@img/img_tutor_howitworks_03@medium.png';
import AboutImg04Small from '@img/img_tutor_howitworks_04@small.png';
import AboutImg04Medium from '@img/img_tutor_howitworks_04@medium.png';
import BannerImg from '@img/img-tutor-howitworks-dashboard.jpg';
import { Button } from '@components/Button';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { Icon } from '@components/icons';
import { Link, useHistory } from 'react-router-dom';
import {
  AcademicCapIcon,
  CalendarDaysIcon,
  CheckBadgeIcon,
  CheckCircleIcon,
  PaperAirplaneIcon,
  StarIcon,
  VideoCameraIcon,
} from '@heroicons/react/24/outline';
import { useTranslation } from 'react-i18next';
import { text } from '@fortawesome/fontawesome-svg-core';
import { HiddenHeadingMeta } from '@src/components/HiddenHeadingMeta';
import { ReactHelmet } from '@src/components/ReactHelmet';
import mixpanel from 'mixpanel-browser';

export const TutorHowTo = () => {
  // const { authenticated } = useAuth();
  const { t, i18n } = useTranslation('tutorHowTo');

  // Animation on scoll plugin initial settings
  useEffect(() => {
    mixpanel.track('Page Viewed', {
      item_category: 'Student How It Works',
    });
    AOS.init({
      duration: 1800,
      // delay: 500,
      offset: 100,
      anchorPlacement: 'top-bottom',
      once: true,
    });
    AOS.refresh();
    // console.log(parseInt(twConfig.theme.screens.md), width);
  }, []);

  const aboutInfoItems = [
    {
      icon: PaperAirplaneIcon,
      iconForeground: 'text-green-700',
      iconBackground: 'bg-green-50',
      title: t('aboutItems.title1'),
      desc: t('aboutItems.desc1'),
      mainimg: { small: AboutImg01Small, medium: AboutImg01Medium },
      imgdesc: null,
    },
    {
      icon: CheckCircleIcon,
      iconForeground: 'text-purple-700',
      iconBackground: 'bg-purple-50',
      title: t('aboutItems.title2'),
      desc: t('aboutItems.desc2'),
      mainimg: { small: AboutImg02Small, medium: AboutImg02Medium },
      imgdesc: null,
    },
    {
      icon: CalendarDaysIcon,
      iconForeground: 'text-red-700',
      iconBackground: 'bg-red-50',
      title: t('aboutItems.title3'),
      desc: t('aboutItems.desc3'),
      mainimg: { small: AboutImg03Small, medium: AboutImg03Medium },
      imgdesc: null,
    },
    {
      icon: VideoCameraIcon,
      iconForeground: 'text-indigo-700',
      iconBackground: 'bg-indigo-50',
      title: t('aboutItems.title4'),
      desc: t('aboutItems.desc4'),
      mainimg: { small: AboutImg04Small, medium: AboutImg04Medium },
      imgdesc: null,
    },
  ];

  const aboutSection = () => {
    return (
      <section className=" bg-white w-full my-6 md:my-20  space-y-14 md:space-y-20 flex flex-col items-center">
        {aboutInfoItems.map((item: any, index: any) => {
          return (
            <div
              key={`homeAbout_${index}`}
              data-aos="fade-in"
              className="max-w-container flex flex-col-reverse md:grid grid-cols-10 md:items-center gap-5 md:gap-10"
            >
              <>
                <div className="space-y-4 md:space-y-4 md:col-span-5">
                  <div>
                    <span
                      className={classNames(
                        item.iconBackground,
                        item.iconForeground,
                        'rounded-lg inline-flex p-3 ring-4 ring-white'
                      )}
                    >
                      <item.icon className="h-6 w-6" aria-hidden="true" />
                    </span>
                  </div>
                  <p className="text-2xl md:text-4xl md:leading-[3.6rem] font-semibold text-brand-blue whitespace-pre-line">
                    {item.title}
                  </p>
                  <p className=" text-18 text-black leading-7 whitespace-pre-line">
                    {item.desc}
                  </p>
                </div>
                <div className="block text-right space-y-1 md:col-span-5 ">
                  <picture>
                    <source
                      media={'(max-width:400px)'}
                      srcSet={item.mainimg.small}
                    />
                    <source
                      media="(min-width:401px)"
                      srcSet={item.mainimg.medium}
                    />
                    <img
                      src={item.mainimg.small}
                      alt="lading-img "
                      className="md:max-h-[500px] rounded-md shadow-lg"
                      style={{ width: 'auto' }}
                    />
                  </picture>
                  {item.imgdesc ? <p>{item.imgdesc}</p> : null}
                </div>
              </>
            </div>
          );
        })}
      </section>
    );
  };

  const heroContents = () => {
    return (
      <div
        data-aos="fade-in"
        className="w-full lg:w-none sm:px-6 lg:px-0 space-y-8 md:space-y-8  text-center"
      >
        <h1 className="leading-snug text-2xl sm:leading-[3.5rem] sm:text-3xl md:leading-snug md:text-4xl lg:leading-snug  font-bold md:whitespace-pre-line">
          {t('hero.title')}
        </h1>
        <div>
          <h3 className="mx-auto max-w-2xl text-lg tracking-tight text-slate-700 whitespace-pre-line">
            {t('hero.subtitle')}
          </h3>
          <div className="flex justify-center w-full">
            <Link
              to="/my/dashboard/jobs"
              className="mt-4 inline-flex items-center rounded-full border border-transparent bg-indigo-600 px-6 py-3 text-base font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
            >
              {t('hero.ctaText')}
            </Link>
          </div>
        </div>
      </div>
    );
  };

  const inquirySection = () => {
    return (
      <section className={`w-full pb-10`}>
        <div className="max-w-container">
          <div
            className={`text-white h-60 md:h-72 lg:h-[450px] overflow-hidden bg-center bg-cover bg-no-repeat relative flex justify-center rounded-lg  bg-[#4C4EDC]`}
            // style={{ backgroundImage: `url('${bottomBannerImg}')` }}
          >
            <div className="p-6 md:p-10 w-full h-full flex flex-row justify-between items-center">
              <div className="w-full sm:px-6">
                <h2 className="text-2xl md:text-3xl lg:text-5xl font-bold">
                  {t('bannerSection.title')}
                </h2>
                <p className="mt-4 md:mt-12 text-sm md:text-lg font-normal whitespace-pre-line">
                  {t('bannerSection.desc')}
                </p>
                <Button
                  to="/student-request"
                  className={`mt-4 md:mt-12 text-[#396CF7] items-center rounded-full h-10 md:h-12 px-10 md:px-12 text-sm md:text-md bg-white`}
                >
                  {t('bannerSection.ctaText')}
                </Button>
              </div>
              <img
                className="hidden lg:block h-full rounded-md"
                src={BannerImg}
                alt={''}
              />
            </div>
          </div>
        </div>
      </section>
    );
  };

  function classNames(...classes: string[]) {
    return classes.filter(Boolean).join(' ');
  }

  return (
    <>
      <HiddenHeadingMeta text="For tutors | Linkstory" />
      <ReactHelmet
        keywords="tutor, how it works"
        title="For tutors | Linkstory"
      />
      <section className=" w-full overflow-hidden relative flex justify-center">
        <div
          data-aos="fade-in"
          className="max-w-container !py-20 h-full object-cover object-center flex flex-col justify-center"
        >
          {heroContents()}
        </div>
      </section>
      {aboutSection()}
      {inquirySection()}
    </>
  );
};
