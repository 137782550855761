import { useState, useEffect } from 'react';
import { Swiper } from 'swiper/react';
import SwiperCore, {
  Pagination,
  Autoplay,
  Navigation,
  Scrollbar,
  Lazy,
} from 'swiper';
import styled, { Interpolation } from 'styled-components';

import 'swiper/swiper-bundle.min.css';
import 'swiper/swiper.min.css';
import 'swiper/components/lazy/lazy.min.css';
import 'swiper/components/navigation/navigation.min.css';
import 'swiper/components/pagination/pagination.min.css';

interface SliderProps {
  children: React.ReactNode;
  initialSlide?: number;
  slidesPerView: any;
  breakpoints?: any;
  stylesOverride?: Interpolation<React.CSSProperties>;
  onslidechange?: () => void;
}

const StyledRoot = styled.div<{
  stylesOverride?: Interpolation<React.CSSProperties>;
}>`
  .swiper-button-prev {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='current' height='current' fill='none' viewBox='0 0 15 25'%3E%3Cpath fill='%2316244D' d='m14.334 3.83-8.839 8.68 8.839 8.68a2.2 2.2 0 0 1 0 3.155 2.298 2.298 0 0 1-3.212 0L.666 14.077a2.2 2.2 0 0 1 0-3.155L11.122.654a2.298 2.298 0 0 1 3.212 0c.865.872.888 2.304 0 3.177Z'/%3E%3C/svg%3E");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
  }

  .swiper-button-next {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='15' height='25' fill='none' viewBox='0 0 15 25'%3E%3Cpath fill='%2316244D' d='m.666 21.168 8.839-8.68-8.839-8.68a2.2 2.2 0 0 1 0-3.154 2.298 2.298 0 0 1 3.212 0l10.456 10.268a2.2 2.2 0 0 1 0 3.155L3.878 24.345a2.298 2.298 0 0 1-3.212 0c-.865-.872-.888-2.304 0-3.177Z'/%3E%3C/svg%3E");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
  }

  .swiper-button-prev::after {
    display: none;
  }

  .swiper-button-next::after {
    display: none;
  }

  .swiper-container {
    padding-left: 10px;
    padding-right: 10px;
  }

  .swiper-button-next:after,
  .swiper-button-prev:after {
    font-size: 25px;
  }

  .swiper-button-next,
  .swiper-button-prev {
    top: unset;
    bottom: 0px;
    width: 25px;
    height: 25px;
    color: #16244d;
  }

  @media (max-width: 768px) {
    .swiper-button-next,
    .swiper-button-prev {
      display: none;
    }
  }

  @media (max-width: 419px) {
    .swiper-slide {
      /* padding: 20px; */
    }
  }

  .swiper-button-next,
  .swiper-container-rtl .swiper-button-prev {
    right: 10px;
    left: auto;
  }

  .swiper-button-prev,
  .swiper-container-rtl .swiper-button-next {
    right: auto;
    left: calc(50% - (var(--swiper-navigation-size) + 20px));
    transform: translateX(-50%);
  }

  .swiper-button-next,
  .swiper-container-rtl .swiper-button-prev {
    left: auto;
    right: calc(50% - (var(--swiper-navigation-size) + 30px));
    left: auto;
  }

  .swiper {
    &-pagination {
      position: absolute;
      bottom: 7px;
      pointer-events: none;
      z-index: 40;
    }
    /* &-wrapper,
          &-container {
              width: 62rem;
              margin: 0;
          }
          &-container {
              margin: 0 3.2rem;
          } */
    &-pagination-bullet {
      pointer-events: auto;
      width: 10px;
      height: 10px;
      background-color: #16244d;
    }
  }
  @media screen and (max-width: 620px) {
    .swiper {
      &-pagination {
        bottom: 6px;
        /* pointer-events: none; */
      }
      &-pagination-bullet {
        width: 8px;
        height: 8px;
      }
    }
  }

  // override css
  ${(props) => props.stylesOverride && props.stylesOverride}
`;

const Slider = ({
  children,
  onslidechange,
  initialSlide,
  slidesPerView,
  breakpoints,
  stylesOverride,
}: SliderProps) => {
  /** SwiperCore 사용 */
  SwiperCore.use([Pagination, Autoplay, Navigation, Scrollbar, Lazy]);

  const [swiperSetting, setSwiperSetting] = useState<Swiper | null>(null);

  useEffect(() => {
    if (!swiperSetting) {
      setSwiperSetting({
        // centeredSlides: true,
        pagination: {
          clickable: true,
          dynamicBullets: true,
        },
        // Disable preloading of all images
        preloadImages: false,
        // Enable lazy loading
        lazy: {
          loadPrevNext: true,
          loadOnTransitionStart: true,
          loadPrevNextAmount: 2,
        },
        watchSlidesVisibility: true,
        observer: true,
        spaceBetween: 24,
        navigation: true,
        initialSlide,
        scrollbar: { draggable: true, el: null },
        slidesPerView,
        updateOnWindowResize: true,
        breakpoints: breakpoints
          ? breakpoints
          : {
              // 1024: {
              //   slidesPerView: 5.6,
              //   slidesPerGroup: 5,
              //   spaceBetween: 30,
              // },
              960: {
                slidesPerView: 4.5,
                slidesPerGroup: 4,
                spaceBetween: 30,
              },
              720: {
                slidesPerView: 3.4,
                slidesPerGroup: 3,
                spaceBetween: 25,
              },
              492: {
                slidesPerView: 2.4,
                slidesPerGroup: 2,
                spaceBetween: 20,
              },
              320: {
                slidesPerView: 1.4,
                slidesPerGroup: 1,
                spaceBetween: 20,
                centeredSlides: true,
              },
            },
      });
    }
  }, [swiperSetting]);

  return (
    /** Swiper에 option값을 받아와서 적용 */
    <StyledRoot stylesOverride={stylesOverride}>
      {swiperSetting && (
        <Swiper {...swiperSetting} onSlideChange={onslidechange}>
          {children}
        </Swiper>
      )}
    </StyledRoot>
  );
};

export default Slider;
