import * as React from 'react';
import * as Types from '@src/types';
import { DownloadFileItem } from './DownloadFileItem';
import { uniqueId } from 'lodash';

export interface DownloadFilesProps {
  filesItems: Types.JobFile[];
}

export const DownloadFiles: React.FC<DownloadFilesProps> = ({ filesItems }) => {
  return (
    <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
      <ul className="divide-y divide-gray-200 rounded-md border border-gray-200 bg-white">
        {filesItems.map((item) => {
          return (
            <DownloadFileItem key={`DownLoadFile_${uniqueId()}`} file={item} />
          );
        })}
      </ul>
    </dd>
  );
};
