import React from 'react';
import ReactDOM from 'react-dom';
import { QueryClient, QueryClientProvider } from 'react-query';
import '../src/plugins/bridge';
import App from './App';
import './index.css';
import './assets/fonts/linkstory-inc.css';
import reportWebVitals from './reportWebVitals';
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import { hotjar } from 'react-hotjar';
import { HelmetProvider } from 'react-helmet-async';
import './i18n';
import mixpanel from 'mixpanel-browser';

// Enabling the debug mode flag is useful during implementation,
// but it's recommended you remove it for production
if (process.env.REACT_APP_MIXPANEL_PROJECT_TOKEN) {
  mixpanel.init(process.env.REACT_APP_MIXPANEL_PROJECT_TOKEN, {
    //Opting Users Out of Tracking by Default
    // opt_out_tracking_by_default:
    //   process.env.REACT_APP_WEB_ENV !== 'prod' ? true : false,
    debug: process.env.REACT_APP_WEB_ENV !== 'prod' ? true : false,
  });
}

const client = new QueryClient({
  defaultOptions: {
    queries: {
      // Default 3회 > 불필요한 재요청 최소화 꼭 필요한 경우 각자의 UseQuery option 에서 설정
      retry: 0,
      //Set this to true if you want errors to be thrown in the render phase and propagate to the nearest error boundary
      // useErrorBoundary: true,
    },
    // mutations: {
    //   useErrorBoundary: true,
    // },
  },
});

Sentry.init({
  environment:
    process.env.REACT_APP_WEB_ENV === 'prod'
      ? 'production'
      : window.location.hostname === 'localhost'
      ? 'development'
      : 'stage',

  dsn: 'https://c83a110234044ffe95e18fe88d55aa69@o1334777.ingest.sentry.io/6601678',
  integrations: [new BrowserTracing()],

  beforeSend: async (event) => {
    // 로컬 환경에서의 에러는 수집하지 않도록 처리
    if (window.location.hostname === 'localhost') {
      console.log('sentry report : ', event);
      return null;
    }

    // Check if it is an exception, and if so, show the report dialog
    else if (event.exception) {
      // Sentry.showReportDialog({
      //   eventId: event.event_id,
      // });
      window.dataLayer.push({
        event: 'error',
      });
    }

    return event;
  },

  // We recommend adjusting this value in production, or using tracesSampler
  // for finer control
  // 추후 프로덕션에서는 레이트 수정 필요
  tracesSampleRate: 0.5,
});

hotjar.initialize(3089518, 6);

ReactDOM.render(
  <HelmetProvider>
    <React.StrictMode>
      <QueryClientProvider client={client}>
        <App />
      </QueryClientProvider>
    </React.StrictMode>
  </HelmetProvider>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
