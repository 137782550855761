import React from 'react';
import { Icon } from './icons';
import { Link, useLocation } from 'react-router-dom';
import { ReactComponent as Logo } from '../assets/svg/logo.svg';
import twConfig from '../tailwindResolver';
import { useTranslation } from 'react-i18next';

export const Footer = () => {
  const { pathname } = useLocation();
  const { t, i18n } = useTranslation(['gnb']);

  return (
    <footer
      className={`${
        pathname === '/about-linkstoryplus' || pathname === '/free-essays'
          ? 'bg-brand-black-bg border-t border-white border-opacity-50'
          : 'bg-gray-800'
      }`}
    >
      <div>
        {/* Desktop view */}
        <div className="hidden md:block max-w-container space-y-8 first-line:flex flex-col my-12 ">
          <section
            className={`justify-between grid grid-auto-flow: column grid-cols-2 `}
          >
            <Link to="/" className="col-span-1">
              {/* <img src={Logo} alt=""  /> */}
              <Logo
                fill={twConfig.theme.colors['white']}
                // className="md:h-5 h-4 mt-3"
                height={24}
                width={100}
              />
            </Link>

            <div className="flex flex-col items-end col-span-1 w-full ">
              <div>
                <p className="pb-3 text-10 md:text-14 text-white text-opacity-60">
                  Find us on
                </p>
                <div className="flex space-x-6 items-center">
                  <div className="opacity-80 hover:opacity-100 cursor-pointer">
                    <Icon.Instagram
                      onClick={() =>
                        window.open('https://instagram.com/linkstory.me')
                      }
                    />
                  </div>
                  <div className="opacity-80 hover:opacity-100 cursor-pointer">
                    <Icon.Youtube
                      onClick={() =>
                        window.open(
                          'https://www.youtube.com/channel/UC_1IMf7UbrFaO5_NtuCIEHA'
                        )
                      }
                    />
                  </div>
                  <div className="opacity-80 hover:opacity-100 cursor-pointer">
                    <Icon.Twitter
                      onClick={() =>
                        window.open('https://twitter.com/linkstory_me')
                      }
                    />
                  </div>
                  <div className="opacity-80 hover:opacity-100 cursor-pointer">
                    <Icon.Tiktok
                      onClick={() =>
                        window.open('https://www.tiktok.com/@linkstory.me/')
                      }
                    />
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section className=" flex justify-between">
            <div className="text-10 md:text-14 flex flex-col space-y-2">
              <div className="flex space-x-4 md:space-x-8 text-white">
                <div className=" text-white text-opacity-60 ">
                  © Linkstory Inc. All rights reserved.
                </div>
                <Link
                  to="/termsandconditions"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="underline text-white text-opacity-80"
                >
                  Terms and Conditions
                </Link>
                <Link
                  to="/privacy"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="underline text-white text-opacity-80"
                >
                  Privacy Policy
                </Link>
              </div>
              <div className="text-white text-opacity-60 mt-2">
                16192 Coastal Highway, Lewes, DE 19958, United States.
              </div>
              <p className="text-10 md:text-14 text-white text-opacity-50 flex items-center space-x-2">
                <div>For support, please contact us at</div>
                <a className="underline" href="mailto:support@linkstory.me">
                  support@linkstory.me
                </a>
              </p>
            </div>
          </section>
        </div>

        {/* Mobile view */}
        <div className="block md:hidden space-y-10 max-w-container items-center flex-col my-12 ">
          <Link to="/">
            {/* <img src={Logo} alt=""  /> */}
            <Logo
              fill={twConfig.theme.colors['white']}
              // className="md:h-5 h-4 mt-3"
              height={24}
              width={150}
              className=" m-auto"
            />
          </Link>
          <section className=" pt-8 flex flex-col">
            <div className="space-y-3 text-sm flex flex-col items-center">
              <div className="flex space-x-4 space-y-4 md:space-x-8 text-white">
                <div className=" text-white text-opacity-60 ">
                  © Linkstory Inc. All rights reserved.
                </div>
              </div>
              <div className="flex  space-x-4 text-white">
                <Link
                  to="/termsandconditions"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Terms and Conditions
                </Link>
                <Link to="/privacy" target="_blank" rel="noopener noreferrer">
                  Privacy Policy
                </Link>
              </div>
              <div className="text-white text-opacity-60 mt-2 text-center">
                16192 Coastal Highway, Lewes, DE 19958, United States.
              </div>
            </div>
          </section>
          <section className="flex flex-col  w-full ">
            <div className="flex flex-col items-center">
              <p className="pb-3 text-sm text-white text-opacity-60">
                Find us on
              </p>
              <div className="flex space-x-6 items-center">
                <div className="opacity-80 hover:opacity-100 cursor-pointer">
                  <Icon.Instagram
                    onClick={() =>
                      window.open('https://instagram.com/linkstory.me')
                    }
                  />
                </div>
                <div className="opacity-80 hover:opacity-100 cursor-pointer">
                  <Icon.Youtube
                    onClick={() =>
                      window.open(
                        'https://www.youtube.com/channel/UC_1IMf7UbrFaO5_NtuCIEHA'
                      )
                    }
                  />
                </div>
                <div className="opacity-80 hover:opacity-100 cursor-pointer">
                  <Icon.Twitter
                    onClick={() =>
                      window.open('https://twitter.com/linkstory_me')
                    }
                  />
                </div>
                <div className="opacity-80 hover:opacity-100 cursor-pointer">
                  <Icon.Tiktok
                    onClick={() =>
                      window.open('https://www.tiktok.com/@linkstory.me/')
                    }
                  />
                </div>
              </div>
            </div>
          </section>
          <section className="flex flex-col items-center">
            <p className="text-sm text-white text-opacity-50 flex flex-col items-center">
              <div>For support, please contact us at</div>
              <a className="mt-2 underline" href="mailto:support@linkstory.me">
                support@linkstory.me
              </a>
            </p>
          </section>
        </div>
      </div>
    </footer>
  );
};
