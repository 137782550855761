import React, { useEffect } from 'react';
import { Link, useHistory, useParams } from 'react-router-dom';
import { BreadCrumbs } from '@src/components/BreadCrumbs';
import {
  CalendarIcon,
  ClockIcon,
  CurrencyDollarIcon,
  PencilSquareIcon,
} from '@heroicons/react/24/outline';
import { Role, SessionType, Subject, User } from '@src/types';
import { useAuth } from '@src/hooks';
import { useQuery } from 'react-query';
import { fetcher } from '@src/plugins/react-query';
import { useForm } from 'react-hook-form';
import { Select } from '@src/components/Select';
import { TextField } from '@src/components/TextField';
import moment from 'moment';
import { PriceField } from '@src/components/PriceField';
import { api } from '@src/plugins/axios';
import { ErrorMessage } from '@hookform/error-message';
import { Avatar } from '@src/components/Avatar';
import { getS3Uri } from '@src/utils';
import { HiddenHeadingMeta } from '@src/components/HiddenHeadingMeta';
import { ReactHelmet } from '@src/components/ReactHelmet';

interface FormValues {
  subjectId: number;
  terms: boolean;
  startedAt: string;
  endedAt: string;
  title: string;
}

export const SessionEdit = () => {
  const { push, goBack } = useHistory();
  const { id } = useParams<{ id: string }>();
  const { authenticated } = useAuth();
  const { data: me } = useQuery<User>('/users/me', fetcher, {
    enabled: !!authenticated,
  });

  const {
    register,
    reset,
    handleSubmit,
    watch,
    formState: { isSubmitting, errors, isDirty, isValid },
  } = useForm<FormValues>({
    mode: 'onChange',
  });

  const { data: subjectsItems } = useQuery<Subject[]>(`/v2/subjects`, fetcher, {
    keepPreviousData: true,
    // refetchOnWindowFocus: false,
  });

  const { data: mentorSubjects } = useQuery<any[]>(
    `/v2/subject-link?mentorId=${Number(me?.id)}`,
    fetcher,
    {
      enabled: !!me?.id,
    }
  );

  const { data: sessionItem } = useQuery<SessionType>(
    `/lessons/detail/${id}`,
    fetcher,
    {
      enabled: !!authenticated,
      onSuccess: (item) => {
        let defaultValues = {
          title: item.title,
          subjectId: item.subjectId,
          startedAt: moment
            .utc(item.startedAt)
            .local()
            .format('YYYY-MM-DD[T]HH:mm'),
          endedAt: moment
            .utc(item.endedAt)
            .local()
            .format('YYYY-MM-DD[T]HH:mm'),
        };
        //@ts-ignore
        reset({ ...defaultValues });
      },
    }
  );

  if (!sessionItem) return <></>;

  const contentsSection = () => {
    return (
      <div className="">
        <div className=" border-gray-200">
          <dl>
            <div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt className="text-sm font-medium text-gray-500">Student</dt>
              <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0 flex items-center gap-2">
                <Avatar
                  src={getS3Uri(sessionItem?.student?.avatar)}
                  alt="avatar"
                  className="!wh-8 object-cover rounded-full"
                />
                <span>{sessionItem?.student?.firstName}</span>
              </dd>
            </div>

            <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt className="text-sm font-medium text-gray-500">Subject</dt>
              <Select
                readOnly
                value={sessionItem.subjectId}
                helper={errors.subjectId?.message}
                className="block w-full max-w-lg  border-gray-300 shadow-sm h-10  mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0"
                {...register('subjectId', {
                  required: 'Please select session subject',
                })}
              >
                <option value="" selected disabled hidden>
                  Please select session subject
                </option>
                {subjectsItems
                  ?.filter((item) =>
                    mentorSubjects?.find(
                      (option) => option.subjectId === item.id
                    )
                  )
                  .map((item) => {
                    return (
                      <option
                        label={item.title.toUpperCase()}
                        value={Number(item.id)}
                      />
                    );
                  })}
              </Select>
            </div>
            <div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt className="text-sm font-medium text-gray-500">
                Session Title
              </dt>
              <TextField
                className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0 truncate-2-lines h-10"
                placeholder="Please give the session a title."
                helper={errors.title?.message}
                {...register('title', {
                  required: 'Please enter section title.',
                })}
              />
            </div>
            <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt className="text-sm font-medium text-gray-500">Start time</dt>
              <div>
                <TextField
                  type="datetime-local"
                  data-placeholder="YYYY. MM. DD hh:mm"
                  className={`text-sm h-10 ${
                    !!watch('startedAt') ? 'has-value' : ''
                  }`}
                  helper={errors.startedAt?.message}
                  {...register('startedAt', {
                    required: 'Please enter your prefered time',
                    validate: (value: string) => {
                      if (moment(value).isAfter(watch('endedAt'))) {
                        return 'The session start time cannot be later than the end time.';
                      }
                    },
                  })}
                />
              </div>
            </div>
            <div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt className="text-sm font-medium text-gray-500">End time</dt>
              <div>
                <TextField
                  type="datetime-local"
                  data-placeholder="YYYY. MM. DD hh:mm"
                  min={moment(watch('startedAt')).format('YYYY-MM-DD[T]HH:mm')}
                  className={`text-sm h-10 ${
                    !!watch('endedAt') ? 'has-value' : ''
                  }`}
                  helper={errors.endedAt?.message}
                  {...register('endedAt', {
                    required: 'Please enter your prefered time',
                    validate: (value: string) => {
                      if (moment(value).isBefore(watch('startedAt'))) {
                        return 'Session end time cannot be earlier than start time.';
                      }
                    },
                  })}
                />
              </div>
            </div>

            <div className="bg-gray-50 px-4 py-3 text-right sm:px-6 flex justify-end gap-3">
              <button className="inline-flex justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">
                Submit
              </button>
            </div>
          </dl>
        </div>
      </div>
    );
  };

  return (
    <>
      <HiddenHeadingMeta text="Session edit | Linkstory" />
      <ReactHelmet keywords="session, edit" title="Session edit | Linkstory" />
      <BreadCrumbs
        homePath="/my/dashboard"
        breadCrumbsPages={[
          {
            name: 'Session Details',
            path: `/my/dashboard/session/${id}`,
          },
          {
            name: 'Edit Session',
            path: `/my/dashboard/session/edit/${id}`,
          },
        ]}
      />
      <div className="space-y-4 bg-white border w-full rounded-md overflow-hidden">
        <section className="whitespace-pre-line">
          <div className="border-b border-gray-200 bg-white px-4 py-5 sm:px-6">
            <div className="-ml-4 -mt-2 flex flex-wrap items-center justify-between sm:flex-nowrap">
              <div className="ml-4 mt-2 flex gap-2 items-center">
                <PencilSquareIcon className="wh-6 flex-shrink-0 self-center text-xs" />
                <h3 className="text-lg font-medium leading-6 text-gray-900">
                  Edit Session
                </h3>
              </div>
            </div>
          </div>

          <form
            className="flex flex-col gap-4"
            onSubmit={handleSubmit(async (data) => {
              await api
                .patch(`lessons/v2/lessons/session-detail/${id}`, {
                  title: data.title,
                  subjectId: data.subjectId,
                  startedAt: moment(data.startedAt).toISOString(),
                  endedAt: moment(data.endedAt).toISOString(),
                })
                .then(() => {
                  alert('Modification completed successfully.');
                  goBack();
                });
            })}
          >
            {contentsSection()}
          </form>
        </section>
      </div>
    </>
  );
};
