import { FC, forwardRef, useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import {
  MomentFormat,
  utcToDiffMinutes,
  utcToLocalFormat,
} from '../plugins/moment';
import { fetcher } from '../plugins/react-query';
import {
  Chat,
  FileType,
  JobApplicationItemType,
  JobType,
  Message,
  MessageType,
  Product,
  Role,
  SessionType,
  User,
} from '../types';
import { Button } from './Button';
import '../plugins/imessage.css';
import { getS3Uri } from '../utils';
import { Avatar } from './Avatar';
import {
  BanknotesIcon,
  CalendarDaysIcon,
  CurrencyDollarIcon,
  ExclamationTriangleIcon,
  EyeIcon,
  FireIcon,
  HandRaisedIcon,
  InformationCircleIcon,
  MegaphoneIcon,
  SparklesIcon,
} from '@heroicons/react/24/outline';
import { Divider } from '@material-ui/core';
import moment from 'moment';
import { DownloadFiles } from './DownloadFiles';
import { DownloadFileItem } from './DownloadFileItem';
import Item from 'antd/lib/list/Item';
import { ImagePreview } from './ImagePreview';
import { Link } from 'react-router-dom';

export interface MessageBubbleProps {
  chat: Chat;
  message: Message;
  setPaymentPopup: () => void;
  setPrice: (price: any) => void;
  setProductId: (productId: any) => void;
  setJobId: (productId: any) => void;
  setStartedAt: (startedAt: any) => void;
  isTimeDiff: boolean;
}

export const MessageBubble = forwardRef<HTMLInputElement, MessageBubbleProps>(
  ({
    chat,
    message,
    setPaymentPopup,
    setStartedAt,
    setPrice,
    setProductId,
    setJobId,
    isTimeDiff,
  }) => {
    const { data: me } = useQuery<User>('/users/me', fetcher);
    const isMine = message.userId === me?.id;
    const user = isMine
      ? me
      : me?.role === Role.MENTOR
      ? chat.student
      : chat.mentor;
    const [jobApplication, setJobApplication] =
      useState<JobApplicationItemType>();

    const { data: product } = useQuery<Product>(
      `/products/${message.productId}`,
      fetcher,
      { enabled: !!message.productId }
    );

    const { data: job } = useQuery<JobType>(
      `/v2/jobs/${message.jobId}`,
      fetcher,
      { enabled: !!message.jobId }
    );

    const { data: session } = useQuery<SessionType>(
      `/lessons/detail/${message.lessonId}`,
      fetcher,
      { enabled: !!message.lessonId }
    );

    useEffect(() => {
      if (message.type === MessageType.APPLICATION) {
        setJobApplication(JSON.parse(message.text));
      }
    }, [message]);

    const now = new Date().toISOString();

    interface TimeMarkProps {}

    const TimeMark: FC<TimeMarkProps> = ({}) => {
      return (
        <div className="flex flex-col items-end justify-end">
          {isMine && (
            <div
              className={`text-black text-xs whitespace-nowrap ${
                isMine ? 'mr-2' : 'ml-2'
              }`}
            >
              {me?.role === Role.STUDENT
                ? message.isMentorRead && 'Seen'
                : message.isStudentRead && 'Seen'}
            </div>
          )}
          {isTimeDiff && (
            <time
              className={`text-12 text-gray-500 self-end flex-none ${
                isMine ? 'mr-2' : 'ml-2'
              }`}
            >
              {utcToLocalFormat(message.createdAt, MomentFormat.hmma)}
            </time>
          )}
        </div>
      );
    };

    return (
      <div>
        {message.type === MessageType.REQUEST && message.jobId ? (
          <div
            className={`flex  ${isMine ? 'flex-row-reverse space-x-2' : ''}`}
            style={{}}
          >
            <div className="flex flex-col w-full">
              <div
                className={`flex items-center ${
                  isMine ? 'flex-row-reverse whitespace-pre' : ''
                }`}
              >
                <div className="w-2" />
              </div>
              <div
                className={`flex items-end justify-end ${
                  isMine ? 'flex-row' : 'flex-row-reverse'
                }`}
              >
                <TimeMark />
                <div className="flex flex-col-reverse space-y-3 max-w-[80%] min-w-[220px] sm:max-w-[300px] lg:w-full">
                  <div
                    className={`text-left bg-gray-100 py-4 px-4 md:px-4 rounded-md`}
                    style={{ wordBreak: 'break-word' }}
                  >
                    <div className="space-y-4">
                      <div className="flex items-center gap-3 mb-4">
                        <span
                          className={`rounded-lg inline-flex p-2 bg-yellow-100 ring-2 ring-white`}
                        >
                          <SparklesIcon
                            className={`wh-5  text-yellow-700`}
                            aria-hidden="true"
                          />
                        </span>
                        <p className="font-bold text-sm">{`Session Request`}</p>
                      </div>

                      <div className="space-y-1">
                        <div className="text-sm ">
                          {`${job?.writer.firstName} would like you to schedule a session`}
                        </div>
                        <div className="h-[1px] bg-gray-500 w-full !my-3" />
                        <div className="text-sm flex">
                          <p className="text-gray-600 mr-2">Education level:</p>
                          <p>{job?.grade.title}</p>
                        </div>
                        <div className="text-sm flex">
                          <p className="text-gray-600 mr-2">Subject:</p>
                          <p>{job?.subject.title}</p>
                        </div>
                        <div className="h-[1px] bg-gray-500 w-full !my-3" />
                        <div className="text-sm ">
                          {`Preferred date & time`}
                        </div>
                        <div className="text-sm flex">
                          <p className="text-gray-600 mr-2">1. </p>
                          <p>
                            {utcToLocalFormat(
                              job?.firstRequestTime || '',
                              MomentFormat.YYYYMMDDHmm
                            )}{' '}
                            {moment.tz(moment.tz.guess()).zoneAbbr()}
                          </p>
                        </div>
                        {job?.secondRequestTime && (
                          <div className="text-sm flex">
                            <p className="text-gray-600 mr-2">2. </p>
                            <p>
                              {utcToLocalFormat(
                                job?.secondRequestTime || '',
                                MomentFormat.YYYYMMDDHmm
                              )}{' '}
                              {moment.tz(moment.tz.guess()).zoneAbbr()}
                            </p>
                          </div>
                        )}
                        {job?.thirdRequestTime && (
                          <div className="text-sm flex">
                            <p className="text-gray-600 mr-2">3. </p>
                            <p>
                              {utcToLocalFormat(
                                job?.thirdRequestTime || '',
                                MomentFormat.YYYYMMDDHmm
                              )}{' '}
                              {moment.tz(moment.tz.guess()).zoneAbbr()}
                            </p>
                          </div>
                        )}

                        {job?.questionAnswers?.length !== 0 && (
                          <div className="h-[1px] bg-gray-500 w-full !my-3" />
                        )}

                        {job?.questionAnswers?.map((item, index) => {
                          return (
                            <>
                              <div className="text-sm flex">
                                <p className="text-gray-600 mr-2">
                                  Q{index + 1}.
                                </p>
                                <p>{item.question.question}</p>
                              </div>
                              <div className="text-sm flex">
                                <p className="text-gray-600 mr-2">
                                  A{index + 1}.
                                </p>
                                <p>{item.text}</p>
                              </div>
                            </>
                          );
                        })}
                        {job?.description && (
                          <>
                            <div className="h-[1px] bg-gray-500 w-full !my-3" />
                            <div>
                              <div className="text-sm flex">
                                <p className="">{job?.description}</p>
                              </div>
                            </div>
                          </>
                        )}
                        {job?.jobFiles && job?.jobFiles?.length > 0 && (
                          <div className="w-full">
                            <DownloadFiles filesItems={job.jobFiles} />
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                  {!isMine && (
                    <>
                      <div className="w-4" />
                      <div className="flex-none">
                        <Avatar
                          className="wh-8 lg:wh-10 aspect-w-1"
                          src={
                            user?.avatar.startsWith('https')
                              ? user?.avatar
                              : getS3Uri(user?.avatar)
                          }
                        />
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        ) : message.type === MessageType.TEXT ? (
          <div
            className={`flex flex-col space-y-2 ${
              isMine ? 'flex-row-reverse space-x-3' : ''
            }`}
          >
            {!isMine && (
              <div className="flex-none">
                <Avatar
                  className="!wh-8 lg:!wh-10"
                  src={
                    user?.avatar.startsWith('https')
                      ? user?.avatar
                      : getS3Uri(user?.avatar)
                  }
                />
              </div>
            )}
            {!isMine && <div className="w-4" />}

            <div className="flex flex-col w-full">
              <div
                className={`imessage flex justify-end ${
                  isMine ? 'flex-row' : 'flex-row-reverse'
                }`}
              >
                <TimeMark />
                <p
                  className={`${
                    isMine
                      ? 'from-me text-white items-end bg-[#4E4F4F]'
                      : 'from-them items-start text-black bg-[#F5F7FA]'
                  } break-words`}
                >
                  {message.text?.split('<br />').map((text) => {
                    return (
                      <>
                        {text}
                        <br />
                      </>
                    );
                  })}
                </p>
              </div>
            </div>
          </div>
        ) : message.type === MessageType.APPLICATION ? (
          <div className={`flex ${isMine ? 'flex-row-reverse space-x-2' : ''}`}>
            <div className="flex flex-col w-full">
              <div
                className={`flex items-center ${
                  isMine ? 'flex-row-reverse whitespace-pre' : ''
                }`}
              >
                <div className="w-2" />
              </div>
              <div
                className={`flex items-end justify-end ${
                  isMine ? 'flex-row' : 'flex-row-reverse'
                }`}
              >
                <TimeMark />
                <div className="flex flex-col-reverse space-y-3 max-w-[80%] min-w-[220px] sm:max-w-[300px] lg:w-full">
                  <div
                    className={`text-left bg-gray-100 py-4 px-4 md:px-4 rounded-md`}
                    style={{ wordBreak: 'break-word' }}
                  >
                    <div className="space-y-4">
                      <div className="flex items-center gap-3 mb-4">
                        <span
                          className={`rounded-lg inline-flex p-2  bg-purple-100 ring-2 ring-white`}
                        >
                          <HandRaisedIcon
                            className={`wh-5  text-purple-700`}
                            aria-hidden="true"
                          />
                        </span>
                        <p className="font-bold text-sm">{`Interested Tutor`}</p>
                      </div>

                      <div className="space-y-1">
                        {me?.role === Role.STUDENT && (
                          <>
                            <div className="text-sm ">
                              {`${jobApplication?.mentorFirstName} ${jobApplication?.mentorLastName} has responded to your request`}
                            </div>
                            <div className="h-[1px] bg-gray-500 w-full !my-3" />
                          </>
                        )}

                        <div className="text-sm ">
                          {`${jobApplication?.message}`}
                        </div>

                        <div className="h-[1px] bg-gray-500 w-full !my-3" />
                        {job?.subject.title && (
                          <div className="text-sm flex">
                            <p className={`text-gray-600 mr-2`}>Subject:</p>
                            <p>{job?.subject.title}</p>
                          </div>
                        )}

                        <div className="text-sm flex">
                          <p className={`text-gray-600 mr-2`}>Hourly rate:</p>
                          <p>$ {Number(jobApplication?.price).toFixed(2)}</p>
                        </div>
                        <div className="border-l-4 border-yellow-400 bg-yellow-50 p-4 !mt-3">
                          <div className="flex">
                            <div className="">
                              <p className="text-sm text-yellow-700 flex flex-col">
                                <span>
                                  {me?.role === Role.MENTOR
                                    ? `Your job application has been submitted. When
                                  the student replies, you will receive an email
                                  notification.`
                                    : `Respond to your interested tutor so they can proceed to create a session for you. `}
                                </span>
                                <span className="text-xs mt-2 flex items-center gap-1">
                                  <EyeIcon
                                    className="wh-4 text-yellow-400"
                                    aria-hidden="true"
                                  />
                                  <span>
                                    This message is only visible to you
                                  </span>
                                </span>
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    {message.isFirstSession && isMine && (
                      <p className=" text-red-400 text-13">
                        Note: Your first session will be paid by
                        <br />
                        Linkstory
                      </p>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : message.type === MessageType.LESSON ? (
          // 세션 스케줄 메시지
          <div className={`flex ${isMine ? 'flex-row-reverse space-x-2' : ''}`}>
            <div className="flex flex-col w-full">
              <div
                className={`flex items-center ${
                  isMine ? 'flex-row-reverse whitespace-pre' : ''
                }`}
              >
                <div className="w-2" />
              </div>
              <div
                className={`flex items-end justify-end ${
                  isMine ? 'flex-row' : 'flex-row-reverse'
                }`}
              >
                <TimeMark />
                <div className="flex flex-col-reverse space-y-3 max-w-[80%] min-w-[220px] sm:max-w-[300px] lg:w-full">
                  <div
                    className={`text-left bg-gray-100 py-4 px-4 md:px-4 rounded-md`}
                    style={{ wordBreak: 'break-word' }}
                  >
                    <div className="space-y-4">
                      <div className="flex items-center gap-3 mb-4">
                        <span
                          className={`rounded-lg inline-flex p-2 bg-pink-100 ring-2 ring-white`}
                        >
                          <CalendarDaysIcon
                            className={`wh-5  text-pink-700`}
                            aria-hidden="true"
                          />
                        </span>
                        <p className="font-bold text-sm">{`Session Scheduled`}</p>
                      </div>

                      <div className="space-y-1">
                        {me?.role === Role.STUDENT && (
                          <>
                            <div className="text-sm ">
                              {`${session?.mentorName} has scheduled a tutoring session`}
                            </div>
                            <div className="h-[1px] bg-gray-500 w-full !my-3" />
                          </>
                        )}
                        <div className="text-sm flex">
                          <p className="text-gray-600 mr-2">Subject:</p>
                          <p>{session?.subjectTitle}</p>
                        </div>
                        <div className="h-[1px] bg-gray-500 w-full !my-3" />
                        <div className="text-sm flex">
                          <p className="text-gray-600 mr-2">Start Time:</p>
                          <p>
                            {utcToLocalFormat(
                              session?.startedAt || '',
                              MomentFormat.YYYYMMDDHmm
                            )}{' '}
                            {moment.tz(moment.tz.guess()).zoneAbbr()}
                          </p>
                        </div>
                        <div className="text-sm flex">
                          <p className="text-gray-600 mr-2">End Time:</p>
                          <p>
                            {utcToLocalFormat(
                              session?.endedAt || '',
                              MomentFormat.YYYYMMDDHmm
                            )}{' '}
                            {moment.tz(moment.tz.guess()).zoneAbbr()}
                          </p>
                        </div>
                        <div className="text-sm flex">
                          <p className="text-gray-600 mr-2">Duration:</p>
                          {session && (
                            <span className="inline-flex items-center rounded-full bg-green-100 px-2.5 py-0.5 text-xs font-medium text-green-800">
                              {utcToDiffMinutes(
                                session?.startedAt,
                                session?.endedAt
                              )}{' '}
                              mins
                            </span>
                          )}
                        </div>
                        <div className="h-[1px] bg-gray-500 w-full !my-3" />
                        <div>
                          <div className="text-sm flex">
                            <p className="text-gray-600 mr-2">Price :</p>
                            <p>${Number(session?.price).toFixed(2)}</p>
                          </div>
                        </div>
                        <div className="border-l-4 border-yellow-400 bg-yellow-50 p-4 !mt-3">
                          <div className="flex">
                            <div className="">
                              <p className="text-sm text-yellow-700 flex flex-col whitespace-pre-line">
                                <span>
                                  {`To enter the video call, go to your Dashboard, click on the relevant session in 'Upcoming sessions', and hit 'join session'.`}
                                  {me?.role === Role.STUDENT &&
                                    `\n\nYou will be charged after your scheduled session takes place.`}
                                </span>
                                <span className="text-xs mt-2 flex items-center gap-1">
                                  <EyeIcon
                                    className="wh-4 text-yellow-400"
                                    aria-hidden="true"
                                  />
                                  <span>
                                    This message is only visible to you
                                  </span>
                                </span>
                              </p>
                            </div>
                          </div>
                        </div>
                        <Link
                          to="/my/dashboard"
                          className="!mt-3 inline-flex rounded-md border border-transparent bg-indigo-600 px-3 py-2 text-sm font-medium leading-4 text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 w-full justify-center"
                        >
                          Go to my dashboard
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : message.type === MessageType.FILE ? (
          // 세션 스케줄 메시지
          <div className={`flex ${isMine ? 'flex-row-reverse space-x-2' : ''}`}>
            <div className="flex flex-col w-full">
              <div
                className={`flex items-center ${
                  isMine ? 'flex-row-reverse whitespace-pre' : ''
                }`}
              >
                <div className="w-2" />
              </div>
              <div
                className={`flex items-end justify-end ${
                  isMine ? 'flex-row' : 'flex-row-reverse'
                }`}
              >
                <TimeMark />
                <div className="flex flex-col-reverse space-y-3 max-w-[80%] min-w-[220px] sm:max-w-[300px] lg:w-full">
                  <div className={`text-left `}>
                    <div
                      className={`${
                        message.fileType === FileType.FILE ? 'border' : ''
                      } rounded-md bg-white`}
                    >
                      {message.fileType === FileType.FILE &&
                        message.text &&
                        message.fileName && (
                          <DownloadFileItem
                            file={{
                              fileKey: message.text,
                              filename: message.fileName,
                            }}
                          />
                        )}
                      {message.fileType === FileType.IMAGE &&
                        message.text &&
                        message.fileName && (
                          <ImagePreview
                            file={{
                              fileKey: message.text,
                              filename: message.fileName,
                            }}
                          />
                        )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : (
          message.type === MessageType.PRODUCT && (
            <div
              className={`flex ${isMine ? 'flex-row-reverse space-x-2' : ''}`}
            >
              <div className="flex flex-col w-full">
                <div
                  className={`flex items-center ${
                    isMine ? 'flex-row-reverse whitespace-pre' : ''
                  }`}
                >
                  <div className="w-2" />
                </div>
                <div
                  className={`flex items-end justify-end ${
                    isMine ? 'flex-row' : 'flex-row-reverse'
                  }`}
                >
                  <div className="flex flex-col-reverse space-y-3 max-w-[80%] min-w-[220px] sm:max-w-[300px] lg:w-full">
                    <div
                      className={`text-left bg-gray-100 py-4 px-4 md:px-4 rounded-md`}
                      style={{ wordBreak: 'break-word' }}
                    >
                      <div className="space-y-4">
                        <div className="flex items-center gap-3 mb-4">
                          <span
                            className={`rounded-lg inline-flex p-2  ${
                              message.text === 'Complete'
                                ? 'bg-teal-100'
                                : 'bg-purple-100'
                            } ring-2 ring-white`}
                          >
                            {message.text === 'Complete' ? (
                              <BanknotesIcon
                                className={`wh-5  text-teal-700`}
                                aria-hidden="true"
                              />
                            ) : (
                              <CurrencyDollarIcon
                                className={`wh-5  text-purple-700`}
                                aria-hidden="true"
                              />
                            )}
                          </span>
                          <p className="font-bold text-sm">{`Payment ${
                            message.text === 'Complete'
                              ? 'Completed'
                              : 'Request'
                          }`}</p>
                        </div>

                        <div className="space-y-1">
                          <div className="font-bold text-sm ">
                            Payment details
                          </div>
                          <div className="text-sm flex">
                            <p className="text-gray-600 mr-2">
                              Transaction id:
                            </p>
                            <p>{product?.id}</p>
                          </div>
                          {message.text === 'Complete' && (
                            <div className="text-sm flex">
                              <p className="text-gray-600 mr-2">Status:</p>
                              <span className="inline-flex items-center rounded-full bg-green-100 px-2.5 py-0.5 text-xs font-medium text-green-800">
                                {message.text === 'Complete' && 'completed'}
                              </span>
                            </div>
                          )}

                          <div className="h-[1px] bg-gray-500 w-full !my-3" />
                          <div className="text-sm flex">
                            <p className="text-gray-600 mr-2">Title:</p>
                            <p>{product?.title}</p>
                          </div>
                          <div className="text-sm flex">
                            <p className="text-gray-600 mr-2">Date:</p>
                            <p>
                              {utcToLocalFormat(
                                product?.startedAt || '',
                                MomentFormat.YYYYMMDDHmm
                              )}{' '}
                              {moment.tz(moment.tz.guess()).zoneAbbr()}
                            </p>
                          </div>
                          <div className="text-sm flex">
                            <p className="text-gray-600 mr-2">Time:</p>
                            <p>
                              {utcToLocalFormat(
                                product?.startedAt || '',
                                MomentFormat.hmma
                              )}{' '}
                              {moment.tz(moment.tz.guess()).zoneAbbr()}
                            </p>
                          </div>
                          <div className="h-[1px] bg-gray-500 w-full !my-3" />
                          <div className="text-sm flex">
                            <p className={`text-gray-600 mr-2`}>Amount:</p>
                            <p
                              className={`${
                                message.isFirstSession && 'line-through'
                              }`}
                            >
                              ${' '}
                              {product?.price
                                ? product?.price.toFixed(2)
                                : Number(0).toFixed(2)}
                            </p>
                          </div>
                          <div>
                            <div className="text-sm flex">
                              <p className="text-gray-600 mr-2">Discount:</p>
                              <p>
                                {message.isFirstSession
                                  ? `$ ${
                                      product?.price
                                        ? product?.price.toFixed(2)
                                        : Number(0).toFixed(2)
                                    }}`
                                  : '$ 0.00'}
                              </p>
                            </div>
                            <div className="h-[1px] bg-gray-500 w-full my-3" />
                            <div className=" flex text-lg lg:text-xl ">
                              <p className="text-gray-600 mr-2 ">Total:</p>
                              <p className="font-semibold">
                                {message.isFirstSession
                                  ? '$ 0.00'
                                  : `$ ${
                                      product?.price
                                        ? product?.price.toFixed(2)
                                        : Number(0).toFixed(2)
                                    }`}
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    {!isMine && (
                      <>
                        <div className="w-4" />
                        <div className="flex-none">
                          <Avatar
                            className="wh-8 lg:wh-10 aspect-w-1"
                            src={
                              user?.avatar.startsWith('https')
                                ? user?.avatar
                                : getS3Uri(user?.avatar)
                            }
                          />
                        </div>
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
          )
        )}
      </div>
    );
  }
);
