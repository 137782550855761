import { parse } from 'qs';
import React, { useEffect, useMemo, useState } from 'react';
import ReactPlayer from 'react-player';
import { useQuery } from 'react-query';
import { Link, useHistory, useLocation, useParams } from 'react-router-dom';
// import { AccordionCustom } from '../components/AccordionCustom';
import { HorizontalScrollView } from '@components/HorizontalScrollView';
// import { VODCurriculumItem } from '../components/VODCurriculumItem';
import { VODMentorCard } from '@components/VODMentorCard';
import { api } from '@src/plugins/axios';
import { EditorView } from '@src/plugins/froala';
import { fetcher } from '@src/plugins/react-query';
import twConfig from '../../tailwindResolver';
import { ReactComponent as Icon1 } from '@assets/icons/icon-play-filled.svg';
import { useAuth } from '../../hooks';
import {
  Role,
  Subscription,
  Tag,
  TagLinkTargetType,
  User,
  Vod,
  VodItem,
  VodView,
} from '../../types';
import styled from 'styled-components';
import { useSubscriptionPopup } from '../../hooks/subscription';
import { TOP_MENTORS_IDS } from '@src/constants/topMentors';
import { TopMentorsSlider } from '@src/components/TopMentorsSlider';
import { CardBanner } from '@src/components/CardBanner';
import { BottomSheet } from 'react-spring-bottom-sheet';
import { PaperAirplaneIcon } from '@heroicons/react/24/outline';
import { ReactHelmet } from '@src/components/ReactHelmet';
import { HiddenHeadingMeta } from '@src/components/HiddenHeadingMeta';
import mixpanel from 'mixpanel-browser';

export const VODPage = () => {
  const { authenticated } = useAuth();
  const { push, action, replace } = useHistory();
  const [open, setOpen] = useState(false);
  const history = useHistory();
  const { id } = useParams<{ id: string }>();
  const { search, state: locationState, pathname } = useLocation<any>();
  const { item } = parse(search, { ignoreQueryPrefix: true });
  const vodItemId = Number(item);
  const [selectedVOD, setSelectedVod] = useState({ free: false, id: null });
  const [enablePlayer, setEnablePlayer] = useState(false);

  const { data: homePageMentors } = useQuery<User[]>(
    `/users/mentors/${TOP_MENTORS_IDS}`,
    fetcher,
    {
      keepPreviousData: true,
      enabled: !!TOP_MENTORS_IDS,
      cacheTime: 3000000,
      staleTime: 3000000,
      refetchOnWindowFocus: false,
    }
  );

  const { data: me } = useQuery<User>('/users/me', fetcher, {
    enabled: !!authenticated,
  });
  const { data: tags } = useQuery<Tag[]>(
    `/tag-links/tags?type=${TagLinkTargetType.VOD}&id=${id}`,
    fetcher,
    {
      enabled: !!id,
      keepPreviousData: true,
    }
  );
  const { data: subscription } = useQuery<Subscription>(
    `/subscriptions/valid`,
    fetcher,
    {
      enabled: !!authenticated,
    }
  );
  const { showSubsPopup, showSubscribedPopup } = useSubscriptionPopup(
    subscription,
    me
  );

  const { data: vod } = useQuery<Vod>(`/vods/${id}`, fetcher, {
    enabled: !!id,
    keepPreviousData: true,
  });

  const { data: vodItem } = useQuery<VodItem>(
    `/vod-items/right/${vodItemId}`,
    fetcher,
    { enabled: !!vodItemId, keepPreviousData: true }
  );

  const { data: thumbnail, isLoading } = useQuery<string>(
    `/vods/thumbnail?videoId=${vodItem?.videoUrl?.replace(
      'https://vimeo.com/',
      ''
    )}`,
    fetcher,
    { enabled: !!vodItem }
  );

  useEffect(() => {
    // console.log(item, locationState, '!!SEARCH');
    if (action === 'POP' && !item && locationState?.from === '/vods') {
      replace('/vods', { from: '/vods:id' });
    }
  }, [action, history, item, locationState]);

  // canceling api calls when unmounts
  useEffect(() => {
    if (!id || !vod) return;

    // console.log('!!VOD ITEMS SORT && SET SELECTED VOD ITEM');
    if (id) {
      const vodItems: any = vod?.vodItems?.sort((a, b) => a.rank - b.rank);
      if (vodItems[0]?.id && selectedVOD?.id !== vodItems[0]?.id) {
        setSelectedVod(vodItems[0]);
      }
    }
  }, [id, vod]);

  // 오른쪽 커리큘럼에서 선택된 VOD Item 번호 PUSH
  useEffect(() => {
    setEnablePlayer(false);
    if (selectedVOD?.id) {
      push(`/vods/${id}?item=${selectedVOD?.id}`);
    }
  }, [selectedVOD]);

  useEffect(() => {
    if (vodItemId && vod && id) {
      mixpanel.track('Page Viewed', {
        item_category: 'VOD Details',
      });
      window.dataLayer.push({ ecommerce: null });
      window.dataLayer.push({
        event: 'view_item',
        ecommerce: {
          items: [
            {
              item_id: id,
              item_name: vod.title,
              item_category: 'vods',
              item_variant: vodItemId,
            },
          ],
        },
      });
    }
  }, [vodItemId, vod, id]);

  useEffect(() => {
    if (!id || !me) return;
    const vodView = async () =>
      await api.get(`/vod-views/vod/${id}`).then((res) => {
        if (!res) {
          return;
        }
        if (res.data) {
          updateVodView(id);
        } else {
          createVodView(id);
        }
      });
    vodView();

    setTimeout(() => {
      // 페이지 접속 10초 후 POPUP 띄움
      setOpen(true);
    }, 1000 * 10);
  }, [id]);

  useEffect(() => {
    // 강제로 Froala 에디터로 작성된 Heading 폰트 리사이즈 하는 코드
    //@ts-ignore
    const headingTags: HTMLCollectionOf<HTMLSpanElement>[] =
      document.getElementsByTagName('span');
    for (let i = 0; i < headingTags.length; i++) {
      //@ts-ignore
      const froalaHeading = headingTags[i].style.fontSize == '24px';
      if (froalaHeading) {
        //@ts-ignore
        headingTags[i].classList.add('froala-header');
      }
    }
  });

  const createVodView = async (vodId: any) => {
    await api.post<VodView>(`/vod-views/add`, {
      complete: true,
      vodId: Number(vodId),
    });
  };

  const updateVodView = async (vodId: any) => {
    await api.patch(`/vod-views/update`, {
      complete: true,
      vodId: Number(vodId),
    });
  };

  const now = new Date();

  const videoSection = (
    <>
      <div
        className="bg-black rounded-md relative cursor-pointer md:h-505 md:pt-0 flex items-center w-full"
        style={{ aspectRatio: '16/9', height: 'fit-content' }}
      >
        {enablePlayer ? (
          <StyledPlayerContainer>
            <ReactPlayer
              url={
                subscription
                  ? vodItem?.videoUrl ?? vod?.vodItems[0].videoUrl
                  : vodItem?.videoUrl
              }
              playsinline
              className="react-player"
              width="100%"
              height="100%"
              style={{
                position: 'absolute',
                top: 0,
                left: 0,
                background: '#000000',
              }}
              controls
            />
          </StyledPlayerContainer>
        ) : (
          //Thumbnail section
          <section
            className="w-full h-full flex"
            style={{ height: 'fit-content' }}
          >
            {!isLoading && thumbnail ? (
              <div
                className="relative w-full flex items-center justify-center"
                style={{ aspectRatio: '16/9', height: 'fit-content' }}
              >
                <img
                  src={thumbnail}
                  className="object-cover w-full"
                  // className="w-full h-full"
                  alt="thumb-nail"
                />
                <div
                  onClick={() => handlePlay()}
                  style={{ transform: 'translateY(-50%)' }}
                  className={`absolute top-[50%] hover:scale-110 hover:opacity-90 rounded-full  grid place-content-center wh-10 md:wh-14`}
                >
                  {/* <TriangleIcon /> */}
                  <Icon1
                    fill={twConfig.theme.colors['white']}
                    className="w-16 md:w-26 md:h-26"
                  />
                </div>
              </div>
            ) : (
              <div className="w-full h-full flex items-center justify-center">
                <svg
                  role="status"
                  className="w-8 h-8 mr-2 text-gray-200 animate-spin dark:text-gray-600"
                  viewBox="0 0 100 101"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                    fill="currentColor"
                  ></path>
                  <path
                    d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                    fill={twConfig.theme.colors['brand-1']}
                  ></path>
                </svg>
              </div>
            )}
          </section>
        )}
      </div>
    </>
  );

  const videoDetailSection = (
    <>
      <div className="bg-brand-navy4 shadow-lg rounded-lg py-8 px-6 ">
        <div className="flex-col md:flex-row space-y-4 md:space-x-4 md:flex justify-between items-center pb-2">
          <div>
            <div className="space-x-2 mt-1">
              {tags?.map((tag) => (
                <span key={tag.id} className="text-13 text-brand-1 mt-4">
                  {tag.name}
                </span>
              ))}
            </div>
            {/* <div className="bg-brand-1 text-white font-semibold rounded-lg text-xs text-center w-32 p-0.5 mt-2 mb-2 ">
              {vod.vodItems.length} Lesson(s)
            </div> */}
            <h1 className="text-md md:text-20 font-semibold text-white">
              {vod?.title}
            </h1>
          </div>
          {/* {me && me.role === Role.STUDENT && (
            <button
              onClick={() =>
                !subscription ||
                now.toISOString() > subscription?.current_period_end
                  ? showSubsPopup()
                  : showSubscribedPopup()
              }
              className={`rounded-full md:px-12 px-8 py-2 font-semibold ${
                !subscription ||
                now.toISOString() > subscription?.current_period_end
                  ? 'bg-brand-blue text-white'
                  : 'hidden'
              }`}
            >
              {!subscription ||
              now.toISOString() > subscription?.current_period_end
                ? 'Subscribe'
                : ''}
            </button>
          )} */}
        </div>
        {/* <p className="text-gray-400 text-13 mt-6">{vod.introduction}</p> */}
      </div>
    </>
  );

  const handleOnClickCurriculumItem = (vodItem: any) => {
    // setPlaying(false);
    setSelectedVod(vodItem);
    // push(`/vods/${id}?item=${vodItem.id}`);
  };

  const handlePlay = () => {
    if (!me) {
      push({ pathname: '/login', state: { from: { pathname: pathname } } });
    } else {
      if (
        !subscription ||
        now.toISOString() > subscription?.current_period_end
      ) {
        selectedVOD?.free ? setEnablePlayer(true) : showSubsPopup();
      } else if (subscription) {
        setEnablePlayer(true);
      }
    }
  };

  // const curriculumSection = (
  //   <div className="bg-brand-navy4 shadow-lg rounded-lg h-fit max-h-505 md:max-h-505">
  //     <div className="overflow-y-auto h-fit rounded-lg pb-4">
  //       {/* <p
  //         onClick={() => push(`/vods/${id}`)}
  //         className={`text-white text-15 px-6 py-4 cursor-pointer ${
  //           !vodItem && 'bg-gray-800 text-brand-1'
  //         }`}
  //       >
  //         Mentor Introduction
  //       </p> */}

  //       {vod?.vodChapters
  //         .sort((a, b) => a.rank - b.rank)
  //         .map((vodChapter, index) => (
  //           <AccordionCustom
  //             key={vodChapter.id}
  //             title={
  //               <div>
  //                 <p className="text-white">{vodChapter.name}</p>
  //               </div>
  //             }
  //             content={
  //               <div>
  //                 {vodChapter.vodItems
  //                   .sort((a, b) => a.rank - b.rank)
  //                   .map((item) => (
  //                     <VODCurriculumItem
  //                       key={item.id}
  //                       vodItem={item}
  //                       selected={item.id === vodItemId}
  //                       onClick={() => handleOnClickCurriculumItem(item)}
  //                     />
  //                   ))}
  //               </div>
  //             }
  //             defaultExpanded={true}
  //             whiteIcon={true}
  //           />
  //         ))}
  //     </div>
  //   </div>
  // );

  const infoSection = (
    <div className="bg-white mb-10">
      <div>
        <div className="py-4 px-5 text-littleblack text-14 leading-normal">
          <StyledFroalaContainer>
            <EditorView model={vod?.description} />
          </StyledFroalaContainer>
        </div>
      </div>

      <div className="md:px-5">
        {/* Desktop */}
        <div className="hidden md:grid grid-cols-4 gap-5">
          {vod?.vodMentors.map((vodMentor) => (
            <VODMentorCard
              key={vodMentor.id}
              mentor={vodMentor.user}
              onClick={() => push(`/mentors/${vodMentor.user.id}`)}
            />
          ))}
        </div>

        {/* Mobile */}
        <div className="grid md:hidden gap-8">
          <HorizontalScrollView>
            <div className="w-1 h-full" />
            {vod?.vodMentors.map((vodMentor) => (
              <VODMentorCard
                key={vodMentor.id}
                mentor={vodMentor.user}
                onClick={() => push(`/mentors/${vodMentor.user.id}`)}
              />
            ))}
            <div className="w-1 h-full" />
          </HorizontalScrollView>
        </div>
      </div>
    </div>
  );

  return (
    <>
      <HiddenHeadingMeta text="Video | Linkstory" />
      <ReactHelmet keywords="Video, details" title="Video | Linkstory" />
      <BottomSheet
        open={open}
        onDismiss={() => setOpen(false)}
        snapPoints={({ minHeight }) => minHeight}
      >
        <div className="isWindow px-4 pb-4 flex gap-3  flex-col">
          <h3 className="text-xl font-bold">Need help now?</h3>
          <p className="whitespace-pre-line">
            {`Submit a Quick Request to get help on anything from college essays to subject tutoring.`}
          </p>
          <Link
            to="/student-request"
            className="flex gap-2 items-center justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700"
          >
            <PaperAirplaneIcon className="wh-4" />
            <span>Quick Request</span>
          </Link>
          <button
            onClick={() => setOpen(false)}
            className="flex items-center justify-center rounded-md border bg-white px-4 py-2 text-sm font-medium text-indigo-600 shadow-sm"
          >
            Dismiss
          </button>
        </div>
      </BottomSheet>
      <div className="bg-brand-black-bg">
        {/* desktop */}
        <div className="hidden max-w-screen-xl mx-auto px-4 pt-20 w-full lg:grid lg:grid-cols-10 lg:space-x-7">
          <div className="col-span-10 flex-1 space-y-8">
            {videoSection}
            {videoDetailSection}
            {/* Mentor Slide Section */}
          </div>
          {/* <div className="col-span-4">{curriculumSection}</div> */}
        </div>

        {/* mobile */}
        <div className="max-w-screen-xl mx-auto px-4 pt-20 w-full flex flex-col lg:hidden space-y-8 ">
          {videoSection}
          {/* {curriculumSection} */}
          {videoDetailSection}
        </div>
        <div className="mt-10 w-full bg-white">
          <div className="md:py-10 max-w-screen-xl mx-auto bg-white">
            {infoSection}
          </div>
        </div>
        <CardBanner
          isEssay
          title="Need help now?"
          description="Submit a Quick Request to get help on anything from college essays to subject tutoring."
          className="!bg-white !py-8 !px-0"
          cardClassName="bg-indigo-600 text-white"
        />
      </div>
    </>
  );
};

const StyledPlayerContainer = styled.div`
  iframe {
    width: 100%;
    height: 100%;
  }
`;

const StyledFroalaContainer = styled.div`
  @media (max-width: 768px) {
    .fr-view img.fr-dii.fr-fil {
      display: none;
    }
  }
`;
