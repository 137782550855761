import React, { FC } from 'react';

export interface EmptyStateProps {
  MainIcon?: (
    props: React.SVGProps<SVGSVGElement> & {
      title?: string | undefined;
      titleId?: string | undefined;
    }
  ) => JSX.Element;
  title: string;
  subTitle: string;
  noCta?: boolean;
  ctaText?: string;
  onClick?: () => void;
  className?: string;
}

export const EmptyState: FC<EmptyStateProps> = ({
  MainIcon,
  title,
  subTitle,
  ctaText,
  noCta,
  onClick,
  className = '',
}) => {
  return (
    <div className={`text-center  ${className}`}>
      {MainIcon && (
        <MainIcon className="mx-auto wh-8 text-gray-400" aria-hidden="true" />
      )}
      <h3 className="mt-2 text-sm font-medium text-gray-900">{title}</h3>
      <p className="mt-1 text-sm text-gray-500">{subTitle}</p>
      {ctaText && onClick && (
        <div className="mt-6">
          <button
            type="button"
            onClick={() => {
              onClick();
            }}
            className="inline-flex items-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
          >
            {ctaText}
          </button>
        </div>
      )}
    </div>
  );
};
