import { BreadcrumbItemProps } from 'antd';

export interface PaginatedDto {
  page: number;
  limit: number;
  sort?: any;
}
export interface Paginated<T> {
  items: T[];
  total: number;
}

export interface Activity {
  id: number;
  createdAt: string;
  updatedAt: string;
  title: string;
  content: string;
  endedAt: string;
  closed: boolean;
}

export interface Subject {
  id: number;
  title: string;
  createdAt: string;
  updatedAt: string;
  isHidden: boolean;
}

export interface SubjectQuestion {
  id: number;
  question: string;
  createdAt: string;
  updatedAt: string;
  isHidden: boolean;
}

export interface Grade {
  id: number;
  title: string;
  createdAt: string;
  updatedAt: string;
  isHidden: boolean;
}

export interface Notice {
  id: number;
  createdAt: string;
  updatedAt: string;
  title: string;
  content: string;
  endedAt: string;
  closed: boolean;
}

export interface Document {
  id: number;
  createdAt: string;
  updatedAt: string;
  title: string;
  content: string;
  userId: number;
}

export interface Calculator {
  id: number;
  createdAt: string;
  updatedAt: string;
  schoolTagId: number;
  majorTagId?: number;
  gpa: number;
  sat: number;
  act: number;
  extra?: number;
  ap?: number;
}

export interface Chat {
  id: number;
  createdAt: string;
  updatedAt: string;
  studentNew: boolean;
  mentorNew: boolean;
  isStarted: boolean;
  studentId: number;
  mentorId: number;
  student?: User;
  mentor?: User;
  newMessageDate: string;
  messages?: Message[];
  isAnswerd: boolean;
  status: ChatStatus;
  isStudentWriteAt: null | string;
}

export interface CreditCard {
  id: number;
  createdAt: string;
  updatedAt: string;
  paymentMethodId: string;
}

export interface Enquiry {
  id: number;
  createdAt: string;
  updatedAt: string;
  majorTagId: number;
  locationTagId: number;
  gpa: number;
  sat: number;
  act: number;
  extra: number;
  ap: number;
  checked: boolean;
}

export interface Mentor {
  id: number;
  createdAt: string;
  updatedAt: string;
  firstName: string;
  lastName: string;
  avatar: string;
}

export interface Student {
  id: number;
  createdAt: string;
  updatedAt: string;
  firstName: string;
  lastName: string;
  avatar: string;
  pageCallUserId: string;
  firstSessionCompleted: boolean;
}

export interface Lesson {
  id: number;
  createdAt: string;
  updatedAt: string;
  studentId: number;
  roomId: string;
  mentorId: number;
  studentFirstName: string;
  studentLastName: string;
  mentorFirstName: string;
  mentorLastName: string;
  mentorName?: string;
  studentName?: string;
  subject?: string;
  startedAt: string;
  firstSession: boolean;
  status: LessonState;
  mentor?: Mentor;
  payment: Payment;
  student?: Student;
}

export enum LessonState {
  UPCOMING = 'UPCOMING',
  INCOMPLETE = 'INCOMPLETE',
  CHECKING = 'CHECKING',
  PENDING = 'PENDING',
  COMPLETED = 'COMPLETED',
  CANCEL = 'CANCEL',
}

export interface LessonReview {
  title: any;
  id: number;
  createdAt: string;
  updatedAt: string;
  studentId: number;
  lessonId: number;
  description: string;
  text: string;
  rating: number;
  totalRating?: string;
  student: { firstName: string };
}

export interface LessonFeedback {
  id: number;
  createdAt: string;
  updatedAt: string;
  mentorId: number;
  lessonId: number;
  studentId: number;
  text: string;
}

export interface Message {
  isAnswered?: boolean;
  id: number;
  createdAt: string;
  updatedAt: string;
  text: string;
  isFirstSession?: boolean;
  userId: number;
  chatId: number;
  priceId: string | null;
  productId: string | null;
  jobId: number | null;
  lessonId: number | null;
  type: MessageType;
  fileName: string | null;
  fileType: FileType | null;
  isMentorRead: boolean;
  isStudentRead: boolean;
  mentorReadAt: string | null;
  studentReadAt: string | null;
}

export enum MessageType {
  TEXT = 'TEXT',
  PRODUCT = 'PRODUCT',
  REQUEST = 'REQUEST',
  LESSON = 'LESSON',
  APPLICATION = 'APPLICATION',
  FILE = 'FILE',
}

export interface Product {
  id: number;
  createdAt: string;
  updatedAt: string;
  title: string;
  price: number;
  count: number;
  startedAt: string;
}

export interface Payment {
  id: number;
  createdAt: string;
  updatedAt: string;
  status: PaymentStatus;
  userId: number;
  productId: number;
  chargeId?: string;
  amount: number;
  lessonId: number;
  product?: Product;
}

export enum PaymentStatus {
  SUCCESS = 'SUCCESS',
  CANCEL = 'CANCEL',
}

export interface Subscription {
  id: number;
  createdAt: string;
  updatedAt: string;
  current_period_start: string;
  current_period_end: string;
  price: string;
  userId: number;
  valid: boolean;
}

export interface Tag {
  id: number;
  createdAt: string;
  updatedAt: string;
  type: TagType;
  name: string;
  parentId: number;
  majors: Tag[];
}

export enum TagType {
  MENTOR = 'MENTOR',
  VOD = 'VOD',
  ACTIVITY = 'ACTIVITY',
  NOTICE = 'NOTICE',
  SCHOOL = 'SCHOOL',
  MAJOR = 'MAJOR',
  LOCATION = 'LOCATION',
  GRADE = 'GRADE',
  DEPARTMENT = 'DEPARTMENT',
}

export interface TagLink {
  id: number;
  createdAt: string;
  updatedAt: string;
  targetType: TagLinkTargetType;
  targetId: number;
  tagId: number;
}

export enum TagLinkTargetType {
  USER = 'USER',
  VOD = 'VOD',
  ACTIVITY = 'ACTIVITY',
  NOTICE = 'NOTICE',
}

export interface User {
  id: number;
  createdAt: string;
  updatedAt: string;
  email: string;
  role: Role;
  firstName: string;
  lastName: string;
  avatar: string;
  nationality: number;
  school: number;
  major: number;
  dob: string;
  sat: number;
  gpa: number;
  pageCallAccessToken: string;
  googleId: string;
  facebookId: string;
  isEmailValidated: boolean;
  firstSessionCompleted: boolean;
  studentType?: StudentType;
  // MENTOR only
  proofOfEducation: string;
  payPalEmail: string;
  act: number;
  ap: number;
  approved: boolean;
  disclosure: boolean;
  homePageMentor: boolean;
  homePageConsultant: boolean;
  pageCallUserId: string;
  extra: number;
  introductionVideoUrl: string;
  introduction: string;
  description: string;
  chatState: ChatState;
  priority: number;
  subscriptions: Subscription[];
  adminMemo: string;
  availability: boolean;
  avgResponseTime: string;
  avgResponseRate: string;
  avgRating?: number | null;
  totalReviewCount: number;
  feeRate: number;
}

export enum ChatState {
  AVAILABLE = 'AVAILABLE',
  NOTAVAILABLE = 'NOTAVAILABLE',
}

export enum StudentType {
  HIGH_SCHOOL_STUDENT = 'HIGH_SCHOOL_STUDENT',
  PARENT = 'PARENT',
  TRANSFER_STUDENT = 'TRANSFER_STUDENT',
  HIGH_SCHOOL_COUNSELOR = 'HIGH_SCHOOL_COUNSELOR',
  INDEPENDENT_EDUCATIONAL_COUNSELOR = 'INDEPENDENT_EDUCATIONAL_COUNSELOR',
}

export enum ReferralType {
  INSTAGRAM = 'INSTAGRAM',
  FACEBOOK = 'FACEBOOK',
  YOUTUBE = 'YOUTUBE',
  TIKTOK = 'TIKTOK',
  TWITTER = 'TWITTER',
  EMAIL = 'EMAIL',
  GOOGLE = 'GOOGLE',
  WORD_OF_MOUTH = 'WORD_OF_MOUTH',
  OTHER = 'OTHER',
}

export enum Role {
  ADMIN = 'ADMIN',
  MENTOR = 'MENTOR',
  STUDENT = 'STUDENT',
}

export interface Vod {
  id: number;
  createdAt: string;
  updatedAt: string;
  title: string;
  description: string;
  thumbnail: string;
  category: string;
  summary: string;
  introduction: string;
  introUrl: string;
  disclosure: boolean;
  vodChapters: VodChapter[];
  vodItems: VodItem[];
  vodMentors: VodMentor[];
}

export interface VodChapter {
  id: number;
  createdAt: string;
  updatedAt: string;
  name: string;
  vodId: number;
  exposureRank: number;
  disclosure: boolean;
  rank: number;
  vodItems: VodItem[];
}

export interface VodItem {
  id: number;
  createdAt: string;
  updatedAt: string;
  title: string;
  videoUrl: string;
  disclosure: boolean;
  rank: number;
  free: boolean;
  vodId: number;
  vodChapterId: number;
}

export interface VodMentor {
  id: number;
  createdAt: string;
  updatedAt: string;
  vodId: number;
  userId: number;
  user: User;
}

export interface VodView {
  id: number;
  createdAt: string;
  updatedAt: string;
  complete: boolean;
  userId: number;
  vodId: number;
}

export enum durationType {
  ONCE = 'once',
  REPEATING = 'repeating',
  FOREVER = 'forever',
}

export interface FaqItem {
  title: string;
  content: JSX.Element | string;
}

export interface FaqItems {
  FaqItems: FaqItem[];
}

export const GOOGLE_SOCIAL_URL = `https://accounts.google.com/o/oauth2/v2/auth?client_id=${process.env.REACT_APP_GOOGLE_CLIENT_ID}&redirect_uri=${process.env.REACT_APP_GOOGLE_REDIRECT_URI}&response_type=code&scope=openid+profile+email`;

export interface GoogleSocialSignUp {
  idToken: string;
  email: string;
  firstName: string;
  lastName: string;
  picture: string;
  birthDate?: {
    day: number;
    month: number;
    year: number;
  };
}

export interface FacebookSocialSignUp {
  id: string;
  first_name: string;
  last_name: string;
  picture?: {
    data: {
      height: number;
      is_silhouette: boolean;
      url: string;
      width: number;
    };
  };
  email?: string;
}

export interface SocialSignUp {
  google?: GoogleSocialSignUp;
  facebook?: FacebookSocialSignUp;
}

export interface EssayItemType {
  id: string;
  school: { name: string; logo: string };
  title: string;
  subtitle: string;
  pdf: string;
  locked: boolean;
}

export enum PricingPlanEnum {
  LINKSTORYPLUS = 'LINKSTORYPLUS',
  MENTORING = 'MENTORING',
}

export enum MailChimpMemberStatus {
  SUBSCRIBED = 'subscribed',
  PENDING = 'pending',
  // NONE_SUBSCRIBED = 'nonesubscribed',
}

export enum MailChimpTagsType {
  DOLLAR_COUPON_MAIL = 'dollarcouponemail',
  $30_MENTORING = '$30mentoring',
  STUDENT = 'student',
}

export enum SignUpType {
  EMAIL = 'email',
  GOOGLE = 'google',
  FACEBOOK = 'facebook',
  APPLE = 'apple',
}

export enum TutorType {
  MENTOR = 'MENTOR',
  ADVISOR = 'ADVISOR',
}

export interface WebibarItemType {
  id: number;
  tutorId: number;
  featured: boolean;
  type: TutorType;
  webinar: {
    img: string;
    title: string;
    summary: string;
    date: string;
    endDate: string;
    timezone: string;
    duration: string;
    mc_tag: string;
    video_url?: string;
  };
}

export interface QuestionAnswerType {
  text: string;
  questionId: number;
}

export enum FileType {
  FILE = 'FILE',
  IMAGE = 'IMAGE',
}

export enum RequestResponseType {
  DEFALT = 'DEFALT',
  REJECT_PRESET = 'REJECT_PRESET',
  REJECT_CUSTOM = 'REJECT_CUSTOM',
}

export interface CreateLessonType {
  startedAt: Date;
  endedAt: Date;
  price: number;
  studentId: number;
}

export interface JobType {
  createdAt: string;
  description: string;
  firstRequestTime: string;
  secondRequestTime?: string;
  thirdRequestTime?: string;
  grade: Grade;
  gradeId: number;
  id: number;
  isClosed: boolean;
  isOnline: boolean;
  isPrivate: boolean;
  subjectId: number;
  subject: Subject;
  targetMentorId: number;
  updatedAt: string;
  writer: User;
  writerId: number;
  isApplied: boolean;
  questionAnswers?: QuestionAnswersType[];
  jobFiles: JobFile[];
  jobSubmits: [];
  jobSubmitCount: number;
}

export interface JobMessageType extends Message {
  chat: Chat;
}

export interface JobAdminType extends JobType {
  messages: JobMessageType[];
}

export interface JobFile {
  createdAt?: string;
  fileKey: string;
  filename: string;
  id?: number;
  jobId?: number;
  updatedAt?: string;
}

export interface QuestionAnswersType {
  createdAt: string;
  id: number;
  jobId: number;
  question: {
    createdAt: string;
    id: number;
    isHidden: false;
    question: String;
    subjectId: number;
    updatedAt: string;
  };
  questionId: number;
  text: string;
  updatedAt: string;
  writerId: number;
}

export enum OrderType {
  ASC = 'ASC',
  DESC = 'DESC',
}

export enum ChatStatus {
  LOCKED = 'LOCKED',
  REQUESTS = 'REQUESTS',
  ONLINE = 'ONLINE',
}

export interface AppliedJobType {
  id: number;
  createdAt: string;
  updatedAt: string;
  jobId: number;
  price: number;
  message: string;
  studentId: number;
  mentorId: number;
  job: JobType;
  student: User;
}

export enum ReceiptStatus {
  READY = 'READY',
  PENDING = 'PENDING',
  COMPLETE = 'COMPLETE',
}

export enum PaymentReceiptStatus {
  UNPAID = 'UNPAID',
  PAID = 'PAID',
}

export interface Receipt {
  id: number;
  status: ReceiptStatus;
  jobId?: number;
  mentorId: number;
  mentor: User;
  lessonId: number;
  lesson: SessionType;
  // 멘토가 요청한 금액
  price: string;
  // 수수료를 제외한 실제 지급 금액
  realPrice: string;
  totalPrice: string;
  totalRealPrice: string;
  paymentStatus: PaymentReceiptStatus;
  sessionTitle: string;
  sessionReview: { title: string; description: string };
}

export interface PaymentDetails {
  totalEarnings: number;
  amountPaid: number;
  amountOwed: number;
  payPercent: number;
}

export interface SessionType {
  createdAt: string;
  endedAt: string;
  firstSession: boolean;
  id: number;
  lessonReviews: LessonReview[];
  mentorId: number;
  mentorName: string;
  paymentId?: number;
  price: number;
  receipts: Receipt[];
  roomId: number;
  startedAt: string;
  status: LessonState;
  studentId: number;
  studentName: string;
  subjectId: number;
  subjectTitle: string;
  title: string;
  updatedAt: string;
  mentor: User;
  student: User;
  newPrice: number | null;
  changePriceState: ChangePriceState;
}

export enum ChangePriceState {
  NONE = 'NONE', // 요청 없음
  REQUEST_CHANGE_PRICE = 'REQUEST_CHANGE_PRICE', // 금액 변경 요청
  ACCEPT = 'ACCEPT', // 수락
  DECLINE = 'DECLINE', // 거절
}

export interface JobApplicationItemType {
  message: string;
  mentorFirstName: string;
  mentorLastName: string;
  price: string;
}

export enum emailVerifiacationStatus {
  PENDING = 'PENDING', // 이메일 인증 대기 상태
  VERIFIED = 'VERIFIED', // 이메일 인증 완료 상태
}

export enum paymentMethodStatus {
  NOTADDED = 'ADDED', // 이메일 인증 대기 상태
  ADDED = 'ADDED', // 이메일 인증 완료 상태
}

export enum stibeeGroupId {
  STAGE_STUDENT_GROUP_ID = '222802',
  STAGE_TUTOR_GROUP_ID = '222801',
  PROD_STUDENT_GROUP_ID = '222821',
  PROD_TUTOR_GROUP_ID = '222820',
}

export interface AdminSessionType {
  amount: number;
  changePriceState: ChangePriceState;
  chargeId: string;
  createdAt: string;
  endedAt: null | string;
  firstSession: boolean;
  id: number;
  mentorFirstName: string;
  mentorId: number;
  mentorLastName: string;
  mentorName: string;
  newPrice: null | number;
  paymentId: number;
  price: null | number;
  roomId: null;
  startedAt: string;
  status: LessonState;
  studentFirstName: string;
  studentId: number;
  studentLastName: string;
  studentName: string;
  subjectId: null | number;
  subjectTitle: string;
  title: string;
  updatedAt: string;
  mentorFeeRate: number | null;
  paymentStatus: PaymentReceiptStatus;
  realPrice: null | string;
}
