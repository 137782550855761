import React, { useEffect, useMemo, useState } from 'react';
import { Link, useHistory, useLocation, useParams } from 'react-router-dom';
import { WEBINAR_ITEMS } from '@constants/webinar';
import { MomentFormat, utcToLocalFormat } from '@plugins/moment';
import { Avatar, Divider } from '@material-ui/core';
import { SwiperSlide } from 'swiper/react';
import styled, { css } from 'styled-components';
import {
  Chat,
  ChatState,
  MailChimpMemberStatus,
  Role,
  Tag,
  TagLink,
  TagType,
  TutorType,
  User,
} from '@src/types';
import { WebinarSlideCard } from '@components/WebinarSlideCard';
import Slider from '@components/Slider';
import { SearchMentoring } from '@components/SearchMentoring';
import { useAuth } from '@src/hooks';
import { fetcher } from '@plugins/react-query';
import { useQuery } from 'react-query';
import {
  patchMailChimpUpdateMember,
  postMailChimpAddMember,
} from '@api/mailchimp';
import { getS3Uri } from '@src/utils';
import { parse } from 'qs';
import moment from 'moment-timezone';
import { api } from '@src/plugins/axios';
import { fullModalState } from '@plugins/ridge';
import ReactPlayer from 'react-player';
import { TOP_MENTORS_IDS } from '@src/constants/topMentors';
import { TopMentorsSlider } from '@src/components/TopMentorsSlider';

export const WebinarDetails = () => {
  const { id } = useParams<{ id: string }>();
  const { search, pathname } = useLocation();
  const { tutorId } = parse(search, { ignoreQueryPrefix: true });
  const { authenticated } = useAuth();
  const { data: me } = useQuery<User>('/users/me', fetcher, {
    enabled: !!authenticated,
  });
  const { data: mentor, status } = useQuery<User>(`/users/${tutorId}`, fetcher);
  const { push } = useHistory();
  const [item, setItem] = useState<any>(null);
  const [searchType, setSearchType] = useState('/mentors');
  const [q, setQ] = useState('');
  const [success, setSuccess] = useState<boolean>(false);
  const [ErrorMessage, setErrorMessage] = useState<string>('');
  const { data: chat } = useQuery<Chat>(`/chats/mentor/${id}`, fetcher, {
    enabled: me?.role === Role.STUDENT,
  });

  const { data: schoolTags } = useQuery<Tag[]>(
    `/tags?type=${TagType.SCHOOL}`,
    fetcher
  );
  const { data: tagLinks } = useQuery<TagLink[]>(
    `/tag-links/mentor/${tutorId}`,
    fetcher
  );

  const schoolTagId = tagLinks?.find(
    (tagLink: any) => tagLink.tag.type === TagType.SCHOOL
  )?.tagId;

  const school = schoolTags?.find((tag) => tag.id === schoolTagId);

  const onSearch = (value?: string) => {
    push({
      pathname: searchType,
      search: `?searchterm=${value ? value : q}`,
    });
    window.dataLayer.push({
      event: 'search',
      ecommerce: {
        search_term: q,
      },
    });
  };

  const { data: homePageMentors } = useQuery<User[]>(
    `/users/mentors/${TOP_MENTORS_IDS}`,
    fetcher,
    {
      keepPreviousData: true,
      enabled: !!TOP_MENTORS_IDS,
      cacheTime: 3000000,
      staleTime: 3000000,
      refetchOnWindowFocus: false,
    }
  );

  const randomizeArr = (data: any): any => {
    const mentors = data?.sort(() => Math.random() - 0.5) ?? [];
    return mentors;
  };

  const mentorsRandomized: any = useMemo(() => {
    return randomizeArr(homePageMentors);
  }, [homePageMentors]);

  const CSSOverride = css`
    .swiper-container {
      padding-left: 0px;
      padding-right: 0px;
    }
  `;

  const SliderBreakPoints = {
    960: {
      slidesPerView: 3,
      slidesPerGroup: 3,
      spaceBetween: 25,
    },
    640: {
      slidesPerView: 2,
      slidesPerGroup: 2,
      spaceBetween: 20,
    },
    320: {
      slidesPerView: 1,
      slidesPerGroup: 1,
      spaceBetween: 20,
    },
  };

  useEffect(() => {
    if (id) {
      const item = WEBINAR_ITEMS.find((item) => item.id === Number(id));
      setItem(item);

      if (!item) {
        // 404 not found
        push('/404');
      }
    }
  }, [id, push]);

  const handleBookAMentor = (chat: any, me: any) => {
    if (!authenticated) {
      push({
        pathname: '/login',
        state: { from: { pathname: pathname } },
      });
    } else if (me?.role !== Role.STUDENT) {
      alert('Only student accounts are allowed to talk to tutors');
    } else {
      chat
        ? push(`/my/chats?id=${chat.id}`)
        : api
            .post('/chats', { mentorId: mentor?.id })
            .then(({ data }) => push(`/my/chats?id=${data.id}`))
            .catch(() => alert('Something went wrong'));
    }
  };

  const handleApiMailChimpMember = async (formData: any) => {
    const response = !authenticated
      ? await postMailChimpAddMember(formData)
      : await patchMailChimpUpdateMember(formData);
    console.log('RESPONSE : ', response);
    if (response !== 'success') {
      setErrorMessage(response.toString());
    } else {
      setSuccess(true);
    }
  };

  const onSubmit = (item: any) => {
    if (!authenticated) {
      push({
        pathname: '/login',
        state: { from: { pathname: pathname } },
      });
      return;
    }

    setSuccess(false);
    setErrorMessage('');

    const formData = {
      email_address: me?.email,
      tags: [item.webinar.mc_tag],
      status: MailChimpMemberStatus.SUBSCRIBED,
    };
    if (formData.email_address) {
      handleApiMailChimpMember(formData);
    } else {
      alert('Error : please sign out and re-login');
    }
  };

  if (!item) return null;

  return (
    <div className="bg-[#F7F9FC]">
      <main className="max-w-container ">
        <section className="flex flex-col lg:grid grid-cols-12 my-10 space-y-8 lg:space-y-0">
          <div
            style={{ height: 'fit-content' }}
            className="col-span-4 bg-white rounded-xl border shadow-sm overflow-hidden gap-5"
          >
            <img src={item.webinar.img} alt={`img ${mentor?.firstName}`} />
            <div className="px-5 py-8 ">
              {new Date(item.webinar.date) > new Date() && (
                <h3 className="font-bold text-xl">Reserve your spot now!</h3>
              )}

              <p className="mt-4 text-16 text-[#7783A8]">
                {utcToLocalFormat(item.webinar.date, MomentFormat.ddddLLHmma)}{' '}
                {moment.tz(moment.tz.guess()).zoneAbbr()}
              </p>
              <p className="mt-2 inline-block px-3 py-1 text-xs font-medium tracking-wider text-brand-blue bg-[#E0E7FF] rounded-md">
                {item.webinar.duration}
              </p>
              <button
                type="submit"
                onClick={() => {
                  if (
                    new Date(item.webinar.date) < new Date() &&
                    item.webinar.video_url
                  ) {
                    fullModalState.set({
                      open: true,
                      content: (
                        <StyledPlyrWrapper>
                          <ReactPlayer
                            url={item.webinar.video_url}
                            className="react-player rounded-md overflow-hidden absolute top-0 left-0 bg-black"
                            width="100%"
                            height="100%"
                            controls
                            style={{
                              maxHeight: 'calc(100vh - 126px)',
                            }}
                          />
                        </StyledPlyrWrapper>
                      ),
                    });
                  } else {
                    onSubmit(item);
                  }
                }}
                disabled={
                  new Date(item.webinar.date) < new Date() &&
                  !item.webinar.video_url
                }
                className="disabled:bg-gray-400 disabled:text-black disabled:text-opacity-60 mt-10 mb-2 inline-flex items-center justify-center h-12 px-10 font-medium tracking-wide text-white transition duration-200 bg-brand-blue rounded-md hover:bg-brightness-110 focus:shadow-outline focus:outline-none w-full"
              >
                {new Date(item.webinar.date) < new Date() &&
                !item.webinar.video_url
                  ? 'Recording coming soon...'
                  : item.webinar.video_url
                  ? 'Watch it again'
                  : 'Register'}
              </button>
              <p className=" !my-2 text-sm">
                <p className="whitespace-pre-line text-sm">
                  {/* 에러 메시지 길 경우에 줄바꿈 처리 */}
                  {ErrorMessage &&
                    ErrorMessage.replace(/(\.[^.]*\.)/g, '$1 \n')}
                  {success &&
                    `Please allow up to two minutes for the instructions to arrive`}
                </p>
              </p>
              <p className="text-sm text-[#7783A8]">
                Please note that the webinars will be recorded and posted on our
                website for other users to watch. The recordings may also be
                used in our promotional content in the future. You are NOT
                required to turn your camera on or speak during the session.{' '}
              </p>
            </div>
          </div>
          <div className="col-span-8 lg:pl-8">
            <h1 className="text-xl lg:text-4xl font-bold">
              {item.webinar.title}
            </h1>
            <div className="my-5 flex flex-wrap gap-4 items-center">
              <p className=" text-16 text-[#7783A8]">
                {utcToLocalFormat(item.webinar.date, MomentFormat.ddddLLHmma)}{' '}
                {moment.tz(moment.tz.guess()).zoneAbbr()}
              </p>
              <p className=" inline-block px-3 py-1 text-xs font-medium tracking-wider text-brand-blue bg-[#E0E7FF] rounded-md">
                {item.webinar.duration}
              </p>
            </div>
            <p className="whitespace-pre-line">{item.webinar.summary}</p>
            <div className="my-10">
              <Divider />
            </div>
            <div className="flex items-center justify-between flex-wrap gap-8">
              <div className="flex items-center gap-4 ">
                <Avatar
                  src={getS3Uri(mentor?.avatar)}
                  alt="avatar"
                  className="!wh-16 object-cover rounded-full"
                />
                <div>
                  <p className="text-[#7783A8] text-xs">{school?.name}</p>
                  <p className="font-semibold text-xl mt-1">
                    {mentor?.firstName} {mentor?.lastName}
                  </p>
                </div>
              </div>
              {mentor?.chatState === ChatState.AVAILABLE && (
                <button
                  className="text-12 lg:text-16 inline-flex items-center justify-center h-12 px-4 lg:px-6 lg:mr-6 font-medium tracking-wide text-white transition duration-200 bg-brand-blue rounded-full hover:bg-brightness-110 focus:shadow-outline focus:outline-none"
                  onClick={() => handleBookAMentor(chat, me)}
                >
                  {`Talk to ${mentor.firstName}`}
                </button>
              )}
            </div>
            <div className="mt-10">
              <h4 className="text-xs text-[#7783A8]">BIO</h4>
              <p>{mentor?.introduction}</p>
            </div>
          </div>
          <div className="col-span-12">
            {/* Upcoming by peer mentors */}
            <div>
              {WEBINAR_ITEMS.filter(
                (item) =>
                  new Date(item.webinar.endDate) > new Date() &&
                  item.type === TutorType.MENTOR &&
                  item.id !== Number(id)
              ).length >= 1 && (
                <h2 className="mt-4 lg:mt-8 mb-4 lg:mb-6  text-xl lg:text-2xl font-bold text-brand-navy2">
                  Upcoming by peer tutors
                </h2>
              )}
              <Slider
                slidesPerView={'auto'}
                breakpoints={SliderBreakPoints}
                stylesOverride={CSSOverride}
              >
                {WEBINAR_ITEMS.filter(
                  (item) =>
                    new Date(item.webinar.endDate) > new Date() &&
                    item.type === TutorType.MENTOR &&
                    item.id !== Number(id)
                )
                  ?.sort(
                    (a, b) =>
                      new Date(b.webinar.endDate).getTime() -
                      new Date(a.webinar.endDate).getTime()
                  )
                  .map((item: any, index: number) => (
                    <SwiperSlide key={`Webinar_Slide_${index}`}>
                      <WebinarSlideCard item={item} />
                    </SwiperSlide>
                  ))}
              </Slider>
            </div>
            <div>
              {WEBINAR_ITEMS.filter(
                (item) =>
                  new Date(item.webinar.endDate) > new Date() &&
                  item.type === TutorType.ADVISOR &&
                  item.id !== Number(id)
              ).length >= 1 && (
                <h2 className="mt-4 lg:mt-8 mb-4 lg:mb-6  text-xl lg:text-2xl font-bold text-brand-navy2">
                  Upcoming by by advisors
                </h2>
              )}
              <Slider
                slidesPerView={'auto'}
                breakpoints={SliderBreakPoints}
                stylesOverride={CSSOverride}
              >
                {WEBINAR_ITEMS.filter(
                  (item) =>
                    new Date(item.webinar.endDate) > new Date() &&
                    item.type === TutorType.MENTOR &&
                    item.id !== Number(id)
                )
                  ?.sort(
                    (a, b) =>
                      new Date(b.webinar.endDate).getTime() -
                      new Date(a.webinar.endDate).getTime()
                  )
                  .map((item: any, index: number) => (
                    <SwiperSlide key={`Webinar_Slide_${index}`}>
                      <WebinarSlideCard item={item} />
                    </SwiperSlide>
                  ))}
              </Slider>
            </div>
            {/* Watch past webinars */}
            <div>
              {WEBINAR_ITEMS.filter(
                (item) => new Date(item.webinar.date) < new Date()
              ).length >= 1 && (
                <h2 className="mt-4 lg:mt-8 mb-4 lg:mb-6  text-xl lg:text-2xl font-bold text-brand-navy2">
                  Watch past webinars
                </h2>
              )}
              <Slider
                slidesPerView={'auto'}
                breakpoints={SliderBreakPoints}
                stylesOverride={CSSOverride}
              >
                {WEBINAR_ITEMS.filter(
                  (item) =>
                    new Date(item.webinar.date) < new Date() &&
                    item.id !== Number(id)
                )
                  ?.sort(
                    (a, b) =>
                      new Date(b.webinar.date).getTime() -
                      new Date(a.webinar.date).getTime()
                  )
                  .map((item: any, index: number) => (
                    <SwiperSlide key={`Webinar_Slide_${index}`}>
                      <WebinarSlideCard item={item} isExpired={true} />
                    </SwiperSlide>
                  ))}
              </Slider>
            </div>
            <div className="relative bg-[#DBE4FE] flex flex-col items-center py-8 px-4 gap-4 mt-10 rounded-xl">
              <svg
                className="absolute bottom-0 left-0 "
                xmlns="http://www.w3.org/2000/svg"
                width="106"
                height="162"
                fill="none"
                viewBox="0 0 106 162"
              >
                <path
                  fill="#fff"
                  fill-opacity=".5"
                  fill-rule="evenodd"
                  d="M51.478 3.032a94.989 94.989 0 0 1 28.495 18.975A94.869 94.869 0 0 1 98.97 50.09a89.287 89.287 0 0 1 0 69.448 91.83 91.83 0 0 1-18.997 28.273A94.99 94.99 0 0 1 61.071 162H10c-5.523 0-10-4.477-10-10v-16.671a53.057 53.057 0 0 0 37.61-1.37 55.29 55.29 0 0 0 16.908-11.385 51.038 51.038 0 0 0 11.398-16.888 50.42 50.42 0 0 0 4.179-20.682 52.888 52.888 0 0 0-3.96-20.543 52.938 52.938 0 0 0-11.617-17.407A55.284 55.284 0 0 0 37.61 35.669 55.34 55.34 0 0 0 0 34.363V10C0 4.477 4.477 0 10 0h33.415a84.596 84.596 0 0 1 8.063 3.032ZM0 101.46V68.539a23.69 23.69 0 0 1 17.096-7.258C30.21 61.281 40.84 71.901 40.84 85c0 13.1-10.63 23.719-23.745 23.719A23.69 23.69 0 0 1 0 101.461Z"
                  clip-rule="evenodd"
                />
              </svg>
              <svg
                className="absolute bottom-0 right-0 "
                xmlns="http://www.w3.org/2000/svg"
                width="103"
                height="145"
                fill="none"
                viewBox="0 0 103 145"
              >
                <path
                  fill="#fff"
                  fill-opacity=".95"
                  fill-rule="evenodd"
                  d="M103 .902V135c0 5.522-4.477 10-10 10H1.033A131.772 131.772 0 0 1 0 128.5C0 65.86 44.132 13.529 103 .902Z"
                  clip-rule="evenodd"
                />
              </svg>
              <h2 className="text-center font-semibold whitespace-pre-line md:whitespace-normal text-lg md:text-2xl">{`Want to speak 1:1 with a tutor? \nFind them here!`}</h2>
              <div className="w-full max-w-[450px]" style={{ zIndex: 1 }}>
                <SearchMentoring
                  placeholder="Search - school, major, subject"
                  value={q}
                  className="text-black font-semibold"
                  onChange={(e) => {
                    setQ(e.target.value);
                  }}
                  onSearch={() => onSearch()}
                  onSearchTypeChange={(value) => setSearchType(value)}
                />
              </div>
            </div>
            {/* Mentor Slide Section */}
            <TopMentorsSlider
              mentors={mentorsRandomized}
              className="!max-w-full"
              wrapperClassName="!bg-transparent"
            />
          </div>
        </section>
      </main>
    </div>
  );
};

const StyledPlyrWrapper = styled.div`
  iframe {
    width: 100%;
    height: 100%;
  }

  /* @media (min-width: 700px) { */
  --video--width: 1920;
  --video--height: 1080;
  /* pointer-events : none; */
  position: relative;
  padding-bottom: calc(
    var(--video--height) / var(--video--width) * 100%
  ); /* 41.66666667% */
  overflow: hidden;
  max-width: 100%;
  /* } */
`;
