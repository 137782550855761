import React, { useState } from 'react';
import { Link, useHistory, useParams } from 'react-router-dom';
import { AttachedFiles } from '@src/components/AttachedFiles';
import { BreadCrumbs } from '@src/components/BreadCrumbs';
import {
  BriefcaseIcon,
  ClockIcon,
  CurrencyDollarIcon,
} from '@heroicons/react/24/outline';
import { Receipt, Role, SessionType, User } from '@src/types';
import { useAuth, usePageCall } from '@src/hooks';
import { useQuery } from 'react-query';
import { fetcher } from '@src/plugins/react-query';
import { MomentFormat, utcToLocalFormat } from '@src/plugins/moment';
import { api } from '@src/plugins/axios';
import { popupState } from '@src/plugins/ridge';
import { Avatar } from '@src/components/Avatar';
import { getS3Uri } from '@src/utils';
import moment from 'moment';
import { HiddenHeadingMeta } from '@src/components/HiddenHeadingMeta';
import { ReactHelmet } from '@src/components/ReactHelmet';

export const SubmittedSessionDetails = () => {
  const { push, goBack } = useHistory();
  const { id } = useParams<{ id: string }>();
  const { authenticated } = useAuth();
  const { data: me } = useQuery<User>('/users/me', fetcher, {
    enabled: !!authenticated,
  });

  const { data: receiptItem } = useQuery<Receipt>(
    `/v2/receipts/${id}`,
    fetcher,
    {
      enabled: !!authenticated,
    }
  );

  const contentsSection = () => {
    return (
      <>
        <div className="">
          <div className=" border-gray-200">
            <dl>
              <div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                {me?.role === Role.MENTOR ? (
                  <>
                    <dt className="text-sm font-medium text-gray-500">
                      Student
                    </dt>
                    <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0 flex items-center gap-2">
                      <Avatar
                        src={getS3Uri(receiptItem?.lesson.student?.avatar)}
                        alt="avatar"
                        className="!wh-8 object-cover rounded-full"
                      />
                      <span>{receiptItem?.lesson.studentName}</span>
                    </dd>
                  </>
                ) : (
                  <>
                    <dt className="text-sm font-medium text-gray-500">TUtor</dt>
                    <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0 flex items-center gap-2">
                      <Avatar
                        src={getS3Uri(receiptItem?.lesson.mentor?.avatar)}
                        alt="avatar"
                        className="!wh-8 object-cover rounded-full"
                      />
                      <span>{receiptItem?.lesson.mentor?.firstName}</span>
                    </dd>
                  </>
                )}
              </div>

              <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                <dt className="text-sm font-medium text-gray-500">Subject</dt>
                <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                  {receiptItem?.lesson.subjectTitle}
                </dd>
              </div>
              <div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                <dt className="text-sm font-medium text-gray-500">
                  Session Title
                </dt>
                <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0 truncate-2-lines">
                  {receiptItem?.lesson.title}
                </dd>
              </div>

              <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                <dt className="text-sm font-medium text-gray-500">
                  Start time
                </dt>
                <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0 space-y-3">
                  <dt className="text-sm flex items-center gap-2">
                    <ClockIcon
                      className="wh-4 flex-shrink-0"
                      aria-hidden="true"
                    />
                    <span>
                      {receiptItem?.lesson &&
                        utcToLocalFormat(
                          receiptItem?.lesson.startedAt,
                          MomentFormat.YYYYMMDDhmma
                        )}{' '}
                      {moment.tz(moment.tz.guess()).zoneAbbr()}
                    </span>
                  </dt>
                </dd>
              </div>
              <div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                <dt className="text-sm font-medium text-gray-500">End Time</dt>
                <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0 space-y-3">
                  <dt className="text-sm flex items-center gap-2">
                    <ClockIcon
                      className="wh-4 flex-shrink-0"
                      aria-hidden="true"
                    />
                    <span>
                      {receiptItem?.lesson &&
                        utcToLocalFormat(
                          receiptItem?.lesson?.endedAt,
                          MomentFormat.YYYYMMDDhmma
                        )}{' '}
                      {moment.tz(moment.tz.guess()).zoneAbbr()}
                    </span>
                  </dt>
                </dd>
              </div>

              <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                <dt className="text-sm font-medium text-gray-500">Price</dt>
                <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0 space-y-3">
                  <dt className="text-sm flex items-center gap-4">
                    <div className="flex items-center gap-2">
                      <CurrencyDollarIcon
                        className="wh-4 flex-shrink-0"
                        aria-hidden="true"
                      />
                      <span>
                        {receiptItem?.price
                          ? Number(receiptItem?.price).toFixed(2)
                          : (0).toFixed(2)}
                      </span>
                    </div>
                  </dt>
                </dd>
              </div>
              <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                <dt className="text-sm font-medium text-gray-500">
                  Session Summary
                </dt>
                <div
                  className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0"
                  style={{ wordBreak: 'break-word' }}
                >
                  {receiptItem?.sessionReview}
                </div>
              </div>
            </dl>
          </div>
        </div>
      </>
    );
  };

  return (
    <>
      <HiddenHeadingMeta text="Submitted session details | Linkstory" />
      <ReactHelmet
        keywords="session, details"
        title="Submitted session details | Linkstory"
      />
      <BreadCrumbs
        homePath="/my/dashboard"
        breadCrumbsPages={[
          {
            name: 'Session Details',
            path: `/my/dashboard/session/${id}`,
          },
        ]}
      />

      <div className="space-y-4 bg-white border w-full rounded-md overflow-hidden">
        <section className="whitespace-pre-line">
          <div className="border-b border-gray-200 bg-white px-4 py-5 sm:px-6">
            <div className="-ml-4 -mt-2 flex flex-wrap items-center justify-between sm:flex-nowrap">
              <div className="ml-4 mt-2 flex gap-2 items-center">
                <BriefcaseIcon className="wh-6 flex-shrink-0 self-center text-xs" />
                <h3 className="text-lg font-medium leading-6 text-gray-900">
                  Submitted Session Details
                </h3>
              </div>
              <div className="ml-4 mt-2 flex-shrink-0"></div>
            </div>
          </div>
          {contentsSection()}
        </section>
      </div>
    </>
  );
};
