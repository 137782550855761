import React, { useEffect, useState } from 'react';
import { Link, useHistory, useParams } from 'react-router-dom';
import { AttachedFiles } from '@src/components/AttachedFiles';
import { BreadCrumbs } from '@src/components/BreadCrumbs';
import {
  ArrowRightCircleIcon,
  BriefcaseIcon,
  ClockIcon,
  CreditCardIcon,
  CurrencyDollarIcon,
  VideoCameraIcon,
  XMarkIcon,
} from '@heroicons/react/24/outline';
import {
  ChangePriceState,
  LessonState,
  Role,
  SessionType,
  User,
} from '@src/types';
import { useAuth, usePageCall } from '@src/hooks';
import { useQuery } from 'react-query';
import { fetcher } from '@src/plugins/react-query';
import { MomentFormat, utcToLocalFormat } from '@src/plugins/moment';
import { api } from '@src/plugins/axios';
import { popupState } from '@src/plugins/ridge';
import { Avatar } from '@src/components/Avatar';
import { getS3Uri } from '@src/utils';
import PaymentMethodAlertModal from '@src/components/PaymentMethodAlertModal';
import moment from 'moment';
import { HiddenHeadingMeta } from '@src/components/HiddenHeadingMeta';
import { ReactHelmet } from '@src/components/ReactHelmet';
import mixpanel from 'mixpanel-browser';

export const SessionDetails = () => {
  const { push, goBack } = useHistory();
  const { id } = useParams<{ id: string }>();
  const { authenticated } = useAuth();
  const { data: me } = useQuery<User>('/users/me', fetcher, {
    enabled: !!authenticated,
  });
  const [isPaymentMethod, setIsPaymentMethod] = useState(false);
  const [isPaymentMethodModalOpen, setIsPaymentMethodModalOpen] =
    useState(false);

  const { data } = useQuery<any>(`/credit-cards`, fetcher, {
    enabled: !!me,
    refetchOnWindowFocus: false,
    onSuccess(data) {
      if (data.data[0]) {
        setIsPaymentMethod(true);
      }
    },
  });

  const {
    data: sessionItem,
    refetch,
    isFetching: isSessionItemFetching,
  } = useQuery<SessionType>(`/lessons/detail/${id}`, fetcher, {
    enabled: !!authenticated,
  });

  useEffect(() => {
    mixpanel.track('Page Viewed', {
      session_id: id,
      session_title: sessionItem?.title,
      item_category: 'Session Details',
    });
  }, []);

  const { terminatePageCall } = usePageCall();

  const contentsSection = () => {
    return (
      <>
        <div className="">
          <div className=" border-gray-200">
            <dl>
              <div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                {me?.role === Role.MENTOR ? (
                  <>
                    <dt className="text-sm font-medium text-gray-500">
                      Student
                    </dt>
                    <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0 flex items-center gap-2">
                      <Avatar
                        src={getS3Uri(sessionItem?.student?.avatar)}
                        alt="avatar"
                        className="!wh-8 object-cover rounded-full"
                      />
                      <span>{sessionItem?.student?.firstName}</span>
                    </dd>
                  </>
                ) : (
                  <>
                    <dt className="text-sm font-medium text-gray-500">
                      Mentor
                    </dt>
                    <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0 flex items-center gap-2">
                      <Avatar
                        src={getS3Uri(sessionItem?.mentor?.avatar)}
                        alt="avatar"
                        className="!wh-8 object-cover rounded-full"
                      />
                      <span>{sessionItem?.mentor?.firstName}</span>
                    </dd>
                  </>
                )}
              </div>
              <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                <dt className="text-sm font-medium text-gray-500">Subject</dt>
                <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                  {sessionItem?.subjectTitle}
                </dd>
              </div>
              <div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                <dt className="text-sm font-medium text-gray-500">
                  Session Title
                </dt>
                <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0 truncate-2-lines">
                  {sessionItem?.title}
                </dd>
              </div>

              <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                <dt className="text-sm font-medium text-gray-500">
                  Start time
                </dt>
                <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0 space-y-3">
                  <dt className="text-sm flex items-center gap-2">
                    <ClockIcon
                      className="wh-4 flex-shrink-0"
                      aria-hidden="true"
                    />
                    <span>
                      {sessionItem &&
                        utcToLocalFormat(
                          sessionItem?.startedAt,
                          MomentFormat.YYYYMMDDhmma
                        )}
                      {` `}
                      {moment.tz(moment.tz.guess()).zoneAbbr()}
                    </span>
                  </dt>
                </dd>
              </div>
              <div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                <dt className="text-sm font-medium text-gray-500">End Time</dt>
                <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0 space-y-3">
                  <dt className="text-sm flex items-center gap-2">
                    <ClockIcon
                      className="wh-4 flex-shrink-0"
                      aria-hidden="true"
                    />
                    <span>
                      {sessionItem &&
                        utcToLocalFormat(
                          sessionItem?.endedAt,
                          MomentFormat.YYYYMMDDhmma
                        )}{' '}
                      {moment.tz(moment.tz.guess()).zoneAbbr()}
                    </span>
                  </dt>
                </dd>
              </div>

              <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                <dt className="text-sm font-medium text-gray-500">Price</dt>
                <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0 space-y-3">
                  <dt className="text-sm flex items-center gap-4">
                    <div className="flex items-center gap-2">
                      <CurrencyDollarIcon
                        className="wh-4 flex-shrink-0"
                        aria-hidden="true"
                      />
                      <span>
                        {sessionItem?.price
                          ? Number(sessionItem?.price).toFixed(2)
                          : (0).toFixed(2)}
                      </span>
                    </div>
                    {sessionItem?.newPrice &&
                      sessionItem.changePriceState ===
                        (ChangePriceState.REQUEST_CHANGE_PRICE ||
                          ChangePriceState.DECLINE) && (
                        <>
                          <ArrowRightCircleIcon className="wh-4 text-gray-700" />
                          <div className="flex items-center gap-2">
                            <CurrencyDollarIcon
                              className="wh-4 flex-shrink-0"
                              aria-hidden="true"
                            />
                            <span>
                              {sessionItem?.newPrice &&
                                Number(sessionItem?.newPrice).toFixed(2)}
                            </span>
                          </div>
                          {sessionItem.changePriceState ===
                          ChangePriceState.REQUEST_CHANGE_PRICE ? (
                            <span className="inline-flex items-center rounded-full bg-green-100 px-2.5 py-0.5 text-xs font-medium text-green-800">
                              Requested
                            </span>
                          ) : (
                            <span className="inline-flex items-center rounded-full bg-red-100 px-2.5 py-0.5 text-xs font-medium text-green-800">
                              Declined
                            </span>
                          )}
                        </>
                      )}
                  </dt>

                  {me?.role === Role.MENTOR &&
                    (sessionItem?.status === LessonState.INCOMPLETE ||
                      sessionItem?.status === LessonState.UPCOMING) && (
                      <Link
                        to={`/my/dashboard/session/rate-edit/${id}`}
                        className="inline-flex items-center rounded border border-transparent bg-indigo-100 px-2.5 py-1.5 text-xs font-medium text-indigo-700 hover:bg-indigo-200 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                      >
                        Request Price Change
                      </Link>
                    )}
                  {me?.role === Role.STUDENT &&
                    sessionItem?.changePriceState ===
                      ChangePriceState.REQUEST_CHANGE_PRICE &&
                    !isSessionItemFetching && (
                      <div className="gap-2 flex">
                        <button
                          type="button"
                          onClick={async () => {
                            window.confirm(
                              'Are you sure you want to decline the price change?'
                            ) &&
                              (await api
                                .patch(
                                  `/lessons/v2/lessons/change-price/${sessionItem.id}/confirm`,
                                  {
                                    changePriceState: ChangePriceState.DECLINE,
                                  }
                                )
                                .then(() => {
                                  refetch();
                                  mixpanel.track(
                                    'Session Rate Change Declined',
                                    {
                                      sessionId: sessionItem.id,
                                    }
                                  );
                                  alert('You have declined the price change.');
                                }));
                          }}
                          className="inline-flex items-center rounded border border-transparent bg-indigo-100 px-2.5 py-1.5 text-xs font-medium text-indigo-700 hover:bg-indigo-200 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                        >
                          Decline
                        </button>
                        <button
                          type="button"
                          onClick={async () => {
                            window.confirm(
                              'Are you sure you want to accept the price change?'
                            ) &&
                              (await api
                                .patch(
                                  `/lessons/v2/lessons/change-price/${sessionItem.id}/confirm`,
                                  {
                                    changePriceState: ChangePriceState.ACCEPT,
                                  }
                                )
                                .then(() => {
                                  refetch();
                                  mixpanel.track(
                                    'Session Rate Change Accepted',
                                    {
                                      sessionId: sessionItem.id,
                                    }
                                  );
                                  alert('The price change has been accepted.');
                                }));
                          }}
                          className="inline-flex items-center rounded border border-transparent bg-indigo-100 px-2.5 py-1.5 text-xs font-medium text-indigo-700 hover:bg-indigo-200 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                        >
                          Accept
                        </button>
                      </div>
                    )}
                </dd>
              </div>

              <div className="px-4 py-3 text-right sm:px-6 flex justify-end gap-3 flex-wrap">
                {sessionItem?.status === LessonState.INCOMPLETE ||
                  (sessionItem?.status === LessonState.UPCOMING && (
                    <button
                      onClick={() => {
                        mixpanel.track('Session Join Clicked', {
                          item_id: sessionItem.id,
                          item_name: sessionItem.title,
                        });
                        if (me?.role === Role.STUDENT && !isPaymentMethod) {
                          setIsPaymentMethodModalOpen(true);
                        } else if (
                          sessionItem?.changePriceState ===
                          ChangePriceState.REQUEST_CHANGE_PRICE
                        ) {
                          if (me?.role === Role.STUDENT) {
                            alert(
                              'Mentor requested a change to the session price. You must accept or decline to join the session.'
                            );
                          } else {
                            alert(
                              'Your student must accept or decline the price change in order to participate in the session.'
                            );
                          }
                        } else if (sessionItem?.roomId) {
                          window.open(
                            `https://app.pagecall.net/${
                              sessionItem && sessionItem?.roomId
                            }/?access_token=${me?.pageCallAccessToken}`
                          );
                          mixpanel.track('Session Joined', {
                            item_id: sessionItem.id,
                            item_name: sessionItem.title,
                          });
                        } else {
                          mixpanel.track('Session Join Failed', {
                            item_id: sessionItem.id,
                            item_name: sessionItem.title,
                          });
                          alert(
                            'There is no video chat room created. Contact customer service'
                          );
                        }
                      }}
                      className="flex items-center gap-1 justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                    >
                      <VideoCameraIcon className="wh-4" /> <span>Join</span>
                    </button>
                  ))}

                {me?.role === Role.MENTOR &&
                  (sessionItem?.status === LessonState.INCOMPLETE ||
                    sessionItem?.status === LessonState.UPCOMING) && (
                    <>
                      <Link
                        to={`/my/dashboard/session/edit/${id}`}
                        className="inline-flex justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                      >
                        Edit
                      </Link>
                      <Link
                        to={`/my/dashboard/sessions/submit/${id}`}
                        className="inline-flex justify-center rounded-md border border-transparent bg-green-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                      >
                        Submit session
                      </Link>
                    </>
                  )}
              </div>
            </dl>
          </div>
        </div>
      </>
    );
  };

  return (
    <>
      <HiddenHeadingMeta text="Session details | Linkstory" />
      <ReactHelmet keywords="session" title="Session details | Linkstory" />
      <BreadCrumbs
        homePath="/my/dashboard"
        breadCrumbsPages={[
          {
            name: 'Session Details',
            path: `/my/dashboard/session/${id}`,
          },
        ]}
      />
      <PaymentMethodAlertModal
        icon={<CreditCardIcon className="wh-8 text-gray-400" />}
        title="Register your payment method"
        subTitle="You must register your payment method to participate in the session."
        open={isPaymentMethodModalOpen}
        onClose={() => setIsPaymentMethodModalOpen(false)}
        ctaText="Register"
        ctaOnClick={() => push('/my/dashboard/account/payment-method')}
      />
      <div className="space-y-4 bg-white border w-full rounded-md overflow-hidden">
        <section className="whitespace-pre-line">
          <div className="border-b border-gray-200 bg-white px-4 py-5 sm:px-6">
            <div className="-ml-4 -mt-2 flex flex-wrap items-center justify-between sm:flex-nowrap">
              <div className="ml-4 mt-2 flex gap-2 items-center">
                <BriefcaseIcon className="wh-6 flex-shrink-0 self-center text-xs" />
                <h3 className="text-lg font-medium leading-6 text-gray-900">
                  Session Details
                </h3>
              </div>
              <div className="ml-4 mt-2 flex-shrink-0">
                {sessionItem && (
                  <button
                    onClick={() => {
                      if (me?.role === Role.MENTOR) {
                        popupState.set({
                          icon: false,
                          needConfirm: true,
                          confirmText: 'I understand',
                          title:
                            'Are you sure you want to cancel this session?',
                          description: (
                            <div>
                              Please read our{' '}
                              <Link
                                to="/termsandconditions"
                                target="_blank"
                                rel="noopener noreferrer"
                                className="underline"
                              >
                                Terms and Conditions
                              </Link>{' '}
                              regarding tutoring session cancellations before
                              you confirm cancellation.
                            </div>
                          ),
                          buttonText: 'Confirm cancellation',
                          onClick: () => {
                            api
                              .patch(`/lessons/v2/lessons/cancel/${id}`)
                              .then(() => {
                                terminatePageCall(
                                  sessionItem?.roomId,
                                  sessionItem.id
                                );
                                mixpanel.track('Session cancelled', {
                                  item_id: sessionItem.id,
                                  item_name: sessionItem.title,
                                });
                                alert('Session has been cancelled.');
                                goBack();
                              })
                              .catch((err) => {
                                alert(
                                  'Cancellation failed. Contact customer service'
                                );
                              });
                          },
                        });
                      } else if (me?.role === Role.STUDENT) {
                        alert(
                          'For cancellation, please contact the tutor via chat.'
                        );
                      }
                    }}
                    className="inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 gap-1"
                  >
                    <XMarkIcon className="wh-4 flex-shrink-0 self-center text-xs" />
                    <p>Cancel Session</p>
                  </button>
                )}
              </div>
            </div>
          </div>
          {contentsSection()}
        </section>
      </div>
    </>
  );
};
