import React, { useState, forwardRef, InputHTMLAttributes } from 'react';
import { useId } from 'react-id-generator';
import styled from 'styled-components';
import { Label } from './Label';

export interface TextFieldProps extends InputHTMLAttributes<HTMLInputElement> {
  label?: string;
  helper?: string;
  autoFocus?: boolean;
  onKeyDownHandle?: boolean;
  isPassword?: boolean;
  wrapperClassName?: string;
  required?: boolean;
}

export const TextField = forwardRef<HTMLInputElement, TextFieldProps>(
  (
    {
      className = '',
      wrapperClassName = '',
      label,
      helper,
      autoFocus,
      onKeyDownHandle,
      isPassword,
      required,
      ...props
    },
    ref
  ) => {
    const [id] = useId(1, 'textfield');
    const [visibility, setVisibility] = useState(false);
    const onKeyDownHandler = (e: any) => {
      if (
        [
          'A',
          'B',
          'C',
          'D',
          'E',
          'F',
          'G',
          'H',
          'I',
          'J',
          'K',
          'L',
          'M',
          'N',
          'O',
          'P',
          'Q',
          'R',
          'S',
          'T',
          'U',
          'V',
          'W',
          'X',
          'Y',
          'Z',
        ].includes(e.key) ||
        e.key === 'CapsLock'
      ) {
        console.log(e);
        alert('Caps Lock is turned on.');
      }
    };

    const toggleVisibility = () => {
      setVisibility(!visibility);
    };

    return (
      <div className={`label-col ${wrapperClassName}`}>
        {label && <Label htmlFor={id} text={label} />}
        <div className="w-full relative">
          <StyledInputWrapper>
            <input
              type={
                isPassword && visibility
                  ? 'text'
                  : isPassword && !visibility
                  ? 'password'
                  : ''
              }
              autoFocus={autoFocus}
              ref={ref}
              id={id}
              onKeyDown={(e) => onKeyDownHandle && onKeyDownHandler(e)}
              className={`textfield w-full ${
                helper ? 'border-error' : ''
              } ${className}`}
              {...props}
            />
          </StyledInputWrapper>
          {required && (
            <div className="absolute -top-1 -right-1 bg-red-400 wh-2 rounded-full"></div>
          )}

          {isPassword ? (
            <div className="absolute inset-y-0 right-0 flex items-center px-2">
              <input
                className="hidden js-password-toggle"
                id="toggle"
                type="checkbox"
              />
              <label
                className="bg-gray-300 hover:bg-gray-400 rounded px-2 py-1 text-sm text-gray-600 cursor-pointer js-password-label"
                htmlFor="toggle"
                onClick={() => toggleVisibility()}
              >
                {visibility ? 'hide' : 'show'}
              </label>
            </div>
          ) : (
            ''
          )}
        </div>

        {helper && <p className="text-sm text-error">{helper}</p>}
      </div>
    );
  }
);

const StyledInputWrapper = styled.div`
  input[type='datetime-local']:not(.has-value):before {
    color: lightgray;
    content: attr(data-placeholder);
    width: 100%;
    white-space: nowrap;
  }

  input[type='datetime-local']:focus::before {
    display: none;
  }

  input[type='date']:not(.has-value):before {
    color: lightgray;
    content: attr(data-placeholder);
    width: 100%;
    white-space: nowrap;
  }

  input[type='date']:focus::before {
    display: none;
  }
`;
