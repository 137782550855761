import React, { FC, InputHTMLAttributes, useRef } from 'react';
import { Icon } from './icons';

interface SearchProps extends InputHTMLAttributes<HTMLInputElement> {
  onSearch?: () => void;
  wrapperClassName?: string;
}

export const Search: FC<SearchProps> = ({
  value,
  wrapperClassName,
  onSearch = () => {},
  ...props
}) => {
  const input = useRef<HTMLInputElement>(null);
  return (
    <form
      className={`flex items-center px-4 h-10 rounded-md border border-gray-200 focus-within:border-brand-1 ${wrapperClassName}`}
      onSubmit={(e) => {
        e.preventDefault();
        onSearch();
      }}
    >
      <div className="flex justify-center items-center -ml-2 w-10 h-10">
        <Icon.Search />
      </div>
      <input
        ref={input}
        className="flex-1 text-sm placeholder-gray-400"
        value={value}
        {...props}
      />
      {value && (
        <div className="flex justify-center items-center -mr-2 wh-10">
          <Icon.X
            className="wh-4"
            onClick={() => {
              Object.getOwnPropertyDescriptor(
                window.HTMLInputElement.prototype,
                'value'
              )?.set?.call(input.current, '');
              input.current?.dispatchEvent(
                new Event('change', { bubbles: true })
              );
            }}
          />
        </div>
      )}
    </form>
  );
};
