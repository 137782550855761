import { AxiosError, AxiosResponse } from 'axios';
import { useInfiniteQuery } from 'react-query';
import { getAdvisors } from '../../api/advisors';
import { GetAdvisorsDto } from '../../api/advisors/type';
import { getNextPageParam } from '../../plugins/react-query';
import { Paginated, User } from '../../types';

export function useAdvisors(getAdvisorsDto: GetAdvisorsDto) {
  const { page, limit } = getAdvisorsDto;

  return useInfiniteQuery<AxiosResponse<Paginated<User>>, AxiosError>(
    ['/users/mentors', getAdvisorsDto],
    ({ pageParam = 1 }) => getAdvisors({ ...getAdvisorsDto, page: pageParam }),
    {
      getNextPageParam: (last, pages) => {
        return getNextPageParam(limit, last, pages);
      },
      keepPreviousData: true,
      enabled: !!page,
    }
  );
}
