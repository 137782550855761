import React from 'react';
import { Button } from '@components/Button';

export const PasswordSendPage = () => {
  return (
    <div className="max-w-screen-xl mx-auto h-screen md:h-screen-15 justify-center flex items-center px-5">
      <div className="space-y-6 flex flex-col">
        <h1 className="font-bold text-center text-20 mb-5">
          Reset your password
        </h1>
        <h4 className="font-semibold text-center text-15">
          Need to reset your password? No problem!
          <br /> A password request email has been sent to your inbox.
          <br />
          <br /> If you did not receive an email,
          <br />
          please check your junk folder or input your email address again.
        </h4>
        <div className="pt-3" />
        <Button text="Home" to="/" className="rounded-full filled-brand-1" />
      </div>
    </div>
  );
};
