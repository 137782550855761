import { HiddenHeadingMeta } from '@src/components/HiddenHeadingMeta';
import { ReactHelmet } from '@src/components/ReactHelmet';
import mixpanel from 'mixpanel-browser';
import { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import HeroImg from '@img/heroimg-signin.png';
import { ReactComponent as TickCircleIcon } from '@svg/icon-tickcircle.svg';
import { Button } from '@components/Button';
import { useAuth } from '@src/hooks';

export const SignupStudentPendingPage = () => {
  const { state } = useLocation<{ email: string }>();
  const { push } = useHistory();
  const { resendEmail } = useAuth();
  const [button, setButton] = useState(true);

  useEffect(() => {
    if (state) {
      localStorage.clear();
      mixpanel.reset();
    } else {
      push('/');
    }
  }, [state]);

  useEffect(() => {
    setTimeout(() => setButton(true), 60000);
  });

  return (
    <>
      <HiddenHeadingMeta text="Activation pending | Linkstory" />
      <ReactHelmet
        keywords="signup, pending"
        title="Activation pending | Linkstory"
      />
      <div
        className=" absolute inset-0 bg-center bg-cover bg-no-repeat grid place-items-center px-4"
        style={{ backgroundImage: `url('${HeroImg}')` }}
      >
        <div className="bg-white rounded-lg py-8 px-10 w-full max-w-lg grid place-items-center text-center space-y-6">
          <TickCircleIcon />

          <h1 className="text-30 font-bold ">Activation Pending</h1>

          <p className="text-gray-500">
            In order to complete the sign-up process, please click the
            verification link sent to your email. <br />
            If you did not receive it, please check your spam folder.
            <br />
            <br />
            To resend the verification email, please click this link.
          </p>

          <Button
            text={
              button ? 'Resend Email' : `You can resend email in 60 seconds`
            }
            className="filled-brand-1 rounded-full w-full"
            type="submit"
            disabled={!button}
            onClick={() => {
              resendEmail(state.email);
              setButton(false);
            }}
          />
        </div>
      </div>
    </>
  );
};
