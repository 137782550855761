import React, { useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Dropdown } from '@src/components/Dropdown';
import { Icon } from '@src/components/icons';
import {
  BriefcaseIcon,
  CalendarIcon,
  InformationCircleIcon,
} from '@heroicons/react/24/outline';
import { AttachedFiles } from '@src/components/AttachedFiles';
import { BreadCrumbs } from '@src/components/BreadCrumbs';
import { useQuery } from 'react-query';
import { JobType, User } from '@src/types';
import { fetcher } from '@src/plugins/react-query';
import { MomentFormat, utcToLocalFormat } from '@src/plugins/moment';
import moment from 'moment';
import { TextField } from '@material-ui/core';
import { TextArea } from '@src/components/TextArea';
import { useForm } from 'react-hook-form';
import { PriceField } from '@src/components/PriceField';
import { api } from '@src/plugins/axios';
import { DownloadFiles } from '@src/components/DownloadFiles';
import { HiddenHeadingMeta } from '@src/components/HiddenHeadingMeta';
import { ReactHelmet } from '@src/components/ReactHelmet';
import mixpanel from 'mixpanel-browser';
import { useAuth } from '@src/hooks';

interface FormValues {
  message: string;
  price: number;
}

export const JobsApplication = () => {
  const { push, goBack } = useHistory();
  const { id } = useParams<{ id: string }>();

  const {
    register,
    reset: resetForm,
    handleSubmit,
    formState: { isSubmitting, errors, isDirty, isValid },
  } = useForm<FormValues>({
    mode: 'onChange',
  });

  const { data: JobItem } = useQuery<JobType>(`/v2/jobs/${id}`, fetcher, {
    enabled: !!id,
    keepPreviousData: true,
  });

  useEffect(() => {
    if (JobItem?.jobSubmits && JobItem?.jobSubmits.length > 0) {
      goBack();
    }
  }, [JobItem]);

  const { authenticated } = useAuth();
  const { data: me, isFetching: meIsFetching } = useQuery<User>(
    '/users/v2/me',
    fetcher,
    {
      enabled: !!authenticated,
    }
  );

  const contentsSection = () => {
    return (
      <>
        <div className="">
          <div className="border-t border-gray-200">
            <dl>
              <div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                <dt className="text-sm font-medium text-gray-500">Student</dt>
                <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                  {JobItem?.writer.firstName}
                </dd>
              </div>
              <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                <dt className="text-sm font-medium text-gray-500">
                  Availability
                </dt>
                <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0 space-y-3">
                  {JobItem?.firstRequestTime && (
                    <dt className="text-sm flex gap-2 items-center">
                      <CalendarIcon
                        className="wh-4 flex-shrink-0"
                        aria-hidden="true"
                      />
                      <div>
                        {utcToLocalFormat(
                          JobItem?.firstRequestTime,
                          MomentFormat.YYYYMMDDhmma
                        )}
                      </div>

                      <div>{moment.tz(moment.tz.guess()).zoneAbbr()}</div>
                    </dt>
                  )}
                  {JobItem?.secondRequestTime && (
                    <dt className="text-sm flex gap-2 items-center">
                      <CalendarIcon
                        className="wh-4 flex-shrink-0"
                        aria-hidden="true"
                      />
                      <div>
                        {utcToLocalFormat(
                          JobItem?.secondRequestTime,
                          MomentFormat.YYYYMMDDhmma
                        )}
                      </div>

                      <div>{moment.tz(moment.tz.guess()).zoneAbbr()}</div>
                    </dt>
                  )}
                  {JobItem?.thirdRequestTime && (
                    <dt className="text-sm flex gap-2 items-center">
                      <CalendarIcon
                        className="wh-4 flex-shrink-0"
                        aria-hidden="true"
                      />
                      <div>
                        {utcToLocalFormat(
                          JobItem?.thirdRequestTime,
                          MomentFormat.YYYYMMDDhmma
                        )}
                      </div>

                      <div>{moment.tz(moment.tz.guess()).zoneAbbr()}</div>
                    </dt>
                  )}
                </dd>
              </div>
              <div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                <dt className="text-sm font-medium text-gray-500">
                  Education level
                </dt>
                <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0 truncate-2-lines">
                  {JobItem?.grade.title}
                </dd>
              </div>
              <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                <dt className="text-sm font-medium text-gray-500">Subject</dt>
                <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                  {JobItem?.subject.title}
                </dd>
              </div>
              <div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                <dt className="text-sm font-medium text-gray-500">
                  Description
                </dt>
                <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                  {JobItem?.description}
                </dd>
              </div>
              <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                <dt className="text-sm font-medium text-gray-500">
                  Tell us more
                </dt>
                <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0 space-y-3">
                  {JobItem?.questionAnswers?.map((item) => {
                    return (
                      <div>
                        <h4 className="font-semibold">
                          {` Q. ${item.question.question}`}
                        </h4>
                        <p> {` A. ${item.text}`}</p>
                      </div>
                    );
                  })}
                </dd>
              </div>
              {JobItem?.jobFiles && JobItem?.jobFiles?.length > 0 && (
                <div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                  <dt className="text-sm font-medium text-gray-500">
                    Attachments
                  </dt>
                  <DownloadFiles filesItems={JobItem.jobFiles} />
                </div>
              )}
            </dl>
          </div>
        </div>
      </>
    );
  };

  const formSection = () => {
    return (
      <>
        <form
          onSubmit={handleSubmit(async (data) => {
            try {
              await api
                .post('/job-submits', {
                  jobId: JobItem?.id,
                  studentId: JobItem?.writer.id,
                  message: data.message,
                  price: Number(data.price.toFixed(2)),
                })
                .then(() => {
                  mixpanel.track('Job app submitted', {
                    jobId: JobItem?.id,
                    mentor_name: `${me?.firstName} ${me?.lastName}`,
                    message: data.message,
                    student_name: JobItem?.writer.firstName,
                    price: Number(data.price.toFixed(2)),
                  });
                  window.dataLayer.push({ event: 'apply_job' });
                  push('/my/dashboard/jobs/applied');
                });
            } catch (e) {
              mixpanel.track('Job app failed', {
                jobId: JobItem?.id,
                mentor_name: `${me?.firstName} ${me?.lastName}`,
                message: data.message,
                student_name: JobItem?.writer.firstName,
                price: Number(data.price.toFixed(2)),
              });
              alert('Data transfer failed.');
            }
          })}
        >
          <div className="">
            <div className="space-y-6 px-4 py-5 sm:p-6">
              <div className="grid grid-cols-3 gap-6">
                <div className="col-span-3 md:col-span-3">
                  <label
                    htmlFor="price"
                    className="block text-sm font-medium text-gray-700 mb-1"
                  >
                    Hourly rate
                  </label>
                  <div className="rounded-md bg-blue-50 p-4 mb-2">
                    <div className="flex">
                      <div className="flex-shrink-0">
                        <InformationCircleIcon
                          className="h-5 w-5 text-blue-400"
                          aria-hidden="true"
                        />
                      </div>
                      <div className="ml-3">
                        <h3 className="text-sm font-medium text-blue-800">
                          Average rate ranges from $30-$50/hr.
                        </h3>
                        <div className="mt-2 text-sm text-blue-700">
                          <p>
                            We recommend adjusting your rate depending on the
                            details of the student request.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <PriceField
                    placeholder="0.00"
                    className="md:max-w-[300px]"
                    inputClassName="text-sm"
                    helper={errors.price?.message}
                    step=".01"
                    {...register('price', {
                      setValueAs: (value) => Number(value),
                      required: 'Please input price',
                    })}
                  />
                </div>
              </div>

              <div>
                <label
                  htmlFor="about"
                  className="block text-sm font-medium text-gray-700"
                >
                  {`Message to ${JobItem?.writer.firstName} (minimum 25 words)`}
                </label>
                <div className="mt-1">
                  <TextArea
                    rows={3}
                    minLength={25}
                    className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                    placeholder="Write message..."
                    helper={errors.message?.message}
                    {...register('message', {
                      required: 'Please input message',
                      validate: (val: string) => {
                        if (val.length < 25) {
                          return 'Please enter at least 25 characters.';
                        }
                      },
                    })}
                  />
                </div>
              </div>
            </div>
            <div className="px-4 py-3 text-right sm:px-6">
              <button
                type="submit"
                className="inline-flex justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
              >
                Submit
              </button>
            </div>
          </div>
        </form>
      </>
    );
  };

  return (
    <>
      <HiddenHeadingMeta text="Job application | Linkstory" />
      <ReactHelmet
        keywords="job, application"
        title="Job application | Linkstory"
      />
      <BreadCrumbs
        homePath="/my/dashboard"
        breadCrumbsPages={[
          {
            name: 'Jobs',
            path: '/my/dashboard/jobs',
          },
          {
            name: 'Application',
            path: `/my/dashboard/jobs/application/${id}`,
          },
        ]}
      />
      <div className="space-y-4 bg-white border w-full rounded-md overflow-hidden">
        <section className="whitespace-pre-line">
          <div className=" border-gray-200 bg-white px-4 py-5 sm:px-6">
            <div className="-ml-4 -mt-2 flex flex-wrap items-center justify-between sm:flex-nowrap gap-2">
              <div className="ml-4 mt-2 flex gap-2 items-center">
                <BriefcaseIcon className="wh-6 flex-shrink-0 self-center text-xs" />
                <h3 className="text-lg font-medium leading-6 text-gray-900">
                  Job Details
                </h3>
              </div>
            </div>
          </div>
          {contentsSection()}
        </section>
      </div>
      <div className="space-y-4 bg-white border w-full rounded-md overflow-hidden">
        <section className="whitespace-pre-line">
          <div className="border-b border-gray-200 bg-white px-4 py-5 sm:px-6">
            <div className="-ml-4 -mt-2 flex flex-wrap items-center justify-between sm:flex-nowrap gap-2">
              <div className="ml-4 mt-2 flex gap-2 items-center">
                <BriefcaseIcon className="wh-6 flex-shrink-0 self-center text-xs" />
                <h3 className="text-lg font-medium leading-6 text-gray-900">
                  Apply for this position
                </h3>
              </div>
            </div>
          </div>
          {formSection()}
        </section>
      </div>
    </>
  );
};
