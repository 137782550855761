import React, { useEffect, useState } from 'react';
import { Link, useHistory, useParams } from 'react-router-dom';
import { BreadCrumbs } from '@src/components/BreadCrumbs';
import {
  CheckBadgeIcon,
  CheckIcon,
  ClockIcon,
  PencilIcon,
} from '@heroicons/react/24/outline';
import { fetcher } from '@src/plugins/react-query';
import { LessonState, SessionType, Subject, User } from '@src/types';
import { useAuth, usePageCall } from '@src/hooks';
import { useQuery } from 'react-query';
import { useForm } from 'react-hook-form';
import { TextField } from '@src/components/TextField';
import { Select } from '@src/components/Select';
import moment from 'moment';
import { PriceField } from '@src/components/PriceField';
import { TextArea } from '@src/components/TextArea';
import { Checkbox } from '@src/components/Checkbox';
import { api } from '@src/plugins/axios';
import { Avatar } from '@src/components/Avatar';
import { getS3Uri } from '@src/utils';
import { HiddenHeadingMeta } from '@src/components/HiddenHeadingMeta';
import { ReactHelmet } from '@src/components/ReactHelmet';
import { StepsComponents } from '@src/components/StepsComponents';

interface FormValues {
  subjectId: number;
  startedAt: string;
  endedAt: string;
  title: string;
  price: number;
  sessionReview: string;
  terms: boolean;
}

export const SubmitSessionFormPage = () => {
  const { push, goBack } = useHistory();
  const { id } = useParams<{ id: string }>();
  const { authenticated } = useAuth();
  const { data: me } = useQuery<User>('/users/me', fetcher, {
    enabled: !!authenticated,
  });
  const [currentStatusId, setCurrentStatusId] = useState<string>('02');
  const [isFilled, setIsFilled] = useState<boolean>(false);
  const [isSubmitted, setIsSubmitted] = useState<boolean>(false);
  const {
    register,
    reset,
    handleSubmit,
    watch,
    trigger,
    formState: { isSubmitting, errors, isDirty, isValid },
  } = useForm<FormValues>({
    mode: 'onChange',
  });
  const { terminatePageCall } = usePageCall();

  useEffect(() => {
    console.log('!!ERROS', errors);
  }, [errors]);

  const { data: subjectsItems } = useQuery<Subject[]>(`/v2/subjects`, fetcher, {
    keepPreviousData: true,
    refetchOnWindowFocus: false,
  });

  const { data: sessionItem } = useQuery<SessionType>(
    `/lessons/detail/${id}`,
    fetcher,
    {
      enabled: !!authenticated,
      refetchOnWindowFocus: false,
      onSuccess: (item) => {
        let defaultValues = {
          title: item.title,
          subjectId: item.subjectId,
          startedAt: moment
            .utc(item.startedAt)
            .local()
            .format('YYYY-MM-DD[T]HH:mm'),
          endedAt: moment
            .utc(item.endedAt)
            .local()
            .format('YYYY-MM-DD[T]HH:mm'),
          price: Number(Number(item.price).toFixed(2)),
        };
        //@ts-ignore
        reset({ ...defaultValues });
      },
    }
  );

  const { data: mentorSubjects } = useQuery<any[]>(
    `/v2/subject-link?mentorId=${Number(me?.id)}`,
    fetcher,
    {
      enabled: !!me?.id,
    }
  );

  const steps = [
    { id: '01', name: 'Select session' },
    { id: '02', name: 'Enter details' },
    { id: '03', name: 'Review & Submit' },
  ];

  if (
    !sessionItem ||
    (sessionItem?.status === LessonState.PENDING && !isSubmitted)
  )
    return <></>;

  const FormSection = () => {
    return (
      <>
        <div className="whitespace-pre-line">
          <div className=" border-gray-200 border-b bg-white px-4 py-5 sm:px-6">
            <div className="-ml-4 -mt-2 flex flex-wrap items-center justify-between sm:flex-nowrap gap-2">
              <div className="ml-4 mt-2 flex gap-2 items-center">
                {isSubmitted ? (
                  <>
                    {' '}
                    <CheckBadgeIcon className="wh-6 flex-shrink-0 self-center text-xs" />
                    <h3 className="text-lg font-medium leading-6 text-gray-900">
                      Submitted Session Info
                    </h3>
                  </>
                ) : isFilled ? (
                  <>
                    <CheckBadgeIcon className="wh-6 flex-shrink-0 self-center text-xs" />
                    <h3 className="text-lg font-medium leading-6 text-gray-900">
                      Check your details before submission
                    </h3>
                  </>
                ) : (
                  <>
                    <PencilIcon className="wh-6 flex-shrink-0 self-center text-xs" />
                    <h3 className="text-lg font-medium leading-6 text-gray-900">
                      Enter Details
                    </h3>
                  </>
                )}
              </div>
            </div>
          </div>
          <div className=" border-gray-200">
            <dl>
              <div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                <dt className="text-sm font-medium text-gray-500">Student</dt>
                <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0 flex items-center gap-2">
                  <Avatar
                    src={getS3Uri(sessionItem?.student?.avatar)}
                    alt="avatar"
                    className="!wh-8 object-cover rounded-full"
                  />
                  <span>{sessionItem?.student?.firstName}</span>
                </dd>
              </div>

              <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                <dt className="text-sm font-medium text-gray-500">Subject</dt>

                <Select
                  readOnly
                  value={sessionItem.subjectId}
                  helper={errors.subjectId?.message}
                  className={`block w-full max-w-lg  border-gray-300 shadow-sm h-10  mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0 ${'bg-gray-100 border-gray-200 text-gray-400 pointer-events-none'}`}
                  {...register('subjectId', {
                    required: 'Please select session subject',
                  })}
                >
                  <option value="" selected disabled hidden>
                    Please select session subject
                  </option>
                  {subjectsItems
                    ?.filter((item) =>
                      mentorSubjects?.find(
                        (option) => option.subjectId === item.id
                      )
                    )
                    .map((item) => {
                      return (
                        <option
                          label={item.title.toUpperCase()}
                          value={Number(item.id)}
                        />
                      );
                    })}
                </Select>
                {/* )} */}
              </div>
              <div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                <dt className="text-sm font-medium text-gray-500">
                  Session Title
                </dt>
                <TextField
                  readOnly
                  className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0 truncate-2-lines h-10"
                  placeholder="Please give the session a title."
                  helper={errors.title?.message}
                  {...register('title', {
                    required: 'Please enter section title.',
                  })}
                />
              </div>
              <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                <dt className="text-sm font-medium text-gray-500">
                  Start time
                </dt>
                <TextField
                  readOnly={isFilled}
                  type="datetime-local"
                  data-placeholder="YYYY. MM. DD hh:mm"
                  className={`mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0 h-10 ${
                    !!watch('startedAt') ? 'has-value' : ''
                  }`}
                  helper={errors.startedAt?.message}
                  {...register('startedAt', {
                    required: 'Please enter your prefered time',
                    validate: (value: string) => {
                      if (moment(value).isAfter(watch('endedAt'))) {
                        return 'The session start time cannot be later than the end time.';
                      }
                    },
                  })}
                />
              </div>
              <div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                <dt className="text-sm font-medium text-gray-500">End time</dt>
                <TextField
                  readOnly={isFilled}
                  type="datetime-local"
                  data-placeholder="YYYY. MM. DD hh:mm"
                  min={moment(watch('startedAt')).format('YYYY-MM-DD[T]HH:mm')}
                  className={`mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0 h-10 ${
                    !!watch('endedAt') ? 'has-value' : ''
                  }`}
                  helper={errors.endedAt?.message}
                  {...register('endedAt', {
                    required: 'Please enter your prefered time',
                    validate: (value: string) => {
                      if (moment(value).isBefore(watch('startedAt'))) {
                        return 'Session end time cannot be earlier than start time.';
                      }
                    },
                  })}
                />
              </div>
              <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                <dt className="text-sm font-medium text-gray-500">
                  Final price
                </dt>
                <PriceField
                  readOnly={isFilled}
                  inputClassName="text-sm"
                  className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0"
                  helper={errors.price?.message}
                  step=".01"
                  {...register('price', {
                    required: 'Please input price',
                    setValueAs: (value) => Number(value),
                    validate: (value: number) => {
                      if (value < 1) {
                        return 'The minimum price you can set is $1';
                      }
                    },
                  })}
                />
              </div>
              <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                <dt className="text-sm font-medium text-gray-500 whitespace-pre-line">
                  {`Session review to be shared with ${sessionItem?.student.firstName} \n(25 characters minimum)`}
                </dt>
                <TextArea
                  readOnly={isFilled}
                  className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0"
                  helper={errors.sessionReview?.message}
                  minLength={25}
                  {...register('sessionReview', {
                    required: 'Please input review',
                    validate: (val: string) => {
                      if (val.length < 25) {
                        return 'Please enter at least 25 characters.';
                      }
                    },
                  })}
                />
              </div>
              {isFilled && !isSubmitted && (
                <div className="bg-gray-50 px-4 flex flex-col items-center">
                  <p className="text-sm text-gray-500 py-6">
                    {`I confirm that the session details are accurate and I authorize Linkstory to charge ${
                      sessionItem?.studentName !== ''
                        ? sessionItem?.studentName
                        : 'N/A'
                    } $${watch('price')} for this session.`}
                  </p>
                  <div className="pb-6">
                    <Checkbox
                      labelClassName="text-sm"
                      label="I understand"
                      {...register('terms', {
                        required: 'This is required',
                      })}
                    />
                  </div>
                </div>
              )}

              <div className="bg-gray-50 px-4 py-3 text-right sm:px-6 flex justify-end gap-3">
                {isFilled && !isSubmitted && (
                  <button
                    onClick={handleSubmit(async (data) => {
                      await api
                        .post(`/v2/receipts`, {
                          mentorId: sessionItem?.mentorId,
                          studentId: sessionItem?.studentId,
                          sessionTitle: data.title,
                          price: Number(data.price.toFixed(2)),
                          sessionReview: data.sessionReview,
                          subjectId: Number(data?.subjectId),
                          startedAt: moment(data.startedAt).toISOString(),
                          endedAt: moment(data.endedAt).toISOString(),
                          lessonId: sessionItem?.id,
                        })
                        .then(() => {
                          terminatePageCall(
                            sessionItem?.roomId,
                            sessionItem.id
                          );
                          setIsSubmitted(true);
                          alert('Session successfully submitted.');
                        })
                        .catch((e) => {
                          alert(
                            'Session submission failed. Please contact customer service.'
                          );
                        });
                    })}
                    className="inline-flex justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                    disabled={!isDirty || !isValid}
                  >
                    {isSubmitting ? 'Submitting' : 'Confirm Submission'}
                  </button>
                )}

                {!isFilled && !isSubmitted && (
                  <button
                    onClick={async (e) => {
                      e.preventDefault();
                      const result = await trigger();
                      console.log(
                        '🚀 ~ file: SubmitSessionFormPage.tsx:427 ~ onClick={ ~ result',
                        result
                      );

                      if (result) {
                        setIsFilled(true);
                        setCurrentStatusId('03');
                      }
                    }}
                    className="inline-flex justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                  >
                    {'Continue'}
                  </button>
                )}
              </div>
            </dl>
          </div>
        </div>
      </>
    );
  };

  return (
    <>
      <HiddenHeadingMeta text="Submit a session | Linkstory" />
      <ReactHelmet
        keywords="session, submission"
        title="Submit a session | Linkstory"
      />
      <BreadCrumbs
        homePath="/my/dashboard"
        breadCrumbsPages={[
          {
            name: 'Selct a session',
            path: `/my/dashboard/sessions/submit`,
          },
          {
            name: 'Enter details',
            path: `/my/dashboard/sessions/submit/${id}`,
          },
        ]}
      />
      <div className="min-w-0 flex-1">
        <h2 className="text-2xl font-bold leading-7 text-gray-900 sm:truncate sm:text-2xl sm:tracking-tight">
          Submit a Session
        </h2>
      </div>
      {!isSubmitted && (
        <StepsComponents steps={steps} currentStatusId={currentStatusId} />
      )}
      {/* Start Submitted alert */}
      {isSubmitted && (
        <div className="bg-green-500 rounded-md">
          <div className="mx-auto max-w-7xl py-3 px-3 sm:px-6 lg:px-8">
            <div className="flex flex-wrap items-center justify-between">
              <div className="flex w-0 flex-1 items-center">
                <span className="flex rounded-lg bg-green-700 p-2">
                  <CheckBadgeIcon
                    className="h-6 w-6 text-white"
                    aria-hidden="true"
                  />
                </span>
                <p className="ml-3 font-medium text-white text-sm">
                  <span className="md:hidden">
                    Your session has been submitted
                  </span>
                  <span className="hidden md:inline whitespace-pre-line leading-tight">
                    {`Your session has been submitted`}
                  </span>
                </p>
              </div>
            </div>
          </div>
        </div>
      )}
      {/* end Submitted alert */}
      <div className="space-y-4 bg-white border w-full rounded-md overflow-hidden">
        {FormSection()}
      </div>
    </>
  );
};
