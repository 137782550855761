import * as Ably from 'ably';
import { get, last, uniqueId } from 'lodash';
import { parse, stringify } from 'qs';
import { FC, useEffect, useRef, useState, Fragment } from 'react';
import { useQuery } from 'react-query';
import { Link, useHistory, useLocation } from 'react-router-dom';
import useDeepCompareEffect from 'use-deep-compare-effect';
import { api } from '../plugins/axios';
import { fetcher } from '../plugins/react-query';
import {
  Chat,
  ChatState,
  ChatStatus,
  FileType,
  Message,
  MessageType,
  RequestResponseType,
  Role,
  User,
} from '../types';
import { MessageBubble } from './MessageBubble';
import { getS3Uri } from '../utils';
import { Avatar } from './Avatar';
import styled from 'styled-components';
import { isMobile } from 'react-device-detect';
import { MomentFormat, utcToLocalFormat } from '../plugins/moment';
import moment from 'moment';
import { ChatRoomManager } from '../class/ChatRoomManager';
import { Collapsible } from './Collapsible';
import {
  ArrowUpTrayIcon,
  CalendarDaysIcon,
  ChevronDownIcon,
  ChevronUpIcon,
  ClockIcon,
  CreditCardIcon,
  EllipsisHorizontalCircleIcon,
  EllipsisVerticalIcon,
  ListBulletIcon,
  LockClosedIcon,
  MegaphoneIcon,
  NoSymbolIcon,
  PaperClipIcon,
  XCircleIcon,
  XMarkIcon,
} from '@heroicons/react/24/outline';
import { StarIcon } from '@heroicons/react/20/solid';
import HTMLEllipsis from 'react-lines-ellipsis/lib/html';
import responsiveHOC from 'react-lines-ellipsis/lib/responsiveHOC';
import { Dropdown } from './Dropdown';
import { useId } from 'react-id-generator';
import { AttachedFiles } from './AttachedFiles';
import { StudentRequestPopup } from './StudentRequestPopup';
import { MentorSchedulePopup } from './MentorSchedulePopup';
import { Item } from 'rc-menu';
import { TextArea } from './TextArea';
import { Button } from './Button';
import { ToggleSwitch } from './ToggleSwitch';
import axios from 'axios';
import { resizeImage } from '@src/plugins/compress';
import mixpanel from 'mixpanel-browser';
import { CreditCardPopUp } from './CreditCardPopUp';
const ResponsiveEllipsis = responsiveHOC()(HTMLEllipsis);

export interface ChatRoomProps {
  refetchChatList: () => void;
}

export const ChatRoom: FC<ChatRoomProps> = ({ refetchChatList }) => {
  const { search } = useLocation();
  const parsed = parse(search, { ignoreQueryPrefix: true });
  const { id } = parsed;
  const [paymentPopup, setPaymentPopup] = useState(false);
  const [offerPopup, setOfferPopup] = useState(false);
  const [mentorSchedulePopup, setMentorSchedulePopup] = useState(false);
  const [studentRequestPopup, setStudentRequestPopup] = useState(false);
  const channelRef = useRef<Ably.Types.RealtimeChannelCallbacks>();
  const [messages, setMessages] = useState<{ [key: string]: Message[] }>({});
  const messagesRef = useRef<{ [key: string]: Message[] }>({});
  const chatMessages = get(messages, id as string, []);
  const [productId, setProductId] = useState<number>();
  const [jobId, setJobId] = useState<number>();
  const [, setPrice] = useState<number>();
  const [, setStartedAt] = useState('');
  const chatWrapper = useRef<HTMLDivElement>(null);
  const refContentEditable = useRef<HTMLDivElement>(null);
  const refDeclineContentEditable = useRef<HTMLDivElement>(null);
  const { data: me } = useQuery<User>('/users/me', fetcher);
  const [isChatBlocked, setIsChatBlocked] = useState(true);
  const [isSendingMessage, setIsSendingMessage] = useState(false);
  const [isSendingStatusChange, setIsSendingStatusChange] = useState(false);
  const [card, setCard] = useState<any>();
  const [creditCardPopup, setCreditCardPopup] = useState(false);
  const [isNoPaymentMethodPopup, setIsNoPaymentMethodPopup] = useState(true);

  const { data, isFetching: isPaymentFetching } = useQuery<any>(
    `/credit-cards`,
    fetcher,

    {
      // enabled: !!me,
      refetchOnWindowFocus: false,
      onSuccess(data) {
        console.log('🚀 ~ file: ChatRoom.tsx:102 ~ onSuccess ~ data', data);
        if (data.data[0]) {
          setCard(data.data[0].card);
        }
      },
    }
  );

  useEffect(() => {
    if (id && me?.role) {
      api.patch(
        `/chats/v2/${
          me.role === Role.STUDENT ? 'student' : 'mentor'
        }/change-read-info/${id}`
      );
    }
  }, [id, me]);

  const meRef = useRef<User | undefined>(me);
  const {
    data: chat,
    error,
    refetch: refetchChatDetail,
  } = useQuery<Chat>(`/chats/${id}`, fetcher, {
    enabled: !!id,
  });

  const { data: isStudentCreditCard, isFetching: isFetchingStudentCreditCard } =
    useQuery<any>(
      `/credit-cards/student/${chat?.studentId}/find-mentor`,
      fetcher,
      {
        enabled: me?.role === Role.MENTOR,
      }
    );
  const { refetch: refetchChats } = useQuery<Chat[]>('/chats', fetcher);
  const user = me?.role === Role.MENTOR ? chat?.student : chat?.mentor;
  const [useEllipsis, setUseEllipsis] = useState<boolean>(true);
  const [requestResponseStatus, setRequestResponseStatus] =
    useState<RequestResponseType>(RequestResponseType.DEFALT);
  const [selectedResponse, setSelectedResponse] = useState<any>();
  const [files, setFiles] = useState<any[]>([]);
  const [declineMessage, setDeclineMessage] = useState<string>();
  const fileInputRef = useRef<HTMLInputElement>(null);

  const handleEllipsis = (e: React.MouseEvent) => {
    e.preventDefault();
    setUseEllipsis(false);
  };

  useEffect(() => {
    // First we get the viewport height and we multiple it by 1% to get a value for a vh unit
    let vh = window.innerHeight * 0.01;
    // Then we set the value in the --vh custom property to the root of the document
    document.documentElement.style.setProperty('--vh', `${vh}px`);

    // We listen to the resize event
    (() => {
      window.addEventListener('resize', () => {
        // We execute the same script as before
        let vh = window.innerHeight * 0.01;
        document.documentElement.style.setProperty('--vh', `${vh}px`);
        scrollToBottom();
      });
    })();
    return () => {
      window.removeEventListener('resize', () => {
        // We execute the same script as before
        let vh = window.innerHeight * 0.01;
        document.documentElement.style.setProperty('--vh', `${vh}px`);
        scrollToBottom();
      });
    };
  }, []);

  const postResponseHistory = async () => {
    // 1분을 최소 값으로 보냄
    api
      .post(`/v2/response-histories`, {
        responseTime:
          Number(
            moment
              .duration(moment().diff(moment(chat?.newMessageDate).local()))
              .asMinutes()
              .toFixed(0)
          ) === 0
            ? 1
            : Number(
                moment
                  .duration(moment().diff(moment(chat?.newMessageDate).local()))
                  .asMinutes()
                  .toFixed(0)
              ),
      })
      .then(() => {
        if (refDeclineContentEditable.current) {
          refDeclineContentEditable.current.textContent = '';
          scrollToBottom();
        }
        setRequestResponseStatus(RequestResponseType.DEFALT);
      });
  };

  const disconnectAbly = () => {
    ChatRoomManager.leaveAllRoom();
  };

  const _setMessages = async (id: string, message: Message) => {
    const updatedMessages = { ...messagesRef.current };
    if (!updatedMessages[id]) {
      updatedMessages[id] = [];
    }

    updatedMessages[id] = [...updatedMessages[id], message];
    setMessages(updatedMessages);
    focusContentsEditable();
  };

  const sendMessage = async () => {
    const messageText =
      refContentEditable.current?.innerText ??
      declineMessage ??
      refDeclineContentEditable.current?.innerText;

    if (refContentEditable.current) {
      refContentEditable.current.textContent = '';
      scrollToBottom();
    }

    if (!me || !chat || (!messageText && files.length === 0)) {
      // alert('sending error');
      return;
    }

    setIsSendingMessage(true);
    if (messageText) {
      _setMessages(chat.id.toString(), {
        id:
          (last(messages[chat.id])?.id ||
            Math.floor(Math.random() * 100000000)) + 1,
        text: messageText,
        userId: me.id,
        chatId: chat.id,
        createdAt: new Date().toISOString(),
        updatedAt: new Date().toISOString(),
        type: MessageType.TEXT,
        priceId: null,
        productId: null,
        jobId: null,
        lessonId: null,
        fileName: null,
        fileType: null,
        isMentorRead: false,
        isStudentRead: false,
        mentorReadAt: null,
        studentReadAt: null,
      });

      await api
        .post('/messages', {
          chatId: chat.id,
          text: messageText,
        })
        .then(() => {
          mixpanel.track('Send Message');
          // 최초 요청 시간 - 한번만 등록 되고 중복 수정 되지 않음
          mixpanel.people.set_once({
            'First Sending Message Date': new Date().toISOString(),
          });
          // 최초 요청 시간 - 지속적으로 업데이트 됨
          mixpanel.people.set({
            'Last Sending Message Date': new Date().toISOString(),
          });
          mixpanel.identify(me?.email);

          // decline 메시지 초기화
          setDeclineMessage(undefined);

          setIsSendingMessage(false);
          refetchChats();
          refetchChatDetail();
          refetchChatList();

          if (
            requestResponseStatus === RequestResponseType.REJECT_CUSTOM ||
            RequestResponseType.REJECT_PRESET
          ) {
            postResponseHistory();
          }
        })
        .catch((error) => {
          alert('Message transmission failed. Contact customer service');
          console.log(error);
          setIsSendingMessage(false);
        });
    }
    if (files.length > 0) {
      try {
        if (files.length > 0) {
          const UPLOADFILES = [...files];

          for (let i = 0; i < UPLOADFILES.length; i++) {
            const formData = new FormData();
            const { data: post } = await api.get(
              `/s3/presignedPost?filename=${UPLOADFILES[i].fileName}`
            );

            Object.entries(post.fields).forEach(([name, value]) =>
              formData.append(name, value as any)
            );
            // IMPORTANCE: https://stackoverflow.com/a/15235866
            // AWS ignores all fields in the request after the file field, so all other fields must appear before the file.
            formData.append(
              'file',
              UPLOADFILES[i].file.type.startsWith('image/')
                ? await resizeImage(UPLOADFILES[i].file)
                : UPLOADFILES[i].file
            );
            await axios.post(post.url, formData, {
              headers: { 'Content-Type': 'multipart/form-data' },
            });

            _setMessages(chat.id.toString(), {
              id:
                (last(messages[chat.id])?.id ||
                  Math.floor(Math.random() * 100000000)) + 1,
              text: post.fields.key,
              userId: me.id,
              chatId: chat.id,
              createdAt: new Date().toISOString(),
              updatedAt: new Date().toISOString(),
              type: MessageType.FILE,
              priceId: null,
              productId: null,
              jobId: null,
              lessonId: null,
              fileName: UPLOADFILES[i].fileName,
              fileType: UPLOADFILES[i].file.type.startsWith('image/')
                ? FileType.IMAGE
                : FileType.FILE,
              isMentorRead: false,
              isStudentRead: false,
              mentorReadAt: null,
              studentReadAt: null,
            });

            await api
              .post('/messages', {
                chatId: chat.id,
                text: post.fields.key,
                fileName: UPLOADFILES[i].fileName,
                type: MessageType.FILE,
                fileType: UPLOADFILES[i].file.type.startsWith('image/')
                  ? FileType.IMAGE
                  : FileType.FILE,
              })
              .then(() => {
                mixpanel.track('Send Message');
                if (me?.role === Role.STUDENT) {
                  setIsChatBlocked(false);
                }
                refetchChats();
                refetchChatDetail();
                refetchChatList();
                setIsSendingMessage(false);
                if (i + 1 === UPLOADFILES.length) {
                  setFiles([]);
                }
              })
              .catch((error) => {
                alert('File transmission failed. Contact customer service');
                console.log(error);
                setIsSendingMessage(false);
              });
          }
        }
      } catch (error) {
        alert('Request failed.');
        console.error(error);
      }
    }

    push(`/my/chats?id=${id}`);
  };

  const _onSetAbly = async () => {
    const token = await api
      .get(`/chats/${id}/token`)
      .then(({ data: token }) => token);
    const realtime = new Ably.Realtime({ token });
    const channel = realtime.channels.get(`chat:${id}`);
    channelRef.current = channel;
    channel.subscribe('message', ({ data }) => {
      if (
        data.type === MessageType.PRODUCT ||
        data.type === MessageType.REQUEST ||
        data.type === MessageType.APPLICATION ||
        data.type === MessageType.LESSON ||
        data.userId !== meRef.current?.id
      ) {
        _setMessages(data.chatId.toString(), data);
      }
    });
    ChatRoomManager.enterRoom(channelRef.current?.name, channelRef.current);
  };

  // set as read so that the new mark can be removed
  useEffect(() => {
    mixpanel.track('Read Message');
    api.post(`/chats/${id}/enter`).then(() => refetchChats());
    setRequestResponseStatus(RequestResponseType.DEFALT);
    setSelectedResponse(undefined);
    setIsNoPaymentMethodPopup(true);
  }, [id, refetchChats]);

  const focusContentsEditable = () => {
    // focust contents editable div and if there is text focuse to the end of the text
    const element = refContentEditable.current;
    if (element) {
      if (element.innerText.length === 0) {
        element.focus();
        scrollToBottom();
        return;
      }

      const selection = window.getSelection();
      const newRange = document.createRange();
      newRange.selectNodeContents(element);
      newRange.collapse(false);
      selection?.removeAllRanges();
      selection?.addRange(newRange);
      scrollToBottom();
    }
  };

  useEffect(() => {
    if (!id) return;
    setMessages({});
    api.get(`/chats/${id}/messages`).then(({ data }) =>
      setMessages((prev) => {
        const updated = { ...prev };
        data.forEach((message: Message) => {
          if (!updated[message.chatId]) {
            updated[message.chatId] = [];
          }
          updated[message.chatId].push(message);
        });
        return updated;
      })
    );
    _onSetAbly();
    return () => {
      disconnectAbly();
    };
  }, [id]);

  useEffect(() => {
    meRef.current = me;
  }, [me]);

  const { push } = useHistory();

  const scrollToBottom = () => {
    setTimeout(() => {
      chatWrapper.current?.scrollTo({
        top: chatWrapper.current?.scrollHeight,
        // behavior: 'smooth',
      });
    }, 200);
  };

  useDeepCompareEffect(() => {
    scrollToBottom();
  }, [chatMessages]);

  useEffect(() => {
    return () => {
      disconnectAbly();
    };
  }, []);

  useEffect(() => {
    messagesRef.current = messages;
    scrollToBottom();
  }, [messages]);

  if (error) {
    if (me?.role === Role.STUDENT) {
      push('/my/chats');
    } else {
      push('/my/chats');
    }
  }

  const isTimeAfter = (a: string, b: string): boolean => {
    const mementFormat = MomentFormat.YYYYMMDDHmm;
    const result = moment(utcToLocalFormat(a, mementFormat)).isAfter(
      utcToLocalFormat(b, mementFormat)
    );
    return result;
  };

  const checkTimeDiff = (
    currentMsg: any,
    nextMsg: any | undefined
  ): boolean => {
    let result: boolean = false;

    const currentUser = currentMsg.userId;
    const nextUser = nextMsg && nextMsg.userId;
    const nextSameUser = nextUser === currentUser;

    const nextTimeDiff =
      nextMsg && isTimeAfter(nextMsg.createdAt, currentMsg.createdAt);

    if (nextTimeDiff || !nextSameUser) {
      result = true;
    }

    return result;
  };

  const onInputClick = (
    event: React.MouseEvent<HTMLInputElement, MouseEvent>
  ) => {
    const element = event.target as HTMLInputElement;
    element.value = '';
  };

  const handleLeaveChatRoom = () => {
    // 작성된 API 없는것 같음 요청 필욘
  };

  const chatHeaderSection = () => {
    return (
      <div className="mt-4 md:mt-0 md:sticky md:top-0 md:z-10 w-full flex flex-col justify-between items-center gap-3">
        <div className="flex md:hidden px-4  justify-between items-center w-full">
          <Link
            to="/my/chats"
            className="px-3 bg-white py-2 md:w-auto rounded-md text-brand-1 border text-sm font-semibold flex items-center gap-2"
          >
            <ListBulletIcon className="wh-5 text-black" /> List
          </Link>
          <button
            onClick={() => {
              if (me?.role === Role.MENTOR) {
                if (isStudentCreditCard) {
                  setMentorSchedulePopup(true);
                } else {
                  alert(
                    'Session cannot be created because the student has not registered a payment method. Ask the student to register a payment method. '
                  );
                }
              } else if (me?.role === Role.STUDENT) {
                setStudentRequestPopup(true);
              }
            }}
            className="px-3 bg-white py-2 md:w-auto rounded-md text-brand-1 border text-sm font-semibold flex items-center gap-2"
          >
            <CalendarDaysIcon className="wh-5 text-black" />{' '}
            {me?.role === Role.MENTOR ? 'Create a session' : 'Request session'}
          </button>

          {/* <Dropdown className="block md:hidden z-20">
            <button className="flex-shrink-0 cursor-pointer inline-flex h-8 w-8 items-center justify-center rounded-full bg-white bg-transparent text-black focus:outline-none ">
              <span className="sr-only">Open options</span>
              <EllipsisHorizontalCircleIcon
                className="wh-6"
                aria-hidden="true"
              />
            </button>
            <Dropdown.View className="mt-0 top-0 right-8 rounded-md w-30 flex flex-col shadow-md overflow-hidden text-sm font-regular">
              <>
                <button
                  onClick={() =>
                    me?.role === Role.MENTOR
                      ? setMentorSchedulePopup(true)
                      : setStudentRequestPopup(true)
                  }
                  className="hover:bg-gray-100 px-4 py-2 text-left"
                >
                  {me?.role === Role.MENTOR
                    ? 'Schedule session'
                    : 'Request session'}
                </button>
              </>
            </Dropdown.View>
          </Dropdown> */}
        </div>

        <Collapsible
          closedButton={
            <>
              <p>{`Show profile`}</p>
              <ChevronDownIcon className="wh-4" />
            </>
          }
          openedButton={
            <>
              <p>{`Hide profile`}</p>
              <ChevronUpIcon className="wh-4" />
            </>
          }
          btnSiblings={
            <>
              <div className="hidden md:flex text-brand-blue text-sm  gap-4 mr-4 md:mr-0">
                {chat?.status !== ChatStatus.REQUESTS && (
                  <button
                    onClick={() => {
                      if (me?.role === Role.MENTOR) {
                        if (isStudentCreditCard) {
                          setMentorSchedulePopup(true);
                        } else {
                          alert(
                            'Session cannot be created because the student has not registered a payment method. Ask the student to register a payment method.'
                          );
                        }
                      } else if (me?.role === Role.STUDENT) {
                        setStudentRequestPopup(true);
                      }
                    }}
                    className="mb-2 px-3 bg-white py-2 md:w-auto rounded-md text-brand-1 border text-sm font-semibold flex items-center gap-2"
                  >
                    <CalendarDaysIcon className="wh-5 text-black" />{' '}
                    {me?.role === Role.MENTOR
                      ? 'Create a session'
                      : 'Request session'}
                  </button>
                )}
                {/* <button
                  onClick={() => handleLeaveChatRoom()}
                  className="font-semibold flex gap-2 items-center"
                >
                  Leave conversation
                </button> */}
              </div>
            </>
          }
          wrapperClassName="w-full"
          btnClassName="hidden px-4 bg-gray-100 h-12 md:w-auto rounded-t-md text-brand-1 border-t border-l border-r text-sm font-semibold md:flex items-center gap-2"
        >
          <div className=" overflow-hidden bg-gray-100 w-full text-left border-t border-l border-r">
            <div className=" px-4 py-5 sm:px-6 rounded-tr-md">
              <div className="flex items-center gap-2">
                <Avatar
                  onClick={() =>
                    me &&
                    me?.role === Role.STUDENT &&
                    push(`/mentors/${user?.id}`)
                  }
                  className={`flex-none !wh-8 md:!wh-10 ${
                    me &&
                    me?.role === Role.STUDENT &&
                    user?.id &&
                    'cursor-pointer'
                  }`}
                  src={
                    user?.avatar.startsWith('https')
                      ? user?.avatar
                      : getS3Uri(user?.avatar)
                  }
                />
                <div className="flex-col w-full">
                  <div
                    className={`flex ${
                      me && me?.role === Role.MENTOR
                        ? 'flex-col'
                        : 'flex-row items-center'
                    } flex-wrap gap-1  truncate`}
                  >
                    <h4
                      onClick={() =>
                        me &&
                        me?.role === Role.STUDENT &&
                        push(`/mentors/${user?.id}`)
                      }
                      className={` text-sm md:text-base font-semibold text-ellipsis overflow-hidden whitespace-nowrap text-left ${
                        me &&
                        me?.role === Role.STUDENT &&
                        user?.id &&
                        'cursor-pointer'
                      }`}
                    >
                      {user?.firstName} {user?.lastName}
                    </h4>
                    {me && me?.role === Role.STUDENT && (
                      <div className="flex gap-2 items-center text-sm">
                        <StarIcon className="wh-4 text-brand-orange" />
                        <p>{`${user?.avgRating ?? 'N/A'} (${
                          user?.totalReviewCount ?? 0
                        })`}</p>
                      </div>
                    )}
                    {me && me?.role === Role.MENTOR && (
                      <div className="flex gap-2 items-center text-sm">
                        <div>Payment method:</div>
                        <span
                          className={`inline-flex items-center rounded-full ${
                            isStudentCreditCard
                              ? 'bg-green-100 text-green-800'
                              : 'bg-pink-100 text-pink-800'
                          }  px-2.5 py-0.5 text-xs font-medium `}
                        >
                          {isStudentCreditCard ? 'Confirmed' : 'Not yet added'}
                        </span>
                      </div>
                    )}
                  </div>
                  {/* <p className="text-sm">$35/hour</p> */}
                </div>
              </div>

              <p
                className={`${
                  user?.introduction ? 'block' : 'hidden'
                } pt-4 pb-4 text-14 leading-[1.5rem]`}
              >
                {useEllipsis ? (
                  <div onClick={(e) => handleEllipsis(e)}>
                    <ResponsiveEllipsis
                      unsafeHTML={user?.introduction}
                      maxLine={'3'}
                      ellipsisHTML={`...<button class=" text-[#AAAAAA]  ml-2 px-2 leading-[1.3rem] rounded-full">more</button>`}
                      basedOn="letters"
                      onReflow={(reflow) => console.log(reflow)}
                    />
                  </div>
                ) : (
                  <div>{user?.introduction}</div>
                )}
              </p>
            </div>
          </div>
        </Collapsible>
      </div>
    );
  };

  const ChatMessageSection = () => {
    return (
      <div
        ref={chatWrapper}
        className="relative border-l border-r border-t bg-white p-4 messages-wrapper h-full space-y-4 lg:space-y-6 md:space-y-8 overflow-y-auto overflow-x-hidden py-4"
      >
        {me?.role === Role.MENTOR &&
          !isFetchingStudentCreditCard &&
          !isStudentCreditCard &&
          isNoPaymentMethodPopup && <NoPaymentBannerSection />}

        {me?.role === Role.STUDENT &&
          !isPaymentFetching &&
          !card &&
          isNoPaymentMethodPopup && <NoPaymentBannerSection />}

        {chat &&
          chatMessages.map((message, index: number) => (
            <>
              {((index > 0 &&
                moment(
                  utcToLocalFormat(
                    chatMessages[index].createdAt,
                    MomentFormat.YYYYMMDD
                  )
                ).isAfter(
                  utcToLocalFormat(
                    chatMessages[index - 1].createdAt,
                    MomentFormat.YYYYMMDD
                  )
                )) ||
                index === 0) && (
                <div className="sticky top-2 z-10 flex justify-content ">
                  <div className="bg-gray-100 min-w-[200px] rounded-full px-4 py-1 text-center text-sm m-auto">
                    {utcToLocalFormat(message.createdAt, MomentFormat.ddddLL)}
                  </div>
                </div>
              )}

              <MessageBubble
                key={message.id}
                chat={chat}
                setProductId={(productId: string) =>
                  setProductId(Number(productId))
                }
                setJobId={(productId: string) => setJobId(Number(jobId))}
                isTimeDiff={checkTimeDiff(
                  chatMessages[index],
                  chatMessages[index + 1] ? chatMessages[index + 1] : undefined
                )}
                setStartedAt={(startedAt: string) => setStartedAt(startedAt)}
                message={message}
                setPrice={(price: number) => setPrice(Number(price))}
                setPaymentPopup={() => setPaymentPopup(true)}
              />
            </>
          ))}
      </div>
    );
  };

  const ChatInputSection = () => {
    console.log('rerender');
    return (
      <>
        <div className="px-4">
          <div className="max-h-[106px] md:max-h-[400px] border md:border-0 flex p-4 md:p-0 md:mx-0 mb-4 mt-4 md:mt-0 md:mb-0 rounded-full md:rounded-none w-full">
            <form
              onSubmit={(e) => {
                e.preventDefault();
                // sendMessage();
              }}
              className="flex justify-between w-full "
            >
              <StyledTextArea>
                <button
                  type="button"
                  onClick={(e) => {
                    e.preventDefault();
                    fileInputRef.current?.click();
                  }}
                  className="mr-5 md:mr-0 cursor-pointer mx-auto"
                >
                  <ArrowUpTrayIcon className="text-gray-400 wh-4" />
                </button>
                <input
                  type="file"
                  style={{ display: 'none' }}
                  ref={fileInputRef}
                  onChange={async (e) => {
                    const file = e.target.files?.item(0);
                    if (!file) {
                      return;
                    } else if (
                      !file.type.startsWith('image/') &&
                      file.size > 10e6
                    ) {
                      alert('Please upload a file smaller than 10 MB');
                      return;
                    }
                    setFiles((prev) => [
                      ...prev,
                      {
                        uuid: uniqueId(),
                        fileName: file.name,
                        file: file,
                      },
                    ]);
                  }}
                />
                <div
                  contentEditable="true"
                  ref={refContentEditable}
                  onPaste={(e) => {
                    e.preventDefault();
                    // Paste Text as Plain Text into a Content Editable Element
                    const text = e.clipboardData.getData('text/plain');
                    document.execCommand('insertHTML', false, text);
                  }}
                  placeholder={
                    isSendingMessage ? 'Sending...' : ' Send your message...'
                  }
                  className=" overflow-y-auto h-full w-full focus:border-transparent focus:ring-0 resize-none md:p-4"
                  onKeyPress={(e) => {
                    if (!isMobile && e.which === 13 && !e.shiftKey) {
                      e.preventDefault();
                      sendMessage();
                    }
                  }}
                />
              </StyledTextArea>
            </form>
            {/* {isMobile && ( */}
            <button
              className="block md:px-4 text-brand-1 font-bold"
              onClick={(e) => {
                e.preventDefault();
                sendMessage();
              }}
            >
              Send
            </button>
            {/* )} */}
          </div>
        </div>
        {/* 파일 리스트 */}
        {!isSendingMessage && files.length > 0 && (
          <div className="pb-4 md:pb-4 px-4">
            <AttachedFiles
              filesItems={files}
              ctaText="Delete"
              onClickCta={(item) => {
                const newFiles = files.filter((f) => f.uuid !== item.uuid);
                setFiles(newFiles);
              }}
            />
          </div>
        )}
      </>
    );
  };

  // const ChatNoPaymentMethodBlockSecion = () => {
  //   return (
  //     <>
  //       <div className="flex flex-col items-center rounded-b-md overflow-hidden">
  //         <p className="text-xs md:text-sm p-4 text-center whitespace-pre-line md:whitespace-normal">{`To continue speaking with ${user?.firstName} ${user?.lastName} \nplease add a payment method `}</p>
  //         <Link
  //           to="/my/dashboard/account/payment-method"
  //           className="text-sm text-center font-semibold bg-brand-blue hover:brightness-105 text-white py-4 px-4 w-full"
  //         >
  //           Add Payment Method
  //         </Link>
  //       </div>
  //     </>
  //   );
  // };

  // interface NoPaymentBannerSectionProps {}
  const NoPaymentBannerSection: FC = () => {
    return (
      <div
        style={{ zIndex: 9999 }}
        className="sticky top-0 bg-indigo-600 rounded-md text-sm w-full bg-opacity-90"
      >
        <div className="mx-auto max-w-7xl py-3 px-3 sm:px-4 lg:px-4 ">
          <div className="flex flex-wrap items-center justify-between">
            <div className="flex flex-1 items-center">
              <span className="flex rounded-lg bg-indigo-800 p-2">
                <CreditCardIcon
                  className="wh-5 text-white"
                  aria-hidden="true"
                />
              </span>
              <p className="ml-3 font-medium text-white">
                {/* <span className="md:hidden">We announced a new product!</span> */}
                <span className="whitespace-pre-line">
                  {me?.role === Role.STUDENT
                    ? `Add a payment method so your tutor can create a session. \nYou will be charged after the session is completed.`
                    : `This student does not have a payment method added. \nWithout a registered payment method, you cannot create a session. `}
                </span>
              </p>
            </div>
            {me?.role === Role.STUDENT && (
              <div className="order-3 mt-2 w-full flex-shrink-0 sm:order-2 sm:mt-0 sm:w-auto">
                <Link
                  to="/my/dashboard/account/payment-method"
                  type="button"
                  className="flex items-center justify-center rounded-md border border-transparent bg-white px-3 py-2 text-sm font-medium text-indigo-600 shadow-sm hover:bg-indigo-50"
                >
                  Add
                </Link>
              </div>
            )}
            <div className="order-2 flex-shrink-0 sm:order-3 sm:ml-3">
              <button
                onClick={() => setIsNoPaymentMethodPopup(false)}
                type="button"
                className="-mr-1 flex rounded-md p-2 hover:bg-indigo-500 focus:outline-none focus:ring-2 focus:ring-white sm:-mr-2"
              >
                <span className="sr-only">Dismiss</span>
                <XMarkIcon className="wh-5 text-white" aria-hidden="true" />
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const MentorUnavailableSection = () => {
    return (
      <>
        <div className="border-t md:border-t-0 flex flex-col items-center rounded-b-md overflow-hidden p-4 text-center">
          {me?.role === Role.MENTOR ? (
            <NoSymbolIcon
              className="wh-8 text-gray-500 m-auto"
              aria-hidden="true"
            />
          ) : (
            <LockClosedIcon
              className="wh-8 text-red-500 m-auto"
              aria-hidden="true"
            />
          )}

          <h3 className="mt-2 text-base md:text-lg font-medium text-gray-900">
            {me?.role === Role.MENTOR
              ? 'You are currently not accepting new students 🥲'
              : 'Mentor unavailable'}
          </h3>
          <p className="mt-1 text-sm text-gray-500">
            {me?.role === Role.MENTOR
              ? `Show students you're open to work`
              : 'This mentor is currently not accepting new students'}
          </p>
          {me?.role === Role.MENTOR && (
            <div className="mt-6">
              <ToggleSwitch
                checked={me?.chatState === ChatState.AVAILABLE ? true : false}
                onChange={async (checked: boolean) => {
                  await api
                    .patch(`/users/update-mentor-availability`, {
                      availability: checked,
                    })
                    .then(() => {
                      alert('Successfully saved!');
                    });
                }}
              />
            </div>
          )}
          {/* <div className="flex gap-2 text-red-400 items-center">
            <LockClosedIcon className="wh-5 " />
            <p className="text-xl">
              {me?.role === Role.MENTOR
                ? 'You are currently not accepting new students 🥲'
                : 'Mentor unavailable'}
            </p>
          </div>
          <p className="text-xs md:text-sm mt-4 text-center whitespace-pre-line md:whitespace-normal">
            {me?.role === Role.MENTOR
              ? `Show students you're open to work`
              : 'This mentor account is currently inactive.'}
          </p> */}
        </div>
      </>
    );
  };

  const presetRejectMessages = [
    {
      id: '1',
      message: `Thanks, ${user?.firstName}, but I’m unavailable at your requested times.`,
      isCustomReply: false,
    },
    {
      id: '2',
      message: `Thanks, ${user?.firstName}, but I’m not taking new student right now`,
      isCustomReply: false,
    },
    {
      id: '3',
      message: `Thanks, ${user?.firstName}, I’m not a good fit for your subject needs`,
      isCustomReply: false,
    },
    {
      id: '4',
      message: `Write your own reply...`,
      isCustomReply: true,
    },
  ];

  const ChatRequestResponseInputSection = () => {
    return (
      <>
        {requestResponseStatus === RequestResponseType.DEFALT ? (
          <div className="flex flex-col border-t md:border-t-0 lg:flex-row gap-3 w-full justify-center items-center rounded-b-md overflow-hidden py-4 px-4">
            <p className="text-sm font-semibold md:text-base text-center whitespace-pre-line md:whitespace-normal">{`Are you  interested in working with \n${user?.firstName} ${user?.lastName}?`}</p>
            <div className="flex gap-4 items-center">
              <div className="flex flex-col items-center gap-2">
                <button
                  onClick={() =>
                    setRequestResponseStatus(RequestResponseType.REJECT_PRESET)
                  }
                  type="button"
                  className="w-[60px] text-center inline-flex items-center justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                >
                  No
                </button>
                <p className="text-xs text-gray-700">Let the student know</p>
              </div>
              <div className="flex flex-col items-center gap-2">
                <button
                  type="button"
                  disabled={isSendingStatusChange}
                  onClick={async () => {
                    setIsSendingStatusChange(true);
                    await api
                      .patch(`/chats/v2/requests/change-status/${id}`, {
                        status: ChatStatus.ONLINE,
                      })
                      .then(() => {
                        setIsSendingStatusChange(false);
                        push(`/my/chats?id=${id}`);
                        setIsChatBlocked(false);
                        refetchChats();
                        refetchChatDetail();
                        refetchChatList();
                        postResponseHistory();
                      })
                      .catch((e) => {
                        setIsSendingStatusChange(false);
                      });
                  }}
                  className="w-[60px] text-center inline-flex items-center justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                >
                  YES
                </button>
                <p className="text-xs text-gray-700">Start a conversation</p>
              </div>
            </div>
          </div>
        ) : requestResponseStatus === RequestResponseType.REJECT_PRESET ? (
          <div className="flex flex-col border-t md:border-t-0 gap-3 w-full justify-center items-center rounded-b-md overflow-hidden py-4  ">
            <p className="text-sm font-semibold md:text-lg text-center whitespace-pre-line md:whitespace-normal">{`Not interested`}</p>
            <div className="flex flex-col gap-3">
              {presetRejectMessages.map(
                (item: {
                  id: string;
                  message: string;
                  isCustomReply: boolean;
                }) => (
                  <div
                    key={`preset_${item.message}`}
                    className="flex items-center"
                  >
                    <input
                      id={item.id}
                      name="notification-method"
                      type="radio"
                      onClick={() => {
                        setSelectedResponse(item);
                        if (!item.isCustomReply) {
                          setDeclineMessage(item.message);
                        } else {
                          // init value
                          setDeclineMessage(undefined);
                        }
                      }}
                      // defaultChecked={item.id === 'email'}
                      className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-500"
                    />
                    <label
                      htmlFor={item.id}
                      className="ml-3 block text-sm font-medium text-gray-700"
                    >
                      {item.message}
                    </label>
                  </div>
                )
              )}
              <Button
                disabled={
                  isSendingStatusChange || isSendingMessage
                    ? true
                    : selectedResponse
                    ? false
                    : false
                }
                onClick={async () => {
                  if (selectedResponse && selectedResponse.isCustomReply) {
                    setRequestResponseStatus(RequestResponseType.REJECT_CUSTOM);
                  } else {
                    setIsSendingStatusChange(true);
                    await api
                      .patch(`/chats/v2/requests/change-status/${id}`, {
                        status: ChatStatus.LOCKED,
                      })
                      .then(() => {
                        sendMessage();
                        setIsSendingStatusChange(false);
                      })
                      .catch((e) => {
                        setIsSendingStatusChange(false);
                      });
                  }
                }}
                type="button"
                className=" items-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
              >
                Send reply
              </Button>
            </div>
          </div>
        ) : (
          requestResponseStatus === RequestResponseType.REJECT_CUSTOM && (
            <div className="flex flex-col border-t md:border-t-0 gap-3 w-full justify-center items-center rounded-b-md overflow-hidden py-4 ">
              <div className="flex gap-2 items-center">
                <XCircleIcon className="wh-5 text-gray-500" />
                <p className="text-sm font-semibold md:text-lg text-center whitespace-pre-line md:whitespace-normal">{`Not interested`}</p>
              </div>

              <form
                onSubmit={(e) => {
                  e.preventDefault();
                  // sendMessage();
                }}
                className="flex justify-between w-full "
              >
                <StyledTextArea>
                  <div
                    contentEditable="true"
                    ref={refDeclineContentEditable}
                    onPaste={(e) => {
                      e.preventDefault();
                      // Paste Text as Plain Text into a Content Editable Element
                      const text = e.clipboardData.getData('text/plain');
                      document.execCommand('insertHTML', false, text);
                    }}
                    placeholder="Please explain why you are not interested in this request to student."
                    className="border mx-4 rounded-md focus:border-brand-blue text-sm overflow-y-auto h-full w-full  focus:ring-0 resize-none md:p-4"
                    style={{ maxWidth: 'calc(100% - 128px)' }}
                    onKeyPress={async (e) => {
                      if (!isMobile && e.which === 13 && !e.shiftKey) {
                        e.preventDefault();
                        // 거절 메시지의 경우 Request status 를 LOCKED 상태로 변경함
                        await api
                          .patch(`/chats/v2/requests/change-status/${id}`, {
                            status: ChatStatus.LOCKED,
                          })
                          .then(() => {
                            sendMessage();
                          });
                      }
                    }}
                  />
                </StyledTextArea>
              </form>
              {/* {isMobile && ( */}
              <button
                className=" items-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                onClick={async (e) => {
                  e.preventDefault();
                  await api
                    .patch(`/chats/v2/requests/change-status/${id}`, {
                      status: ChatStatus.LOCKED,
                    })
                    .then(() => {
                      sendMessage();
                    });
                }}
              >
                Send
              </button>
              {/* )} */}
            </div>
          )
        )}
      </>
    );
  };

  if (!chat) {
    return <></>;
  }

  return (
    <>
      <>
        <CreditCardPopUp
          edit={card ? true : false}
          onClose={() => setCreditCardPopup(false)}
          open={creditCardPopup}
          card={card}
        />
        <StudentRequestPopup
          mentor={chat?.mentor}
          title="Request session"
          chatId={chat.id}
          onClose={() => {
            setStudentRequestPopup(false);
          }}
          open={studentRequestPopup}
          buttonTitle="Request session"
        />
        <MentorSchedulePopup
          mentor={chat?.mentor}
          student={chat?.student}
          title="Create a session"
          chatId={chat.id}
          onClose={() => setMentorSchedulePopup(false)}
          open={mentorSchedulePopup}
          buttonTitle="Create a session"
        />
      </>

      <div className="flex-1 flex-col w-full h-full items-between overflow-hidden">
        <StyledWrapper>
          <div className="flex flex-col h-full overflow-y-hidden justify-between">
            <div className="flex flex-col h-full overflow-y-hidden w-full relative pb-0">
              {chatHeaderSection()}
              {/* <div className="sticky top-0  z-11 border-b border-gray-100 mx-4 h-0" /> */}
              {ChatMessageSection()}
            </div>
            <div className="sticky bottom-0 bg-white border  rounded-b-md md:px-0 ">
              {/* <div className="hidden md:block border-b border-gray-100 h-0" /> */}
              {me?.role === Role.STUDENT && isPaymentFetching ? (
                <></>
              ) : chat.mentor?.chatState === ChatState.NOTAVAILABLE ? (
                MentorUnavailableSection()
              ) : chat.status === ChatStatus.REQUESTS &&
                me?.role === Role.MENTOR &&
                chat.mentor?.chatState === ChatState.AVAILABLE ? (
                ChatRequestResponseInputSection()
              ) : (
                ChatInputSection()
              )}
            </div>
          </div>
        </StyledWrapper>
      </div>
    </>
  );
};

const StyledWrapper = styled.div`
  height: 100%;
  overflow-y: hidden;

  @media (max-width: 768px) {
    /* max-height: calc(100vh - 3.5rem); */
    /* height: calc(100vh - 3.5rem);
    min-height: -webkit-fill-available;
    max-height: -webkit-fill-available; */
    position: fixed;
    width: 100%;
    top: 3.5rem;
    left: 0;
    height: 100vh - 3.5rem; /* Use vh as a fallback for browsers that do not support Custom Properties */
    height: calc((var(--vh, 1vh) * 100) - 3.5rem);
  }
`;

const StyledTextArea = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  div[placeholder]:empty::before {
    content: attr(placeholder);
    color: #b1b1b1;
  }
  [contenteditable] {
    outline: 0px solid transparent;
  }
`;
