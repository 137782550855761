import { PricingPlanEnum } from '../types';

export const PROMOTION_PRICE_MONTH: number = 1;
export const PRICE_MONTH: number = 4;
export const PRICE_YEAR: number = 49.99 * 100;
export const MAX_MENTORING_PRICE: number = 40;

export const PRICING_PLANS = [
  {
    id: 1,
    type: PricingPlanEnum.LINKSTORYPLUS,
    title: null,
    subtitle: 'LEARN ANYTIME, ANYWHERE',
    prefix: '$',
    price: PROMOTION_PRICE_MONTH,
    period: 'month',
    description: 'For a limited time only. \nGet Linkstory+ for $1 a month.',
    notice: `You will be charged $${
      PROMOTION_PRICE_MONTH * 12
    } for the first year. \n$${(PRICE_YEAR / 100).toFixed(
      2
    )} per year thereafter.`,
    subsButton: true,
    disCountModal: false,
    to: '/about-linkstoryplus',
  },
  {
    id: 2,
    type: PricingPlanEnum.MENTORING,
    title: 'Online Mentoring',
    subtitle: 'JUMPSTART YOUR COLLEGE ADMISSIONS',
    prefix: '~$',
    price: MAX_MENTORING_PRICE,
    period: 'hour',
    description:
      'Prices may vary according to \nthe mentor and topic of session.',
    notice: `Please view our mentor pages for more information`,
    subsButton: false,
    disCountModal: false,
    to: '/about-mentoring',
  },
];
