import { FC, useEffect, Fragment } from 'react';
import { Tag, TagLinkTargetType, Vod } from '../types';
import { useQuery } from 'react-query';
import { fetcher } from '../plugins/react-query';
import { Link } from 'react-router-dom';
import { Popover, Transition } from '@headlessui/react';

interface VODCardProps {
  vod: Vod;
}

export const VODCard: FC<VODCardProps> = ({ vod }) => {
  const { data: tags, remove: cancelTagsApi } = useQuery<Tag[]>(
    `/tag-links/tags?type=${TagLinkTargetType.VOD}&id=${vod.id}`,
    fetcher,
    { refetchOnWindowFocus: false, keepPreviousData: true }
  );
  const { data: thumbnail } = useQuery<string>(
    `/vods/thumbnail?videoId=${vod?.vodItems[
      vod?.vodItems.length - 1
    ]?.videoUrl?.replace('https://vimeo.com/', '')}`,
    fetcher
  );
  useEffect(() => {
    return () => {
      cancelTagsApi();
    };
  }, []);

  return (
    <div className="flex flex-col space-y-1">
      <Link
        className="flex flex-col cursor-pointer space-y-1"
        to={{
          pathname: `/vods/${vod?.id}`,
          state: { from: '/vods' },
        }}
      >
        <div className="relative aspect-w-7 aspect-h-4 rounded-lg">
          {thumbnail ? (
            <img src={thumbnail} alt={vod?.title} />
          ) : (
            <div className="animate-pulse w-full h-full flex flex-col space-x-4 ">
              <div
                className="rounded-sm bg-gray-200 h-full w-full"
                style={{ aspectRatio: '16/9' }}
              ></div>
            </div>
          )}
        </div>
      </Link>
      <div className=" space-x-3 flex items-center">
        {tags && (
          <span key={tags[0].id} className="text-12 text-brand-1 mt-2 mb-1">
            {tags[0].name}
          </span>
        )}
        {tags && tags.length > 1 && (
          <Popover className="relative flex items-center">
            {({ open }) => (
              <>
                <Popover.Button className="text-xs rounded-full bg-gray-200 px-2 mt-2 mb-1">
                  {/* <button > */}+{' '}
                  <span className="text-xs">{tags.length - 1}</span>
                  {/* </button> */}
                </Popover.Button>
                <Transition
                  as={Fragment}
                  enter="transition ease-out duration-200"
                  enterFrom="opacity-0 translate-y-1"
                  enterTo="opacity-100 translate-y-0"
                  leave="transition ease-in duration-150"
                  leaveFrom="opacity-100 translate-y-0"
                  leaveTo="opacity-0 translate-y-1"
                >
                  <Popover.Panel className="absolute top-6 left-0 z-10 mt-3 max-w-sm transform px-4 sm:px-0 ">
                    <div className="overflow-hidden rounded-lg shadow-lg ring-1 ring-black ring-opacity-5">
                      <div className="bg-gray-50 p-4">
                        {tags?.map(
                          (tag, index) =>
                            index > 0 && (
                              <span
                                key={tag.id}
                                className="flow-root text-12 text-brand-1 rounded-md px-2 py-2 transition duration-150 ease-in-out hover:bg-gray-100 focus:outline-none focus-visible:ring focus-visible:ring-orange-500 focus-visible:ring-opacity-50"
                              >
                                {tag.name}
                              </span>
                            )
                        )}
                        {/* {tags && (
                          <span
                            key={tags[0].id}
                            className="text-12 text-brand-1 mt-4 mb-1"
                          >
                            {tags[0].name}
                          </span>
                        )} */}
                      </div>
                    </div>
                  </Popover.Panel>
                </Transition>
              </>
            )}
          </Popover>
        )}
      </div>

      {/* <div className="bg-brand-1 text-white font-semibold rounded-lg text-xs text-center w-2/6 p-0.5 ">
        {vods?.vodItems?.length} Lesson(s)
      </div> */}
      <Link
        className="flex flex-col cursor-pointer space-y-1"
        to={{
          pathname: `/vods/${vod?.id}`,
          state: { from: '/vods' },
        }}
      >
        <h1 className="font-semibold text-[1.1rem] leading-[1.5rem] md:text-14.5 whitespace-pre-line">
          {vod?.title}
        </h1>

        <p className="text-12 md:text-11 truncate-2-lines whitespace-pre-line">
          {vod?.introduction}
        </p>
      </Link>
    </div>
  );
};
