import { useHistory } from 'react-router-dom';
import React, { FC } from 'react';
import { EssayItemType } from '../types';
import { Icon } from '../components/icons';
import HTMLEllipsis from 'react-lines-ellipsis/lib/html';
import responsiveHOC from 'react-lines-ellipsis/lib/responsiveHOC';

const ResponsiveEllipsis = responsiveHOC()(HTMLEllipsis);

export interface EssayCardProps {
  essay: EssayItemType;
  index: number;
  authenticated: boolean;
}

export const EssayCard: FC<EssayCardProps> = ({
  essay,
  index,
  authenticated,
}) => {
  const { push } = useHistory();

  return (
    <button
      key={`essayCard=${index}`}
      disabled={!authenticated && essay.locked ? true : false}
      className="flex w-full rounded-md overflow-hidden bg-[#333333] disabled:cursor-not-allowed items-center"
      onClick={() => push(`/free-essays/${essay.id}`)}
    >
      <div
        className=" bg-white rounded-md py-5 lg:py-10 px-4 lg:px-8 w-full max-h-full max-w-[100px] sm:max-w-[120px] md:max-w-[150px] lg:max-w-[200px] flex items-center relative h-full"
        style={{ aspectRatio: '199 / 150' }}
      >
        <img
          className="object-contain h-12 mx-auto"
          src={essay.school.logo}
          alt={`logo-${essay.school.name}`}
        />
        {!authenticated && essay.locked && (
          <div className="absolute top-0 left-0 rounded-md bg-black bg-opacity-50 w-full h-full flex justify-center items-center">
            <Icon.LockFilled className="w-4 md:w-6 lg:w-8" />
          </div>
        )}
      </div>
      <div className="w-full h-full text-left px-4 lg:px-8 flex flex-col justify-center space-y-1 lg:space-y-3 sm:py-2">
        <div className="!text-[#999999] text-xs lg:text-sm">
          <ResponsiveEllipsis
            unsafeHTML={essay.school.name.toUpperCase()}
            maxLine={'1'}
            basedOn="letters"
          />
        </div>
        <div className="text-lg md:text-xl lg:text-2xl font-bold">
          <ResponsiveEllipsis
            unsafeHTML={essay.title}
            maxLine={'1'}
            basedOn="letters"
          />
        </div>
        <div className="text-normal lg:text-xl">
          <ResponsiveEllipsis
            unsafeHTML={essay.subtitle}
            maxLine={'1'}
            basedOn="letters"
          />
        </div>
      </div>
    </button>
  );
};
