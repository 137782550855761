import React, { useEffect, useState } from 'react';
import { Link, useHistory, useLocation, useParams } from 'react-router-dom';
import { FREE_ESSAYS_ITEMS } from '../../../constants/freeEssays';
import { Document, Page } from 'react-pdf/dist/esm/entry.webpack';
import styled from 'styled-components';
import { useAuth } from '../../../hooks';
import { CardBanner } from '@src/components/CardBanner';

export const FreeEssayDetailPage = () => {
  const { push } = useHistory();
  const { pathname } = useLocation();
  const { authenticated } = useAuth();
  const { id } = useParams<{ id: string }>();
  const [essay, setEssay] = useState<any>(null);
  const [numPages, setNumPages] = useState(1);
  const [pageNumber, setPageNumber] = useState(1); //setting 1 to show fisrt page

  useEffect(() => {
    if (id) {
      const essay = FREE_ESSAYS_ITEMS.find((item) => item.id === id);
      if (essay) {
        setEssay(essay);
      } else {
        push('/404');
      }
    }
  }, [id]);

  const handleRightClick = (e: React.MouseEvent) => {
    e.preventDefault();
  };

  const handleDrag = (e: React.MouseEvent) => {
    e.preventDefault();
  };

  function onDocumentLoadSuccess({ numPages }: any) {
    setNumPages(numPages);
    setPageNumber(1);
  }

  function changePage(offset: number) {
    setPageNumber((prevPageNumber) => prevPageNumber + offset);
  }

  function previousPage() {
    changePage(-1);
  }

  function nextPage() {
    changePage(1);
  }

  if (!essay) return <></>;
  if (!authenticated && essay.locked)
    return (
      <div className="flex-1 !my-16 flex items-center flex-col space-y-8 text-center">
        <h3 className="text-3xl whitespace-pre-line text-brand-orange font-semibold">
          {`Sign up for free to read this essay. \nIt only takes 10 seconds.`}
        </h3>
        <Link
          className="border-2 bg-black border-white text-white rounded-full h-10 md:h-12 px-5 md:px-8 text-normal tracking-wide flex items-center"
          to={{
            pathname: '/login',
            state: { from: { pathname: pathname } },
          }}
        >
          Sign up for free
        </Link>
      </div>
    );

  return (
    <div
      onContextMenu={(e) => handleRightClick(e)}
      onDragStart={(e) => handleDrag(e)}
      className="max-w-container"
    >
      <div className="max-w-screen-md mx-auto w-full md:py-20 py-4 px-4">
        <button
          onClick={() => push('/free-essays')}
          className="bg-gray-100 text-gray-700 p-2 rounded-md text-15"
        >
          ← Back
        </button>

        <div className="mb-6">
          {/* <div className="mt-5 bg-red-50 text-red-500 rounded-full text-11 max-w-max p-2 px-4">
            Free Essay
          </div> */}
          <div className="mt-5 text-sm text-black text-opacity-50">
            {essay.school.name.toUpperCase()}
          </div>
          <h1 className="mt-2 text-24 font-semibold">{essay.title}</h1>
          <h2 className="mt-2 text-normal font-semibold text-black text-opacity-50">
            {essay.subtitle}
          </h2>
        </div>
        <StyledContainer>
          <p className="mt-6 text-14 text-gray-700 space-y-8">
            <Document file={essay.pdf} onLoadSuccess={onDocumentLoadSuccess}>
              <Page
                className="shadow-xl rounded-xl overflow-hidden"
                pageNumber={pageNumber}
              />
            </Document>

            <div
              className="flex shadow-xl rounded-md w-fit bg-white bg-opacity-10 mx-auto border border-opacity-50 items-center"
              style={{ width: 'fit-content' }}
            >
              <button
                type="button"
                className="text-xs py-3 px-4 hover:bg-gray-100 h-full"
                disabled={pageNumber <= 1}
                onClick={previousPage}
              >
                {'<'}
              </button>
              <p className="px-2">
                {pageNumber || (numPages ? 1 : '--')} of {numPages || '--'}
              </p>
              <button
                type="button"
                className="text-xs py-3 px-4 hover:bg-gray-100 h-full"
                disabled={pageNumber >= numPages}
                onClick={nextPage}
              >
                {'>'}
              </button>
            </div>
            {/* {!authenticated && (
              <div className="!my-16 flex items-center flex-col space-y-8 text-center">
                <h3 className="text-xl lg:text-3xl whitespace-pre-line text-brand-orange font-semibold">
                  {`Sign up for free to read more essays. \nIt only takes 10 seconds.`}
                </h3>
                <Link
                  className="border-2 bg-black border-white text-white rounded-full h-10 md:h-12 px-5 md:px-8 text-normal tracking-wide flex items-center"
                  to={{
                    pathname: '/login',
                    state: { from: { pathname: pathname } },
                  }}
                >
                  Sign up for free
                </Link>
              </div>
            )} */}
          </p>
        </StyledContainer>
      </div>
      <CardBanner
        title="Need essay help?"
        description={`Put in a Quick Request now. \nAnything from content brainstorming to editing- \nwe’ve got you!`}
        className="bg-transparent"
      />
    </div>
  );
};

const StyledContainer = styled.div`
  .react-pdf__Page__canvas {
    width: 100% !important;
    height: unset !important;
  }
`;
