import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Button } from './Button';
import { ReactComponent as TickCircleIcon } from '../assets/svg/icon-tickcircle.svg';
import { Icon } from './icons';
import { popupState } from '../plugins/ridge';
import { Checkbox } from '../components/Checkbox';
import { useOnClickOutside } from 'usehooks-ts';

export const PopUp = () => {
  const popup = popupState.useValue();
  const location = useLocation();
  const [confirm, setConfirm] = useState(false);
  const refPopup = React.useRef(null);

  useEffect(() => {
    //  if route opens block body scroll
    if (popup.title) {
      document.body.style.overflow = 'hidden';
    }
    return () => {
      document.body.style.overflow = 'auto';
    };
  });

  useEffect(() => {
    //  if route changes close modal
    onClose();
  }, [location]);

  const onClose = () => {
    // if modal closed body to be scrollable
    setConfirm(false);
    popupState.reset();
  };

  useOnClickOutside(refPopup, () => {
    if (popup.disableClickAway) {
      return;
    } else {
      onClose();
    }
  });

  if (!popup.title) return <></>;

  return (
    <div className="z-50 fixed inset-0 bg-littleblack flex items-center justify-center px-4">
      {/* <ClickAwayListener
        onClickAway={popup.disableClickAway ? () => {} : onClose}
      > */}
      <div
        ref={refPopup}
        className=" w-80 flex flex-col  bg-white rounded-xl overflow-hidden p-6 space-y-2"
      >
        <button className="self-end" onClick={onClose}>
          <Icon.X />
        </button>

        <div className="flex flex-col justify-center items-center text-center space-y-5">
          {popup.icon && <TickCircleIcon />}

          <h1 className="font-header text-lg md:text-20 font-bold flex-1 whitespace-pre-line">
            {popup.title}
          </h1>
          <p className="font-header text-sm md:text-14 text-gray-500 whitespace-pre-wrap">
            {popup.description}
          </p>
          {popup.needConfirm && (
            <Checkbox
              label={popup.confirmText}
              checked={confirm}
              onClick={() => setConfirm(!confirm)}
            />
          )}
          {popup.buttonText && (
            <Button
              text={popup.buttonText}
              className={`font-header disabled:bg-gray-400 bg-brand-1 text-white rounded-full w-full  `}
              onClick={() => {
                popup.onClick();
                onClose();
              }}
              disabled={popup.needConfirm && !confirm ? true : false}
            />
          )}
        </div>
      </div>
      {/* </ClickAwayListener> */}
    </div>
  );
};
