import { XMarkIcon } from '@heroicons/react/24/outline';
import { fetcher } from '@src/plugins/react-query';
import { JobFile } from '@src/types';
import { getS3Uri } from '@src/utils';
import React, { FC, useCallback, useRef, useState } from 'react';
import { useQuery } from 'react-query';
import ImageViewer from 'react-simple-image-viewer';

export interface ImagePreviewItemProps {
  file: JobFile;
  ctaText?: string;
  onClickCta?: (item: any) => void;
}

export const ImagePreview: FC<ImagePreviewItemProps> = ({ file }) => {
  const [isViewerOpen, setIsViewerOpen] = useState(false);

  const openImageViewer = useCallback(() => {
    setIsViewerOpen(true);
  }, []);

  const closeImageViewer = () => {
    setIsViewerOpen(false);
  };

  return (
    <div>
      {/* {images.map((src, index) => ( */}
      <img
        src={getS3Uri(file.fileKey)}
        onClick={() => openImageViewer()}
        width="300"
        style={{ margin: '2px' }}
        alt=""
      />
      {/* //   ))} */}

      {isViewerOpen && (
        <ImageViewer
          backgroundStyle={{
            zIndex: 99,
            backgroundColor: '#000000b0',
            marginTop: '3.5rem',
            padding: '10px',
            maxHeight: 'calc(100vh - 3.5rem)',
          }}
          src={[getS3Uri(file.fileKey)]}
          disableScroll={false}
          closeOnClickOutside={true}
          onClose={closeImageViewer}
          closeComponent={<XMarkIcon className="text-white wh-5" />}
        />
      )}
    </div>
  );
};
