import { useHistory } from 'react-router-dom';

export const ErrorPage = () => {
  const { push } = useHistory();

  return (
    <section className="py-8 px-4 text-center">
      <div className="max-w-auto md:max-w-lg mx-auto">
        <div className="flex flex-col items-center my-12 md:my-20">
          <div className="font-bold text-2xl">
            Linkstory could not connect to your account.
          </div>
          <div className="text-gray-400 font-medium text-sm md:text-xl lg:text-xl mt-8">
            Your email is required for us to connect with your Facebook account.
          </div>
          <button
            type="button"
            className="flex items-center rounded-lg bg-brand-1 px-4 py-2 text-white mt-8"
            onClick={() => push('/signup')}
          >
            <span className="font-medium subpixel-antialiased">Try again</span>
          </button>
        </div>
      </div>
    </section>
  );
};
