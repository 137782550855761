import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { withCookies, Cookies, ReactCookieProps } from 'react-cookie';
import UnsupportedBrowserDC from '../components/UnsupportedBrowserDC';

interface UnsupportedBrowserProps extends ReactCookieProps {
  browserSupported: boolean;
  browserName?: string;
}

function UnsupportedBrowser(props: UnsupportedBrowserProps) {
  const [cookies, setCookies] = useState<Cookies | undefined>(props.cookies);
  const [hasCookies, setHasCookies] = useState<boolean>(false);
  const [showPopUp, setShowPopUp] = useState<boolean>(true);

  useEffect(() => {
    if (cookies && showPopUp) {
      const currentCookies = cookies.get(UnsupportedBrowserDC.COOKIE_VALUE);

      setShowPopUp(!currentCookies);
      setHasCookies(!!currentCookies);
    } else {
      setCookies(props.cookies);
    }
  }, [cookies, props.cookies, showPopUp, props.browserSupported]);

  const closePopUp = (selCheck: boolean): void => {
    if (cookies) {
      if (selCheck) {
        const expires: Date = moment().add(1, 'minutes').toDate();
        cookies.set(UnsupportedBrowserDC.COOKIE_VALUE, true, {
          path: '/',
          expires,
        });
      }
    }
    setShowPopUp(false);
  };

  //   const removeCookies = (): void => {
  //     console.log(cookies, 'remove cookie');
  //     if (cookies) {
  //       cookies.remove(UnsupportedBrowserDC.COOKIE_VALUE);
  //     }
  //   };

  if (props.browserSupported || hasCookies || !showPopUp) return <></>;

  return (
    <>
      {showPopUp && cookies ? (
        <div className="hidden md:flex w-full justify-center bg-black bg-opacity-90 text-white h-14 items-center">
          <div className="max-w-screen-xl w-full flex justify-between px-4 items-center">
            <div className="flex space-x-4 items-center ">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                // height="21"
                fill="none"
                viewBox="0 0 23 21"
              >
                <path
                  stroke="#F54040"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="m9.809 2.406-8.47 14.14a2 2 0 0 0 1.71 3h16.94a2 2 0 0 0 1.71-3l-8.47-14.14a2 2 0 0 0-3.42 0v0Zm1.71 5.14v4m0 4h.01"
                />
              </svg>
              <article className="tracking-wide">
                For the best experience, we recommend using Chrome
              </article>
            </div>
            <div className="space-x-4">
              <button
                className="hover:opacity-80"
                onClick={() => closePopUp(true)}
              >
                Don't show again
              </button>
              <button
                className="text-sm hover:opacity-80"
                onClick={() => closePopUp(false)}
              >
                X
              </button>
            </div>
          </div>

          {/* {hasCookies ? (
            <button onClick={removeCookies}>Remove Cookies</button>
          ) : null} */}
        </div>
      ) : null}
    </>
  );
}

export default withCookies(UnsupportedBrowser);
