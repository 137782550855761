import { stringify } from 'qs';
import { api } from '../../plugins/axios';
import { GetVodsDto } from './type';

export function getVods(getVodsDto: GetVodsDto) {
  const queryString = stringify(getVodsDto, {
    addQueryPrefix: true,
    encode: false,
  });
  return api.get(`/vods${queryString}`);
}
