import React from 'react';
import { useForm } from 'react-hook-form';
import { useHistory, useParams } from 'react-router-dom';
import { Button } from '@components/Button';
import { TextField } from '@components/TextField';
import { api } from '@plugins/axios';

interface FormValues {
  password: string;
  confirmPassword: string;
}

export const PasswordResetPage = () => {
  const { push } = useHistory();
  const { token } = useParams<{ token: string }>();
  const {
    register,
    watch,
    handleSubmit,
    formState: { errors },
  } = useForm<FormValues>({
    mode: 'onChange',
  });

  return (
    <div className="max-w-screen-xl mx-auto md:h-screen-15 justify-center flex items-center px-5 py-20">
      <div className="space-y-8 flex flex-col md:w-96">
        <h1 className="font-bold text-center text-24 mb-5">Reset Password</h1>
        <h2 className="font-semibold text-center text-15">
          Please set up a new password.
        </h2>
        <form
          onSubmit={handleSubmit(async (data) => {
            try {
              const response = await api.patch('users/change-password', {
                token: token,
                password: data.password,
              });
              if (response) {
                alert(
                  'The password change has been completed.\nPlease log in again.'
                );
                push('/login');
              }
            } catch (error) {
              console.log(error);
            }
          })}
        >
          <TextField
            autoComplete="off"
            isPassword={true}
            label="Password* (Use 8 or more characters with a mix of letters, numbers & symbols)"
            placeholder="Enter your password"
            helper={errors.password?.message}
            {...register('password', {
              required: 'Please enter your password.',
              validate: {
                banned: (value: string) => {
                  const regex =
                    /^[~`!@#$%^&*()_+=[\]\{}|;':",.\/<>?a-zA-Z0-9-]+$/;
                  if (!regex.test(value)) {
                    return 'Only use letters, numbers, and common punctuation characters';
                  }
                },
                length: (value) =>
                  (value && value.length >= 8) ||
                  'Use 8 characters or more for your password',
              },
            })}
          />
          <TextField
            autoComplete="off"
            isPassword={true}
            label="Confirm password"
            placeholder="Enter your password"
            helper={errors.confirmPassword?.message}
            {...register('confirmPassword', {
              required: 'Please enter your password.',
              validate: (val: string) => {
                if (watch('password') !== val) {
                  return 'Those passwords didn’t match. Try again.';
                }
              },
            })}
          />

          <Button
            text="Save"
            disabled={!watch('password')}
            className="rounded-full filled-brand-1 w-full mt-10"
          />
        </form>
      </div>
    </div>
  );
};
