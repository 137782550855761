import { newRidgeState } from 'react-ridge-state';
import { Socket } from 'socket.io-client';
import { User } from '../types';

export const tokenState = newRidgeState<string | null>(null, {
  onSet: (newState) => {
    if (newState) {
      localStorage.setItem('token', newState);
    } else {
      localStorage.removeItem('token');
    }
  },
});

export const socketState = newRidgeState<Socket | null>(null);

export const idState = newRidgeState<number>(0);

export const accessTokenState = newRidgeState<string>('', {
  onSet: (newState) => {
    if (newState) {
      localStorage.setItem('accessToken', newState);
    } else {
      localStorage.removeItem('accessToken');
    }
  },
});

export const osState = newRidgeState<string>('');

export const popupState = newRidgeState<{
  icon: boolean;
  title: string;
  description: JSX.Element | string;
  buttonText?: string;
  onClick: () => void;
  disableClickAway?: boolean;
  needConfirm?: boolean;
  confirmText?: string;
}>({
  icon: false,
  title: '',
  description: '',
  buttonText: '',
  onClick: () => {},
  disableClickAway: false,
  needConfirm: false,
  confirmText: '',
});

export const fullModalState = newRidgeState<{
  open: boolean;
  content?: JSX.Element | null;
}>({
  open: false,
  content: null,
});

export const modalDiscountState = newRidgeState<{
  open: boolean;
  title: string;
  content?: JSX.Element | null;
  me: User | undefined;
  authenticated: boolean;
}>({
  open: false,
  title: '',
  content: null,
  me: undefined,
  authenticated: false,
});

function setInitialState() {
  const token = localStorage.getItem('token');
  tokenState.set(token);
}

setInitialState();
