import { AxiosError, AxiosResponse } from 'axios';
import { useInfiniteQuery } from 'react-query';
import { getVods } from '../../api/vods';
import { GetVodsDto } from '../../api/vods/type';
import { getNextPageParam } from '../../plugins/react-query';
import { Paginated, Vod } from '../../types';

export function useVods(getVodsDto: GetVodsDto) {
  const { page, limit } = getVodsDto;

  return useInfiniteQuery<AxiosResponse<Paginated<Vod>>, AxiosError>(
    ['/users/mentors', getVodsDto],
    ({ pageParam = 1 }) => getVods({ ...getVodsDto, page: pageParam }),
    {
      getNextPageParam: (last, pages) => {
        return getNextPageParam(limit, last, pages);
      },
      keepPreviousData: true,
      enabled: !!page,
      refetchOnWindowFocus: false,
    }
  );
}
