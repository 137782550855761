import moment from 'moment';
import React, { useState } from 'react';

import { BreadCrumbs } from '@src/components/BreadCrumbs';
import { EmptyState } from '@src/components/EmptyState';
import { Pagination } from '@src/components/Pagination';
import { Table } from '@src/components/Table';
import { TextField } from '@src/components/TextField';

import * as Icons from '@heroicons/react/24/outline';
import * as Types from '@src/types';
import * as Hooks from '@src/hooks';
import {
  MomentFormat,
  utcToDiffMinutes,
  utcToLocalFormat,
} from '@src/plugins/moment';
import { fetcher } from '@src/plugins/react-query';

import CountUp from 'react-countup';
import { useForm } from 'react-hook-form';
import { useQuery } from 'react-query';
import { Link } from 'react-router-dom';
import ReactTooltip from 'react-tooltip';
import { HiddenHeadingMeta } from '@src/components/HiddenHeadingMeta';
import { ReactHelmet } from '@src/components/ReactHelmet';

interface FormValues {
  from: Date;
  to: Date;
}

export const PaymentsPage = () => {
  const {
    register,
    reset: resetForm,
    watch,
  } = useForm<FormValues>({
    mode: 'onChange',
  });
  const { authenticated } = Hooks.useAuth();
  const { data: me, isFetching: meIsFetching } = useQuery<Types.User>(
    '/users/v2/me',
    fetcher,
    {
      enabled: !!authenticated,
    }
  );

  const queryString = Hooks.useQueryString({
    ...(watch('from') && {
      from: moment(watch('from')).startOf('day').toISOString(),
    }),
    ...(watch('to') && {
      to: moment(watch('to')).endOf('day').toISOString(),
    }),
  });

  const { data: PaymentDetails } = useQuery<Types.PaymentDetails>(
    `/v2/receipts/mentor-dashboard/detail`,
    fetcher,
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
    }
  );

  const { data: PaymentItems } = useQuery<Types.Paginated<Types.Receipt>>(
    `/v2/receipts/mentor-dashboard${queryString}`,
    fetcher,
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
    }
  );

  const paymentStats = [
    {
      id: 1,
      name: 'Total earnings',
      stat: Number(PaymentDetails?.totalEarnings),
      icon: Icons.CurrencyDollarIcon,
    },
    {
      id: 2,
      name: 'Amount paid',
      stat: PaymentDetails?.amountPaid ?? 0,
      icon: Icons.BanknotesIcon,
    },
    {
      id: 3,
      name: 'Amount owed',
      stat: PaymentDetails?.amountOwed ?? 0,
      icon: Icons.Square3Stack3DIcon,
    },
    {
      id: 4,
      name: 'Pay percent',
      stat: PaymentDetails?.payPercent ?? 0,
      icon: Icons.ReceiptPercentIcon,
    },
  ];

  const PaymentsSection = () => {
    return (
      <div className=" bg-white border max-w-[1100px] w-full rounded-md overflow-hidden">
        <div className="border-b border-gray-200 bg-white px-4 py-5 sm:px-6">
          <div className="-ml-4 -mt-2 flex flex-wrap items-center justify-between sm:flex-nowrap">
            <div className="ml-4 mt-2 flex gap-2">
              <div className="flex gap-2">
                <Icons.ChartBarSquareIcon
                  className={`wh-6 flex-shrink-0 self-center `}
                  aria-hidden="true"
                />
                <h3 className="text-lg font-medium leading-6 text-gray-900">
                  Overview
                </h3>
              </div>
              <button className="border inline-flex items-center rounded-full bg-gray-100 px-2.5 py-0.5 text-xs font-medium text-gray-800 gap-1 whitespace-pre-line">
                <p
                  data-for="tip-tooltip"
                  data-tip={`Total earnings: Total earnings from Linkstory
                  Amount paid: Amount paid out from total earnings
                  Amount owed: Earnings to be disbursed on next pay day 
                  Pay percent: Percentage of total earnings paid out
                  `}
                  className="flex items-center gap-1"
                >
                  <Icons.LightBulbIcon
                    className={`wh-3 flex-shrink-0 self-center text-xs `}
                    aria-hidden="true"
                  />
                  <p>Tip</p>
                </p>
                <ReactTooltip
                  id="tip-tooltip"
                  className="max-w-[80vw]"
                  delayHide={1000}
                  effect="solid"
                  place="bottom"
                />
              </button>
            </div>
          </div>
        </div>
        <div className="p-6 space-y-4">
          <dl className=" grid grid-cols-1 gap-5 sm:grid-cols-2 lg:grid-cols-4">
            {paymentStats.map((item, index) => (
              <div
                key={item.id}
                className="relative overflow-hidden rounded-lg bg-white px-4 py-5 shadow sm:px-6 sm:py-6"
              >
                <div className="absolute rounded-md bg-brand-orange p-3">
                  <item.icon
                    className="wh-5 md:wh-6 text-white"
                    aria-hidden="true"
                  />
                </div>
                <div className="ml-14 md:ml-16 ">
                  <dt>
                    <p className="truncate text-sm font-medium text-gray-500">
                      {item.name}
                    </p>
                  </dt>
                  <dd className="flex items-baseline">
                    <p className="text-lg md:text-xl font-semibold text-gray-900">
                      <CountUp
                        end={Number(item.stat) ?? 0}
                        prefix={index !== paymentStats.length - 1 ? '$' : ''}
                        suffix={index === paymentStats.length - 1 ? '%' : ''}
                        separator=","
                        decimal="."
                        decimals={2}
                        duration={1}
                      />
                    </p>
                  </dd>
                </div>
              </div>
            ))}
          </dl>
        </div>
      </div>
    );
  };
  const filterSection = () => {
    return (
      <form
        onSubmit={(e) => {
          // e.preventDefault();
        }}
      >
        <div date-rangepicker="" className="flex items-center gap-4">
          <div className="relative">
            <TextField
              type="date"
              data-placeholder="YYYY. MM. DD"
              className={`text-sm h-10 ${!!watch('from') ? 'has-value' : ''}`}
              {...register('from')}
            />
          </div>
          <span className=" text-gray-500">to</span>
          <div className="relative">
            <TextField
              type="date"
              min={moment().format('YYYY-MM-DD[T]HH:mm')}
              data-placeholder="YYYY. MM. DD"
              className={`text-sm h-10 ${!!watch('from') ? 'has-value' : ''}`}
              {...register('to')}
            />
          </div>
          <button
            type="button"
            onClick={() => resetForm()}
            className="group bg-brand-blue hover:brightness-105 rounded-lg py-2.5 px-3 text-white"
          >
            <Icons.ArrowPathIcon className="wh-4 transition duration-300 group-hover:rotate-90" />
          </button>
        </div>
      </form>
    );
  };
  const contentsSection = () => {
    return (
      <>
        <div className="bg-white border rounded-md overflow-hidden">
          <Table>
            <Table.Head>
              <Table.Row className="whitespace-nowrap">
                <Table.Th>Session price</Table.Th>
                <Table.Th>Payment</Table.Th>
                <Table.Th>Session start</Table.Th>
                <Table.Th>Session ended</Table.Th>
                <Table.Th>Duration</Table.Th>
                <Table.Th>Student Name</Table.Th>
                <Table.Th>Details</Table.Th>
              </Table.Row>
            </Table.Head>
            <Table.Body>
              {PaymentItems &&
                PaymentItems.items.map((item) => (
                  <Table.Row key={item.lessonId}>
                    <Table.Td>${Number(item.price).toFixed(2)}</Table.Td>
                    <Table.Td>${Number(item.realPrice).toFixed(2)}</Table.Td>
                    <Table.Td>
                      {utcToLocalFormat(
                        item?.lesson.startedAt,
                        MomentFormat.YYYYMMDDhmma
                      )}
                    </Table.Td>
                    <Table.Td>
                      {utcToLocalFormat(
                        item?.lesson.endedAt,
                        MomentFormat.YYYYMMDDhmma
                      )}
                    </Table.Td>
                    <Table.Td>
                      <span className="inline-flex items-center rounded-full bg-green-100 px-2.5 py-0.5 text-xs font-medium text-green-800">
                        {utcToDiffMinutes(
                          item?.lesson.startedAt,
                          item?.lesson.endedAt
                        )}
                        mins
                      </span>
                    </Table.Td>
                    <Table.Td>{item.lesson.studentName}</Table.Td>
                    <Table.Td>
                      <Link
                        className="font-normal text-indigo-600 hover:text-indigo-900"
                        to={`/my/dashboard/session/${item.lessonId}`}
                      >
                        Details
                      </Link>
                    </Table.Td>
                  </Table.Row>
                ))}
              {!PaymentItems?.items.length && (
                <Table.Row>
                  <Table.Td colSpan={4} className="w-full">
                    <EmptyState
                      title="No Payment History"
                      subTitle="There is no payment information yet."
                      noCta
                      className="w-full"
                    />
                  </Table.Td>
                </Table.Row>
              )}
            </Table.Body>
          </Table>
        </div>
      </>
    );
  };
  return (
    <>
      <HiddenHeadingMeta text="Payments | Linkstory" />
      <ReactHelmet keywords="payments, tutor" title="Payments | Linkstory" />
      <BreadCrumbs
        homePath="/my/dashboard"
        breadCrumbsPages={[
          { name: 'Payments', path: '/my/dashboard/payments' },
        ]}
      />
      {PaymentsSection()}
      <div className="space-y-4 max-w-[1100px] w-full ">
        <section className="bg-white border rounded-md overflow-hidden whitespace-pre-line">
          <div className="border-b border-gray-200 bg-white px-4 py-5 sm:px-6">
            <div className="-ml-4 -mt-2 flex flex-wrap items-center justify-between sm:flex-nowrap">
              <div className="ml-4 mt-2 flex gap-2">
                <Icons.CurrencyDollarIcon
                  className={`wh-6 flex-shrink-0 self-center `}
                  aria-hidden="true"
                />
                <h3 className="text-lg font-medium leading-6 text-gray-900">
                  Payments
                </h3>
              </div>
            </div>
          </div>
          <div className="px-4 py-5 sm:px-6 space-y-6">
            <p className="text-xs">
              {`Here is a list of all the payments you have received through Linkstory. \nClick on the details link to see the session details.`}
            </p>

            {filterSection()}
            {contentsSection()}
          </div>
        </section>
        {PaymentItems?.total && (
          <Pagination className="flex flex-wrap">
            <Pagination.Label
              count={PaymentItems?.items.length}
              total={PaymentItems?.total}
            />
            <Pagination.Nav
              basePath="/my/dashboard/payments"
              total={PaymentItems?.total}
            />
          </Pagination>
        )}
      </div>
    </>
  );
};
