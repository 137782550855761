import {
  ArrowRightCircleIcon,
  CalendarIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
  ClockIcon,
  CreditCardIcon,
  CurrencyDollarIcon,
  EllipsisVerticalIcon,
  EnvelopeIcon,
  HandRaisedIcon,
  LightBulbIcon,
  UserCircleIcon,
  UserIcon,
} from '@heroicons/react/24/outline';
import { Avatar } from '@src/components/Avatar';
import { Dropdown } from '@src/components/Dropdown';
import { TOP_MENTORS_IDS } from '@src/constants/topMentors';
import { useAuth } from '@src/hooks';
import { fetcher } from '@src/plugins/react-query';
import {
  ChangePriceState,
  Chat,
  LessonState,
  Paginated,
  Role,
  SessionType,
  User,
} from '@src/types';
import { getS3Uri } from '@src/utils';
import { useQuery } from 'react-query';
import { Link, NavLink, useHistory } from 'react-router-dom';
import ReactTooltip from 'react-tooltip';
import { useCallback, useEffect, useState } from 'react';
import { api } from '@src/plugins/axios';
import { EmptyState } from '@src/components/EmptyState';
import {
  MomentFormat,
  utcToDiffMinutes,
  utcToLocalFormat,
} from '@src/plugins/moment';
import ReactStars from 'react-rating-stars-component';
import { useForm } from 'react-hook-form';
import { Button } from '@src/components/Button';
import { TextArea } from '@src/components/TextArea';
import { TextField } from '@src/components/TextField';
import { HiddenHeadingMeta } from '@src/components/HiddenHeadingMeta';
import { ReactHelmet } from '@src/components/ReactHelmet';
import mixpanel from 'mixpanel-browser';
import { setBaggageValue } from '@sentry/utils';

interface SessionReviewFormValues {
  title: string;
  description: string;
  rating: number;
}

export const StudentDashboardDefault = () => {
  const { push } = useHistory();
  const { authenticated } = useAuth();
  const { data: me } = useQuery<User>('/users/me', fetcher, {
    enabled: !!authenticated,
  });

  const {
    register,
    setValue,
    reset: resetSessionReviewForm,
    handleSubmit,
    watch,
    formState: { isSubmitting, errors, isDirty, isValid },
  } = useForm<SessionReviewFormValues>({
    mode: 'onChange',
  });

  useEffect(() => {
    mixpanel.track('Page Viewed', {
      item_category: 'Dashboard - Student',
    });
  }, []);

  const [rating, setRating] = useState<number | null>(null);

  const {
    data: upcomingSessionItems,
    refetch: refetchUpcomimgSessionItems,
    isFetching: isUpcomingSessionFetching,
  } = useQuery<Paginated<SessionType>>(
    `/lessons/my/histories?status=${LessonState.UPCOMING}`,
    fetcher
  );

  const { data: unReviewedSessionItems } = useQuery<SessionType[]>(
    `lessons/my/un-reviewed`,
    fetcher
  );

  const { data: myMentors } = useQuery<User[]>(
    `/users/v2/students/my-mentors`,
    fetcher,
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
    }
  );

  const { data: interestedMentors } = useQuery<User[]>(
    `/users/v2/students/interested-mentors`,
    fetcher,
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
    }
  );
  const [isPaymentMethod, setIsPaymentMethod] = useState(false);
  const { isFetching: isPaymentMethodFetching } = useQuery<any>(
    `/credit-cards`,
    fetcher,
    {
      enabled: !!me,
      refetchOnWindowFocus: false,
      onSuccess(data) {
        if (data.data[0]) {
          setIsPaymentMethod(true);
        }
      },
    }
  );

  const [isReviewSubmitting, setIsReviewSubmitting] = useState(false);
  const [isReviewSent, setIsReviewSent] = useState(false);
  const [selectedReviewIndex, setSelectedReviewIndex] = useState(0);

  // reactStar 컴포넌트 prop 변경시에도 리랜더링 되지 않는 문제 해결을 위한 Tweak
  const [starKeyForce, setStarKeyForce] = useState(0);

  useEffect(() => {
    setStarKeyForce((prev) => prev + 1);
  }, [rating]);

  const handleNavigateMessage = async (mentorId: number) => {
    await api
      .get(`/chats/mentor/${mentorId}`)
      .then(({ data }) => push(`/my/chats?id=${data.id}`));
  };

  const ReviewSessionSection = () => {
    return (
      // !isReviewSent &&
      unReviewedSessionItems &&
      unReviewedSessionItems[selectedReviewIndex] && (
        <div className="overflow-hidden bg-white shadow rounded-md max-w-[1100px]">
          <div className="border-b border-gray-200 bg-white px-4 py-5 sm:px-6">
            <div className="-ml-4 -mt-2 flex flex-wrap items-center justify-between sm:flex-nowrap">
              <div className="ml-4 mt-2 flex gap-2 justify-between w-full">
                <div className="flex gap-2 items-center">
                  <CalendarIcon className="wh-6 flex-shrink-0 self-center text-xs" />
                  <h3 className="text-lg font-medium leading-6 text-gray-900">
                    Rate Tutor
                  </h3>
                </div>
                <div className="flex gap-2 items-center">
                  <span className="inline-flex items-center rounded-full bg-yellow-100 px-3 py-0.5 text-sm font-medium text-gray-800">
                    {`${selectedReviewIndex + 1} / ${
                      unReviewedSessionItems.length
                    }`}
                  </span>
                  <nav className={`pagination-nav `}>
                    <button
                      className={`pagination-nav-button rounded-l-md disabled:bg-gray-100 disabled:cursor-not-allowed`}
                      disabled={selectedReviewIndex === 0}
                      onClick={() => {
                        setSelectedReviewIndex(selectedReviewIndex - 1);
                      }}
                    >
                      <ChevronLeftIcon className="wh-5" />
                    </button>
                    <button
                      className={`pagination-nav-button rounded-r-md disabled:bg-gray-100 disabled:cursor-not-allowed`}
                      disabled={
                        selectedReviewIndex + 1 ===
                        unReviewedSessionItems.length
                      }
                      onClick={() => {
                        setSelectedReviewIndex(selectedReviewIndex + 1);
                      }}
                    >
                      <ChevronRightIcon className="wh-5" />
                    </button>
                  </nav>
                </div>
              </div>
            </div>
          </div>
          <form
            onSubmit={handleSubmit(async (data) => {
              setIsReviewSubmitting(true);
              if (rating) {
                await api
                  .post(`/lesson-reviews`, {
                    lessonId: unReviewedSessionItems[selectedReviewIndex].id,
                    title: data.title,
                    description: data.description,
                    rating: rating,
                    mentorId:
                      unReviewedSessionItems[selectedReviewIndex].mentorId,
                  })
                  .then(() => {
                    mixpanel.track('Review', {
                      mentorId:
                        unReviewedSessionItems[selectedReviewIndex].mentorId,
                      mentorName:
                        unReviewedSessionItems[selectedReviewIndex].mentorName,
                      studentId:
                        unReviewedSessionItems[selectedReviewIndex].studentId,
                      studentName:
                        unReviewedSessionItems[selectedReviewIndex].studentName,
                      lessonId: unReviewedSessionItems[selectedReviewIndex].id,
                      rating: rating,
                      title: data.title,
                      description: data.description,
                    });
                    window.dataLayer.push({
                      event: 'Review',
                      mentorId:
                        unReviewedSessionItems[selectedReviewIndex].mentorId,
                      mentorName:
                        unReviewedSessionItems[selectedReviewIndex].mentorName,
                      studentId:
                        unReviewedSessionItems[selectedReviewIndex].studentId,
                      studentName:
                        unReviewedSessionItems[selectedReviewIndex].studentName,
                      lessonId: unReviewedSessionItems[selectedReviewIndex].id,
                      rating: rating,
                      title: data.title,
                      description: data.description,
                    });
                    setIsReviewSent(true);
                    alert('Your review has been sent.');
                    setValue('title', '');
                    setValue('description', '');
                    setRating(null);
                    setIsReviewSubmitting(false);
                  })
                  .catch((error) => {
                    setIsReviewSubmitting(false);
                  });
              } else {
                alert('Please select a rating');
              }
            })}
            className="p-4 flex flex-col items-center w-full"
          >
            <div className="flex flex-col items-center gap-3 w-full">
              <Avatar
                src={getS3Uri(
                  unReviewedSessionItems[selectedReviewIndex]?.mentor?.avatar
                )}
                alt="avatar"
                className="!wh-12 object-cover rounded-full"
              />
              <div className="flex flex-col items-center">
                <h3 className="font-medium">
                  {
                    unReviewedSessionItems[selectedReviewIndex]?.mentor
                      .firstName
                  }{' '}
                  {unReviewedSessionItems[selectedReviewIndex]?.mentor.lastName}
                </h3>
                <p className="text-sm text-gray-400">
                  <time
                    dateTime={
                      unReviewedSessionItems[selectedReviewIndex]?.startedAt
                    }
                  >
                    {unReviewedSessionItems &&
                      utcToLocalFormat(
                        unReviewedSessionItems[selectedReviewIndex]?.startedAt,
                        MomentFormat.LL
                      )}
                  </time>
                </p>
              </div>
              <ReactStars
                key={starKeyForce}
                count={5}
                onChange={(newValue) => {
                  if (newValue && newValue < 1) {
                    alert(
                      'Minimum rating is 1 star. Please select 1 star or higher.'
                    );
                  } else if (newValue) {
                    setRating(newValue);
                  }
                }}
                size={24}
                isHalf={true}
                value={rating ?? 0}
                // emptyIcon={<i className="far fa-star" />}
                // halfIcon={<i className="fa fa-star-half-alt" />}
                // fullIcon={<i className="fa fa-star" />}
                activeColor="#FFB400"
                edit={true}
              />

              <div className="max-w-[600px] flex flex-col items-center mb-4">
                <h3 className="font-medium">Session Summary</h3>
                <p className="text-sm text-gray-400 text-center">
                  {unReviewedSessionItems[selectedReviewIndex]?.receipts[0]
                    ?.sessionReview &&
                    unReviewedSessionItems[selectedReviewIndex]?.receipts[0]
                      ?.sessionReview}
                </p>
              </div>
              <TextField
                minLength={10}
                helper={errors.title?.message}
                label="Title"
                {...register('title', {
                  required: 'Please enter review title',
                  validate: (val: string) => {
                    if (val.length < 10) {
                      return 'Please enter at least 10 characters.';
                    }
                  },
                })}
                className="max-w-[600px] bg-gray-100 rounded-lg border-0 w-full text-sm"
                wrapperClassName="max-w-[600px] w-full"
                placeholder="Please write a short, condensed review title"
              />
              <TextArea
                minLength={50}
                helper={errors.description?.message}
                {...register('description', {
                  required: 'Please enter review details',
                  validate: (val: string) => {
                    if (val.length < 50) {
                      return 'Please enter at least 50 characters.';
                    }
                  },
                })}
                className="max-w-[600px] lg:min-h-[100px] bg-gray-100 rounded-lg border-0 w-full text-sm"
                wrapperClassName="max-w-[600px] lg:min-h-[100px] w-full"
                placeholder={`Leave a review for ${unReviewedSessionItems[selectedReviewIndex]?.mentor?.firstName} ${unReviewedSessionItems[selectedReviewIndex]?.mentor?.lastName} | (Minimum 50 words)`}
              />
              <Button
                text={isReviewSubmitting ? 'Submitting' : ' Submit review'}
                className=" disabled:bg-gray-400 h-auto inline-flex items-center rounded-md border border-transparent bg-indigo-600 px-3 py-2 text-sm font-medium leading-4 text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 "
                type="submit"
                isSubmitting={isReviewSubmitting}
                disabled={isReviewSubmitting}
              />
            </div>
          </form>
        </div>
      )
    );
  };

  const SessionItemComponent = (session: SessionType) => {
    return (
      <div
        className={`flex items-center px-4 sm:px-6 py-4  w-full justify-between`}
      >
        <div className="min-w-0 flex-1 sm:flex sm:items-center sm:justify-between">
          <div className="truncate">
            <div className="flex text-sm flex-wrap">
              <div className="flex items-center gap-2">
                <Link
                  to={`/my/dashboard/session/${session.id}`}
                  className="truncate font-medium text-indigo-600"
                >
                  {session.title !== '' ? session.title : 'Untitled'}
                </Link>
                <span className="inline-flex items-center rounded-full bg-green-100 px-2.5 py-0.5 text-xs font-medium text-green-800">
                  {utcToDiffMinutes(session.startedAt, session.endedAt)} mins
                </span>
              </div>
              <div className="hidden mt-2 xl:flex items-start space-x-3 xl:mt-0 xl:ml-3 xl:border-l xl:border-gray-400 xl:border-opacity-50 xl:pl-3 text-gray-500">
                <dt className="flex items-center text-sm gap-1 ">
                  <CurrencyDollarIcon
                    className="wh-4  text-gray-400"
                    aria-hidden="true"
                  />
                  <dd>{Number(session.price).toFixed(2)}</dd>
                </dt>
              </div>
            </div>
            <div className="mt-2 flex gap-2">
              <div className="flex items-center text-sm text-gray-500">
                <CalendarIcon
                  className="mr-1.5 wh-4 flex-shrink-0 text-gray-400"
                  aria-hidden="true"
                />
                <p>
                  <time dateTime={session.startedAt}>
                    {utcToLocalFormat(session.startedAt, MomentFormat.LL)}
                  </time>
                </p>
              </div>
              <div className="flex items-center text-sm text-gray-500 gap-1.5">
                <ClockIcon
                  className="wh-4 flex-shrink-0 text-gray-400"
                  aria-hidden="true"
                />
                <p>
                  <time dateTime={session.startedAt}>
                    {utcToLocalFormat(session.startedAt, MomentFormat.hmma)}
                  </time>
                </p>
              </div>
            </div>
            <div className="mt-2 xl:hidden flex gap-2">
              <div className="flex items-start space-x-3 text-gray-500">
                <dt className="flex items-center text-sm gap-1 ">
                  <CurrencyDollarIcon
                    className="wh-4  text-gray-400"
                    aria-hidden="true"
                  />
                  <dd>{Number(session.price).toFixed(2)}</dd>
                </dt>
              </div>
            </div>
          </div>
          <div className="mt-4 flex-shrink-0 sm:mt-0 sm:ml-5">
            <div className="flex overflow-hidden items-center gap-3 ">
              <p className="text-sm text-gray-500">
                {session.mentor.firstName}
              </p>
              <Avatar
                src={getS3Uri(session.mentor?.avatar)}
                alt="avatar"
                className="!wh-8 object-cover rounded-full"
              />
            </div>
          </div>
        </div>
        <div className="ml-5 flex-shrink-0">
          <ChevronRightIcon
            className="h-5 w-5 text-gray-400"
            aria-hidden="true"
          />
        </div>
      </div>
    );
  };

  const ScheduledSessionsSection = () => {
    return (
      <div className="overflow-hidden bg-white shadow rounded-md max-w-[1100px]">
        <div className="border-b border-gray-200 bg-white px-4 py-5 sm:px-6">
          <div className="-ml-4 -mt-2 flex flex-wrap items-center justify-between sm:flex-nowrap">
            <div className="ml-4 mt-2 flex gap-2 items-center">
              <CalendarIcon className="wh-6 flex-shrink-0 self-center text-xs" />
              <h3 className="text-lg font-medium leading-6 text-gray-900">
                Upcoming sessions
              </h3>
            </div>
          </div>
        </div>
        <ul className="divide-y divide-gray-200">
          {upcomingSessionItems?.items
            ?.sort((a: any, b: any) => {
              return a.startedAt > b.startedAt
                ? 0
                : b.startedAt > a.startedAt
                ? -1
                : 1;
            })
            .map((session) => (
              <li key={session.id}>
                {session.changePriceState ===
                ChangePriceState.REQUEST_CHANGE_PRICE ? (
                  <>
                    {SessionItemComponent(session)}
                    <div className="px-4 sm:px-6 pb-4">
                      <div className="text-gray-800 flex justify-center gap-2 items-center ">
                        <span className="text-xl font-bold tracking-normal text-gray-800">
                          ${Number(session.price).toFixed(2)}
                        </span>
                        <ArrowRightCircleIcon className="wh-6" />
                        <span className="text-xl font-bold tracking-normal text-gray-800">
                          ${Number(session.newPrice).toFixed(2)}
                        </span>
                      </div>
                      <div className="mt-2 sm:mt-3 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3">
                        {!isUpcomingSessionFetching && (
                          <>
                            <button
                              type="button"
                              className="inline-flex w-full justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:col-start-2 sm:text-sm"
                              onClick={async () => {
                                window.confirm(
                                  'Are you sure you want to accept the price change?'
                                ) &&
                                  (await api
                                    .patch(
                                      `/lessons/v2/lessons/change-price/${session.id}/confirm`,
                                      {
                                        changePriceState:
                                          ChangePriceState.ACCEPT,
                                      }
                                    )
                                    .then(() => {
                                      refetchUpcomimgSessionItems();
                                      mixpanel.track(
                                        'Session Rate Change Accepted',
                                        {
                                          sessionId: session.id,
                                        }
                                      );
                                      alert(
                                        'The price change has been accepted.'
                                      );
                                    }));
                              }}
                            >
                              Accept price change
                            </button>
                            <button
                              type="button"
                              className="mt-3 inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:col-start-1 sm:mt-0 sm:text-sm"
                              onClick={async () => {
                                window.confirm(
                                  'Are you sure you want to decline the price change?'
                                ) &&
                                  (await api
                                    .patch(
                                      `/lessons/v2/lessons/change-price/${session.id}/confirm`,
                                      {
                                        changePriceState:
                                          ChangePriceState.DECLINE,
                                      }
                                    )
                                    .then(() => {
                                      refetchUpcomimgSessionItems();
                                      mixpanel.track(
                                        'Session Rate Change Declined',
                                        {
                                          sessionId: session.id,
                                        }
                                      );
                                      alert(
                                        'You have declined the price change.'
                                      );
                                    }));
                              }}
                            >
                              Decline price change
                            </button>
                          </>
                        )}
                      </div>
                    </div>
                  </>
                ) : (
                  <Link
                    to={`/my/dashboard/session/${session.id}`}
                    className="block hover:bg-gray-50 w-full"
                  >
                    {SessionItemComponent(session)}
                  </Link>
                )}
              </li>
            ))}
          {!upcomingSessionItems?.items?.length && (
            <div className="sm:col-span-2 p-6">
              <EmptyState
                MainIcon={UserIcon}
                title="None yet"
                subTitle="Schedule a session today!"
                ctaText="Quick Request"
                onClick={() => push('/student-request')}
              />
            </div>
          )}
        </ul>
      </div>
    );
  };

  const MyMentorsSection = () => {
    return (
      <div className=" bg-white border w-full rounded-md">
        <div className="border-b border-gray-200 b px-4 py-5 sm:px-6">
          <div className="-ml-4 -mt-2 flex flex-wrap items-center justify-between sm:flex-nowrap">
            <div className="ml-4 mt-2 flex gap-2">
              <div className="flex gap-2 items-center">
                <UserCircleIcon className="wh-6 flex-shrink-0 self-center text-xs" />
                <h3 className="text-lg font-medium leading-6 text-gray-900">
                  My tutors
                </h3>
              </div>
              <button className="border inline-flex items-center rounded-full bg-gray-100 px-2.5 py-0.5 text-xs font-medium text-gray-800 gap-1 whitespace-pre-line">
                <p
                  data-for="tip-tooltip"
                  data-tip={'Tutors you’ve recently scheduled sessions with'}
                  className="flex items-center gap-1"
                >
                  <LightBulbIcon
                    className={`wh-3 flex-shrink-0 self-center text-xs `}
                    aria-hidden="true"
                  />
                  <p>Tip</p>
                </p>
                <ReactTooltip
                  id="tip-tooltip"
                  className="max-w-[80vw]"
                  delayHide={1000}
                  effect="solid"
                  place="bottom"
                />
              </button>
            </div>
            <div className="ml-4 mt-2 flex-shrink-0">
              <Link
                to={'/my/chats'}
                className="inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:brand-blue focus:ring-offset-2 gap-1"
              >
                <EnvelopeIcon className="wh-4 flex-shrink-0 self-center text-xs" />
                <p>All messages</p>
              </Link>
            </div>
          </div>
        </div>
        <div className="p-6 grid grid-cols-1 gap-4 sm:grid-cols-2">
          {myMentors?.map((item, index) => {
            if (index < 4)
              return (
                <div
                  key={item.email}
                  className="relative flex items-center space-x-3 rounded-lg border border-gray-300 bg-white px-6 py-5 shadow-sm  focus-within:ring-offset-2 hover:border-gray-400"
                >
                  <Avatar
                    src={getS3Uri(item.avatar)}
                    alt="avatar"
                    className="wh-10 object-cover rounded-full"
                  />
                  <div className="min-w-0 flex-1 flex-shrink-0">
                    <div className="focus:outline-none truncate">
                      <button
                        onClick={() => handleNavigateMessage(item.id)}
                        className="text-sm font-medium text-gray-900"
                      >
                        {item.firstName + ' ' + item.lastName}
                      </button>
                      <p className="truncate text-xs text-gray-500">
                        {item.role}
                      </p>
                    </div>
                  </div>
                  <Dropdown>
                    <button className="flex-shrink-0 cursor-pointer inline-flex h-8 w-8 items-center justify-center rounded-full bg-white bg-transparent text-gray-400 hover:text-gray-500 focus:outline-none ">
                      <span className="sr-only">Open options</span>
                      <EllipsisVerticalIcon
                        className="h-5 w-5"
                        aria-hidden="true"
                      />
                    </button>
                    <Dropdown.View className="mt-0 top-0 right-8 rounded-md w-30 flex flex-col shadow-md overflow-hidden text-sm font-regular">
                      <>
                        <button
                          onClick={() => handleNavigateMessage(item.id)}
                          className="hover:bg-gray-100 px-4 py-2 text-left"
                        >
                          Messages
                        </button>
                        <button
                          onClick={() => push(`/mentors/${item.id}`)}
                          className="hover:bg-gray-100 px-4 py-2 text-left"
                        >
                          Profile
                        </button>
                        {/* <button className="hover:bg-gray-100 px-4 py-2 text-left">
                          Hide Tutor
                        </button> */}
                      </>
                    </Dropdown.View>
                  </Dropdown>
                </div>
              );
          })}
          {!myMentors?.length && (
            <div className="sm:col-span-2">
              <EmptyState
                MainIcon={UserIcon}
                title="None yet"
                subTitle="Tutors you’ve recently scheduled sessions with"
                ctaText="Quick Request"
                onClick={() => push('/student-request')}
              />
            </div>
          )}
        </div>
      </div>
    );
  };

  const InteredstedMentorsSection = () => {
    return (
      <div className=" bg-white border w-full rounded-md">
        <div className="border-b border-gray-200  px-4 py-5 sm:px-6">
          <div className="-ml-4 -mt-2 flex flex-wrap items-center justify-between sm:flex-nowrap">
            <div className="ml-4 mt-2 flex gap-2">
              <div className="flex gap-2 items-center">
                <HandRaisedIcon className="wh-5 flex-shrink-0 self-center text-xs" />
                <h3 className="text-lg font-medium leading-6 text-gray-900">
                  Interested Tutors
                </h3>
              </div>
              <button className="border inline-flex items-center rounded-full bg-gray-100 px-2.5 py-0.5 text-xs font-medium text-gray-800 gap-1 whitespace-pre-line">
                <p
                  data-for="tip-tooltip"
                  data-tip={'Tutors who’ve responded to your Quick Requests'}
                  className="flex items-center gap-1"
                >
                  <LightBulbIcon
                    className={`wh-3 flex-shrink-0 self-center text-xs `}
                    aria-hidden="true"
                  />
                  <p>Tip</p>
                </p>
                <ReactTooltip
                  id="tip-tooltip"
                  className="max-w-[80vw]"
                  delayHide={1000}
                  effect="solid"
                  place="bottom"
                />
              </button>
            </div>
            <div className="ml-4 mt-2 flex-shrink-0">
              <Link
                to={'/my/chats'}
                className="inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:brand-blue focus:ring-offset-2 gap-1"
              >
                <EnvelopeIcon className="wh-4 flex-shrink-0 self-center text-xs" />
                <p>All messages</p>
              </Link>
            </div>
          </div>
        </div>
        <div className="p-6 grid grid-cols-1 gap-4 sm:grid-cols-2">
          {interestedMentors?.map((item, index) => {
            if (index < 4)
              return (
                <div
                  key={item.email}
                  className="relative flex items-center space-x-3 rounded-lg border border-gray-300 bg-white px-6 py-5 shadow-sm  focus-within:ring-offset-2 hover:border-gray-400"
                >
                  <Avatar
                    src={getS3Uri(item.avatar)}
                    alt="avatar"
                    className="wh-10 object-cover rounded-full"
                  />
                  <div className="min-w-0 flex-1 flex-shrink-0">
                    <div className="focus:outline-none truncate">
                      <button
                        onClick={() => handleNavigateMessage(item.id)}
                        className="text-sm font-medium text-gray-900"
                      >
                        {item.firstName + ' ' + item.lastName}
                      </button>
                      <div className="truncate text-xs text-gray-500 flex items-center gap-1">
                        {/* <CurrencyDollarIcon className="wh-4 text-gray-400" />{' '} */}
                        <span>TUTOR</span>
                      </div>
                    </div>
                  </div>
                  <Dropdown>
                    <button className="flex-shrink-0 cursor-pointer inline-flex h-8 w-8 items-center justify-center rounded-full bg-white bg-transparent text-gray-400 hover:text-gray-500 focus:outline-none ">
                      <span className="sr-only">Open options</span>
                      <EllipsisVerticalIcon
                        className="h-5 w-5"
                        aria-hidden="true"
                      />
                    </button>
                    <Dropdown.View className="mt-0 top-0 right-8 rounded-md w-30 flex flex-col shadow-md overflow-hidden text-sm font-regular">
                      <>
                        <button
                          onClick={() => handleNavigateMessage(item.id)}
                          className="hover:bg-gray-100 px-4 py-2 text-left"
                        >
                          Messages
                        </button>
                        <button
                          onClick={() => push(`/mentors/${item.id}`)}
                          className="hover:bg-gray-100 px-4 py-2 text-left"
                        >
                          Profile
                        </button>
                        {/* <button className="hover:bg-gray-100 px-4 py-2 text-left">
                          Hide mentor
                        </button> */}
                      </>
                    </Dropdown.View>
                  </Dropdown>
                </div>
              );
          })}
          {!interestedMentors?.length && (
            <div className="sm:col-span-2">
              <EmptyState
                MainIcon={UserIcon}
                title="None yet"
                subTitle="Tutors who’ve responded to your Quick Requests"
                ctaText="Quick Request"
                onClick={() => push('/student-request')}
              />
            </div>
          )}
        </div>
      </div>
    );
  };

  const NoPaymentMethodSection = () => {
    return (
      <div className="bg-indigo-600 rounded-md">
        <div className="mx-auto max-w-7xl py-3 px-3 sm:px-6 lg:px-8">
          <div className="flex flex-wrap items-center justify-between">
            <div className="flex flex-1 items-center">
              <span className="flex rounded-lg bg-indigo-700 p-2">
                <CreditCardIcon
                  className="h-6 w-6 text-white"
                  aria-hidden="true"
                />
              </span>

              <p className="ml-3 truncate font-medium text-white">
                <span className="md:hidden whitespace-normal">
                  Get started with booking a session by adding a payment method.
                </span>
                <span className="hidden md:inline">
                  Get started with booking a session by adding a payment method.
                </span>
              </p>
            </div>
            <div className="order-3 mt-2 w-full flex-shrink-0 sm:order-2 sm:mt-0 sm:w-auto">
              <Link
                to="/my/dashboard/account/payment-method"
                className="flex items-center justify-center rounded-md border border-transparent bg-white px-4 py-2 text-sm font-medium text-indigo-600 shadow-sm hover:bg-indigo-50"
              >
                Add
              </Link>
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <>
      {isPaymentMethodFetching ? (
        <></>
      ) : (
        !isPaymentMethod && NoPaymentMethodSection()
      )}
      {unReviewedSessionItems &&
        unReviewedSessionItems[0] &&
        ReviewSessionSection()}
      {ScheduledSessionsSection()}
      {MyMentorsSection()}
      {InteredstedMentorsSection()}
      <HiddenHeadingMeta text="Student Dashboard | Linkstory" />
      <ReactHelmet
        keywords="student, dashboard"
        title="Student Dashboard | Linkstory"
      />
    </>
  );
};
