import React, { FC, HTMLAttributes, useEffect, useState } from 'react';
import { useOnClickOutside } from 'usehooks-ts';

interface FilterDropdownProps extends HTMLAttributes<HTMLDivElement> {
  placeholder: string;
  checkedLength?: number;
  isRadioButton?: boolean;
}

export const FilterDropdown: FC<FilterDropdownProps> = ({
  children,
  placeholder,
  checkedLength,
  isRadioButton,
  ...props
}) => {
  const [menuOpen, setMenuOpen] = useState(false);
  const buttonRef = React.useRef(null);
  const refDropdown = React.useRef<HTMLUListElement>(null);

  useOnClickOutside(buttonRef, (event) => {
    const isDropdownClick: boolean | null =
      refDropdown.current && refDropdown.current.contains(event.target as Node);

    if (!isDropdownClick) {
      setMenuOpen(false);
    }
  });

  return (
    <div {...props}>
      <div className="relative">
        <button
          type="button"
          className="bg-white relative w-full border border-gray-200 rounded-md shadow-sm pl-3 h-10 text-left cursor-pointer focus:outline-none focus:ring-1 focus:ring-brand-1 focus:border-brand-1 sm:text-sm"
          aria-haspopup="listbox"
          aria-expanded="true"
          ref={buttonRef}
          onClick={() => setMenuOpen(!menuOpen)}
        >
          <div className="flex gap-2">
            <span className="block truncate">{placeholder}</span>
            {checkedLength && checkedLength !== 0 && (
              <span
                style={{ lineHeight: '20px' }}
                className="text-xs absolute top-[-10px] right-[-10px] block rounded-full bg-brand-blue truncate w-[20px] h-[20px] text-white text-center"
              >
                {checkedLength}
              </span>
            )}
          </div>

          <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
            <svg
              className="h-5 w-5 text-gray-400"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
              fill="currentColor"
              aria-hidden="true"
            >
              <path d="M10 3a1 1 0 01.707.293l3 3a1 1 0 01-1.414 1.414L10 5.414 7.707 7.707a1 1 0 01-1.414-1.414l3-3A1 1 0 0110 3zm-3.707 9.293a1 1 0 011.414 0L10 14.586l2.293-2.293a1 1 0 011.414 1.414l-3 3a1 1 0 01-1.414 0l-3-3a1 1 0 010-1.414z" />
            </svg>
          </span>
        </button>

        <ul
          ref={refDropdown}
          onClick={() => {
            isRadioButton && setMenuOpen(false);
          }}
          className={`absolute z-10 mt-1 w-full bg-white shadow-lg max-h-60 rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm
              transition ease-in duration-100 ${
                menuOpen ? 'opacity-100' : 'opacity-0 pointer-events-none'
              }`}
        >
          {children}
        </ul>
      </div>
    </div>
  );
};
