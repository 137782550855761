import moment from 'moment';
import React, { FC, useEffect, useState } from 'react';
import { api } from '../plugins/axios';
import { Button } from './Button';
import { Icon } from './icons';
import { TextField } from './TextField';
import { Checkbox } from '../components/Checkbox';
import { useForm } from 'react-hook-form';
import { Grade, Subject, User } from '@src/types';
import { ClockIcon, StarIcon } from '@heroicons/react/24/outline';
import { getS3Uri } from '@src/utils';
import { Avatar } from './Avatar';
import { Select } from './Select';
import { useQuery } from 'react-query';
import { fetcher } from '@src/plugins/react-query';
import { useParams } from 'react-router-dom';
import { MomentFormat, utcToLocalFormat } from '@src/plugins/moment';
import { ErrorMessage } from '@hookform/error-message';
import mixpanel from 'mixpanel-browser';

interface StudentRequestPopupProps {
  title: string;
  buttonTitle: string;
  chatId: number;
  mentor: User | undefined;
  open: boolean;
  onClose: () => void;
}

interface FormValues {
  subjectId: number;
  gradeId: number;
  firstRequestTime: string;
  secondRequestTime?: string;
  thirdRequestTime?: string;
  terms: boolean;
  isPrivate: boolean;
}

export const StudentRequestPopup: FC<StudentRequestPopupProps> = ({
  title,
  onClose,
  chatId,
  mentor,
  open,
  buttonTitle,
}) => {
  const {
    register,
    reset: resetForm,
    handleSubmit,
    watch,
    setValue,
    setError,
    clearErrors,
    formState: { isSubmitting, errors, isDirty, isValid },
  } = useForm<FormValues>({
    mode: 'onChange',
  });

  const { data: subjectsItems } = useQuery<Subject[]>(`/v2/subjects`, fetcher, {
    keepPreviousData: true,
    refetchOnWindowFocus: false,
  });

  const { data: mentorSubjects } = useQuery<any[]>(
    `/v2/subject-link?mentorId=${mentor?.id}`,
    fetcher,
    {
      enabled: !!mentor?.id,
    }
  );
  const [currentTime, setCurrentTime] = useState<string>(
    moment().toISOString()
  );

  useEffect(() => {
    const timerID = setInterval(() => tick(), 1000);
    return () => {
      clearInterval(timerID);
    };
  }, []);

  const tick = () => {
    setCurrentTime(moment().toISOString());
  };

  useEffect(() => {
    //  if route opens block body scroll
    if (open) {
      document.body.style.overflow = 'hidden';
    } else {
      return () => {
        document.body.style.overflow = 'auto';
      };
    }
  }, [open]);

  const handleOnClose = () => {
    console.log('reset');
    resetForm();
    document.body.style.overflow = 'auto';
    onClose();
  };

  const { data: gradesItems } = useQuery<Grade[]>(
    `/v2/grades/visibility`,
    fetcher,
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
    }
  );

  if (!open || !mentor) return <></>;

  const mentorProfileSection = () => {
    return (
      <>
        <Avatar
          className={`flex-none !wh-8 md:!wh-10 `}
          src={
            mentor?.avatar.startsWith('https')
              ? mentor?.avatar
              : getS3Uri(mentor?.avatar)
          }
        />
        <div className="flex-col w-full">
          <div className="flex flex-wrap gap-2 items-center truncate">
            <h4
              className={` text-sm md:text-base font-semibold text-ellipsis overflow-hidden whitespace-nowrap text-left `}
            >
              {mentor?.firstName} {mentor.lastName}
            </h4>

            {/* <div className="flex gap-2 items-center text-sm">
              <StarIcon className="wh-4 text-brand-orange" />
              <p>{`4.9 (9)`}</p>
            </div> */}
          </div>
          {/* <p className="text-sm">$35/hour</p> */}
        </div>
      </>
    );
  };

  const formSection = () => {
    return (
      <>
        <Select
          label="Subject"
          helper={errors.subjectId?.message}
          className="block w-full max-w-lg  border-gray-300 shadow-sm h-10  text-sm"
          {...register('subjectId', {
            required: 'Please select session subject',
          })}
        >
          <option value="" selected disabled hidden>
            Please select session subject
          </option>
          {subjectsItems
            ?.filter((item) =>
              mentorSubjects?.find((option) => option.subjectId === item.id)
            )
            .map((item) => {
              return (
                <option value={Number(item.id)} key={`subject_${item.id}`}>
                  {item.title.toUpperCase()}
                </option>
              );
            })}
        </Select>
        <Select
          label="Education Level"
          helper={errors.gradeId?.message}
          className="block w-full max-w-lg  border-gray-300 shadow-sm h-10  text-sm"
          {...register('gradeId', {
            required: 'Please selsect your education level',
          })}
        >
          <option value="" selected disabled hidden>
            Please select your education level
          </option>
          {gradesItems
            ?.sort(function (a, b) {
              return a.title.localeCompare(b.title);
            })
            .map((item) => {
              return (
                <option value={Number(item.id)} key={`grade_${item.id}`}>
                  {item.title.toUpperCase()}
                </option>
              );
            })}
        </Select>
        <div>
          <label
            htmlFor="currentTime"
            className="block text-sm  text-gray-700 sm:mt-px "
          >
            Your current time
          </label>
          <div
            className="mt-1 sm:col-span-2 flex gap-2 text-base items-center text-gray-600"
            id="currentTime"
          >
            <dt className="">
              <span className="sr-only">Date</span>
              <ClockIcon className="wh-5 " aria-hidden="true" />
            </dt>
            <dd>
              <div>
                {utcToLocalFormat(currentTime, MomentFormat.YYYYMMDDHmmss)}{' '}
                {moment.tz(moment.tz.guess()).zoneAbbr()}
              </div>
            </dd>
          </div>
        </div>
        <TextField
          label={`Best times that work for you`}
          type="datetime-local"
          data-placeholder="YYYY. MM. DD hh:mm"
          min={moment().format('YYYY-MM-DD[T]HH:mm')}
          helper={errors.firstRequestTime?.message}
          className={`text-sm h-10 ${
            !!watch('firstRequestTime') ? 'has-value' : ''
          }`}
          {...register('firstRequestTime', {
            required: 'Please enter your best times at least one',
            validate: (val: string) => {
              if (moment(new Date(val)).isBefore(new Date())) {
                return 'You cannot select earlier than the current time.';
              }
            },
          })}
        />
        <TextField
          type="datetime-local"
          data-placeholder="YYYY. MM. DD hh:mm"
          min={moment().format('YYYY-MM-DD[T]HH:mm')}
          className={`text-sm h-10 ${
            !!watch('secondRequestTime') ? 'has-value' : ''
          }`}
          name="secondRequestTime"
          helper={errors.secondRequestTime?.message}
          onChange={(e) => {
            setValue('secondRequestTime', e.target.value);
            if (moment(new Date(e.target.value)).isBefore(new Date())) {
              setError('secondRequestTime', {
                type: 'manual',
                message: 'You cannot select earlier than the current time.',
              });
            } else {
              clearErrors('secondRequestTime');
            }
          }}
        />
        <TextField
          type="datetime-local"
          data-placeholder="YYYY. MM. DD hh:mm"
          min={moment().format('YYYY-MM-DD[T]HH:mm')}
          className={`text-sm h-10 ${
            !!watch('thirdRequestTime') ? 'has-value' : ''
          }`}
          name="thirdRequestTime"
          helper={errors.thirdRequestTime?.message}
          onChange={(e) => {
            setValue('thirdRequestTime', e.target.value);
            if (moment(new Date(e.target.value)).isBefore(new Date())) {
              setError('thirdRequestTime', {
                type: 'manual',
                message: 'You cannot select earlier than the current time.',
              });
            } else {
              clearErrors('thirdRequestTime');
            }
          }}
        />
        <div>
          <p className="text-12 text-gray-400 pb-6">
            Plase remember to check the subject, date, and time before you
            submit your request
          </p>
          <div className="pb-6">
            <Checkbox
              label="I understand"
              {...register('terms', {
                required: 'This is required',
              })}
            />
          </div>
        </div>
        <Button
          text={isSubmitting ? 'Submitting' : buttonTitle}
          className={`text-sm md:text-xl disabled:bg-gray-400 bg-brand-1 text-white rounded-full w-full `}
          type="submit"
          isSubmitting={isSubmitting}
          disabled={!isDirty || !isValid || isSubmitting}
        />
      </>
    );
  };

  return (
    <div className="z-50 fixed inset-0 top-[3.5rem] md:top-0 bg-littleblack flex items-center justify-center px-4">
      <div
        className="max-w-md flex flex-col  bg-white rounded-xl overflow-hidden p-6 space-y-4 overflow-y-scroll"
        style={{ maxHeight: 'calc(100vh - 10rem)' }}
      >
        <div className="flex justify-between">
          <h1 className="text-md md:text-20 font-bold flex-1">{title}</h1>
          <button className="" onClick={handleOnClose}>
            <Icon.X />
          </button>
        </div>
        <div className="flex items-center gap-2">{mentorProfileSection()}</div>
        <form
          className="flex flex-col gap-4"
          onSubmit={handleSubmit(async (data) => {
            await api
              .post(`/v2/jobs/target/${mentor?.id}`, {
                ...data,
                isOnline: true,
                isPrivate: true,
                targetMentorId: Number(mentor?.id),
                firstRequestTime: moment(data.firstRequestTime).toISOString(),
                ...(data.secondRequestTime && {
                  secondRequestTime: moment(
                    data.secondRequestTime
                  ).toISOString(),
                }),
                ...(data.thirdRequestTime && {
                  thirdRequestTime: moment(data.thirdRequestTime).toISOString(),
                }),
              })
              .then(async () => {
                const { data: me } = await api.get<User>('/users/me');
                handleOnClose();
                window.dataLayer.push({ event: 'student_request_chat' });
                mixpanel.track('Request', {
                  request_type: 'Chat',
                });
                // 유저의 요청 카운트 로직 1씩 중가
                mixpanel.people.increment('Request Counts', 1);
                // 최초 요청 시간 - 한번만 등록 되고 중복 수정 되지 않음
                mixpanel.people.set_once({
                  'First Request Date': new Date().toISOString(),
                });
                // 최초 요청 시간 - 지속적으로 업데이트 됨
                mixpanel.people.set({
                  'Last Request Date': new Date().toISOString(),
                });
                mixpanel.identify(me?.email);
              })
              .catch((e) => {
                alert(
                  'An error occurred during transmission. Contact customer service'
                );
              });
          })}
        >
          {formSection()}
        </form>
      </div>
    </div>
  );
};
