import { Avatar } from '@src/components/Avatar';
import { fetcher } from '@src/plugins/react-query';
import { User } from '@src/types';
import { getS3Uri } from '@src/utils';
import React, { useState } from 'react';
import { useQuery } from 'react-query';
import { Link, useHistory, useParams } from 'react-router-dom';
import { CheckIcon } from '@heroicons/react/24/solid';

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(' ');
}

export const RequestSentPage = () => {
  const { push } = useHistory();
  const { id } = useParams<{ id: string }>();
  const { data: tutor } = useQuery<User>(`/users/${id}`, fetcher, {
    enabled: !!id,
  });
  const [card, setCard] = useState<any>();
  const { isFetching } = useQuery<any>(`/credit-cards`, fetcher, {
    refetchOnWindowFocus: false,
    onSuccess(data) {
      if (data.data[0]) {
        setCard(data.data[0].card);
      }
    },
  });

  const steps = [
    {
      id: '01',
      name: 'Payment Method',
      description:
        'You will need to add a payment method for your mentor to schedule your session.',
      status: card ? 'complete' : 'current',
    },
    {
      id: '02',
      name: 'You’ll hear back soon',
      description: `${
        id
          ? 'A tutor should message you about your request within 24 hours.'
          : 'Tutors should send you a offers within 24 hours.'
      } `,
      status: 'upcoming',
    },
    {
      id: '03',
      name: 'Meet your tutor online',
      description:
        'Once you add a payment method and schedule a session with your tutor you will be able to talk to them online!',
      status: 'upcoming',
    },
  ];

  const paymentMethodLinkBtn = () => {
    if (!card)
      return (
        <Link
          to="/my/dashboard/account/payment-method"
          className="mt-4 inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-full shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
        >
          Add
        </Link>
      );
  };

  const StepsSection = () => {
    return (
      <div className="max-w-[800px] lg:border-t lg:border-b lg:border-gray-200">
        <nav className="mx-auto " aria-label="Progress">
          <ol className="overflow-hidden rounded-md lg:flex lg:rounded-none lg:border-l lg:border-r lg:border-gray-200">
            {steps.map((step, stepIdx) => (
              <li key={step.id} className="relative overflow-hidden lg:flex-1">
                <div
                  className={classNames(
                    stepIdx === 0 ? 'border-b-0 rounded-t-md' : '',
                    stepIdx === steps.length - 1
                      ? 'border-t-0 rounded-b-md'
                      : '',
                    'border border-gray-200 overflow-hidden lg:border-0'
                  )}
                >
                  {step.status === 'complete' ? (
                    <div className="group">
                      <span
                        className="absolute top-0 left-0 h-full w-1 bg-transparent group-hover:bg-gray-200 lg:bottom-0 lg:top-auto lg:h-1 lg:w-full"
                        aria-hidden="true"
                      />
                      <span
                        className={classNames(
                          stepIdx !== 0 ? 'lg:pl-9' : '',
                          'px-6 py-5 flex lg:flex-col items-start lg:items-center text-sm font-medium'
                        )}
                      >
                        <span className="flex-shrink-0">
                          <span className="flex h-10 w-10 items-center justify-center rounded-full bg-indigo-600">
                            <CheckIcon
                              className="h-6 w-6 text-white"
                              aria-hidden="true"
                            />
                          </span>
                        </span>
                        <span className="mt-0.5 lg:mt-3 lg:space-y-2 ml-4 lg:ml-0 lg:text-center flex min-w-0 flex-col">
                          <span className="text-sm font-medium">
                            {step.name}
                          </span>
                          <span className="text-sm font-medium text-gray-500">
                            {step.description}
                          </span>
                        </span>
                        {paymentMethodLinkBtn()}
                      </span>
                    </div>
                  ) : step.status === 'current' ? (
                    <div aria-current="step">
                      <span
                        className="absolute top-0 left-0 h-full w-1 bg-indigo-600 lg:bottom-0 lg:top-auto lg:h-1 lg:w-full"
                        aria-hidden="true"
                      />
                      <span
                        className={classNames(
                          stepIdx !== 0 ? 'lg:pl-9' : '',
                          'px-6 py-5 flex lg:flex-col lg:items-center items-start text-sm font-medium'
                        )}
                      >
                        <span className="flex-shrink-0">
                          <span className="flex h-10 w-10 items-center justify-center rounded-full border-2 border-indigo-600">
                            <span className="text-indigo-600">{step.id}</span>
                          </span>
                        </span>
                        <span className="mt-0.5 lg:mt-2 lg:space-y-2 ml-4 lg:ml-0 flex min-w-0 flex-col lg:text-center">
                          <span className="text-sm font-medium text-indigo-600">
                            {step.name}
                          </span>
                          <span className="text-sm font-medium text-gray-500">
                            {step.description}
                          </span>
                        </span>
                        {paymentMethodLinkBtn()}
                      </span>
                    </div>
                  ) : (
                    <div className="group">
                      <span
                        className="absolute top-0 left-0 h-full w-1 bg-transparent group-hover:bg-gray-200 lg:bottom-0 lg:top-auto lg:h-1 lg:w-full"
                        aria-hidden="true"
                      />
                      <span
                        className={classNames(
                          stepIdx !== 0 ? 'lg:pl-9' : '',
                          'px-6 py-5 flex lg:flex-col items-start lg:items-center lg:text-center text-sm font-medium'
                        )}
                      >
                        <span className="flex-shrink-0">
                          <span className="flex h-10 w-10 items-center justify-center rounded-full border-2 border-gray-300">
                            <span className="text-gray-500">{step.id}</span>
                          </span>
                        </span>
                        <span className="mt-0.5 lg:mt-2 ml-4 lg:ml-0 lg:space-y-2 flex min-w-0 flex-col">
                          <span className="text-sm font-medium text-gray-700">
                            {step.name}
                          </span>
                          <span className="text-sm font-medium text-gray-500">
                            {step.description}
                          </span>
                        </span>
                      </span>
                    </div>
                  )}

                  {stepIdx !== 0 ? (
                    <>
                      {/* Separator */}
                      <div
                        className="absolute inset-0 top-0 left-0 hidden w-3 lg:block"
                        aria-hidden="true"
                      >
                        <svg
                          className="h-full w-full text-gray-300"
                          viewBox="0 0 12 82"
                          fill="none"
                          preserveAspectRatio="none"
                        >
                          <path
                            d="M0.5 0V31L10.5 41L0.5 51V82"
                            stroke="currentcolor"
                            vectorEffect="non-scaling-stroke"
                          />
                        </svg>
                      </div>
                    </>
                  ) : null}
                </div>
              </li>
            ))}
          </ol>
        </nav>
      </div>
    );
  };

  if (isFetching) return <></>;

  return (
    <div className="bg-[#F7F9FD] flex-1 flex flex-col justify-center min-h-screen">
      <div className="max-w-container flex flex-col items-center !py-8 space-y-6">
        <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
          <div className="text-center">
            <p className="text-4xl font-bold tracking-tight text-gray-900 sm:text-5xl lg:text-6xl">
              Tutoring request sent!
            </p>
            <p className="mx-auto mt-5 max-w-xl text-xl text-gray-500">
              {`${
                id
                  ? 'A tutor should message you about your request within 24 hours.'
                  : 'Tutors should send you offers within 24 hours.'
              } `}
            </p>
          </div>
        </div>
        {StepsSection()}
      </div>
    </div>
  );
};
