import React, { forwardRef, InputHTMLAttributes } from 'react';
import { useId } from 'react-id-generator';
import { Label } from './Label';

export interface PriceFieldProps extends InputHTMLAttributes<HTMLInputElement> {
  label?: string;
  helper?: string;
  inputClassName?: string;
}

export const PriceField = forwardRef<HTMLInputElement, PriceFieldProps>(
  ({ className = '', label, helper, inputClassName = '', ...props }, ref) => {
    const [id] = useId(1, 'textfield');

    return (
      <div className="label-col">
        {label && <Label htmlFor={id} text={label} />}

        <div
          className={`border pl-4 rounded-md overflow-hidden ${
            props?.disabled || (props.readOnly && 'bg-gray-100 text-gray-400')
          } flex items-center ${helper ? 'border-error' : ''} ${className}`}
        >
          <p className="font-medium">$</p>
          <input
            type="number"
            onWheel={(event) => event.currentTarget.blur()}
            ref={ref}
            id={id}
            className={`read-only:bg-gray-100 read-only:text-gray-400 disabled:bg-gray-100 disabled:text-gray-400 flex-1 border-none focus:border-none focus:ring-0 ${inputClassName}`}
            {...props}
          />
        </div>
        {helper && <p className="text-sm text-error">{helper}</p>}
      </div>
    );
  }
);
