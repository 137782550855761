import { FC, HTMLAttributes } from 'react';
import { Link } from 'react-router-dom';
import { MomentFormat, utcToLocalFormat } from '@plugins/moment';
import { WebibarItemType } from '@src/types';
import moment from 'moment-timezone';

interface WebinarSlideCardProps extends HTMLAttributes<HTMLDivElement> {
  item: WebibarItemType;
  isExpired?: boolean;
}

export const WebinarSlideCard: FC<WebinarSlideCardProps> = ({
  item,
  isExpired,
}) => {
  return (
    <div
      className="rounded-xl flex flex-col justify-between bg-white mb-12 md:mb-16 border shadow-sm overflow-hidden"
      style={{
        // filter: 'drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25))',
        // aspectRatio: '275/340',
        minWidth: 'content-fit',
      }}
    >
      <div
        className="relative  w-full "
        // style={{ aspectRatio: '16/9' }}
      >
        {item.webinar.img ? (
          <Link
            to={{
              pathname: '/webinar/' + item.id,
              search: `?tutorId=${item.tutorId}`,
            }}
          >
            <img
              src={item.webinar.img}
              alt="avatar"
              className="rounded-t-xl  w-full h-full object-cover"
            />
          </Link>
        ) : (
          <div className="rounded-t-xl absolute w-full h-full object-cover bg-gray-10" />
        )}
      </div>
      <div
        className="flex flex-col space-y-3 text-left justify-center px-4 py-3"
        style={{ aspectRatio: '275/120' }}
      >
        <Link
          to={{
            pathname: '/webinar/' + item.id,
            search: `?tutorId=${item.tutorId}`,
          }}
        >
          <h3 className="truncate-2-lines font-bold">{item.webinar.title}</h3>
        </Link>
        <p className="mt-4 text-12 text-[#7783A8]">
          {utcToLocalFormat(item.webinar.date, MomentFormat.ddddLLHmma)}{' '}
          {moment.tz(moment.tz.guess()).zoneAbbr()}
        </p>
        <p
          style={{ width: 'fit-content' }}
          className="inline-block px-3 py-1 text-xs font-medium tracking-wider text-brand-blue bg-[#E0E7FF] rounded-md"
        >
          {item.webinar.duration}
        </p>
      </div>
      <Link
        to={{
          pathname: '/webinar/' + item.id,
          search: `?tutorId=${item.tutorId}`,
        }}
        className="mt-10 inline-flex items-center justify-center h-12 px-10 font-medium tracking-wide text-white transition duration-200 bg-brand-blue rounded-b-xl hover:bg-brightness-110 focus:shadow-outline focus:outline-none w-full"
      >
        {isExpired ? 'Watch it Again' : 'Register'}
      </Link>
    </div>
  );
};
