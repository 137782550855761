import React, { FC, useState } from 'react';
import { Tag, TagLink, TagLinkTargetType } from '../types';
import { Icon } from './icons';
import { api } from '../plugins/axios';
import { useOnClickOutside } from 'usehooks-ts';

export interface MultiDropdownProps {
  id: string;
  options: any[] | undefined;
  selectedOptions: any[] | undefined;
  onToggle: () => void;
  disabled?: boolean;
  placeholder: string;
}

export const MultiDropdownSubjects: FC<MultiDropdownProps> = ({
  id,
  options,
  selectedOptions,
  onToggle = () => {},
  disabled,
  placeholder,
  ...props
}) => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const buttonRef = React.useRef(null);
  const refDropdown = React.useRef<HTMLDivElement>(null);

  useOnClickOutside(buttonRef, (event) => {
    const isDropdownClick: boolean | null =
      refDropdown.current && refDropdown.current.contains(event.target as Node);

    if (!isDropdownClick) {
      setIsDropdownOpen(false);
    }
  });

  return (
    <div className="w-full flex flex-col items-center mx-auto">
      <div className="w-full">
        <div className="flex flex-col items-center relative">
          <div className="w-full ">
            <div
              className={`my-1 p-1 flex border border-gray-200  rounded  ${
                disabled ? 'bg-gray-100 text-gray-40' : 'bg-white'
              } `}
            >
              <div className="flex flex-auto flex-wrap">
                {options?.map((item, index) => {
                  const selectedOption: any = selectedOptions?.find(
                    (option) => option.subjectId === item.id
                  );
                  return (
                    !!selectedOption && (
                      <div
                        key={item.title + `Tags`}
                        className="flex justify-center items-center m-1 font-medium py-1 px-2 bg-white rounded-full  border "
                      >
                        <div className="text-xs font-normal leading-none max-w-full flex-initial">
                          {item.title}
                        </div>
                        {!disabled && (
                          <div className="flex flex-auto flex-row-reverse">
                            <button
                              type="button"
                              disabled={disabled}
                              data-tag={selectedOption}
                              onClick={(e) =>
                                (!e.currentTarget.getAttribute('data-tag')
                                  ? api
                                      .post(`/v2/subject-link`, {
                                        subjectId: item.id,
                                        mentorId: id,
                                      })
                                      .then(() => {
                                        alert(
                                          'The subject has been added successfully.'
                                        );
                                      })
                                  : api
                                      .delete(
                                        `/v2/subject-link/${selectedOption?.id}`
                                      )
                                      .then(() => {
                                        alert(
                                          'The subject has been deleted successfully.'
                                        );
                                      })
                                ).then(() => onToggle())
                              }
                            >
                              <Icon.CloseX className="feather feather-x cursor-pointer hover:text-brand-blue rounded-full w-4 h-4 ml-2" />
                            </button>
                          </div>
                        )}
                      </div>
                    )
                  );
                })}

                <div className="flex-1">
                  <input
                    disabled={disabled}
                    onClick={() => setIsDropdownOpen(true)}
                    placeholder={placeholder}
                    className="text-xs bg-transparent p-1 px-2 appearance-none outline-none h-full w-full text-gray-800"
                  ></input>
                </div>
              </div>
              <div className="text-gray-300 w-8 py-1 pl-2 pr-1 border-l flex items-center border-gray-200 ">
                <button
                  type="button"
                  ref={buttonRef}
                  onClick={() => setIsDropdownOpen(!isDropdownOpen)}
                  className="cursor-pointer w-6 h-6 text-gray-600 outline-none focus:outline-none"
                >
                  <Icon.ArrowUp
                    className={`ease-in feather feather-chevron-up w-4 h-4 ${
                      !isDropdownOpen && 'rotate-180'
                    }`}
                  />
                </button>
              </div>
            </div>
          </div>
          <div
            ref={refDropdown}
            className={`${
              isDropdownOpen ? 'absolute' : 'hidden'
            }  shadow top-[100%] bg-white z-40 w-full l-0 rounded max-h-[300px] overflow-y-auto`}
          >
            <div className="flex flex-col w-full">
              {options?.map((option, index) => {
                const selectedOption: any = selectedOptions?.find(
                  (selectedOption) => selectedOption.subjectId === option.id
                );
                return (
                  <div
                    key={option.name + index}
                    className={`cursor-pointer w-full border-gray-100 border-b hover:bg-gray-100 ${
                      index === 0 && 'border-t'
                    }`}
                    data-tag={selectedOption}
                    onClick={(e) =>
                      (!e.currentTarget.getAttribute('data-tag')
                        ? api
                            .post(`/v2/subject-link`, {
                              subjectId: option.id,
                              mentorId: id,
                            })
                            .then(() => {
                              alert('The subject has been added successfully.');
                            })
                        : api
                            .delete(`/v2/subject-link/${selectedOption?.id}`)
                            .then(() => {
                              alert(
                                'The subject has been deleted successfully.'
                              );
                            })
                      ).then(() => onToggle())
                    }
                  >
                    <div
                      className={`flex w-full items-center p-2 pl-2  border-l-2 relative ${
                        !!selectedOption && 'border-brand-blue'
                      }`}
                    >
                      <div className="w-full items-center flex">
                        <div className="mx-2 leading-6  ">{option.title}</div>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
