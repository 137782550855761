import React, { FC } from 'react';
import styled from 'styled-components';

export interface HeadingMetaProps {
  text: string;
  className?: string;
}

const StyledH1 = styled.h1`
  position: absolute;
  clip: rect(1px 1px 1px 1px); /* for Internet Explorer */
  clip: rect(1px, 1px, 1px, 1px);
  padding: 0;
  border: 0;
  height: 1px;
  width: 1px;
  overflow: hidden;
`;

export const HiddenHeadingMeta: FC<HeadingMetaProps> = ({ text }) => {
  return <StyledH1>{text}</StyledH1>;
};
