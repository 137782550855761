import { popupState } from '../../plugins/ridge';
import { useHistory, useLocation } from 'react-router-dom';
import { Subscription, User, Role } from '../../types';
import { useEffect, useState } from 'react';

interface LocationState {
  pathname: string;
}

export const useSubscriptionPopup = (
  subscription: Subscription | undefined,
  me: User | undefined
) => {
  const now = new Date();
  const { push } = useHistory();
  const location = useLocation<LocationState>();
  const [from, setFrom] = useState<string>(location.pathname || '/');

  useEffect(() => {
    // 로그인 전 페이지 Props 로 전송하기위한 setState
    setFrom(location.pathname || '/');
  }, [location, from]);

  const showSubsPopup = () =>
    popupState.set({
      icon: false,
      title: 'Would you like to subscribe?',
      description:
        'Subscribe today and enjoy unlimited access to all Linkstory+ contents.',
      buttonText: 'Subscribe now',
      onClick: () => {
        !me
          ? push({
              pathname: '/login',
              state: { from: { pathname: from } },
            })
          : me && me.role !== Role.STUDENT
          ? alert('Only student accounts are allowed to subscribe')
          : !subscription ||
            now.toISOString() > subscription?.current_period_end
          ? push('/subscription/payment')
          : console.log();
      },
    });

  const showSubscribedPopup = () =>
    popupState.set({
      icon: false,
      title: 'You are already subscribed!',
      description:
        'Enjoy unlimited access to all Linkstory+ contents anytime, anywhere.',
      onClick: () => {},
    });

  return { showSubsPopup, showSubscribedPopup };
};
