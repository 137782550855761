import React, { FC } from 'react';
import AboutImg01Small from '../../../assets/img/about-img-01@small.jpg';
import AboutImg01Medium from '../../../assets/img/about-img-01@medium.jpg';
import AboutImg04Small from '../../../assets/img/about-img-04@small.jpg';
import AboutImg04Medium from '../../../assets/img/about-img-04@medium.jpg';
import CollegeLogos from '@img/CollegeLogos.png';
import { Button } from '../../../components/Button';
import { Subscription, User } from '../../../types';
import twConfig from '../../../tailwindResolver';
import { Icon } from '../../../components/icons';

export interface sectionEssaysProps {
  authenticated: boolean;
  subscription: Subscription | undefined;
  me: User | undefined;
  width: number;
}

const aboutLinkstoryPlus = [
  {
    cateicon: <Icon.DegreeHat className="w-5 md:w-6 text-brand-orange2" />,
    mainTitle: 'Read Common App essays from\nstudents at the best universities',
    cate: 'ACCEPTED ESSAYS',
    title: 'Everyone has a story, but how will you tell yours?',
    desc: 'It is challenging to fit your life into 650 words—get inspired by some success stories.',
    btntitle: 'Read a sample',
    link: '/free-essays',
    mainimg: { small: AboutImg04Small, medium: AboutImg04Medium },
    imgdesc: null,
    keyPoints: [
      {
        icon: <Icon.Grading className="h-4 md:h-5 text-brand-orange2" />,
        desc: 'Common Application',
      },
      {
        icon: <Icon.Ballot className="h-4 md:h-5 text-brand-orange2" />,
        desc: 'Supplemental essays',
      },
      {
        icon: <Icon.IconUC className="h-4 md:h-5 text-brand-orange2" />,
        desc: 'Personal Insight Questions',
      },
    ],
  },
  {
    cateicon: <Icon.PlayFilled className="w-5 md:w-6 text-brand-orange2" />,
    mainTitle: 'Learn from video lessons \nat your own pace',
    cate: 'VIDEO LESSONS',
    title: 'Watch video lessons that \ngo beyond the numbers',
    desc: 'How to start your own non-profit, search for local scholarships, secure internships—hear the stories behind impressive GPAs and test scores.',
    btntitle: 'Browse videos',
    link: '/vods',
    mainimg: { small: AboutImg01Small, medium: AboutImg01Medium },
    keyPoints: [
      {
        icon: <Icon.FactCheck className="h-4 md:h-5 text-brand-orange2" />,
        desc: 'Common Application',
      },
      {
        icon: <Icon.Groups className="h-4 md:h-5 text-brand-orange2" />,
        desc: 'Career and networking',
      },
      {
        icon: <Icon.HistoryEdu className="h-4 md:h-5 text-brand-orange2" />,
        desc: 'Scholarships',
      },
      {
        icon: <Icon.Sports className="h-4 md:h-5 text-brand-orange2" />,
        desc: 'Extracurriculars',
      },
    ],
  },
];

const keyPoints = [
  { title: 'Up-to-date information' },
  { title: 'No credit card required' },
  { title: 'New content every week' },
];

const pictureSection = (item: any, index: number) => {
  return (
    <div
      className={`block text-xs md:text-base text-right space-y-1 md:col-span-5 ${
        index !== 0 && 'md:col-start-6'
      }`}
    >
      <picture className="">
        <source media={'(max-width:400px)'} srcSet={item.mainimg.small} />
        <source media="(min-width:401px)" srcSet={item.mainimg.medium} />
        <img
          src={item.mainimg.small}
          alt="lading-img"
          style={{ minWidth: '-webkit-fill-available' }}
        />
      </picture>
    </div>
  );
};

const descriptionSection = (item: any, index: number) => {
  return (
    <div
      className={`space-y-4 md:space-y-8 md:col-span-4 md:max-w-[400px] ${
        index === 0 && 'md:col-start-7'
      }`}
    >
      <h2 className="text-[1.5rem] leading-[2rem] md:text-2xl md:leading-[2rem] font-semibold text-white md:whitespace-pre-line">
        {item.title}
      </h2>
      <p className=" text-base md:text-lg text-white text-opacity-70 leading-7 whitespace-pre-line">
        {item.desc}
      </p>
      <Button
        className="border-2 border-white text-white rounded-full h-10 md:h-12 px-5 md:px-8 text-sm"
        to={item.link}
      >
        {item.btntitle}
      </Button>
    </div>
  );
};

export const SectionEssays: FC<sectionEssaysProps> = ({
  authenticated,
  subscription,
  me,
  width,
}) => {
  return (
    <section className=" bg-brand-black-bg w-full md:pt-12 pb-10 space-y-14 md:space-y-20 flex flex-col items-center">
      <div className="hidden md:block space-y-4 md:space-y-10">
        <div className="max-w-container space-y-12 text-center">
          <h4 className="font-bold text-white text-2xl">
            Lesson videos and essays by 100 students from 40+ universities
          </h4>
          <img src={CollegeLogos} alt="college logos" className="m-auto" />
          <div className="text-md text-gray-300 text-opacity-1 mt-4 flex flex-row gap-6 justify-center">
            {keyPoints.map((point: any) => {
              return (
                <div className="flex flex-row gap-1">
                  <Icon.Check2 className="mt-1 w-8 text-brand-orange2" />
                  <span className="font-semibold">{point.title}</span>
                </div>
              );
            })}
          </div>
        </div>
      </div>
      {aboutLinkstoryPlus.map((item: any, index: any) => {
        return (
          <>
            <div className="max-w-container flex flex-col items-center text-white space-y-12">
              <h1 className="font-bold text-left w-full text-[2rem] leading-[2.5rem] md:text-5xl md:leading-[4rem] md:whitespace-pre-line">
                {item.mainTitle}
              </h1>
              <div className="hidden md:block w-full ">
                <div data-aos="fade-in" className="w-full flex space-x-12">
                  {item.keyPoints.map((point: any) => {
                    return (
                      <div className="flex justify-start">
                        <div className="flex items-center  space-x-3">
                          {point.icon}
                          <p className="text-white whitespace-nowrap">
                            {point.desc}
                          </p>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
              <div
                key={`homeAboutLink_${index}`}
                data-aos="fade-in"
                className="w-full grid grid-cols-1 md:grid-cols-10 items-center gap-5 md:gap-6"
              >
                {index === 0 || width < parseInt(twConfig.theme.screens.md) ? (
                  <>
                    {pictureSection(item, index)}
                    {descriptionSection(item, index)}
                  </>
                ) : (
                  <>
                    {descriptionSection(item, index)}
                    {pictureSection(item, index)}
                  </>
                )}
              </div>
            </div>
          </>
        );
      })}
    </section>
  );
};
