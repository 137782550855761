import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { useQuery } from 'react-query';
import { Button } from '@components/Button';
import { Select } from '@components/Select';
import { TextField } from '@components/TextField';
import { api } from '@plugins/axios';
import { fetcher } from '@plugins/react-query';
import { TagType, User } from '@src/types';
import { resizeImage } from '@plugins/compress';
import { Label } from '@components/Label';
import { Checkbox } from '@components/Checkbox';
import { AvatarInput } from '@components/AvatarInput';
import { getS3Uri } from '@src/utils';
import { ErrorMessage } from '@hookform/error-message';
import { patchMailChimpUpdateMember } from '@src/api/mailchimp';
import moment from 'moment';
import { HiddenHeadingMeta } from '@src/components/HiddenHeadingMeta';
import { ReactHelmet } from '@src/components/ReactHelmet';
import mixpanel from 'mixpanel-browser';

interface FormValues {
  firstName: string;
  lastName: string;
  dob?: string;
  school?: number;
  major?: number;
  sat?: number;
  gpa?: number;
}

export const EditMyProfilePage = () => {
  const [year, setYear] = useState('');
  const [month, setMonth] = useState('');
  const [day, setDay] = useState('');
  // const [marketing, setMarketing] = useState(null);
  // const [marketingAgree, setMarketingAgree] = useState<boolean>(false);
  const {
    setValue,
    register,
    handleSubmit,
    setError,
    clearErrors,
    trigger,
    formState: { errors, isDirty, isValid },
  } = useForm<FormValues>({
    mode: 'onChange',
  });

  const { data: me, refetch } = useQuery<User>('/users/me', fetcher);
  // const { data: schoolTags } = useQuery<Tag[]>(
  //   `/tags?type=${TagType.SCHOOL}`,
  //   fetcher
  // );
  // const { data: majorTags } = useQuery<Tag[]>(
  //   `/tags?type=${TagType.MAJOR}`,
  //   fetcher
  // );

  useEffect(() => {
    if (!me) return;
    api.get(`/tag-links/my`).then(({ data }) => {
      const schoolTag = data.find(
        (tagLink: any) => tagLink.tag.type === TagType.SCHOOL
      );
      const majorTag = data.find(
        (tagLink: any) => tagLink.tag.type === TagType.MAJOR
      );
      if (schoolTag) {
        setValue('school', schoolTag?.tagId);
      }
      if (majorTag) {
        setValue('major', majorTag?.tagId);
      }
    });

    // api
    //   .get(`/marketing-agreements/`)
    //   .then(({ data }) => {
    //     const marketing = data;
    //     // console.log('마케팅 수신 동의', marketing);
    //     setMarketing(marketing);
    //     setMarketingAgree(marketing.agreement);
    //   })
    //   .catch((err) => {
    //     throw console.log(err);
    //   });

    const thisMonth =
      me?.dob?.substring(4, 5) === '0'
        ? me?.dob?.substring(4, 6).split('0')[1]
        : me?.dob?.substring(4, 6);
    // me?.dob?.substring(4, 6);

    const thisDay =
      me?.dob?.substring(6, 7) === '0'
        ? me?.dob?.substring(6, 8).split('0')[1]
        : me?.dob?.substring(6, 8);
    // me?.dob?.substring(6, 8);

    setValue('firstName', me.firstName);
    setValue('lastName', me.lastName);
    setValue('dob', me.dob);
    setValue('sat', me.sat);
    setValue('gpa', me.gpa);
    setYear(me?.dob?.substring(0, 4));
    setMonth(thisMonth);
    setDay(thisDay);
    trigger();
  }, [me, setValue]);
  const y = year;
  const m = month?.length === 1 ? `0${month}` : month;
  const d = day?.length === 1 ? `0${day}` : day;

  const getAge = () => {
    const today = new Date();
    const dob: string = y + m + d;
    const year = +dob.substring(0, 4);
    const month = +dob.substring(4, 6);
    const day = +dob.substring(6, 8);
    const birthDate = new Date(year, month - 1, day);

    let age = today.getFullYear() - birthDate.getFullYear();
    const calcMonth = today.getMonth() - birthDate.getMonth();
    if (
      calcMonth < 0 ||
      (calcMonth === 0 && today.getDate() < birthDate.getDate())
    ) {
      age--;
    }
    console.log(age);
    return age;
  };

  const getBirthDay = () => {
    const dob: string = y + m + d;
    const year = +dob.substring(0, 4);
    const month = +dob.substring(4, 6);
    const day = +dob.substring(6, 8);
    const birthDate = moment(new Date(year, month - 1, day)).format(
      'YYYY-MM-DD'
    );
    return birthDate;
  };

  // 13세 이상인지 확인 후 아닐 경우 에러 반환
  useEffect(() => {
    if (y && m && d && getAge() < 13) {
      setError('dob', {
        type: 'manual',
        message: 'You must be at least 13 years old to use Linkstory',
      });
    } else {
      clearErrors(['dob']);
    }
  }, [y, m, d]);

  const maxOffset = 70;
  const thisYear = new Date().getFullYear();
  const allYears = [];
  for (let x = 0; x <= maxOffset; x++) {
    allYears.push(thisYear - x);
  }

  const yearList = allYears.map((y) => (
    <option key={y} value={y}>
      {y}
    </option>
  ));

  const monthList = Array.from({ length: 12 }, (_, i) => i + 1).map((m) => (
    <option key={m} value={m}>
      {m}
    </option>
  ));

  const days = new Date(Number(year), Number(month), 0).getDate();
  const dayList = Array.from({ length: days }, (_, i) => i + 1).map((d) => (
    <option key={d} value={d}>
      {d}
    </option>
  ));

  const avatarOnchange = async (file: File) => {
    const { data: post } = await api.get(
      `/s3/presignedPost?filename=${file.name}`
    );
    const formData = new FormData();
    Object.entries(post.fields).forEach(([name, value]) =>
      formData.append(name, value as any)
    );
    formData.append('file', await resizeImage(file));

    await axios.post(post.url, formData, {
      headers: { 'Content-Type': 'multipart/form-data' },
    });
    await api.patch('/users/me', { avatar: post.fields.key });
    refetch();
  };

  return (
    <>
      <HiddenHeadingMeta text="Account | Linkstory" />
      <ReactHelmet keywords="account" title="Account | Linkstory" />
      <div className="max-w-lg mx-auto bg-white rounded-md py-8 px-4 md:px-10">
        <form
          className="md:p-4 flex flex-col space-y-4"
          onSubmit={handleSubmit(async (data) => {
            if (year && m && d) {
              //@ts-ignore
              data.dob = year + m + d;
            }

            await api
              .patch('/users/me', {
                ...data,
              })
              .then(async () => {
                alert('Your profile has been updated.');
                mixpanel.people.set({
                  $first_name: me?.firstName,
                  $last_name: me?.lastName,
                  $email: me?.email,
                  Role: me?.role,
                });
                mixpanel.track('Profile Updated');

                // mixpanel.identify must be called in order to associate the profile properties you set
                // with that user. You only need to call it once per page load for a given user.
                mixpanel.identify(me?.email);

                await patchMailChimpUpdateMember({
                  email_address: me?.email,
                  merge_fields: {
                    FNAME: me?.firstName,
                    LNAME: me?.lastName,
                    ROLE: me?.role,
                    BIRTHDAY: getBirthDay(),
                  },
                });
                // window.location.reload();
              });

            // if (marketing) {
            //   await api
            //     .patch('/marketing-agreements/', {
            //       agreement: marketingAgree,
            //     })
            //     .catch((err) => {
            //       throw console.log(err);
            //     });
            // } else {
            //   await api
            //     .post(`/marketing-agreements/`, {
            //       agreement: marketingAgree,
            //     })
            //     .catch((err) => {
            //       throw console.log(err);
            //     });
            // }
          })}
        >
          <AvatarInput
            onChange={(file) => avatarOnchange(file)}
            avatarUrl={
              me?.avatar.startsWith('https') ? me?.avatar : getS3Uri(me?.avatar)
            }
          />
          <text className="text-center text-sm text-gray-600">
            Please upload a profile picture (.jpg or .png)
          </text>
          {me?.googleId && (
            <div className="grid grid-1 place-items-center w-full">
              <text>SignUp With Google</text>
            </div>
          )}
          {me?.facebookId && (
            <div className="grid grid-1 place-items-center w-full">
              <text>SignUp With Facebook</text>
            </div>
          )}
          <TextField
            label="First name"
            placeholder="Please enter your firstName."
            helper={errors.firstName?.message}
            {...register('firstName', {
              required: 'Please enter your firstName.',
              validate: {
                alphabetWithSpace: (value) =>
                  (value && /^[a-zA-Z ]*$/.test(value)) ||
                  'Only alphabets and spaces are allowed.',
              },
            })}
            disabled
          />
          <TextField
            label="Last name"
            placeholder="Please enter your lastName."
            helper={errors.firstName?.message}
            {...register('lastName', {
              required: 'Please enter your lastName.',
              validate: {
                alphabetWithSpacesAndPunctuation: (value) =>
                  (value && /^[a-zA-Z .]*$/.test(value)) ||
                  'Only alphabets, spaces and periods are allowed.',
              },
            })}
            disabled
          />

          <TextField label="Email" value={me?.email} disabled />

          <div>
            <Label text="Date of birth" />
            <div className="flex space-x-3.5">
              <div className="w-full">
                <Select
                  // name="year"
                  placeholder="Year"
                  value={year}
                  onChange={(e) => setYear(e.target.value)}
                >
                  <option value="" selected disabled hidden>
                    YYYY
                  </option>
                  {yearList}
                </Select>
              </div>
              <div className="w-full">
                <Select
                  placeholder="Month"
                  value={month}
                  onChange={(e) => setMonth(e.target.value)}
                >
                  <option value="" selected disabled hidden>
                    MM
                  </option>
                  {monthList}
                </Select>
              </div>
              <div className="w-full">
                <Select
                  placeholder="Day"
                  value={day}
                  onChange={(e) => setDay(e.target.value)}
                >
                  <option value="" selected disabled hidden>
                    DD
                  </option>
                  {dayList}
                </Select>
              </div>
            </div>
          </div>
          <ErrorMessage
            errors={errors}
            name="dob"
            render={({ message }) => (
              <p className="!mt-1 text-sm text-error">{message}</p>
            )}
          />
          <div className="flex flex-col space-y-4 pt-8">
            <Button
              disabled={getAge() < 13}
              text="Save"
              className="filled-brand-1 rounded-full"
            />
          </div>
        </form>
      </div>
    </>
  );
};
