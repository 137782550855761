import { icon } from '@fortawesome/fontawesome-svg-core';
import React from 'react';
import { useQuery } from 'react-query';
import {
  Link,
  Redirect,
  Route,
  Switch,
  useHistory,
  useLocation,
} from 'react-router-dom';
import { Tab } from '@components/Tab';
import { fetcher } from '@plugins/react-query';
import { Role, User } from '@src/types';
import { EditMyProfilePage } from './EditMyProfilePage';
import { EditMyProfilePage as EditMentorProfilePage } from '@src/pages/mentor/EditMyProfilePage';
import { MyCreditCardPage } from './MyCreditCardPage';
import { UserIcon } from '@heroicons/react/20/solid';
import { CreditCardIcon } from '@heroicons/react/20/solid';
import { BreadCrumbs } from '@src/components/BreadCrumbs';
import { HiddenHeadingMeta } from '@src/components/HiddenHeadingMeta';
import { ReactHelmet } from '@src/components/ReactHelmet';
export const MyPage = () => {
  const { pathname } = useLocation();
  const { push } = useHistory();

  const { data: me } = useQuery<User>('/users/me', fetcher);

  const RoutesItems = [
    {
      name: 'My Account',
      path: '/my/dashboard/account/profile',
      icon: UserIcon,
      component: EditMyProfilePage,
      authorized: [Role.STUDENT],
    },
    {
      name: 'My Account',
      path: '/my/dashboard/account/profile',
      icon: UserIcon,
      component: EditMentorProfilePage,
      authorized: [Role.MENTOR],
    },
    {
      name: 'Payment Method',
      path: '/my/dashboard/account/payment-method',
      component: MyCreditCardPage,
      icon: CreditCardIcon,
      authorized: [Role.STUDENT],
    },
  ];

  function classNames(...classes: string[]) {
    return classes.filter(Boolean).join(' ');
  }

  if (!me) return <></>;

  const TabsSection = () => {
    return (
      <div>
        <div className="sm:hidden">
          <label htmlFor="tabs" className="sr-only">
            Select a tab
          </label>
          <select
            id="tabs"
            name="tabs"
            className="block w-full rounded-md border-gray-300 focus:border-indigo-500 focus:ring-indigo-500"
            defaultValue={pathname}
            onChange={(e) => push(e.target.value)}
          >
            {RoutesItems.filter((item) =>
              item.authorized.includes(me.role)
            ).map((tab) => (
              <option key={tab.name} value={tab.path}>
                {tab.name}
              </option>
            ))}
          </select>
        </div>
        <div className="hidden sm:block">
          <nav
            className="isolate flex divide-x divide-gray-200 rounded-lg shadow overflow-hidden"
            aria-label="Tabs"
          >
            {RoutesItems.filter((item) =>
              item.authorized.includes(me.role)
            ).map((tab, tabIdx) => (
              <Link
                key={tab.name}
                to={tab.path}
                className={classNames(
                  tab.path === pathname
                    ? 'text-gray-900'
                    : 'text-gray-500 hover:text-gray-700',
                  tabIdx === 0 ? 'rounded-l-lg' : '',
                  tabIdx === RoutesItems.length - 1 ? 'rounded-r-lg' : '',
                  'group relative min-w-0 flex-1 overflow-hidden bg-white py-4 px-4 text-sm font-medium text-center hover:bg-gray-50 focus:z-10'
                )}
                aria-current={tab.path === pathname ? 'page' : undefined}
              >
                <span>{tab.name}</span>
                <span
                  aria-hidden="true"
                  className={classNames(
                    tab.path === pathname ? 'bg-brand-blue' : 'bg-transparent',
                    'absolute inset-x-0 bottom-0 h-0.5'
                  )}
                />
              </Link>
            ))}
          </nav>
        </div>
      </div>
    );
  };

  return (
    <>
      <HiddenHeadingMeta text="Mentor Dashboard | Linkstory" />
      <ReactHelmet
        keywords="mentor, dashboard"
        title="Mentor Dashboard | Linkstory"
      />
      <BreadCrumbs
        homePath="/my/dashboard"
        breadCrumbsPages={[
          {
            name: 'Account',
            path: '/my/dashboard/account/profile',
            hide: pathname !== '/my/dashboard/account/profile',
          },
          {
            name: 'Payment Method',
            path: '/my/dashboard/account/payment-method',
            hide: pathname !== '/my/dashboard/account/payment-method',
          },
        ]}
      />
      <div className="mx-auto w-full pt-4 flex flex-col max-w-lg">
        {TabsSection()}
      </div>

      <Switch>
        {RoutesItems.filter((item) => item.authorized.includes(me.role)).map(
          (item) => {
            return <Route path={item.path} component={item.component} />;
          }
        )}
        <Route path="/my/dashboard/account">
          <Redirect to="/my/dashboard/account/profile" />
        </Route>
      </Switch>
    </>
  );
};
