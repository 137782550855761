import { FC } from 'react';
import Marquee from 'react-fast-marquee';
import { useWindowWidth } from '@react-hook/window-size';

import Logo01 from '../assets/img/College Logos/Berkeley.png';
import Logo02 from '../assets/img/College Logos/Brown.png';
import Logo03 from '../assets/img/College Logos/Columbia.png';
import Logo04 from '../assets/img/College Logos/Cornell.png';
import Logo05 from '../assets/img/College Logos/Dartmouth.png';
import Logo06 from '../assets/img/College Logos/Duke.png';
import Logo07 from '../assets/img/College Logos/Harvard.png';
import Logo08 from '../assets/img/College Logos/MIT.png';
import Logo09 from '../assets/img/College Logos/Northwestern.png';
import Logo10 from '../assets/img/College Logos/Princeton.png';
import Logo11 from '../assets/img/College Logos/Stanford.png';
import Logo12 from '../assets/img/College Logos/UCLA.png';
import Logo13 from '../assets/img/College Logos/Upenn.png';
import Logo14 from '../assets/img/College Logos/USC.png';
import Logo15 from '../assets/img/College Logos/Washu.png';
import Logo16 from '../assets/img/College Logos/Yale.png';

interface CollegeLogosMarqueeProps {
  isDarkTheme?: boolean;
  title?: string;
  withContainer?: boolean;
  bgColor?: string;
}

export const CollegeLogosMarquee: FC<CollegeLogosMarqueeProps> = ({
  isDarkTheme,
  title,
  withContainer,
  bgColor,
}) => {
  const width = useWindowWidth();

  const lightLogos = [
    Logo01,
    Logo02,
    Logo03,
    Logo04,
    Logo05,
    Logo06,
    Logo07,
    Logo08,
    Logo09,
    Logo10,
    Logo11,
    Logo12,
    Logo13,
    Logo14,
    Logo15,
    Logo16,
  ];

  const darkLogos = [
    Logo01,
    Logo02,
    Logo03,
    Logo04,
    Logo05,
    Logo06,
    Logo07,
    Logo08,
    Logo09,
    Logo10,
    Logo11,
    Logo12,
    Logo13,
    Logo14,
    Logo15,
    Logo16,
  ];

  const hexToRgb = (hex: string): [number, number, number] => {
    const aRgbHex = hex.match(/.{1,2}/g);
    const aRgb: [number, number, number] = aRgbHex
      ? [
          parseInt(aRgbHex[0], 16),
          parseInt(aRgbHex[1], 16),
          parseInt(aRgbHex[2], 16),
        ]
      : [0, 0, 0];
    return aRgb;
  };

  const renderImg = (item: string, ind: number) => {
    return (
      <img
        src={item}
        alt=""
        className="max-h-8 md:max-h-16 h-full py-1 px-6 md:py-4 md:px-10"
        key={ind}
      />
    );
  };

  return (
    <div
      // data-aos="fade-in"
      className={`relative w-full flex flex-col items-center h-210 mx-auto ${
        isDarkTheme ? 'bg-brand-black-bg' : 'bg-gray-100'
      } `}
      style={bgColor ? { backgroundColor: `#${bgColor}` } : {}}
    >
      <div
        className={`${
          withContainer ? 'max-w-container' : 'w-full'
        } flex flex-col pb-4 pt-4 md:pb-6 md:pt-6 `}
      >
        {title && (
          <div className="max-w-container text-white ">
            <h1 className="text-xl md:text-3xl text-center py-10">{title}</h1>
          </div>
        )}

        <div className="w-full">
          <Marquee
            loop={0}
            // pauseOnHover={true}
            // gradient={false}
            gradientWidth={width <= 680 ? 20 : 100}
            gradientColor={
              isDarkTheme
                ? [16, 17, 20]
                : bgColor
                ? hexToRgb(bgColor)
                : [243, 243, 243]
            }
          >
            {/* testing code https://www.npmjs.com/package/react-fast-marquee */}
            {isDarkTheme
              ? darkLogos.map((item, ind) => {
                  return renderImg(item, ind);
                })
              : lightLogos.map((item, ind) => {
                  return renderImg(item, ind);
                })}
          </Marquee>
        </div>
      </div>
      <svg
        viewBox="0 0 384 12"
        fill="none"
        aria-hidden="true"
        className="absolute top-full -right-10 md:right-20 w-[384px] max-w-[120%] transition"
      >
        <mask
          id=":r1:-a"
          maskUnits="userSpaceOnUse"
          x="48"
          y="0"
          width="269"
          height="4"
          style={{ maskType: 'alpha' }}
        >
          <path
            transform="rotate(180 316.656 4)"
            fill="#C4C4C4"
            d="M316.656 4h268v4h-268z"
          ></path>
        </mask>
        <g filter="url(#:r1:-b)" mask="url(#:r1:-a)">
          <path
            transform="rotate(180 292.656 1)"
            fill="url(#:r1:-c)"
            d="M292.656 1h220v2h-220z"
          ></path>
        </g>
        <mask
          id=":r1:-d"
          maskUnits="userSpaceOnUse"
          x="116"
          y="0"
          width="268"
          height="12"
          style={{ maskType: 'alpha' }}
        >
          <path
            transform="rotate(180 384 12)"
            fill="#C4C4C4"
            d="M384 12h268v12H384z"
          ></path>
        </mask>
        <g filter="url(#:r1:-e)" mask="url(#:r1:-d)">
          <path
            transform="rotate(180 360 1)"
            fill="url(#:r1:-f)"
            d="M360 1h220v2H360z"
          ></path>
        </g>
        <defs>
          <linearGradient
            id=":r1:-c"
            x1="292.656"
            y1="1"
            x2="512.656"
            y2="1"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="#A78BFA" stop-opacity="0"></stop>
            <stop offset=".323" stop-color="#A78BFA"></stop>
            <stop offset=".672" stop-color="#EC4899" stop-opacity=".3"></stop>
            <stop offset="1" stop-color="#EC4899" stop-opacity="0"></stop>
          </linearGradient>
          <linearGradient
            id=":r1:-f"
            x1="360"
            y1="1"
            x2="580"
            y2="1"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="#A78BFA" stop-opacity="0"></stop>
            <stop offset=".323" stop-color="#A78BFA"></stop>
            <stop offset=".672" stop-color="#EC4899" stop-opacity=".3"></stop>
            <stop offset="1" stop-color="#EC4899" stop-opacity="0"></stop>
          </linearGradient>
          <filter
            id=":r1:-b"
            x="71.656"
            y="-2"
            width="222"
            height="4"
            filterUnits="userSpaceOnUse"
            color-interpolation-filters="sRGB"
          >
            <feFlood flood-opacity="0" result="BackgroundImageFix"></feFlood>
            <feBlend
              in="SourceGraphic"
              in2="BackgroundImageFix"
              result="shape"
            ></feBlend>
            <feGaussianBlur
              stdDeviation=".5"
              result="effect1_foregroundBlur_311_43467"
            ></feGaussianBlur>
          </filter>
          <filter
            id=":r1:-e"
            x="131"
            y="-10"
            width="238"
            height="20"
            filterUnits="userSpaceOnUse"
            color-interpolation-filters="sRGB"
          >
            <feFlood flood-opacity="0" result="BackgroundImageFix"></feFlood>
            <feBlend
              in="SourceGraphic"
              in2="BackgroundImageFix"
              result="shape"
            ></feBlend>
            <feGaussianBlur
              stdDeviation="4.5"
              result="effect1_foregroundBlur_311_43467"
            ></feGaussianBlur>
          </filter>
        </defs>
      </svg>
    </div>
  );
};
