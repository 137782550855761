import React from 'react';
import { useHistory } from 'react-router-dom';
import { Player, Controls } from '@lottiefiles/react-lottie-player';

export const Error404 = () => {
  const { push } = useHistory();

  return (
    <section className="py-8 px-4 text-center">
      <div className="max-w-auto md:max-w-lg mx-auto">
        <div className="flex flex-col items-center my-12 md:my-20">
          <Player
            autoplay
            loop
            src="https://assets5.lottiefiles.com/packages/lf20_kcsr6fcp.json"
            className="max-w-[600px]"
          >
            <Controls
              visible={false}
              buttons={['play', 'repeat', 'frame', 'debug']}
            />
          </Player>
          <div className="font-bold text-3xl xl:text-7xl lg:text-6xl md:text-5xl">
            This page does not exist
          </div>
          <div className="text-gray-400 font-medium text-sm md:text-xl lg:text-2xl mt-8">
            The page you are looking for could not be found.
          </div>
          <button
            type="button"
            className="flex items-center rounded-lg bg-brand-1 px-4 py-2 text-white mt-8"
            onClick={() => push('/')}
          >
            <span className="font-medium subpixel-antialiased">
              Back To Homepage
            </span>
          </button>
        </div>
      </div>
    </section>
  );
};
