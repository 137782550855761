import { Redirect, Route, Switch, useLocation, Link } from 'react-router-dom';
import { ChatPage } from './chat/ChatPage';
import styled from 'styled-components';

export const MyLessonsPage = () => {
  return (
    <>
      <div className="space-y-4 bg-gray-50 flex-1 flex">
        <StyledWrapper className="flex-1 md:max-w-container mx-auto md:px-4 md:pt-4 flex lg:space-x-4">
          <div className="w-full">
            <Switch>
              <Route path="/my/chats" component={ChatPage} />
              <Route path="/my">
                <Redirect to="/my/chats" />
              </Route>
            </Switch>
          </div>
        </StyledWrapper>
      </div>
    </>
  );
};

const StyledWrapper = styled.div`
  @media (min-width: 768px) {
    max-height: calc(100vh - 65px);
    min-height: calc(100vh - 65px);
  }
`;
