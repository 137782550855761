import React, { FC } from 'react';
import { WebibarItemType } from '@src/types';
import { MomentFormat, utcToLocalFormat } from '@plugins/moment';
import { Link } from 'react-router-dom';
import moment from 'moment-timezone';

export interface WebinarFeaturedCardProps {
  item: WebibarItemType;
}

export const WebinarFeaturedCard: FC<WebinarFeaturedCardProps> = ({ item }) => {
  return (
    <main className="py-8 lg:py-12 mx-auto sm:max-w-xl md:max-w-full ">
      <div className="flex flex-col overflow-hidden bg-white border rounded-lg shadow-sm lg:flex-row sm:mx-auto">
        {/* image */}
        <article className="relative lg:w-1/2">
          <Link
            to={{
              pathname: '/webinar/' + item.id,
              search: `?tutorId=${item.tutorId}`,
            }}
          >
            <img
              src={item.webinar.img}
              alt="Persons talking in a work setting."
              className="object-cover w-full lg:absolute lg:h-full"
            />
          </Link>
        </article>

        {/* content */}
        <article className="flex flex-col justify-center p-6 bg-white lg:p-16 lg:pl-10 lg:w-1/2 gap-4 lg:gap-6">
          <h5 className="text-lg lg:text-3xl font-bold leading-none ">
            <Link
              to={{
                pathname: '/webinar/' + item.id,
                search: `?tutorId=${item.tutorId}`,
              }}
            >
              {item.webinar.title}
            </Link>
          </h5>
          <p className=" text-gray-800 truncate-3-lines">
            {item.webinar.summary}
          </p>
          <div className="flex flex-wrap gap-4">
            <p className="text-[#7783A8]">
              {utcToLocalFormat(item.webinar.date, MomentFormat.ddddLLHmma)}{' '}
              {moment.tz(moment.tz.guess()).zoneAbbr()}
            </p>
            <p className="inline-block px-3 py-1 text-xs font-medium tracking-wider text-brand-blue bg-[#E0E7FF] rounded-md">
              {item.webinar.duration}
            </p>
          </div>
          <div className="flex items-center">
            <Link
              to={{
                pathname: '/webinar/' + item.id,
                search: `?tutorId=${item.tutorId}`,
              }}
              className="inline-flex items-center justify-center h-12 px-10 mr-6 font-medium tracking-wide text-white transition duration-200 bg-brand-blue rounded-full hover:bg-brightness-110 focus:shadow-outline focus:outline-none"
            >
              Register
            </Link>
          </div>
        </article>
      </div>
    </main>
  );
};
